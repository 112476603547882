import styled, { css } from 'styled-components';

type WrapperGraphType = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  isClickedInOcult: boolean;
  display: string;
};

export const WrapperGraphComponent = styled.div<WrapperGraphType>`
  ${({ theme, isClickedInOcult, display }) => css`
    width: 35.5vw;
    padding: ${theme.spacings[20]};
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacings[6]};
    animation-name: ${display === 'none' && isClickedInOcult && 'ocult'};
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-duration: 1s;
    display: ${display === 'none' && !isClickedInOcult && 'none'};

    @keyframes ocult {
      0% {
        transform: scale3d(1, 1, 1);
      }
      100% {
        transform: scale3d(0, 0, 0);
        display: none;
      }
    }

    .top {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .left {
      width: 85%;
      height: max-content;
      display: flex;
      align-items: center;
      margin-top: -1rem;
    }

    .WrapperdraggingButton {
      width: max-content;
      height: max-content;
    }

    .dragging-button {
      width: 1.5rem;
      height: 1rem;
      cursor: pointer;
    }

    .firstP {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      color: ${theme.colors.black};
      font-weight: ${theme.font.weights.regular};
      font-family: ${theme.font.family.IbmPlexSans};
      font-size: ${theme.font.sizes.lg};
      margin-left: ${theme.spacings[10]};
    }

    .graphComponentWrapper {
      width: 32vw;
      height: max-content;
      margin-top: ${theme.spacings[12]};
      margin-bottom: ${theme.spacings[12]};

      div {
        margin: 0 auto;
      }
    }

    .font {
      margin: 0 auto;
      width: 500px;
      max-width: 32vw;
      color: ${theme.colors.black};
      text-align: center;
      font-size: 1.1rem;
    }
  `}
`;
