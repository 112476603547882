import React from 'react';

interface IProperties {
  properties: {
    nome: string;
    navegacao: string;
    tipo: string;
  };
}

export function Hidrovias({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Hidrovias</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Navegação: </strong>{' '}
        {properties.navegacao ? properties.navegacao : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>{' '}
        {properties.tipo ? properties.tipo : 'Não informado'}
      </p>
    </div>
  );
}
