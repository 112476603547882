import React, { useEffect, useState } from 'react';
import * as RiIcons from 'react-icons/ri';
import * as FaIcons from 'react-icons/fa';
import { BsFilter } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'components';
import { useMap } from 'hooks/useMap';
import { useOptions } from 'hooks/useOptions';
import ReactTooltip from 'react-tooltip';
import { Filter } from 'components/Filter';
import { Home, Layers, FavoritesLayer, Organization } from './pages';
import linksNav from './linksNav';
import * as Styled from './styles';
import { HiHome } from 'react-icons/hi';
import { DashboardLayer } from './pages/Dashboard';
import { Popup } from 'components/Popup';
import { ISubTheme } from 'contexts/@types/Layers';
import PrintMap from './pages/PrintMap';

interface ISideBar {
  handleSearchInputActive: () => void;
  onLoadingCompleted: () => void;
  onBaseMapModalOpen: () => void;
}

export function SideBar({ handleSearchInputActive, onLoadingCompleted, onBaseMapModalOpen }: ISideBar) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(1);
  const [title, setTitle] = useState('Início');
  const [iconTitleTab, setIconTitleTab] = useState<React.ReactNode>(<HiHome />);

  const { t: translation, i18n } = useTranslation();
  const { openMap } = useOptions();
  const { failedLoadTile, setFailedLoadTile, handleDownloadPrintMap, handleFitExtent } = useMap();

  const [subThemeOpenedBySubstanciaAgrupadora, setSubThemeOpenedBySubstanciaAgrupadora] = useState<ISubTheme | null>(null);

  const handleSubThemeOpenedBySubstanciaAgrupadora = (substanciaAgrupadora: ISubTheme) =>
    setSubThemeOpenedBySubstanciaAgrupadora(substanciaAgrupadora);

  const [dataBreadcrumb, setDataBreadcrumb] = useState<{ label: string; action?: () => void }[]>([
    {
      label: translation('mapPage.sideBar.navigationBottomBar.home'),
      action: () => handleChangeTabIndex(1, 'Início', <HiHome />),
    },
  ]);

  const addBreadcrumb = (itemAddBreadcrumb: { label: string; action?: () => void }, subBreadcrumbs?: string[]) => {
    const labelsBreadcrumb = dataBreadcrumb.map(({ label }) => label);

    const hasSubBreadcrumbs = subBreadcrumbs ? subBreadcrumbs.map(label => ({ label })) : [];

    if (labelsBreadcrumb.includes(itemAddBreadcrumb.label)) {
      const index = labelsBreadcrumb.indexOf(itemAddBreadcrumb.label);
      const newBreadcrumb = dataBreadcrumb.slice(0, index + 1);
      setDataBreadcrumb([...newBreadcrumb, ...hasSubBreadcrumbs]);
    } else {
      setDataBreadcrumb([...dataBreadcrumb, itemAddBreadcrumb, ...hasSubBreadcrumbs]);
    }
  };

  const handleChangeTabIndex = (index: number, text: string, icon: React.ReactNode, subBreadcrumbs?: string[]) => {
    setIsActive(true);
    setTabIndex(index);
    setTitle(translation(text.split('-')[0]));
    setIconTitleTab(icon);

    addBreadcrumb(
      {
        label: translation(text),
        action: () => handleChangeTabIndex(index, text, icon),
      },
      subBreadcrumbs,
    );
  };

  const handlePrintMap = () => {
    handleDownloadPrintMap();
  };

  useEffect(() => {
    if (openMap) {
      setIsActive(true);
    }
  }, [openMap]);

  useEffect(() => {
    if (failedLoadTile) {
      setTimeout(() => setFailedLoadTile(false), 5000);
    }
  }, [failedLoadTile]);

  return (
    <Styled.Wrapper actived={isActive} isEnUS={i18n.language === 'en-US'}>
      {failedLoadTile && (
        <Popup text="Não foi encontrado registro para o(s) filtro(s) informado(s)!" animation={failedLoadTile} />
      )}
      <Styled.Content actived={isActive}>
        <Tabs
          title={title}
          options={linksNav}
          onChange={handleChangeTabIndex}
          actived={tabIndex}
          iconTitle={iconTitleTab}
          breadcrumb={dataBreadcrumb}
          showClearAll={tabIndex === 2}
        >
          <Home
            handleChangeTabIndex={handleChangeTabIndex}
            handleSubThemeOpenedBySubstanciaAgrupadora={handleSubThemeOpenedBySubstanciaAgrupadora}
            onLoadingCompleted={onLoadingCompleted}
          />
          <Layers handleChangeTabIndex={handleChangeTabIndex} />
          <FavoritesLayer handleChangeTabIndex={handleChangeTabIndex} />
          <Organization />
          <Filter
            mapFilter
            applyFilter={async () => {
              handleFitExtent();
            }}
          />
          <DashboardLayer subThemeOpenedBySubstanciaAgrupadora={subThemeOpenedBySubstanciaAgrupadora} />
          {/* 
            <NotFounded pageName="Gráficos" />
            <SubTitle />
            <Information />
            <NotFounded pageName="Download" /> 
          */}
        </Tabs>
      </Styled.Content>
      <Styled.NavigationBar>
        <header>
          <button type="button" className="expandedButton" onClick={() => setIsActive(!isActive)}>
            <RiIcons.RiArrowRightSLine size={38} color="#FFF" />
          </button>
        </header>
        <div className="buttonsBlock">
          <span>
            <button
              type="button"
              className="buttonLink"
              onClick={() => {
                setIsActive(false);
                handleSearchInputActive();
              }}
            >
              <div className="icons-sidebar">
                <RiIcons.RiSearchLine />
              </div>
              <span>{translation('mapPage.sideBar.navigationRightBar.quickSearch')}</span>
            </button>
          </span>
          <span>
            <button
              type="button"
              className="buttonLink"
              onClick={() => {
                handleChangeTabIndex(3, 'Favoritos', <FaIcons.FaRegStar />);
              }}
            >
              <div className="icons-sidebar">
                <FaIcons.FaRegStar />
              </div>
              <span>{translation('mapPage.sideBar.navigationRightBar.favorites')}</span>
            </button>
          </span>
          <span>
            <button
              type="button"
              className="buttonLink"
              onClick={() => {
                handleChangeTabIndex(5, 'Filtro', <BsFilter />);
              }}
            >
              <div className="icons-sidebar">
                <BsFilter />
              </div>
              <span>{translation('mapPage.sideBar.navigationRightBar.filter')}</span>
            </button>
          </span>
          <span data-tip data-for="settingsButton">
            <button type="button" className="buttonLink" disabled>
              <div className="icons-sidebar">
                <RiIcons.RiToolsFill color="#808080" />
              </div>
              <span style={{ color: '#808080' }}>{translation('mapPage.sideBar.navigationRightBar.tools')}</span>
            </button>
          </span>
          <span>
            <button
              type="button"
              className="buttonLink"
              onClick={() => {
                setIsActive(false);
                onBaseMapModalOpen();
              }}
            >
              <div className="icons-sidebar">
                <RiIcons.RiMap2Line />
              </div>
              <span>{translation('mapPage.sideBar.navigationRightBar.settings')}</span>
            </button>
          </span>
          <PrintMap />
          <ReactTooltip id="settingsButton" place="top" type="warning" effect="solid" delayShow={500} delayHide={500}>
            <p>{translation('mapPage.sideBar.navigationRightBar.functionalDev')}</p>
          </ReactTooltip>
        </div>
      </Styled.NavigationBar>
    </Styled.Wrapper>
  );
}
