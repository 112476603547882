import styled from 'styled-components';

export const Wrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  align-items: flex-end;
  position: relative;

  .burger-menu {
    font-size: 1.7rem;
    cursor: pointer;
    object-fit: contain;
    margin-top: -0.7rem;
  }
`;
