import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function findAllStates<T>() {
  const response: AxiosResponse<T> = await api.get(`/estados/`);

  return response;
}

export async function findStateByRegion<T>(region: string) {
  const response: AxiosResponse<T> = await api.get(
    `/estados/?regiao__in=${region}`,
  );

  return response;
}
