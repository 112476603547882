/* eslint-disable react/no-unescaped-entities */
import { interaction } from 'openlayers';
import React from 'react';

interface IProperties {
  properties: {
    poco: string;
    cadastro: number;
    operador: string;
    estado: string;
    bacia: string;
    bloco: string;
    sig_campo: string;
    campo: string;
    terra_mar: string;
    poco_pos_anp: string;
  };
}

export function Pocos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Poços</strong>
      </h4>
      <p>
        <strong>
          Nome do poço para a ANP, definido de acordo com a Resolução ANP nº
          699/2017:{' '}
        </strong>
        {properties.poco ? properties.poco : 'Não informado'}
      </p>
      <p>
        <strong>Cadastro: </strong>
        {properties.cadastro ? properties.cadastro : 'Não informado'}
      </p>
      <p>
        <strong>Operador: </strong>
        {properties.operador ? properties.operador : 'Não informado'}
      </p>
      <p>
        <strong>Estado: </strong>
        {properties.estado ? properties.estado : 'Não informado'}
      </p>
      <p>
        <strong>Nome da bacia sedimentar onde se situa o poço: </strong>
        {properties.bacia ? properties.bacia : 'Não informado'}
      </p>
      <p>
        <strong>
          Nome do bloco exploratório onde se situa o poço (quando aplicável):{' '}
        </strong>
        {properties.bloco ? properties.bloco : 'Não informado'}
      </p>
      <p>
        <strong>Sigla do campo de produção (quando aplicável): </strong>
        {properties.sig_campo ? properties.sig_campo : 'Não informado'}
      </p>
      <p>
        <strong>
          Nome do campo de produção onde se situa o poço (quando aplicável):{' '}
        </strong>
        {properties.campo ? properties.campo : 'Não informado'}
      </p>
      <p>
        <strong>
          Ambiente onde foi feita a perfuração (T: Terra, M: Mar):{' '}
        </strong>
        {properties.terra_mar ? properties.terra_mar : 'Não informado'}
      </p>
      <p>
        <strong>
          Data em relação à ANP ("S": poço posterior à criação da ANP
          ("pós-98"); "N": poço anterior ("pré-98")):{' '}
        </strong>
        {properties.poco_pos_anp ? properties.poco_pos_anp : 'Não informado'}
      </p>
    </div>
  );
}
