import { State } from 'shared/models/State';

export const calcCardProps = (
  baseCalc: State[],
  type: 'state' | 'region',
  region?: string,
) => {
  if (type === 'region') {
    const areaTotalRegion = (
      Number(baseCalc.reduce((a, b) => a + b.area, 0).toFixed(0)) / 1000000
    )
      .toFixed(1)
      .replace('.', ',');

    const populacaoNaoTotalEstimadaRegion = String(
      (baseCalc.reduce((a, b) => a + b.pop_2010, 0) / 1000000).toFixed(1),
    ).replace('.', ',');

    const populacaoTotalEstimadaRegion = String(
      (baseCalc.reduce((a, b) => a + b.popest_2014, 0) / 1000000).toFixed(1),
    ).replace('.', ',');

    const densidadeDemTotal = baseCalc
      .reduce((a, b) => a + b.dens_dem, 0)
      .toFixed(0);

    const numeroMunicipios = baseCalc.reduce((a, b) => a + b.num_mun, 0);

    if (region?.split(', ').length === 1) {
      const arrayCardsForRegion = [
        ['Região (km²):', `${areaTotalRegion} Mi`],
        [
          'População Não Estimada da Região em 2010:',
          `${populacaoNaoTotalEstimadaRegion} Mi`,
        ],
        [
          'População Total Estimada da Região em 2014:',
          `${populacaoTotalEstimadaRegion} Mi`,
        ],
        ['Densidade Demográfica da Região:', `${densidadeDemTotal}`],
        ['Número Total de Municípios da Região:', `${numeroMunicipios}`],
      ];
      return arrayCardsForRegion;
    }

    const arrayCardsForRegion = [
      ['Regiões (km²):', `${areaTotalRegion} Mi`],
      [
        'População Não Estimada das Regiões em 2010:',
        `${populacaoNaoTotalEstimadaRegion} Mi`,
      ],
      [
        'População Total Estimada das Regiões em 2014:',
        `${populacaoTotalEstimadaRegion} Mi`,
      ],
      ['Densidade Demográfica das Regiões:', `${densidadeDemTotal}`],
      ['Número Total de Municípios das Regiões:', `${numeroMunicipios}`],
    ];
    return arrayCardsForRegion;
  }

  const areaTotalState = (
    Number(baseCalc.reduce((a, b) => a + b.area, 0).toFixed(0)) / 1000
  )
    .toFixed(1)
    .replace('.', ',');

  const populacaoNaoTotalEstimadaState = String(
    (baseCalc.reduce((a, b) => a + b.pop_2010, 0) / 1000000).toFixed(1),
  ).replace('.', ',');

  const populacaoTotalEstimadaState = String(
    (baseCalc.reduce((a, b) => a + b.popest_2014, 0) / 1000000).toFixed(1),
  ).replace('.', ',');

  const densidadeDemTotal = baseCalc
    .reduce((a, b) => a + b.dens_dem, 0)
    .toFixed(0);
  const numeroMunicipios = baseCalc.reduce((a, b) => a + b.num_mun, 0);

  if (baseCalc.length === 1) {
    const arrayCardsForState = [
      ['Estado (km²):', `${areaTotalState} Mil`],
      [
        'População Não Estimada do Estado em 2010:',
        `${populacaoNaoTotalEstimadaState} Mi`,
      ],
      [
        'População Total Estimada do Estado em 2014:',
        `${populacaoTotalEstimadaState} Mi`,
      ],
      ['Densidade Demográfica do Estado:', `${densidadeDemTotal}`],
      ['Número Total de Municípios do Estado:', `${numeroMunicipios}`],
    ];
    return arrayCardsForState;
  }

  const arrayCardsForState = [
    ['Estados (km²):', `${areaTotalState} Mil`],
    [
      'População Não Estimada dos Estados em 2010:',
      `${populacaoNaoTotalEstimadaState} Mi`,
    ],
    [
      'População Total Estimada dos Estados em 2014:',
      `${populacaoTotalEstimadaState} Mi`,
    ],
    ['Densidade Demográfica dos Estados:', `${densidadeDemTotal}`],
    ['Número Total de Municípios dos Estados:', `${numeroMunicipios}`],
  ];
  return arrayCardsForState;
};
