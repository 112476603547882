import React from 'react';

interface IProperties {
  properties: {
    nm_comunid: string;
    nm_municip: string;
    cd_uf: string;
    nr_familia: number;
    st_titulad: string;
  };
}

export function AreasQuilombolas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Áreas Quilombolas</strong>
      </h4>
      <p>
        <strong>Nome da Comunidade Quilombola: </strong>{' '}
        {properties.nm_comunid ? properties.nm_comunid : 'Não informado'}
      </p>
      <p>
        <strong>Nome do Município em que está localizada: </strong>{' '}
        {properties.nm_municip ? properties.nm_municip : 'Não informado'}
      </p>
      <p>
        <strong>Código da Unidade Federativa em que está localizada: </strong>{' '}
        {properties.cd_uf ? properties.cd_uf : 'Não informado'}
      </p>
      <p>
        <strong>Número de famílias: </strong>{' '}
        {properties.nr_familia ? properties.nr_familia : 'Não informado'}
      </p>
      <p>
        <strong>Se a comunidade tem titulação: </strong>{' '}
        {properties.st_titulad ? properties.st_titulad : 'Não informado'}
      </p>
    </div>
  );
}
