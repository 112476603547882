import { State } from 'shared/models/State';
import { RepositoryJsonState } from 'shared/services/repositories/json';

export const searchParamState = async (
  uf: string[],
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setGetStateProps: React.Dispatch<React.SetStateAction<State[]>>,
  setGetRegionProps: React.Dispatch<React.SetStateAction<State[]>>,
  state?: string,
  region?: string,
) => {
  try {
    const repository = new RepositoryJsonState();
    if (state !== undefined && region === undefined) {
      const data = (await repository.getStateFindState(state)) as State[];

      if (data) {
        setGetStateProps(data);
        data.map(posicao => uf.push(posicao.sigla));
      }
    }

    if (region !== undefined && state === undefined) {
      const data = (await repository.getStateFindRegion(region)) as State[];

      if (data) {
        setGetRegionProps(data);
      }
    }

    if (region !== undefined && state !== undefined) {
      const dataRegion =
        state !== undefined &&
        ((await repository.getStateFindRegion(region)) as State[]);

      if (dataRegion) {
        setGetRegionProps(dataRegion);
      }

      const dataState = (await repository.getStateFindState(state)) as State[];

      if (dataState) {
        setGetStateProps(dataState);
        dataState.map(posicao => uf.push(posicao.sigla));
      }
    }
  } catch {
    setError(true);
  }
};
