import React from 'react';

interface IProperties {
  properties: {
    nome_porto: string;
    tipo_porto: string;
    situacao: string;
  };
}

export function Portos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Portos</strong>
      </h4>
      <p>
        <strong>Nome do Porto: </strong>{' '}
        {properties.nome_porto ? properties.nome_porto : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de Porto: </strong>{' '}
        {properties.tipo_porto ? properties.tipo_porto : 'Não informado'}
      </p>
      <p>
        <strong>Situação do Porto: </strong>{' '}
        {properties.situacao ? properties.situacao : 'Não informado'}
      </p>
    </div>
  );
}
