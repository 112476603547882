import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacings[32]};
    display: flex;
    justify-content: center;
    gap: ${theme.spacings[32]};

    background-color: ${theme.colors.indigo800};

    a {
      padding: ${theme.spacings[16]} 0;

      display: flex;
      align-items: center;
      gap: ${theme.spacings[8]};

      color: white;

      font-size: ${theme.font.sizes.lg};

      img {
        width: 2.8rem;
      }
    }

    .active {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 5px;
        position: absolute;
        border-radius: 20px;
        bottom: 0;

        background-color: #017ac6;
      }
    }

    @media (max-width: 750px) {
      #nav-label {
        display: none;
      }
    }

    @media (max-width: 300px) {
      justify-content: flex-end;
    }

    @media (max-width: 367px) {
      height: 50px;
    }
  `}
`;
