import axios from 'axios';
import { Environment } from 'shared/environments';
import { IbgeGiniEstados } from '../JsonType/Type';

type GetData = {
  valuesForFilter: {
    uf?: string;
  };
  setDataIbgeGiniEstado: React.Dispatch<
    React.SetStateAction<(string | number)[][][]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const MockApi = axios.create({
  baseURL: Environment.API_MOCK,
});

function returnArraysForChart(
  responseData: IbgeGiniEstados[],
  textFilter: string[],
) {
  const filterData = textFilter.map(uf =>
    responseData.filter(
      posicao => posicao.unidade_federacao.toLowerCase() === uf.toLowerCase(),
    ),
  );

  const regionBase = filterData.map(posicao => [
    ['Estado', 'Ano 1991', 'Ano 2000', 'Ano 2010'],
    [
      posicao[0].unidade_federacao,
      posicao.reduce((a, b) => a + b.ano_1991, 0),
      posicao.reduce((a, b) => a + b.ano_2000, 0),
      posicao.reduce((a, b) => a + b.ano_2010, 0),
    ],
  ]);

  return regionBase;
}

export async function getDataIbgeGiniEstado({
  valuesForFilter,
  setDataIbgeGiniEstado,
  setLoading,
  setError,
}: GetData) {
  await MockApi.get(`ibgeGiniEstados`)
    .then(response => {
      const responseData = response.data as IbgeGiniEstados[];
      const uf = valuesForFilter?.uf?.split(', ');

      if (uf !== undefined && uf?.length > 0) {
        const newArrayGraphics = returnArraysForChart(responseData, uf);

        setDataIbgeGiniEstado(newArrayGraphics);
      }
    })
    .catch(() => {
      setError(true);
      setLoading(false);
    });
}
