import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 60rem;
    padding: ${theme.spacings[16]};
    position: absolute;
    top: ${theme.spacings[24]};

    display: inline-flex;
    align-items: center;

    border-radius: ${theme.radius.sm};

    background-color: ${theme.colors.white};

    z-index: 1;

    input {
      width: 100%;

      font-family: ${theme.font.family.default};
      font-size: ${theme.font.sizes.md};
      font-weight: ${theme.font.weights.regular};

      border: 0;

      background-color: transparent;

      outline: 0;
    }

    svg {
      margin-right: ${theme.spacings[16]};
    }

    button {
      white-space: nowrap;
      text-align: center;
    }

    #buttonPesquisar {
      margin-right: 10px;
    }

    @media (max-width: 800px) {
      width: 40rem;
    }

    @media (max-width: 600px) {
      width: 20rem;
      padding: ${theme.spacings[10]};

      input {
        font-size: ${theme.font.sizes.sm};
      }
    }
  `}
`;

export const OptionsBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings[8]} 0;
    position: absolute;
    top: ${theme.spacings[64]};
    left: 0;

    border: 2px solid ${theme.colors.black};
    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};

    .option {
      padding: ${theme.spacings[8]} ${theme.spacings[12]};
      color: ${theme.colors.gray900};

      cursor: pointer;

      &:hover {
        background-color: ${darken(0.1, theme.colors.white)};
      }
    }

    @media (max-width: 600px) {
      top: ${theme.spacings[48]};
      font-size: ${theme.font.sizes.sm};
    }
  `}
`;
