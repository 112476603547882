import React, { useCallback, useEffect } from 'react';

import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findAllIndicators } from 'services/indicatorsRequest';
import { findRegionByCountry } from 'services/regionRequest';
import { findAllStates } from 'services/stateRequest';
import { findAllCounty } from 'services/countyRequest';

import { FilterContextData, FilterDataProps, FilterProviderProps, KeysDataFilter, MountObjectFilter } from './@types/Filter';
import { CountyProps, IndicatorProps, RegionProps, StateProps } from 'components/Filter/@types/fields';

export const FilterContext = createContext<FilterContextData>({} as FilterContextData);

const initialFilterData: FilterDataProps = {
  regions: [],
  states: [],
  counties: [],
  indicators: [],
};

export function FilterProvider({ children }: FilterProviderProps) {
  const [filterData, setFilterData] = useState<FilterDataProps>(initialFilterData);

  const { i18n } = useTranslation();

  const [indicators, setIndicators] = useState<IndicatorProps[]>([]);
  const [regions, setRegion] = useState<RegionProps[]>([]);
  const [states, setStates] = useState<StateProps[]>([]);
  const [counties, setCounty] = useState<CountyProps[]>([]);

  const [loadingDataFilter, setLoadingDataFilter] = useState(false);

  const addItemToFilterData = useCallback((item: string, key: KeysDataFilter) => {
    setFilterData(prevState => ({
      ...prevState,
      [key]: [...prevState[key], item],
    }));
  }, []);

  const removeItemFromFilterData = useCallback((item: string, key: KeysDataFilter) => {
    setFilterData(prevState => ({
      ...prevState,
      [key]: prevState[key].filter(filterItem => filterItem !== item),
    }));
  }, []);

  const requestsDataFilter = useCallback(async () => {
    setLoadingDataFilter(true);

    const indicator = sessionStorage.getItem('indicatorsData');
    if (!indicator) {
      const indicatorsResponse = await findAllIndicators<IndicatorProps[]>(i18n.language);
      sessionStorage.setItem('indicatorsData', JSON.stringify(indicatorsResponse.data));

      indicatorsResponse.data.forEach(indicator => addItemToFilterData(indicator.id.toString(), 'indicators'));

      setIndicators(indicatorsResponse.data);
    } else {
      const indicatorsData = JSON.parse(indicator) as IndicatorProps[];
      indicatorsData.forEach(indicator => addItemToFilterData(indicator.id.toString(), 'indicators'));
      setIndicators(indicatorsData);
    }

    const regions = sessionStorage.getItem('regionsData');
    if (!regions) {
      const regionsResponse = await findRegionByCountry<RegionProps[]>('Brasil', i18n.language);
      sessionStorage.setItem('regionsData', JSON.stringify(regionsResponse.data));
      setRegion(regionsResponse.data);
    } else {
      const regionsData = JSON.parse(regions) as RegionProps[];
      setRegion(regionsData);
    }

    const states = sessionStorage.getItem('statesData');
    if (!states) {
      const statesResponse = await findAllStates<StateProps[]>();
      sessionStorage.setItem('statesData', JSON.stringify(statesResponse.data));
      setStates(statesResponse.data);
    } else {
      const statesData = JSON.parse(states) as StateProps[];
      setStates(statesData);
    }

    const county = sessionStorage.getItem('countyData');
    if (!county) {
      const countyResponse = await findAllCounty<CountyProps[]>();
      sessionStorage.setItem('countyData', JSON.stringify(countyResponse.data));
      setCounty(countyResponse.data);
    } else {
      const countyData = JSON.parse(county) as CountyProps[];
      setCounty(countyData);
    }

    setLoadingDataFilter(false);
  }, []);

  const clearFilterData = useCallback(() => {
    setFilterData({
      ...initialFilterData,
      indicators: indicators.map(indicator => indicator.id.toString()),
    });
    setIndicators(indicators.map(indicator => ({ ...indicator, checked: true })));
  }, [indicators]);

  const mountObjectFilter = useCallback(() => {
    const objectFilter: MountObjectFilter = {};

    if (filterData.regions.length) objectFilter.regiao_estado = filterData.regions;

    if (filterData.states.length) objectFilter.sigla_uf = filterData.states;

    if (filterData.counties.length) objectFilter.cod_mun = filterData.counties;

    if (filterData.indicators.length) objectFilter.indicador = filterData.indicators;

    return objectFilter;
  }, [filterData]);

  const toggleActiveIndicator = useCallback(
    (id: number) => {
      setIndicators(
        indicators.map(item => {
          if (item.id === id) {
            item.checked = !item.checked;
          }
          return item;
        }),
      );
    },
    [indicators],
  );

  /**
   * Essa funcao eh referente ao antigo context que gerenciava os filtros.
   * Acredito que ela vai ajudar a implementar os filtros no mapa.
   *  function handleApplyMapFilter() {
        let filter = '';

        if (regions.length > 0) {
          filter = `(regiao_estado LIKE ${regions.map(region => `'%${region}%'`).join(' OR regiao_estado LIKE ')})`;
        }

        if (states.length > 0) {
          filter = `(sigla_uf LIKE ${convertStateToInitials(states)
            .map(state => `'%${state}%'`)
            .join(' OR sigla_uf LIKE ')})`;
        }

        if (counties.length > 0) {
          filter = `(cod_mun LIKE ${counties.map(county => `'%${county}%'`).join(' OR cod_mun LIKE ')})`;
        }

        filter =
          mineralSubstances.length > 0
            ? `${filter} AND (substancia_agrupadora_id LIKE ${mineralSubstances
                .map((substance: { id: number }) => `'%${substance.id}%'`)
                .join(' OR substancia_agrupadora_id LIKE ')})`
            : filter;

        if (filter.length > 2) {
          return setMapFilter(filter);
        }

        return setMapFilter('default');
      }
   * 
   */

  useEffect(() => {
    requestsDataFilter();
  }, []);

  return (
    <FilterContext.Provider
      value={{
        filterData,
        indicators,
        counties,
        regions,
        states,
        loadingDataFilter,
        toggleActiveIndicator,
        addItemToFilterData,
        removeItemFromFilterData,
        mountObjectFilter,
        clearFilterData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
