import styled, { css, DefaultTheme } from 'styled-components';

interface ISwitchStyles {
  checked: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    display: none;
  }
`;

const switchModifiers = {
  checked: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.success};

    &::before {
      left: 50%;
    }
  `,
};

export const Switch = styled.div<ISwitchStyles>`
  ${({ theme, checked }) => css`
    width: 4rem;
    height: 1rem;
    margin-left: ${theme.spacings[12]};
    margin-right: ${theme.spacings[8]};
    padding: ${theme.spacings[8]};
    position: relative;

    display: flex;
    align-items: center;

    border-radius: ${theme.radius.full};

    background-color: ${theme.colors.gray600};

    cursor: pointer;

    transition: all 0.4s;

    &::before {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;

      border-radius: ${theme.radius.full};

      background-color: ${theme.colors.white};

      transition: all 0.2s;
    }

    ${checked && switchModifiers.checked(theme)};
  `}
`;
