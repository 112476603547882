import React from 'react';

interface IProperties {
  properties: {
    localidade: string;
    posição: string;
    nome: string;
    ano_1991: number;
    ano_2000: number;
    ano_2010: number;
  };
}

export function Idh({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Índice de Desenvolvimento Humano (IDH)</strong>
      </h4>
      <p>
        <strong>Localidade: </strong>{' '}
        {properties.localidade ? properties.localidade : 'Não informado'}
      </p>
      <p>
        <strong>Posição: </strong>{' '}
        {properties.posição ? properties.posição : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Indice de Desenvolvimento humano em 1991: </strong>{' '}
        {properties.ano_1991
          ? properties.ano_1991.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Indice de Desenvolvimento humano em 2000: </strong>{' '}
        {properties.ano_2000
          ? properties.ano_2000.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Indice de Desenvolvimento humano em 2010: </strong>{' '}
        {properties.ano_2010
          ? properties.ano_2010.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
