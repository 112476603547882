import React from 'react';

interface IProperties {
  properties: {
    caverna: string;
    uf: string;
    municipio: string;
    localidade: string;
  };
}

export function CavidadesNaturais({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Cavidades Naturais</strong>
      </h4>
      <p>
        <strong>Caverna: </strong>{' '}
        {properties.caverna ? properties.caverna : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.municipio ? properties.municipio : 'Não informado'}
      </p>
      <p>
        <strong>Localidade: </strong>{' '}
        {properties.localidade ? properties.localidade : 'Não informado'}
      </p>
    </div>
  );
}
