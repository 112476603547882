/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { RiArrowDropLeftLine, RiArrowLeftLine } from 'react-icons/ri';

import { useClickOutside } from 'hooks/useClickOutside';

import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SelectContainer, Options, ButtonOption } from './styles';

interface SelectProps<T> {
  data: T[];
  disabled?: boolean;
  empty?: string;
  label: string;
  multiple?: boolean;
  value: any;
  mapFilter?: boolean;
  tooltip?: React.ReactNode;
  placeholder: string;
  keyExtractor: (item: T) => string;
  onChoose?: (item: T) => void;
  handleClickTransition?: () => void;
  renderItem: (item: T) => string;
  itemLabel: (item: T) => string;
}

export function SideSelect<T>({
  data,
  disabled,
  empty,
  label,
  multiple,
  value,
  mapFilter,
  tooltip,
  placeholder,
  keyExtractor,
  onChoose,
  renderItem,
  itemLabel,
  handleClickTransition,
}: SelectProps<T>) {
  const [isCollapse, setIsCollapse] = useState(true);
  const [textQuery, setTextQuery] = useState<string>('');

  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useClickOutside(selectRef, () => {
    if (handleClickTransition && !isCollapse) {
      handleClickTransition();
    }
    setIsCollapse(true);
    setTextQuery('');
  });

  function clearText(text: string) {
    return text
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  function filterLabel(item: T) {
    return clearText(itemLabel(item)).includes(clearText(textQuery));
  }

  return (
    <SelectContainer ref={selectRef} state={isCollapse} disabled={disabled}>
      <div className="selectValueContainer">
        <div className="title">
          {label && <strong>{label}</strong>}
          {tooltip}
        </div>
        <div
          className="selectValue"
          onClick={() => {
            if (disabled) return;
            if (handleClickTransition) handleClickTransition();
            if (isCollapse) {
              setTimeout(() => {
                setIsCollapse(!isCollapse);
              }, 200);
              if (inputRef.current) inputRef.current.focus();
            } else {
              if (inputRef.current) inputRef.current.blur();
              setIsCollapse(!isCollapse);
              setTextQuery('');
            }
          }}
        >
          <input
            className="textInput"
            ref={inputRef}
            placeholder={document.activeElement === inputRef.current ? '' : placeholder}
            value={textQuery}
            onChange={e => setTextQuery(e.target.value)}
            disabled={disabled}
          />
          <RiArrowDropLeftLine className="arrowSelectFilter" size={24} />
        </div>
      </div>
      {!isCollapse && (
        <Options state={isCollapse} mapFilter={mapFilter}>
          <div className="selectHeader">
            <div className="returnButton">
              <Button color="link" outline onClick={() => setIsCollapse(!isCollapse)}>
                <RiArrowLeftLine size="20px" color="#fff" />
              </Button>
            </div>
            <div className="selectLabel">
              <h3> {label} </h3>
            </div>
          </div>
          {data.length > 0 ? (
            data
              .filter(i => filterLabel(i))
              .map(item => {
                let isActive = false;
                if (value instanceof Array) {
                  isActive = Boolean(
                    value.find(element => (element.nome ? element.nome === renderItem(item) : element === renderItem(item))),
                  );
                } else {
                  isActive = value.includes(renderItem(item));
                }

                return (
                  <ButtonOption
                    key={keyExtractor(item)}
                    type="button"
                    className="optionButton"
                    onClick={() => {
                      if (!multiple) {
                        if (inputRef.current) inputRef.current.blur();
                        setIsCollapse(true);
                      }

                      if (onChoose) {
                        if (inputRef.current) inputRef.current.focus();
                        onChoose(item);
                      }
                    }}
                    isActive={isActive}
                  >
                    {itemLabel(item)}
                  </ButtonOption>
                );
              })
          ) : (
            <div className="emptyDataContainer">
              <p>{empty}</p>
            </div>
          )}
        </Options>
      )}
    </SelectContainer>
  );
}
