import React, { useEffect, useState } from 'react';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import { HiMiniAdjustmentsHorizontal } from 'react-icons/hi2';
import { ILayers } from 'contexts/@types/Layers';
import { InputSlider, Checkbox } from 'components';
import { useMap } from 'hooks/useMap';
import { useLayers } from 'hooks/useLayers';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

import { TbAlertCircleFilled } from 'react-icons/tb';
import { createPortal } from 'react-dom';
import Notification from 'components/Notification';

interface ILayer {
  checked: boolean;
  layer: ILayers;
  layers?: ILayers[];
  layertitle: string;
  is_new: boolean;
  nm_category: string;
  setLayers?: React.Dispatch<React.SetStateAction<ILayers[]>>;
  handleChangeTabIndex: (index: number, text: string) => void;
}

export function Layer({ layer, layers, layertitle, setLayers, is_new, nm_category }: ILayer) {
  const { t: translation } = useTranslation();
  const [isActived, setIsActived] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(1);
  const [isLayerSampled, setIsLayerSampled] = useState<boolean>(true);

  const favoriteLayers = JSON.parse(localStorage.getItem('layersFavorite') || '[]')[nm_category] || [];
  const favorite = favoriteLayers.find((item: string) => item === layer.layertitle + layer.substancia_agrupadora_id);

  const [isLayerFavorite, setIsLayerFavorite] = useState<boolean>(!!favorite);

  const {
    handleAddOverlayLayer,
    handleRemoveOverlayLayer,
    handleChangeOpacityLayer,
    setOpenLayerFeaturesModal,
    layerActiveFeature,
  } = useMap();

  const [showNotification, setShowNotification] = useState(false);
  const [downloadFinished, setDownloadFinished] = useState(false);

  const { handleAddLayer, handleRemoveLayer, handleChangeLayerOptionsOpen, layersActived } = useLayers();

  const handleFavoriteLayer = () => {
    const layersStoraged = JSON.parse(localStorage.getItem('layersFavorite') || '[]');
    const array = layersStoraged[nm_category] || [];

    if (!isLayerFavorite) {
      localStorage.setItem(
        'layersFavorite',
        JSON.stringify({ ...layersStoraged, [nm_category]: [...array, layer.layertitle + layer.substancia_agrupadora_id] }),
      );

      return setIsLayerFavorite(true);
    }

    if (array.length === 1) {
      delete layersStoraged[nm_category];
      localStorage.setItem('layersFavorite', JSON.stringify(layersStoraged));
    } else {
      const layerstoStoraged = array.filter((item: string) => item !== layer.layertitle + layer.substancia_agrupadora_id);
      localStorage.setItem('layersFavorite', JSON.stringify({ ...layersStoraged, [nm_category]: layerstoStoraged }));
    }

    if (layers && setLayers) {
      const newLayers = layers.filter(item => item.layertitle !== layertitle);
      setLayers(newLayers);
    }

    return setIsLayerFavorite(false);
  };

  const handleChangeOptionOpen = (layer: ILayers, option: string) => {
    handleChangeLayerOptionsOpen(layer, option);
  };

  useEffect(() => {
    if (isLayerSampled) {
      handleChangeOpacityLayer(layer.layertitle + layer.substancia_agrupadora_id, 1);
    } else {
      handleChangeOpacityLayer(layer.layertitle + layer.substancia_agrupadora_id, 0);
    }
  }, [isLayerSampled]);

  useEffect(() => {
    const layerActived = layersActived.find(
      (layerActived: ILayers) =>
        layerActived.id === layer.id && layerActived.substancia_agrupadora_id === layer.substancia_agrupadora_id,
    );
    if (layerActived) {
      setIsActived(true);
    } else {
      setIsActived(false);
    }
  }, [layersActived]);

  async function downloadLayer() {
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    const xhr = new XMLHttpRequest();

    xhr.open('GET', layer.link_shapefile, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${layer.layertitle}.zip`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
      setDownloadFinished(true);

      setTimeout(() => {
        setDownloadFinished(false);
      }, 5000);
    };

    xhr.send();
  }

  return (
    <Styled.Wrapper isActived={isActived}>
      <Styled.Content>
        <div className="layer">
          <Checkbox
            checked={isActived}
            onChecked={value => {
              if (value) {
                handleAddLayer(layer);
                handleAddOverlayLayer(layer);
              } else {
                handleRemoveLayer(layer);
                handleRemoveOverlayLayer(layer.layertitle + layer.substancia_agrupadora_id);
                const uuidLayer = layer.layertitle + layer.substancia_agrupadora_id;

                if (uuidLayer === layerActiveFeature) setOpenLayerFeaturesModal(false);
              }
            }}
          />

          <p>{layertitle}</p>

          {/* TODO: Tradução */}
          {is_new && <span>novo</span>}
        </div>

        {isActived && (
          <Styled.OptionsBlock>
            <div className="menu-icons">
              <Styled.Button
                label={translation('mapPage.sideBar.layers.legend')}
                onClick={() => handleChangeOptionOpen(layer, 'subtitle')}
              >
                <RiIcons.RiMenu2Fill size={20} color="#000" />
              </Styled.Button>

              <Styled.Button label={translation('mapPage.sideBar.layers.favorite')} onClick={handleFavoriteLayer}>
                <FaIcons.FaStar size={20} color={isLayerFavorite ? '#FFD700' : '#000'} />
              </Styled.Button>

              <Styled.Button
                label={translation('mapPage.sideBar.layers.showHide')}
                onClick={() => setIsLayerSampled(!isLayerSampled)}
              >
                {isLayerSampled ? (
                  <RiIcons.RiEyeOffFill size={20} color="#000" />
                ) : (
                  <RiIcons.RiEyeFill size={20} color="#000" />
                )}
              </Styled.Button>

              <Styled.Button label={translation('mapPage.sideBar.layers.graphic')} disabled>
                <RiIcons.RiBarChart2Fill size={20} color="#808080" />
              </Styled.Button>

              <Styled.Button label={translation('mapPage.sideBar.layers.filter')} disabled>
                <HiMiniAdjustmentsHorizontal size={20} color="#808080" />
              </Styled.Button>

              <Styled.Button
                label={translation('mapPage.sideBar.layers.information')}
                onClick={() => handleChangeOptionOpen(layer, 'information')}
              >
                <RiIcons.RiInformationLine size={20} color="#000" />
              </Styled.Button>

              <Styled.Button label={translation('mapPage.sideBar.layers.download')} onClick={downloadLayer}>
                <Styled.LinkDownloadLayer>
                  <MdIcons.MdFileDownload size={20} color="#000" />
                </Styled.LinkDownloadLayer>
              </Styled.Button>
            </div>
          </Styled.OptionsBlock>
        )}
      </Styled.Content>

      {isActived && (
        <Styled.Content>
          {/* `${translation('mapPage.sideBar.layers.transparency')}:` */}
          <InputSlider
            label={''}
            min={0}
            max={1}
            step={0.01}
            value={opacity}
            onChange={({ target }) => {
              handleChangeOpacityLayer(layer.layertitle + layer.substancia_agrupadora_id, Number(target.value));
              setOpacity(Number(target.value));
            }}
          />
        </Styled.Content>
      )}
      {(showNotification || downloadFinished) && (
        <Notification
          text={
            showNotification
              ? 'Esse arquivo pode demorar alguns minutos para ser baixado.'
              : 'Download realizado com sucesso.'
          }
          icon={{
            component: showNotification ? TbAlertCircleFilled : FaIcons.FaCheckCircle,
            color: showNotification ? '#FFFF00' : '#22CB72',
          }}
        />
      )}
    </Styled.Wrapper>
  );
}
