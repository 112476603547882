import React from 'react';

interface IProperties {
  properties: {
    id_uni_estratigrafica: number;
    sigla: string;
    hierarquia: string;
    nome: string;
    amb_tecto: string;
    legenda: string;
    litotipos: string;
  };
}

export function LevantamentoLito50k({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Levantamento Litoestratigráfico Escala 1:50.000</strong>
      </h4>
      <p>
        <strong>Id da unidade estratigráfica: </strong>
        {properties.id_uni_estratigrafica
          ? properties.id_uni_estratigrafica
          : 'Não informado'}
      </p>
      <p>
        <strong>Sigla: </strong>
        {properties.sigla ? properties.sigla : 'Não informado'}
      </p>
      <p>
        <strong>Hierarquia: </strong>
        {properties.hierarquia ? properties.hierarquia : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Ambiente tectônico: </strong>
        {properties.amb_tecto ? properties.amb_tecto : 'Não informado'}
      </p>
      <p>
        <strong>Legenda: </strong>
        {properties.legenda ? properties.legenda : 'Não informado'}
      </p>
      <p>
        <strong>Litotipos: </strong>
        {properties.litotipos ? properties.litotipos : 'Não informado'}
      </p>
    </div>
  );
}
