import React from 'react';

interface IProperties {
  properties: {
    nível: string;
    municipio: string;
    ano_2010: number;
    uf: string;
    regiao_estado: string;
  };
}

export function DensidadePopulacional({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Densidade Populacional</strong>
      </h4>
      <p>
        <strong>Nível: </strong>{' '}
        {properties.nível ? properties.nível : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.municipio ? properties.municipio : 'Não informado'}
      </p>
      <p>
        <strong>Densidade populacional em 2010: </strong>{' '}
        {properties.ano_2010
          ? properties.ano_2010.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Região do estado: </strong>{' '}
        {properties.regiao_estado ? properties.regiao_estado : 'Não informado'}
      </p>
    </div>
  );
}
