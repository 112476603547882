import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { RiArrowDownSLine, RiArrowRightDownLine, RiCloseLine, RiInformationFill, RiMenu2Fill } from 'react-icons/ri';
import Draggable from 'react-draggable';
import * as Styled from './styles';
import { BiGridHorizontal } from 'react-icons/bi';
import { useLayers } from 'hooks/useLayers';
import { useTranslation } from 'react-i18next';
import { TbMapSearch } from 'react-icons/tb';
import { useMap } from 'hooks/useMap';
import { ResizableBox, Resizable } from 'react-resizable';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

interface ILayerOption {
  option: string;
  content: ReactNode;
  setIndex?: React.Dispatch<SetStateAction<number>>;
  index: number;
  pages: number;
}

export function LayerOption({ option, content, setIndex, index, pages }: ILayerOption) {
  const [isActived, setIsActived] = useState<boolean>(true);
  const { handleCloseOptions } = useLayers();
  const { t: translation } = useTranslation();
  const [title, setTitle] = useState('');
  const { setOpenLayerFeaturesModal } = useMap();

  useEffect(() => {
    if (option === 'subtitle') {
      setTitle(translation('mapPage.modalInformations.subtitle'));
    } else if (option === 'information') {
      setTitle(translation('mapPage.modalInformations.information'));
    } else if (option === 'features') {
      setTitle(translation('mapPage.modalInformations.features'));
    }

    if (innerWidth < 400) {
      setIsActived(false);
    }
  }, []);

  const handleClose = () => {
    if (option === 'features') {
      setOpenLayerFeaturesModal(false);
    } else {
      handleCloseOptions(option);
    }

    if (setIndex) setIndex(0);
  };

  const handlePrevious = () => {
    if (!setIndex) return;
    if (index === 0) {
      setIndex(pages - 1);
    } else {
      setIndex(index - 1);
    }
  };

  const handleNext = () => {
    if (!setIndex) return;
    if (index === pages - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <Styled.Box>
      <Styled.GlobalStyles />
      <Draggable handle="#btn-subtitle, #btn-dragglable, #title-dragglable">
        <ResizableBox
          width={250}
          height={300}
          minConstraints={[200, 300]}
          resizeHandles={isActived ? ['se'] : []}
          handle={(h, ref) => (
            <span className={`react-resizable-handle react-resizable-handle-${h}`} ref={ref}>
              <RiArrowRightDownLine className="resize-handle-icon" />
            </span>
          )}
        >
          <Styled.Wrapper isActived={isActived}>
            <Styled.LayerBlock isActived={isActived}>
              <div id="btn-subtitle">
                {option === 'subtitle' && <RiMenu2Fill size={22} />}
                {option === 'information' && <RiInformationFill size={22} />}
                {option === 'features' && <TbMapSearch size={22} />}
              </div>

              <p id="title-dragglable">{title}</p>

              <Styled.Button id="btn-dragglable" label={translation('mapPage.modalInformations.dragglable')}>
                <BiGridHorizontal size={24} />
              </Styled.Button>

              <Styled.Button
                id="btn-minimize"
                onClick={() => setIsActived(!isActived)}
                label={
                  isActived
                    ? translation('mapPage.modalInformations.minimize')
                    : translation('mapPage.modalInformations.maximize')
                }
              >
                <RiArrowDownSLine id="icon-minimize" size={32} />
              </Styled.Button>

              <Styled.Button id="btn-close" onClick={handleClose} label={translation('mapPage.modalInformations.close')}>
                <RiCloseLine size={28} />
              </Styled.Button>
            </Styled.LayerBlock>
            {isActived && <Styled.Content>{content}</Styled.Content>}
            {isActived && (
              <Styled.Footer>
                {option === 'features' && (
                  <>
                    <Styled.Button
                      id="btn-previous"
                      onClick={handlePrevious}
                      label={translation('mapPage.modalInformations.previous')}
                    >
                      <MdNavigateBefore />
                    </Styled.Button>
                    <Styled.Button id="btn-next" onClick={handleNext} label={translation('mapPage.modalInformations.next')}>
                      <MdNavigateNext />
                    </Styled.Button>
                    <div className="counter">
                      ({index + 1} {translation('mapPage.modalInformations.pageCounter')} {pages})
                    </div>
                  </>
                )}
              </Styled.Footer>
            )}
          </Styled.Wrapper>
        </ResizableBox>
      </Draggable>
    </Styled.Box>
  );
}
