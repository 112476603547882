import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IWrapperInputSlider {
  percentage: number;
  offset: number;
}

export const Wrapper = styled.div<IWrapperInputSlider>`
  ${({ theme, percentage, offset }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;

    label {
      color: ${theme.colors.black};
    }

    input {
      position: relative;
      appearance: none;

      width: 100%;
      height: 5px;
      margin-top: ${theme.spacings[8]};

      border-radius: ${theme.radius.xs};

      background: linear-gradient(
        to right,
        ${theme.colors.blue600} ${percentage}%,
        ${transparentize(0.8, theme.colors.blue600)} ${percentage}%
      );

      &:active {
        &::after {
          top: -4rem;

          opacity: 1;
        }
      }

      &::after {
        content: attr(value);
        width: 4rem;
        padding: ${theme.spacings[6]};
        position: absolute;
        top: -2rem;
        left: calc(${percentage}% + ${offset}px);
        pointer-events: none;
        border-radius: 0.4rem;

        font-family: ${theme.font.family.default};
        font-size: ${theme.font.sizes.sm};
        font-weight: ${theme.font.weights.bold};
        text-align: center;

        background-color: ${theme.colors.blueA700};
        color: ${theme.colors.white};

        opacity: 0;
        transition: top 0.2s, opacity 0.2s;
      }

      &::-webkit-slider-thumb {
        appearance: none;

        width: 1.6rem;
        height: 1.6rem;

        border-radius: 100%;

        background: ${theme.colors.blueA700};

        cursor: pointer;

        z-index: 1;

        transition: all 0.2s;

        &:active {
          box-shadow: 0 0 0.8rem ${theme.colors.blueA700};
        }
      }
    }
  `}
`;
