import React from 'react';

interface IProperties {
  properties: {
    nm_oledt: string;
    nm_prop: string;
  };
}

export function Oleodutos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Oleodutos</strong>
      </h4>
      <p>
        <strong>Nome do oleoduto: </strong>{' '}
        {properties.nm_oledt ? properties.nm_oledt : 'Não informado'}
      </p>
      <p>
        <strong>Nome do proprietário: </strong>{' '}
        {properties.nm_prop ? properties.nm_prop : 'Não informado'}
      </p>
    </div>
  );
}
