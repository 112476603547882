import React from 'react';

interface IProperties {
  properties: {
    tip_situac: string;
    bitola: string;
    label: string;
  };
}

export function Ferrovias({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Ferrovias</strong>
      </h4>
      <p>
        <strong>Tipo de situação: </strong>{' '}
        {properties.tip_situac ? properties.tip_situac : 'Não informado'}
      </p>
      <p>
        <strong>Bitola: </strong>{' '}
        {properties.bitola ? properties.bitola : 'Não informado'}
      </p>
      <p>
        <strong>Rótulo: </strong>{' '}
        {properties.label ? properties.label : 'Não informado'}
      </p>
    </div>
  );
}
