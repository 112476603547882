import React from 'react';

interface IProperties {
  properties: {
    ident: string;
    no_logrado: string;
    nu_logrado: string;
    ds_naturez: string;
    ds_classif: string;
    sintese_be: string;
  };
}

export function SitiosArqueologicos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Sítios Arqueológicos</strong>
      </h4>
      <p>
        <strong>Identificação: </strong>{' '}
        {properties.ident ? properties.ident : 'Não informado'}
      </p>
      <p>
        <strong>Nome do logradouro: </strong>{' '}
        {properties.no_logrado ? properties.no_logrado : 'Não informado'}
      </p>
      <p>
        <strong>Número do logradouro: </strong>{' '}
        {properties.nu_logrado ? properties.nu_logrado : 'Não informado'}
      </p>
      <p>
        <strong>Descrição da natureza do sítio: </strong>{' '}
        {properties.ds_naturez ? properties.ds_naturez : 'Não informado'}
      </p>
      <p>
        <strong>Descrição da classificação: </strong>{' '}
        {properties.ds_classif ? properties.ds_classif : 'Não informado'}
      </p>
      <p>
        <strong>Descrição do material encontrado: </strong>{' '}
        {properties.sintese_be ? properties.sintese_be : 'Não informado'}
      </p>
    </div>
  );
}
