import { Button } from 'components';
import React from 'react';
import { FaRegStar } from 'react-icons/fa';
import { GiRoundStar } from 'react-icons/gi';
import { HiEyeOff } from 'react-icons/hi';
import theme from 'styles/theme';
import dashboardIcon from '../../../../assets/svg/dashboard-icon.svg';
import * as Styled from './style';

type TabinationBarProps = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setIsClickedTopMenu: React.Dispatch<
    React.SetStateAction<'ocultGraph' | 'dashboard' | 'favoriteGraph'>
  >;
};

export function TabinationBar({
  isClickedTopMenu,
  setIsClickedTopMenu,
}: TabinationBarProps) {
  return (
    <Styled.Wrapper selected={isClickedTopMenu}>
      <Button
        className="DashboardButton"
        onClick={() => setIsClickedTopMenu('dashboard')}
      >
        <img src={dashboardIcon} alt="Icon_dashboard" />
        Dashboard
      </Button>

      <Button
        className="favoriteGraphButton"
        onClick={() => setIsClickedTopMenu('favoriteGraph')}
      >
        {isClickedTopMenu === 'favoriteGraph' ? (
          <GiRoundStar color={theme.colors.yellow} className="star" />
        ) : (
          <FaRegStar className="star" />
        )}
        Gráficos Favoritos
      </Button>

      <Button
        className="ocultGraphButton"
        onClick={() => setIsClickedTopMenu('ocultGraph')}
      >
        <HiEyeOff className="ocult" />
        Gráficos ocultos
      </Button>
    </Styled.Wrapper>
  );
}
