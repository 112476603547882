export default [
  {
    id: 1,
    label: 'government',
    link: 'https://www.gov.br/pt-br/orgaos-do-governo',
  },
  {
    id: 2,
    label: 'information',
    link: 'https://www.gov.br/acessoainformacao/pt-br',
  },
  {
    id: 3,
    label: 'legislation',
    link: 'http://www4.planalto.gov.br/legislacao',
  },
  {
    id: 4,
    label: 'accessibility',
    link: 'https://www.gov.br/governodigital/pt-br/acessibilidade-digital',
  },
];
