import React from 'react';

interface IProperties {
  properties: {
    fonte: string;
    localidade: string;
    uni_bioestratigrafica: string;
    uni_litoestratigrafica: string;
    uni_cronoestratigrafica: string;
    litologia: string;
    vest_organico: string;
    amb_deposicao: string;
    conteudos: string;
    macrofosseis: string;
    microfosseis: string;
  };
}

export function OcorrenciasFossiliferas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Ocorrências Fossilíferas</strong>
      </h4>
      <p>
        <strong>Fonte: </strong>{' '}
        {properties.fonte ? properties.fonte : 'Não informado'}
      </p>
      <p>
        <strong>Localidade: </strong>{' '}
        {properties.localidade ? properties.localidade : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Bioestratigráfica: </strong>{' '}
        {properties.uni_bioestratigrafica
          ? properties.uni_bioestratigrafica
          : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Litoestratigráfica: </strong>{' '}
        {properties.uni_litoestratigrafica
          ? properties.uni_litoestratigrafica
          : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Cronoestratigráfica: </strong>{' '}
        {properties.uni_cronoestratigrafica
          ? properties.uni_cronoestratigrafica
          : 'Não informado'}
      </p>
      <p>
        <strong>Litologia: </strong>{' '}
        {properties.litologia ? properties.litologia : 'Não informado'}
      </p>
      <p>
        <strong>Vestígios Orgânicos: </strong>{' '}
        {properties.vest_organico ? properties.vest_organico : 'Não informado'}
      </p>
      <p>
        <strong>Ambiente deposição: </strong>{' '}
        {properties.amb_deposicao ? properties.amb_deposicao : 'Não informado'}
      </p>
      <p>
        <strong>Conteúdos: </strong>{' '}
        {properties.conteudos ? properties.conteudos : 'Não informado'}
      </p>
      <p>
        <strong>Macrofósseis: </strong>{' '}
        {properties.macrofosseis ? properties.macrofosseis : 'Não informado'}
      </p>
      <p>
        <strong>Microfósseis: </strong>{' '}
        {properties.microfosseis ? properties.microfosseis : 'Não informado'}
      </p>
    </div>
  );
}
