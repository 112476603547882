import styled, { css } from 'styled-components';

interface IWrapperLayerSelect {
  id: string;
  opened: boolean;
}

export const Wrapper = styled.div<IWrapperLayerSelect>`
  ${({ theme, id, opened }) => css`
    width: 100%;
    border-radius: ${theme.radius.xs};

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.xs};
    }

    p {
      margin-bottom: 0;
      font-weight: ${theme.font.weights.regular};
    }

    #label_qtd_opened {
      border-radius: ${theme.radius.lg};
      background-color: #d2691e;
      color: #fff;
      font-size: ${theme.font.sizes.xs};
      font-weight: ${theme.font.weights.medium};
      margin-left: ${theme.spacings[4]};
      padding: ${theme.spacings[4]} ${theme.spacings[8]};
    }

    #label_new {
      border-radius: ${theme.radius.lg};
      background-color: #01c638;
      color: #fff;
      font-size: ${theme.font.sizes.xs};
      font-weight: ${theme.font.weights.medium};
      margin-left: ${theme.spacings[4]};
      padding: ${theme.spacings[2]} ${theme.spacings[8]};
    }

    ${opened && `#${id}{ font-weight: ${theme.font.weights.semiBold}}`}

    .selectLayer {
      width: 100%;
      padding: ${theme.spacings[4]} ${theme.spacings[8]};
      display: flex;
      align-items: center;
      border-radius: ${theme.radius.xs};
      cursor: pointer;

      svg {
        transition: all 0.2s;
        margin-right: ${theme.spacings[8]};
        color: ${theme.colors.blue600};
      }
    }

    @media (max-width: 400px) {
      .selectLayer {
        padding: ${theme.spacings[6]} 0 0 0;
      }

      #label_qtd_opened {
        font-size: ${theme.font.sizes.xxs};
        padding: ${theme.spacings[1]} ${theme.spacings[4]};
      }
    }
  `}
`;

export const ContentLayer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-left: ${theme.spacings[20]};
    // padding-right: ${theme.spacings[16]};

    @media (max-width: 400px) {
      padding-left: ${theme.spacings[16]};
    }
  `}
`;
