import { AxiosError } from 'axios';

export const errorInterceptor = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(
      new Error(
        'Desculpem-nos o transtorno, estamos em obras para melhor atendê-los!',
      ),
    );
  }

  if (error.response?.status === 404) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }

  return Promise.reject(error);
};
