import React from 'react';

interface IProperties {
  properties: {
    profund: string;
  };
}

export function CurvasBatimetrica({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Batimetria: Curvas Batimétricas</strong>
      </h4>
      <p>
        <strong>Profundidade: </strong>
        {properties.profund ? properties.profund : 'Não informado'}
      </p>
    </div>
  );
}
