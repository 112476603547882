import axios from 'axios';
import { CadastroDeMinerios } from '../JsonType/Type';
import { Environment } from '../../../../../../shared/environments/index';
import { DataSubsEstadoType } from '../index';

type GetData = {
  valueForFilter?: {
    uf?: string[];
    region?: string;
    city?: string;
    subsMin?: string;
  };
  setCadastroMinerarios: React.Dispatch<
    React.SetStateAction<DataSubsEstadoType[]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const Api = axios.create({
  baseURL: Environment.API_URL_BASE,
  timeout: 10000,
});

export async function getDataCadastroDeMinerios({
  valueForFilter,
  setCadastroMinerarios,
  setLoading,
  setError,
}: GetData) {
  try {
    if (
      valueForFilter?.uf?.length === 0 &&
      valueForFilter?.city === undefined &&
      valueForFilter?.region === undefined &&
      valueForFilter?.subsMin === undefined
    ) {
      const faseArray = ['Pesquisa', 'Lavra', 'Disponibilidade', 'Depósito'];

      const returnFilter = faseArray.map(posicao => {
        return Api.get(`cadastros_minerarios/?ds_fase_agrupada=${posicao}`);
      });

      const allData = returnFilter.map(posicao =>
        posicao.then(response => response.data),
      );

      const pesquisa = (await allData[0].then(
        response => response,
      )) as CadastroDeMinerios[];

      const lavra = (await allData[1].then(
        response => response,
      )) as CadastroDeMinerios[];

      const disponibilidade = (await allData[2].then(
        response => response,
      )) as CadastroDeMinerios[];

      const deposito = (await allData[3].then(
        response => response,
      )) as CadastroDeMinerios[];

      const array = [
        ['Cadastro minerário', 'Total de direitos Minerários'],
        [pesquisa[0].ds_fase_agrupada, pesquisa.length],
        [lavra[0].ds_fase_agrupada, lavra.length],
        [disponibilidade[0].ds_fase_agrupada, disponibilidade.length],
        [deposito[0].ds_fase_agrupada, deposito.length],
      ];

      setCadastroMinerarios([
        {
          filterText: '',
          dataChart: array,
        },
      ]);
    } else {
      const faseArray = ['Pesquisa', 'Lavra', 'Disponibilidade', 'Depósito'];

      const uf = valueForFilter?.uf?.join(',').toLocaleUpperCase();

      const city =
        valueForFilter?.city !== null &&
        valueForFilter?.city
          ?.split(', ')
          .map(string => string[0].toUpperCase() + string.substring(1))
          .join(',')
          .split(' ')
          .map(string => string[0].toUpperCase() + string.substring(1))
          .join(' ');

      const region = valueForFilter?.region
        ?.split(', ')
        .map(string => string[0].toUpperCase() + string.substring(1))
        .join(',');

      const subsMin =
        valueForFilter?.subsMin !== null &&
        valueForFilter?.subsMin?.split(', ').join(',').toLocaleUpperCase();

      const arrayFilter = [];

      if (region !== undefined) {
        const request1 = await Api.get<CadastroDeMinerios[]>(
          `cadastros_minerarios/?regiao_estado__in=${region}`,
        );
        const response1 = await request1.data;

        const allCadastrosRequest = faseArray.map(posicao =>
          response1.filter(object => object.ds_fase_agrupada === posicao),
        );

        const arrayForGraph = allCadastrosRequest.map((posicao, index) =>
          posicao && posicao.length > 0
            ? [posicao[0].ds_fase_agrupada, posicao.length]
            : [faseArray[index], 0],
        );

        const titleArray = [
          'Cadastro minerário',
          'Total de direitos Minerários',
        ];
        arrayForGraph.unshift(titleArray);

        const object = {
          filterText: region,
          dataChart: arrayForGraph,
        };

        arrayFilter.push(object);
      }

      if (subsMin !== undefined) {
        const request1 = await Api.get<CadastroDeMinerios[]>(
          `cadastros_minerarios/?NMSubstancia__in=${subsMin}`,
        );
        const response1 = await request1.data;

        const allCadastrosRequest = faseArray.map(posicao =>
          response1.filter(object => object.ds_fase_agrupada === posicao),
        );

        const arrayForGraph = allCadastrosRequest.map((posicao, index) =>
          posicao && posicao.length > 0
            ? [posicao[0].ds_fase_agrupada, posicao.length]
            : [faseArray[index], 0],
        );

        const titleArray = [
          'Cadastro minerário',
          'Total de direitos Minerários',
        ];
        arrayForGraph.unshift(titleArray);

        const object = {
          filterText: subsMin || '',
          dataChart: arrayForGraph,
        };

        arrayFilter.push(object);
      }

      if (city !== undefined) {
        const request1 = await Api.get<CadastroDeMinerios[]>(
          `cadastros_minerarios/?nm_mun__in=${city}`,
        );
        const response1 = await request1.data;

        const allCadastrosRequest = faseArray.map(posicao =>
          response1.filter(object => object.ds_fase_agrupada === posicao),
        );

        const arrayForGraph = allCadastrosRequest.map((posicao, index) =>
          posicao && posicao.length > 0
            ? [posicao[0].ds_fase_agrupada, posicao.length]
            : [faseArray[index], 0],
        );

        const titleArray = [
          'Cadastro minerário',
          'Total de direitos Minerários',
        ];
        arrayForGraph.unshift(titleArray);

        const object = {
          filterText: city || '',
          dataChart: arrayForGraph,
        };

        arrayFilter.push(object);
      }

      if (uf !== undefined) {
        const request1 = await Api.get<CadastroDeMinerios[]>(
          `cadastros_minerarios/?sigla_uf__in=${uf}`,
        );
        const response1 = await request1.data;

        const allCadastrosRequest = faseArray.map(posicao =>
          response1.filter(object => object.ds_fase_agrupada === posicao),
        );

        const arrayForGraph = allCadastrosRequest.map((posicao, index) =>
          posicao && posicao.length > 0
            ? [posicao[0].ds_fase_agrupada, posicao.length]
            : [faseArray[index], 0],
        );

        const titleArray = [
          'Cadastro minerário',
          'Total de direitos Minerários',
        ];
        arrayForGraph.unshift(titleArray);

        const object = {
          filterText: uf,
          dataChart: arrayForGraph,
        };

        arrayFilter.push(object);
      }

      setCadastroMinerarios(arrayFilter);
    }
  } catch (err) {
    setError(true);
    setLoading(false);
  }
}
