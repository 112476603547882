import styled, { css } from 'styled-components';
import theme from 'styles/theme';

interface SideBarProps {
  state: boolean;
  mapFilter?: boolean;
  transition?: boolean;
}

interface MapFilterProps {
  mapFilter?: boolean;
}

const sideBarModifiers = {
  isCollapse: () => css`
    width: 0;

    .headerFilter {
      width: 70px;
      span {
        display: none;
      }
    }

    .expandedButton {
      svg {
        transform: rotate(-180deg);
      }
    }
  `,
  setTransition: () => css`
    width: 50%;
  `,
};

export const SideBar = styled.div<SideBarProps>`
  ${({ theme, state, mapFilter, transition }) => css`
    width: 100%;
    height: 100%;
    position: relative;

    z-index: 1;

    background-color: rgba(243, 244, 246, 0.9);

    transition: all 0.2s;

    border-radius: 0 ${theme.radius.sm} ${theme.radius.sm} 0;

    .expandedButton {
      width: 40px;
      height: 56px;
      padding: ${theme.spacings[12]} 0;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      margin-left: 15px;

      border: 0;
      border-radius: 0 ${theme.radius.sm} ${theme.radius.sm} 0;

      background-color: #017ac6;

      svg {
        transition: all 0.4s;
      }

      @media (max-width: 367px) {
        height: 50px;
      }
    }

    .headerFilter {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #017ac6;
      border: 0;
      border-radius: 0 ${theme.radius.sm} ${theme.radius.sm} 0;

      span {
        color: ${theme.colors.white};
        margin-left: 10px;
      }

      .filter-icon {
        margin-left: 15px;

        @media (max-width: 350px) {
          display: none;
        }
      }

      @media (max-width: 367px) {
        height: 50px;
      }
    }

    .filterText {
      display: flex;
      align-items: center;

      font-family: ${theme.font.family.default};
      font-size: ${theme.font.sizes.lg};
    }

    .filterIcon {
      margin-right: ${theme.spacings[12]};
      padding: ${theme.spacings[2]};

      border-radius: 0.4rem;

      background-color: ${theme.colors.blueA700};
      color: ${theme.colors.white};
    }

    .headerFilterArrowIcon {
      cursor: pointer;

      transition: all 0.2s;
    }

    ${mapFilter &&
    css`
      @media (max-width: 437px) {
        width: ${!state ? '100%' : '0'};
      }
    `}

    ${!mapFilter &&
    css`
      @media (max-width: 650px) {
        width: ${!state ? '26.8rem' : '0'};
      }

      @media (max-width: 535px) {
        width: ${!state ? '100%' : '0'};
      }
    `}

    ${state && sideBarModifiers.isCollapse()}
    ${transition && mapFilter && sideBarModifiers.setTransition()}
  `}
`;

export const MainContainer = styled.div<MapFilterProps>`
  ${({ mapFilter }) => css`
    display: flex;
    flex-direction: column;
    max-height: ${mapFilter ? '90%' : '82%'};

    ${!mapFilter &&
    css`
      @media (max-height: 700px) {
        max-height: 75%;
      }
    `}

    ${mapFilter &&
    css`
      @media (max-height: 880px) {
        max-height: 90%;
      }
    `}
  `}
`;

export const SelectContainer = styled.div<MapFilterProps>`
  ${({ theme }) => css`
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${theme.spacings[16]};

    display: flex;
    flex-direction: column;
    gap: ${theme.spacings[12]};

    .filter-selected {
      display: flex;
      justify-content: flex-start;
      .teste {
        display: flex;
        width: 60px;
        padding: 5px;
        font-family: Arial;
        color: #fff;
        border-radius: 10px;
        font-size: 12px;
        background: #017ac6;
        margin-right: 5px;

        .label {
          margin: 0 auto;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }

    @media (max-height: 650px) {
      gap: ${theme.spacings[12]};
    }

    @media (max-height: 600px) {
      gap: ${theme.spacings[10]};
    }

    @media (max-height: 600px) {
      gap: ${theme.spacings[10]};
    }
  `}
`;

export const FilterSelecteds = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .box {
      padding: 5px;
      font-family: Arial;
      color: #fff;
      border-radius: 10px;
      font-size: 12px;
      background-color: #2a2f3c;
      margin-right: 5px;
      margin-bottom: 5px;

      .label {
        margin-right: 5px;
        font-weight: bold;
      }

      .icon {
        display: none;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .box:hover .icon {
      display: inline;
    }
  `}
`;

export const IndicatorContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings[16]};
    margin-top: 10px;

    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    gap: ${theme.spacings[24]};

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }

    @media (max-height: 650px) {
      gap: ${theme.spacings[12]};
    }

    @media (max-height: 600px) {
      gap: ${theme.spacings[10]};
    }
  `}
`;

export const ButtonContainer = styled.div<MapFilterProps>`
  ${({ theme, mapFilter }) => css`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacings[24]};

    & button {
      flex: 1;
    }

    ${mapFilter &&
    css`
      @media (max-width: 900px) {
        gap: ${theme.spacings[12]};
      }

      @media (max-width: 600px) and (min-height: 750px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 437px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    `}

    ${!mapFilter &&
    css`
      @media (max-width: 535px) and (min-height: 750px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
  `}
`;

export const LoadingContainer = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const FooterContainer = styled.div<MapFilterProps>`
  ${({ mapFilter, theme }) => css`
    width: 100%;
    display: flex;
    gap: ${theme.spacings[24]};
    padding: ${theme.spacings[16]};
    flex-direction: column;
    position: absolute;
    bottom: 0;

    background-color: #f4f5f7;

    .messageFilter {
      display: flex;
      align-items: center;
      justify-content: center;

      color: green;

      @media (max-height: 751px) {
        font-size: 14px;
      }

      @media (max-height: 600px) {
        font-size: 12px;
      }

      @media (max-width: 535px) {
        font-size: 12px;
      }

      ${!mapFilter &&
      css`
        @media (max-width: 535px) and (min-height: 750px) {
          margin-bottom: 72%;
          font-size: 14px;
        }

        @media (max-width: 437px) and (max-height: 750px) {
          margin-bottom: 30%;
          font-size: 14px;
        }
      `}

      .fadeOut {
        opacity: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
      }
      .fadeIn {
        opacity: 1;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
      }

      .warning {
        color: #ff8c00;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  `}
`;
