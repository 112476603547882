/* eslint-disable no-underscore-dangle */
import React from 'react';

interface IProperties {
  properties: {
    ponto: string;
    localizaca: string;
    data_insta: string;
    cota_terre: string;
    latitude_d: string;
    longitude_: string;
    bacia: string;
    municipio: string;
    natureza: string;
    nome: string;
    subbacia: string;
    situacao: string;
    uf: string;
    uso_agua: string;
    data_perfu: string;
    metodo_per: string;
    perfurador: string;
    diametro_b: string;
    topo: string;
    base: string;
    tipo_penet: string;
    condicao: string;
    tipo_capta: string;
    data_medic: string;
    nivel_agua: string;
    vazao: string;
    nivel_bomb: string;
    profundida: string;
    profundi_1: string;
    tipo_forma: string;
    data_teste: string;
    tipo_teste: string;
    metodo_int: string;
    surgencia: string;
    unidade_de: string;
    nivel_dina: string;
    nivel_esta: string;
    vazao_espe: string;
    transmissi: string;
    vazao_esta: string;
    tipo_bomba: string;
    data_anali: string;
    data_colet: string;
    condutivid: string;
    cor: string;
    odor: string;
    sabor: string;
    temperatur: string;
    turbidez: string;
    solidos_se: string;
    aspecto_na: string;
  };
}

export function PocosRimas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Poços Rimas</strong>
      </h4>
      <p>
        <strong>Ponto: </strong>
        {properties.ponto ? properties.ponto : 'Não informado'}
      </p>
      <p>
        <strong>Localização: </strong>
        {properties.localizaca ? properties.localizaca : 'Não informado'}
      </p>
      <p>
        <strong>Data de instalação: </strong>
        {properties.data_insta ? properties.data_insta : 'Não informado'}
      </p>
      <p>
        <strong>Cota terreno: </strong>
        {properties.cota_terre ? properties.cota_terre : 'Não informado'}
      </p>
      <p>
        <strong>Latitude: </strong>
        {properties.latitude_d ? properties.latitude_d : 'Não informado'}
      </p>
      <p>
        <strong>Longitude: </strong>
        {properties.longitude_ ? properties.longitude_ : 'Não informado'}
      </p>
      <p>
        <strong>Bacia: </strong>
        {properties.bacia ? properties.bacia : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>
        {properties.municipio ? properties.municipio : 'Não informado'}
      </p>
      <p>
        <strong>Natureza: </strong>
        {properties.natureza ? properties.natureza : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Sub-bacia: </strong>
        {properties.subbacia ? properties.subbacia : 'Não informado'}
      </p>
      <p>
        <strong>Situação: </strong>
        {properties.situacao ? properties.situacao : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Uso de água: </strong>
        {properties.uso_agua ? properties.uso_agua : 'Não informado'}
      </p>
      <p>
        <strong>Data da perfuração: </strong>
        {properties.data_perfu
          ? new Date(properties.data_perfu).toLocaleDateString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Método de perfuração: </strong>
        {properties.metodo_per ? properties.metodo_per : 'Não informado'}
      </p>
      <p>
        <strong>Perfurador: </strong>
        {properties.perfurador ? properties.perfurador : 'Não informado'}
      </p>
      <p>
        <strong>Diâmetro: </strong>
        {properties.diametro_b ? properties.diametro_b : 'Não informado'}
      </p>
      <p>
        <strong>Topo: </strong>
        {properties.topo ? properties.topo : 'Não informado'}
      </p>
      <p>
        <strong>Base: </strong>
        {properties.base ? properties.base : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de penetração: </strong>
        {properties.tipo_penet ? properties.tipo_penet : 'Não informado'}
      </p>
      <p>
        <strong>Condição: </strong>
        {properties.condicao ? properties.condicao : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de captação: </strong>
        {properties.tipo_capta ? properties.tipo_capta : 'Não informado'}
      </p>
      <p>
        <strong>Data de medição: </strong>
        {properties.data_medic
          ? new Date(properties.data_medic).toLocaleDateString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Nível de água: </strong>
        {properties.nivel_agua ? properties.nivel_agua : 'Não informado'}
      </p>
      <p>
        <strong>Vazão: </strong>
        {properties.vazao ? properties.vazao : 'Não informado'}
      </p>
      <p>
        <strong>Nível da bomba: </strong>
        {properties.nivel_bomb ? properties.nivel_bomb : 'Não informado'}
      </p>
      <p>
        <strong>Profundidade: </strong>
        {properties.profundida ? properties.profundida : 'Não informado'}
      </p>
      <p>
        <strong>Profundidade 1: </strong>
        {properties.profundi_1 ? properties.profundi_1 : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de forma: </strong>
        {properties.tipo_forma ? properties.tipo_forma : 'Não informado'}
      </p>
      <p>
        <strong>Data de teste: </strong>
        {properties.data_teste
          ? new Date(properties.data_teste).toLocaleDateString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de teste: </strong>
        {properties.tipo_teste ? properties.tipo_teste : 'Não informado'}
      </p>
      <p>
        <strong>Método int: </strong>
        {properties.metodo_int ? properties.metodo_int : 'Não informado'}
      </p>
      <p>
        <strong>Surgência: </strong>
        {properties.surgencia ? properties.surgencia : 'Não informado'}
      </p>
      <p>
        <strong>Unidade de medida: </strong>
        {properties.unidade_de ? properties.unidade_de : 'Não informado'}
      </p>
      <p>
        <strong>Nível dinâmico: </strong>
        {properties.nivel_dina ? properties.nivel_dina : 'Não informado'}
      </p>
      <p>
        <strong>Nível estático: </strong>
        {properties.nivel_esta ? properties.nivel_esta : 'Não informado'}
      </p>
      <p>
        <strong>Vazão esperada: </strong>
        {properties.vazao_espe ? properties.vazao_espe : 'Não informado'}
      </p>
      <p>
        <strong>Transmissividade: </strong>
        {properties.transmissi ? properties.transmissi : 'Não informado'}
      </p>
      <p>
        <strong>Vazão estática: </strong>
        {properties.vazao_esta ? properties.vazao_esta : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de bomba: </strong>
        {properties.tipo_bomba ? properties.tipo_bomba : 'Não informado'}
      </p>
      <p>
        <strong>Data de análise: </strong>
        {properties.data_anali
          ? new Date(properties.data_anali).toLocaleDateString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Data de coleta: </strong>
        {properties.data_colet
          ? new Date(properties.data_colet).toLocaleDateString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Condutividade: </strong>
        {properties.condutivid ? properties.condutivid : 'Não informado'}
      </p>
      <p>
        <strong>Cor: </strong>
        {properties.cor ? properties.cor : 'Não informado'}
      </p>
      <p>
        <strong>Odor: </strong>
        {properties.odor ? properties.odor : 'Não informado'}
      </p>
      <p>
        <strong>Sabor: </strong>
        {properties.sabor ? properties.sabor : 'Não informado'}
      </p>
      <p>
        <strong>Temperatura: </strong>
        {properties.temperatur ? properties.temperatur : 'Não informado'}
      </p>
      <p>
        <strong>Turbidez: </strong>
        {properties.turbidez ? properties.turbidez : 'Não informado'}
      </p>
      <p>
        <strong>Sólidos: </strong>
        {properties.solidos_se ? properties.solidos_se : 'Não informado'}
      </p>
      <p>
        <strong>Aspecto: </strong>
        {properties.aspecto_na ? properties.aspecto_na : 'Não informado'}
      </p>
    </div>
  );
}
