import React, { memo, useEffect, useState } from 'react';
import * as Styled from './style';

import { renderAllListWithData } from './functionsUsedInSelectAndLabel/renderAllListWithData';
import Select from '../../Select';
import { returnArrayCorrectForLoop } from './functionsUsedInSelectAndLabel/returnArrayCorrectForLoop';

type SelectAndLabelProps = {
  label: string;
  clean: boolean;
  id: number;
  selectedInput: boolean;
  setSelectedInput: React.Dispatch<React.SetStateAction<boolean>>;
  referenceSelect: React.RefObject<HTMLButtonElement>;
  inputIsActive: number[];
  setInputIsActive: React.Dispatch<React.SetStateAction<number[]>>;
  valueOfInputInText: string;
  setValueOfInputInText: React.Dispatch<React.SetStateAction<string>>;
  filterValue?: string;
  isBrazil: boolean;
};

function SelectAndLabel({
  label,
  clean,
  id,
  selectedInput,
  setSelectedInput,
  referenceSelect,
  inputIsActive,
  setInputIsActive,
  valueOfInputInText,
  setValueOfInputInText,
  filterValue,
  isBrazil,
}: SelectAndLabelProps) {
  const [errorInCallingTheApi, setErrorInCallingTheApi] = useState(false);
  // const [allCountry, setAllCountry] = useState('[]');

  const [allRegionNames, setAllRegionNames] = useState<string[]>([]);
  const [allStateNames, setAllStateNames] = useState<string[]>([]);
  const [allCityNames, setAllCityNames] = useState<string[]>([]);

  const [allMineralSubstanceClassesNames, setAllMineralSubstanceClassesNames] =
    useState<string[]>([]);

  const [allMineralSubstanceNames, setAllMineralSubstanceNames] = useState<
    string[]
  >([]);

  const [allProductionChainStudiesNames, setAllProductionChainStudiesNames] =
    useState<string[]>([]);
  const [loadingSelect, setLoadingSelect] = useState(false);

  useEffect(() => {
    if (clean) {
      setValueOfInputInText('');
      setInputIsActive([]);
    }
  }, [clean]);

  const allData = {
    setAllRegionNames,
    setAllStateNames,
    setAllCityNames,
    setAllMineralSubstanceClassesNames,
    setAllMineralSubstanceNames,
    setAllProductionChainStudiesNames,
    setErrorInCallingTheApi,
  };

  const changeState = async () => {
    if (!errorInCallingTheApi) {
      if (!selectedInput) {
        setLoadingSelect(true);
        await renderAllListWithData({ id, allData, filterValue });
        setLoadingSelect(false);
      }
      setSelectedInput(!selectedInput);
    }
  };

  useEffect(() => {
    if (!errorInCallingTheApi && selectedInput) {
      renderAllListWithData({ id, allData, filterValue });
    }
  }, [filterValue]);

  useEffect(() => {
    if (errorInCallingTheApi) {
      setSelectedInput(false);
      setTimeout(() => setErrorInCallingTheApi(false), 6000);
    }
  }, [errorInCallingTheApi]);

  const repositoryNames = {
    allRegionNames,
    allStateNames,
    allCityNames,
    allMineralSubstanceClassesNames,
    allMineralSubstanceNames,
    allProductionChainStudiesNames,
  };

  function moveToSelect() {
    if (referenceSelect.current) {
      referenceSelect.current.click();
    }
  }

  return (
    <Styled.Wrapper error={errorInCallingTheApi}>
      <Styled.Label onClick={() => moveToSelect()}>{label}</Styled.Label>

      <Select
        width="27rem"
        onClick={changeState}
        selectedInput={selectedInput}
        valueOfInputInText={valueOfInputInText}
        setValueOfInputInText={setValueOfInputInText}
        referenceSelect={referenceSelect}
        inputIsActive={inputIsActive}
        setInputIsActive={setInputIsActive}
        options={returnArrayCorrectForLoop({ posicao: id, repositoryNames })}
        multipleSelect={isBrazil}
        notSelectFirstInputDefault
      />

      {loadingSelect && <p className="loading">carregando</p>}

      {errorInCallingTheApi && (
        <div className="error">
          Ops... Tivemos um problema ao buscar os dados.
        </div>
      )}
    </Styled.Wrapper>
  );
}

export default memo(SelectAndLabel);
