import React from 'react';
import { BiX } from 'react-icons/bi';
import { createPortal } from 'react-dom';
import * as Styled from './styles';

interface IModal {
  children: React.ReactNode;
  onDisabled?: () => void;
}

export function Modal({ children, onDisabled }: IModal) {
  return createPortal(
    <Styled.BackgroundBlur>
      <Styled.Modal>
        <Styled.CloseButton onClick={onDisabled}>
          <BiX size={24} />
        </Styled.CloseButton>
        {children}
      </Styled.Modal>
    </Styled.BackgroundBlur>,
    document.getElementById('modal')!, //eslint-disable-line
  );
}
