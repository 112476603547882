import { City } from 'shared/models/City';
import { RepositoryJsonCity } from 'shared/services/repositories/json';

export const searchParamCity = async (
  city: string,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setGetCityProps: React.Dispatch<React.SetStateAction<City[]>>,
) => {
  try {
    const repository = new RepositoryJsonCity();
    const data = (await repository.getCityFindCity(city)) as City[];

    if (data) {
      setGetCityProps(data);
    }
  } catch {
    setError(true);
  }
};
