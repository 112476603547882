import { Button } from 'components';
import React, { memo, useEffect, useRef, useState } from 'react';
import { IValuesForFilterType } from 'shared/models/ValuesForFilter';
import SelectAndLabel from './SelectAndLabel';
import * as Styled from './style';
import { ButtonsSideBar } from './ButtonsSideBar';

type SidebarType = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
  filterValue: IValuesForFilterType;
  setFilterValue: React.Dispatch<React.SetStateAction<IValuesForFilterType>>;
  setResetFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClickedFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

function SideBar({
  isClickedTopMenu,
  openFilter,
  setOpenFilter,
  filterValue,
  setFilterValue,
  setResetFilter,
  setIsClickedFilter,
}: SidebarType) {
  const [clean, setClean] = useState(false);

  // due to wasting time in solving a bug I had to surrender without adding loop
  const [countrySelected, setCountrySelected] = useState(false);
  const referenceSelect = useRef<HTMLButtonElement>(null);
  const [inputIsActive, setInputIsActive] = useState<number[]>([]);
  const [valueOfInputCountryInText, setValueOfInputCountryInText] =
    useState('');

  const [regionSelected, setRegionSelected] = useState(false);
  const referenceSelect2 = useRef<HTMLButtonElement>(null);
  const [inputIsActive2, setInputIsActive2] = useState<number[]>([]);
  const [valueOfInputInText2, setValueOfInputInText2] = useState('');

  const [stateSelected, setStateSelected] = useState(false);
  const referenceSelect3 = useRef<HTMLButtonElement>(null);
  const [inputIsActive3, setInputIsActive3] = useState<number[]>([]);
  const [valueOfInputInText3, setValueOfInputInText3] = useState('');

  const [citySelected, setCitySelected] = useState(false);
  const referenceSelect4 = useRef<HTMLButtonElement>(null);
  const [inputIsActive4, setInputIsActive4] = useState<number[]>([]);
  const [valueOfInputInText4, setValueOfInputInText4] = useState('');

  const [mineralSubstanceSelected, setMineralSubstanceSelected] =
    useState(false);
  const referenceSelect6 = useRef<HTMLButtonElement>(null);
  const [inputIsActive6, setInputIsActive6] = useState<number[]>([]);
  const [valueOfInputInText6, setValueOfInputInText6] = useState('');
  const [isBrazil, setIsBrazil] = useState(false);

  const [onMouseOver, setOnMouseOver] = useState(false);

  function cleanSelect() {
    setClean(true);

    setTimeout(() => {
      setClean(false);
    }, 400);
  }

  useEffect(() => {
    if (Object.values(filterValue).length === 0) {
      cleanSelect();
    }
  }, [filterValue]);

  useEffect(() => {
    if (
      valueOfInputCountryInText !== '' &&
      valueOfInputCountryInText.includes('Brasil')
    ) {
      setIsBrazil(true);
    } else {
      setIsBrazil(false);
    }
  }, [valueOfInputCountryInText]);

  function checkValuesForFilter() {
    if (
      valueOfInputCountryInText !== '' ||
      valueOfInputInText2 !== '' ||
      valueOfInputInText3 !== '' ||
      valueOfInputInText4 !== '' ||
      valueOfInputInText6 !== ''
    ) {
      const values = {} as IValuesForFilterType;
      if (valueOfInputCountryInText !== '') {
        values.country = valueOfInputCountryInText;
      }
      if (valueOfInputInText2 !== '') {
        values.region = valueOfInputInText2;
      }
      if (valueOfInputInText3 !== '') {
        values.state = valueOfInputInText3;
      }
      if (valueOfInputInText4 !== '') {
        values.city = valueOfInputInText4;
      }
      if (valueOfInputInText6 !== '') {
        values.mineralSubstance = valueOfInputInText6;
      }

      setFilterValue(values);
      setIsClickedFilter(true);
    } else {
      setFilterValue({});
      setResetFilter(true);
    }
  }

  return (
    <>
      {onMouseOver && isClickedTopMenu === 'dashboard' && (
        <Styled.ToolTipSideBar openFilter={openFilter}>
          <div className="wrapperShape">
            <div className="shape" />
          </div>
          {openFilter ? 'Recolher Filtros' : 'Expandir Filtros'}
        </Styled.ToolTipSideBar>
      )}

      <Styled.Wrapper openFilter={openFilter}>
        <Styled.Header>
          <ButtonsSideBar
            setOnMouseOver={setOnMouseOver}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
          />
        </Styled.Header>
        <Styled.Filter openFilter={openFilter}>
          <Styled.CentralizedComponentBottom className="marginTopForFirstChildren">
            <SelectAndLabel
              label="País"
              clean={clean}
              id={0}
              selectedInput={countrySelected}
              setSelectedInput={setCountrySelected}
              referenceSelect={referenceSelect}
              inputIsActive={inputIsActive}
              setInputIsActive={setInputIsActive}
              valueOfInputInText={valueOfInputCountryInText}
              setValueOfInputInText={setValueOfInputCountryInText}
              isBrazil={valueOfInputCountryInText.includes('Brasil')}
            />
          </Styled.CentralizedComponentBottom>

          <Styled.CentralizedComponentBottom isBrazil={isBrazil}>
            <SelectAndLabel
              label="Região"
              clean={clean}
              id={1}
              selectedInput={regionSelected}
              setSelectedInput={setRegionSelected}
              referenceSelect={referenceSelect2}
              inputIsActive={inputIsActive2}
              setInputIsActive={setInputIsActive2}
              valueOfInputInText={valueOfInputInText2}
              setValueOfInputInText={setValueOfInputInText2}
              filterValue={valueOfInputCountryInText}
              isBrazil={valueOfInputCountryInText.includes('Brasil')}
            />
          </Styled.CentralizedComponentBottom>

          <Styled.CentralizedComponentBottom isBrazil={isBrazil}>
            <SelectAndLabel
              label="Estado"
              clean={clean}
              id={2}
              selectedInput={stateSelected}
              setSelectedInput={setStateSelected}
              referenceSelect={referenceSelect3}
              inputIsActive={inputIsActive3}
              setInputIsActive={setInputIsActive3}
              valueOfInputInText={valueOfInputInText3}
              setValueOfInputInText={setValueOfInputInText3}
              filterValue={valueOfInputInText2}
              isBrazil={valueOfInputCountryInText.includes('Brasil')}
            />
          </Styled.CentralizedComponentBottom>

          <Styled.CentralizedComponentBottom isBrazil={isBrazil}>
            <SelectAndLabel
              label="Município"
              clean={clean}
              id={3}
              selectedInput={citySelected}
              setSelectedInput={setCitySelected}
              referenceSelect={referenceSelect4}
              inputIsActive={inputIsActive4}
              setInputIsActive={setInputIsActive4}
              valueOfInputInText={valueOfInputInText4}
              setValueOfInputInText={setValueOfInputInText4}
              filterValue={valueOfInputInText3}
              isBrazil={valueOfInputCountryInText.includes('Brasil')}
            />
          </Styled.CentralizedComponentBottom>

          <Styled.CentralizedComponentBottom isBrazil={isBrazil}>
            <SelectAndLabel
              label="Substância Mineral"
              clean={clean}
              id={5}
              selectedInput={mineralSubstanceSelected}
              setSelectedInput={setMineralSubstanceSelected}
              referenceSelect={referenceSelect6}
              inputIsActive={inputIsActive6}
              setInputIsActive={setInputIsActive6}
              valueOfInputInText={valueOfInputInText6}
              setValueOfInputInText={setValueOfInputInText6}
              isBrazil={valueOfInputCountryInText.includes('Brasil')}
            />
          </Styled.CentralizedComponentBottom>
        </Styled.Filter>
        <Styled.WrapperButton openFilter={openFilter}>
          <Button onClick={() => cleanSelect()} className="clean">
            Limpar
          </Button>

          <Button
            type="button"
            className="apply"
            onClick={() => checkValuesForFilter()}
          >
            Aplicar
          </Button>
        </Styled.WrapperButton>
      </Styled.Wrapper>
    </>
  );
}

export default memo(SideBar);
