import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from './languages/ptBR.json';
import enUS from './languages/enUS.json';
import esES from './languages/esES.json';

const resources = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
};

const { language } = JSON.parse(localStorage.getItem('language') || '{}');

i18next.use(initReactI18next).init({
  lng: language || 'pt-BR',
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
