import React from 'react';
import * as Styled from './styles';

type CardChartType = {
  informationTitle: string;
  informationText: string;
};

export function CardChart({
  informationTitle,
  informationText,
}: CardChartType) {
  return (
    <Styled.CardChartWrapper>
      <h4>{informationTitle}</h4>
      <h3>{informationText}</h3>
    </Styled.CardChartWrapper>
  );
}
