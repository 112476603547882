import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface ILayerBlock {
  isActived: boolean;
}

const activedModifers = {
  actived: css`
    svg {
      transform: rotate(-180deg);
    }
  `,
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};

    border: 0;
    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};
  `}
`;

export const LayerBlock = styled.div<ILayerBlock>`
  ${({ theme, isActived }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};
    padding: ${theme.spacings[12]} ${theme.spacings[16]};

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: ${theme.radius.xs};

    background-color: #2a2f3c;
    color: ${theme.colors.white};

    cursor: pointer;

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.xs};
    }

    svg {
      transition: all 0.2s;
    }

    ${isActived && activedModifers.actived}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-height: 30rem;

    border-radius: ${theme.radius.xs};

    img {
      padding: 10px;
    }

    @media (max-width: 600px) {
      max-width: 280px;
    }

    @media (max-width: 437px) {
      max-width: 200px;
    }

    @media (max-width: 347px) {
      max-width: 167px;
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${transparentize(0.5, theme.colors.indigo800)};
    }
  `}
`;
