import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]};

    background-color: #f8f8ff;

    overflow-y: auto;

    .loadingContainer {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gridCards {
      display: grid;
      gap: ${theme.spacings[20]};
      grid-template-columns: repeat(5, 1fr);

      margin-bottom: ${theme.spacings[32]};

      @media (max-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }

    .gridGraphics {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: ${theme.spacings[20]};

      @media (max-width: 1600px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
      }
    }

    .noContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 1600px) {
      padding: ${theme.spacings[20]};
    }
  `}
`;
