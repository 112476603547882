import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from './styles';

import navegationLinks from './mock';

export function NavegationDashboard() {
  const { t: translation } = useTranslation();

  return (
    <Header>
      {navegationLinks.map(link => (
        <NavLink key={link.href} to={link.href}>
          {link.icon}
          <div id="nav-label">{translation(`dashboardPage.navigationBar.${link.label}`)}</div>
        </NavLink>
      ))}
    </Header>
  );
}
