import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mapIcon from 'assets/svg/map-brazil.svg';

import { NavigationButton } from 'components';
import { TabinationBar } from './components/TabinationBar';
import ColumnsOfDashBoard from './components/ColumnsOfDashboard';

import * as Styled from './styles';
import SideBar from './components/SideBar';
import { FloatingInformationForUser } from './components/ColumnsOfDashboard/FloatingInformationForUser';
import { IValuesForFilterType } from '../../shared/models/ValuesForFilter';

function Dashboard() {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [isClickedTopMenu, setIsClickedTopMenu] = useState(
    'dashboard' as 'dashboard' | 'favoriteGraph' | 'ocultGraph',
  );
  const [selectedOptionChart, setSelectedOptionChart] = useState('');
  const [filterValue, setFilterValue] = useState<IValuesForFilterType>({});
  const [resetFilter, setResetFilter] = useState(false);
  const [isClickedFilter, setIsClickedFilter] = useState(false);

  const { t: translation } = useTranslation();

  return (
    <Styled.Wrapper>
      {selectedOptionChart !== '' && (
        <FloatingInformationForUser selectedOptionChart={selectedOptionChart} />
      )}
      <Styled.DashboardNavigation
        openFilter={openFilter}
        isClicked={isClickedTopMenu}
      >
        <TabinationBar
          isClickedTopMenu={isClickedTopMenu}
          setIsClickedTopMenu={setIsClickedTopMenu}
        />

        {isClickedTopMenu === 'dashboard' && (
          <SideBar
            isClickedTopMenu={isClickedTopMenu}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setResetFilter={setResetFilter}
            setIsClickedFilter={setIsClickedFilter}
          />
        )}

        <ColumnsOfDashBoard
          isClickedTopMenu={isClickedTopMenu}
          setSelectedOptionChart={setSelectedOptionChart}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
          isClickedFilter={isClickedFilter}
          setIsClickedFilter={setIsClickedFilter}
        />
      </Styled.DashboardNavigation>
      <NavigationButton />
    </Styled.Wrapper>
  );
}

export default memo(Dashboard);
