import styled, { css } from 'styled-components';

import { Header } from 'screens/Map/components/SideBar/pages/Home/styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]} ${theme.spacings[24]};

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }

    h1 {
      font-size: ${theme.font.sizes.lg};
      font-weight: ${theme.font.weights.bold};
      line-height: 1;
      text-align: center;
      padding-top: 20px;

      @media (max-width: 600px) {
        font-size: ${theme.font.sizes.md};
      }

      @media (max-width: 400px) {
        font-size: ${theme.font.sizes.sm};
        padding-top: ${theme.spacings[6]};
        margin-bottom: ${theme.spacings[10]};
      }
    }

    & section:first-of-type h1 {
      padding-top: 0;
    }

    hr {
      margin: ${theme.spacings[16]} 0;
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[10]};
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ theme }) => css`
    padding: ${theme.spacings[24]} 0;

    @media (max-width: 400px) {
      padding: ${theme.spacings[4]};
    }
  `}
`;

export const ContanierLink = styled(Header)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

  ${({ theme }) => css`
    padding: ${theme.spacings[24]};

    @media (max-width: 400px) {
      padding: 0;
    }
  `}
`;
