import styled, { css } from 'styled-components';

export const ChartContainer = styled.div`
  padding: 24px 32px;

  .labelLegend {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    font-family: Arial;
    font-size: 13px;
  }
`;
