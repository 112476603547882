import React from 'react';

interface IProperties {
  properties: {
    projeto: string;
    classe: string;
    num_lab: string;
  };
}

export function GeoquimicaIntegrada({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Geoquímica Integrada</strong>
      </h4>
      <p>
        <strong>Projeto: </strong>{' '}
        {properties.projeto ? properties.projeto : 'Não informado'}
      </p>
      <p>
        <strong>Classe: </strong>{' '}
        {properties.classe ? properties.classe : 'Não informado'}
      </p>
      <p>
        <strong>Num_Lab: </strong>{' '}
        {properties.num_lab ? properties.num_lab : 'Não informado'}
      </p>
    </div>
  );
}
