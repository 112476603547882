import React from 'react';

interface IProperties {
  properties: {
    cd_uf: string;
    nm_uf: string;
    sigla_uf: string;
    nm_regiao: string;
    area_km2: string;
    classifica: string;
    data: string;
    rigeo: string;
    relatorio: string;
    mapa: string;
    mapa_rec: string;
    sig: string;
    ege: string;
  };
}

export function MapaIndicedosMapasGeologicosEstaduais({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Mapa Índice dos Mapas Geológicos Estaduais</strong>
      </h4>
      <p>
        <strong>CD_UF: </strong>
        {properties.cd_uf ? properties.cd_uf : 'Não informado'}
      </p>
      <p>
        <strong>NM_UF: </strong>
        {properties.nm_uf ? properties.nm_uf : 'Não informado'}
      </p>
      <p>
        <strong>SIGLA_UF: </strong>
        {properties.sigla_uf ? properties.sigla_uf : 'Não informado'}
      </p>
      <p>
        <strong>NM_REGIAO: </strong>
        {properties.nm_regiao ? properties.nm_regiao : 'Não informado'}
      </p>
      <p>
        <strong>AREA_KM2: </strong>
        {properties.area_km2 ? properties.area_km2 : 'Não informado'}
      </p>
      <p>
        <strong>Produto: </strong>
        {properties.classifica ? properties.classifica : 'Não informado'}
      </p>
      <p>
        <strong>Data da Publicação: </strong>
        {properties.data ? properties.data : 'Não informado'}
      </p>
      <p>
        <strong>Metadados RIGEO: </strong>
        {properties.rigeo ? (
          <a href={properties.rigeo} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Download Relatório ou Notas Explicativas: </strong>
        {properties.relatorio ? (
          <a href={properties.relatorio} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Download Mapa Geológico em PDF: </strong>
        {properties.mapa ? (
          <a href={properties.mapa} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Download Mapa de Recursos Minerais em PDF: </strong>
        {properties.mapa_rec ? (
          <a href={properties.mapa_rec} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Download SIG (raster e vetores): </strong>
        {properties.sig ? (
          <a href={properties.sig} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Download Estudos Geoeconômicos Estaduais: </strong>
        {properties.ege ? (
          <a href={properties.ege} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
    </div>
  );
}
