import React from 'react';

interface IProperties {
  properties: {
    nome: string;
    ano_plan: number;
    tensao: number;
  };
}

export function LinhaTransmissaoPlanejado({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Linhas de Transmissão Planejado</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Ano de planejamento: </strong>{' '}
        {properties.ano_plan ? properties.ano_plan : 'Não informado'}
      </p>
      <p>
        <strong>Tensão: </strong>{' '}
        {properties.tensao
          ? properties.tensao.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
