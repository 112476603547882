import { SetStateAction } from 'react';
import { City } from 'shared/models/City';
import { MineralSubstance } from 'shared/models/MineralSubstance';
import { MineralSubstanceClasses } from 'shared/models/MineralSubstanceClasses';
import { ProductionChainStudies } from 'shared/models/ProductionChainStudies';
import { Region } from 'shared/models/Region';
import { State } from 'shared/models/State';
import {
  RepositoryJsonCity,
  RepositoryJsonMineralSubstance,
  RepositoryJsonMineralSubstanceClasses,
  RepositoryJsonProductionChainStudies,
  RepositoryJsonRegion,
  RepositoryJsonState,
} from 'shared/services/repositories/json';

type RenderAllListWithDataType = {
  id: number;
  allData: {
    setAllRegionNames: React.Dispatch<React.SetStateAction<string[]>>;
    setAllStateNames: (value: SetStateAction<string[]>) => void;
    setAllCityNames: (value: SetStateAction<string[]>) => void;
    setAllMineralSubstanceClassesNames: (
      value: SetStateAction<string[]>,
    ) => void;
    setAllMineralSubstanceNames: (value: SetStateAction<string[]>) => void;
    setAllProductionChainStudiesNames: (
      value: SetStateAction<string[]>,
    ) => void;
    setErrorInCallingTheApi: (value: React.SetStateAction<boolean>) => void;
  };
  filterValue?: string;
};

export async function renderAllListWithData({
  id,
  allData,
  filterValue,
}: RenderAllListWithDataType) {
  switch (id) {
    case 0:
      break;
    case 1:
      try {
        const repository = new RepositoryJsonRegion();
        const response = (await repository.getRegion(filterValue)) as Region[];

        const newArrayWithNames = [];
        for (let i = 0; i < response.length; i += 1) {
          newArrayWithNames.push(response[i].nome);
        }

        allData.setAllRegionNames(newArrayWithNames);
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    case 2:
      try {
        const repository = new RepositoryJsonState();
        if (filterValue !== undefined) {
          const response = (await repository.getStateFindRegion(
            filterValue,
          )) as State[];

          const newArrayWithNames = [];
          for (let i = 0; i < response.length; i += 1) {
            newArrayWithNames.push(response[i].nome);
          }

          allData.setAllStateNames(newArrayWithNames);
        } else {
          const response = (await repository.getState()) as State[];

          const newArrayWithNames = [];
          for (let i = 0; i < response.length; i += 1) {
            newArrayWithNames.push(response[i].nome);
          }

          allData.setAllStateNames(newArrayWithNames);
        }
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    case 3:
      try {
        const repository = new RepositoryJsonCity();
        const response = (await repository.getCity(filterValue)) as City[];

        const newArrayWithNames = [];
        for (let i = 0; i < response.length; i += 1) {
          newArrayWithNames.push(response[i].nome);
        }

        allData.setAllCityNames(newArrayWithNames);
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    case 4:
      try {
        const repository = new RepositoryJsonMineralSubstanceClasses();
        const response =
          (await repository.getMineralSubstanceClasses()) as MineralSubstanceClasses[];

        const newArrayWithNames = [];
        for (let i = 0; i < response.length; i += 1) {
          newArrayWithNames.push(response[i].nome);
        }

        allData.setAllMineralSubstanceClassesNames(newArrayWithNames);
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    case 5:
      try {
        const repository = new RepositoryJsonMineralSubstance();
        const response =
          (await repository.getMineralSubstance()) as MineralSubstance[];

        const newArrayWithNames = [];
        for (let i = 0; i < response.length; i += 1) {
          newArrayWithNames.push(response[i].nome);
        }

        allData.setAllMineralSubstanceNames(newArrayWithNames);
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    case 6:
      try {
        const repository = new RepositoryJsonProductionChainStudies();
        const response =
          (await repository.getProductionChainStudies()) as ProductionChainStudies[];
        const newArrayWithNames = [];
        for (let i = 0; i < response.length; i += 1) {
          newArrayWithNames.push(response[i].name);
        }
        allData.setAllProductionChainStudiesNames(newArrayWithNames);
      } catch (error) {
        allData.setErrorInCallingTheApi(true);
        setTimeout(() => allData.setErrorInCallingTheApi(false), 6000);
      }
      break;
    default:
      return '';
  }
  return '';
}
