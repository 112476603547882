import React from 'react';

interface IProperties {
  properties: {
    tot10: number;
    tot_hom10: number;
    tot_mul10: number;
    tot_urb10: number;
    tot_urb_hom10: number;
    tot_urb_mul10: number;
    tot_rur10: string;
    tot_rur_hom10: string;
    tot_rur_mul10: string;
  };
}

export function RendaNominalMensal({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Renda Nominal Mensal</strong>
      </h4>
      <p>
        <strong>Valor Total no ano de 2010 (R$): </strong>
        {properties.tot10 ? properties.tot10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Homens no ano de 2010 (R$): </strong>
        {properties.tot_hom10 ? properties.tot_hom10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Mulheres no ano de 2010 (R$): </strong>
        {properties.tot_mul10 ? properties.tot_mul10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Total na área Urbana no ano de 2010 (R$): </strong>
        {properties.tot_urb10 ? properties.tot_urb10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Homens na área Urbana no ano de 2010 (R$): </strong>
        {properties.tot_urb_hom10 ? properties.tot_urb_hom10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Mulheres na área Urbana no ano de 2010 (R$): </strong>
        {properties.tot_urb_mul10 ? properties.tot_urb_mul10.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Total na área Rural no ano de 2010 (R$): </strong>
        {properties.tot_rur10 ? properties.tot_rur10 : 'Não informado'}
      </p>
      <p>
        <strong>Valor Homens na área Rural no ano de 2010 (R$): </strong>
        {properties.tot_rur_hom10 ? properties.tot_rur_hom10 : 'Não informado'}
      </p>
      <p>
        <strong>Valor Mulheres na área Rural no ano de 2010 (R$): </strong>
        {properties.tot_rur_mul10 ? properties.tot_rur_mul10 : 'Não informado'}
      </p>
    </div>
  );
}
