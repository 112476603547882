import React, { memo, useEffect, useState } from 'react';
import { objectGraph } from 'shared/models/ModalInterface';
import { IValuesForFilterType } from 'shared/models/ValuesForFilter';
import WrapperGraph from '../../WrapperGraph';
import * as Styled from './style';

type ColumnOcultGraphType = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  setFilterValue: React.Dispatch<React.SetStateAction<IValuesForFilterType>>;
};

function ColumnOcultGraph({
  isClickedTopMenu,
  setSelectedOptionChart,
  setFilterValue,
}: ColumnOcultGraphType) {
  const [ocultGraphics, setOcultGraphics] = useState<objectGraph[]>(
    {} as objectGraph[],
  );
  const [updateInOcultGraphics, setUpdateInOcultGraphics] = useState(false);

  useEffect(() => {
    setFilterValue({});
    const getItemStorage = JSON.parse(
      localStorage.getItem('ocultGraph') || '{}',
    );
    if (Object.keys(getItemStorage).length > 0) {
      const object = getItemStorage.ocultGraph as objectGraph[];
      setOcultGraphics(object);
    }
  }, []);

  useEffect(() => {
    if (updateInOcultGraphics) {
      const getItemStorage = JSON.parse(
        localStorage.getItem('ocultGraph') || '{}',
      );

      if (Object.values(getItemStorage).length > 0) {
        const object = getItemStorage.ocultGraph as objectGraph[];
        setOcultGraphics(object);
      } else {
        setOcultGraphics([]);
      }
      setUpdateInOcultGraphics(false);
    }
  }, [updateInOcultGraphics]);

  return (
    <Styled.Wrapper>
      {Object.keys(ocultGraphics).length === 0 && (
        <div className="noExistsChart">
          <p>Não existem gráficos ocultos</p>
        </div>
      )}
      {Object.values(ocultGraphics).length > 0 &&
        ocultGraphics.map(ocultGraph => (
          <div key={`${Date.now()}${ocultGraph.titleGraph}`} id={ocultGraph.id}>
            <WrapperGraph
              chartModel={ocultGraph.chartModel}
              titleGraph={ocultGraph.titleGraph}
              isClickedTopMenu={isClickedTopMenu}
              setUpdateGraphics={setUpdateInOcultGraphics}
              data={ocultGraph.data}
              fontUsedLink={ocultGraph.fontUsedLink}
              setSelectedOptionChart={setSelectedOptionChart}
              id={ocultGraph.id}
            />
          </div>
        ))}
    </Styled.Wrapper>
  );
}

export default memo(ColumnOcultGraph);
