import React, { useEffect, useRef, useState } from 'react';
import { LayerSelect, Layer } from 'components';
import { useLayers } from 'hooks/useLayers';
import { ICategory, ILayers, ISubTheme, ITheme } from 'contexts/@types/Layers';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { RiMenuSearchLine } from 'react-icons/ri';

interface ILayersProps {
  handleChangeTabIndex: (index: number, text: string, icon?: React.ReactNode) => void;
}

function clearText(text: string) {
  return text
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function Layers({ handleChangeTabIndex }: ILayersProps) {
  const [query, setQuery] = useState<string>('');
  const { categoryList, handleOpenCategory, handleOpenTheme, handleOpenSubTheme, handleCountLayersActived } = useLayers();

  const { t: translation, i18n } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [filteredCategories, setFilteredCategories] = useState<Set<number>>(new Set());
  const [filteredTheme, setFilteredTheme] = useState<Set<number>>(new Set());
  const [filteredSubTheme, setFilteredSubTheme] = useState<Set<number>>(new Set());
  const [filteredLayer, setFilteredLayer] = useState<Set<number>>(new Set());

  const renderLayers = (layers: ILayers[], category_name: string) => {
    return layers.map(layer => {
      if (query && !filteredLayer.has(layer.id)) return;

      return (
        <Layer
          key={'layer_' + layer.layertitle}
          layertitle={layer.layertitle}
          layer={layer}
          is_new={!!layer.nova_camada}
          handleChangeTabIndex={handleChangeTabIndex}
          checked={false}
          nm_category={category_name}
        />
      );
    });
  };

  useEffect(() => {
    handleCountLayersActived();
  }, []);

  const updateStates = (category_id: number, theme_id?: number, subtheme_id?: number, layer_id?: number) => {
    setFilteredCategories(prevState => prevState.add(category_id));
    if (theme_id) setFilteredTheme(prevState => prevState.add(theme_id));
    if (subtheme_id) setFilteredSubTheme(prevState => prevState.add(subtheme_id));
    if (layer_id) setFilteredLayer(prevState => prevState.add(layer_id));
  };

  useEffect(() => {
    setFilteredCategories(new Set());
    setFilteredTheme(new Set());
    setFilteredSubTheme(new Set());
    setFilteredLayer(new Set());
    if (!query) return;

    categoryList.forEach(category => {
      if (clearText(category.nm_categoria).includes(clearText(query))) {
        updateStates(category.id_categoria);
      }
      category.temas.forEach(theme => {
        if (clearText(theme.nm_tema).includes(clearText(query))) {
          updateStates(category.id_categoria, theme.id_tema);
        }
        theme.subtema?.forEach(subTheme => {
          if (clearText(subTheme.nm_subtema).includes(clearText(query))) {
            updateStates(category.id_categoria, theme.id_tema, subTheme.id_subtema);
          }
          subTheme.layers?.forEach(layer => {
            if (clearText(layer.layertitle).includes(clearText(query))) {
              updateStates(category.id_categoria, theme.id_tema, subTheme.id_subtema, layer.id);
            }
          });
        });
        theme.layers?.forEach(layer => {
          if (clearText(layer.layertitle).includes(clearText(query))) {
            setFilteredCategories(prevState => prevState.add(category.id_categoria));
            setFilteredTheme(prevState => prevState.add(theme.id_tema));
            setFilteredLayer(prevState => prevState.add(layer.id));
          }
        });
      });
    });
  }, [query]);

  return (
    <Styled.Wrapper>
      <div
        className="inputWrapper"
        onClick={() => {
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        <RiMenuSearchLine size={24} />
        <input
          ref={inputRef}
          placeholder={translation('mapPage.sideBar.layers.search')}
          className="textInput"
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
      </div>
      {(!query || filteredCategories.size !== 0) &&
        categoryList.map((category: ICategory, indexCategory: number) => {
          if (query && !filteredCategories.has(category.id_categoria)) return;

          return (
            <LayerSelect
              id={'category_' + category.id_categoria}
              name={category.nm_categoria}
              key={'category_' + category.id_categoria}
              qtdOpened={category.qtdOpenend}
              opened={!!category.opened}
              onChange={() => handleOpenCategory(!category.opened, indexCategory)}
            >
              {category.temas.map((theme: ITheme, indexTheme: number) => {
                if (!theme.visivel) return;
                if (query && !filteredTheme.has(theme.id_tema)) return;

                return (
                  <LayerSelect
                    id={'theme_' + theme.id_tema}
                    name={theme.nm_tema}
                    key={'theme_' + theme.id_tema}
                    qtdOpened={theme.qtdOpenend}
                    opened={!!theme.opened}
                    onChange={() => handleOpenTheme(!theme.opened, indexCategory, indexTheme)}
                  >
                    {theme.layers && theme.layers?.length ? renderLayers(theme.layers, theme.nm_tema) : ''}

                    {theme.subtema && theme.subtema?.length ? (
                      <>
                        {theme.subtema.map((subTheme: ISubTheme, indexSubTheme: number) => {
                          if (!subTheme.visivel) return;
                          if (query && !filteredSubTheme.has(subTheme.id_subtema)) return;

                          return (
                            <LayerSelect
                              id={'sub_theme_' + subTheme.id_subtema}
                              name={subTheme.nm_subtema}
                              key={'sub_theme_' + subTheme.id_subtema}
                              qtdOpened={subTheme.qtdOpenend}
                              opened={!!subTheme.opened}
                              is_new={false}
                              onChange={() => handleOpenSubTheme(!subTheme.opened, indexCategory, indexTheme, indexSubTheme)}
                            >
                              {subTheme.layers && subTheme.layers?.length
                                ? renderLayers(subTheme.layers, subTheme.nm_subtema)
                                : ''}
                            </LayerSelect>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </LayerSelect>
                );
              })}
            </LayerSelect>
          );
        })}
      {query && filteredCategories.size === 0 && (
        <div className="notFound">
          <h2>{translation('mapPage.sideBar.layers.notFound')}</h2>
        </div>
      )}
    </Styled.Wrapper>
  );
}

{
  /* <span>
<strong>Não há camadas habilitadas para esse tema</strong>
</span> */
}
