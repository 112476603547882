export const Environment = {
  /**
   * Quantidade de linhas das listagens
   */
  LIMITE_DE_LINHAS: 20,

  /**
   * Texto padrão para o campo de busca
   */
  INPUT_DE_BUSCA: 'Pesquisar...',

  /**
   * Texto quando não existe registros
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado',

  /**
   * Url base bara a api
   */
  API_URL_BASE: `${process.env.REACT_APP_URL_BASE_API}`,
  // API_URL_BASE: `http://127.0.0.1:8000/api`,
  API_MOCK: `${process.env.REACT_APP_MOCK}`,
};
