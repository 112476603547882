export const changeText = (
  text: string,
  oldState: string,
  setNewText: React.Dispatch<React.SetStateAction<string>>,
  isActive: boolean,
  multipleSelected: boolean,
) => {
  if (isActive && oldState !== '' && multipleSelected) {
    const textSlice = oldState.split(', ');
    const validatesWhetherTheTextExists = textSlice.filter(
      posicao => posicao === text,
    );

    if (validatesWhetherTheTextExists.length === 0) {
      const newText = `${oldState}, ${text}`;
      setNewText(newText);
    }
  }

  if (isActive && oldState === '' && multipleSelected) {
    setNewText(text);
  }

  if (!isActive && oldState !== '' && multipleSelected) {
    const textSlice = oldState.split(', ');
    const arrayOfText = textSlice.filter(posicao => posicao !== text);

    const newArray = arrayOfText.join(', ');
    setNewText(newArray);
  }
};
