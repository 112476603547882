import React from 'react';

interface IProperties {
  properties: {
    nome: string;
    concession: string;
    tensao: number;
  };
}

export function LinhaTransmissaoExistente({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Linhas de Transmissão Existente</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Concessão: </strong>{' '}
        {properties.concession ? properties.concession : 'Não informado'}
      </p>
      <p>
        <strong>Tensão: </strong>{' '}
        {properties.tensao
          ? properties.tensao.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
