import styled, { createGlobalStyle, css } from 'styled-components';
import { transparentize } from 'polished';

interface ILayerBlock {
  isActived: boolean;
}

interface IButton {
  label: string;
}

const activedModifers = {
  actived: css`
    #icon-minimize {
      transform: rotate(-180deg);
    }
  `,
};

export const Box = styled.div`
  position: relative;
  width: 250px;
  margin: 5px;
`;

export const GlobalStyles = createGlobalStyle`
${({ theme }) => css`
  .react-resizable {
    position: fixed;
    width: 100%;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    color: ${theme.colors.white};
  }
  .react-resizable-handle-se {
    bottom: -34px;
    right: 4px;
    cursor: se-resize;
  }
`}
`;

export const Wrapper = styled.div<ILayerBlock>`
  ${({ theme, isActived }) => css`
    width: 100%;
    height: ${isActived ? '100%' : '0%'};
    margin-right: ${theme.spacings[10]};
    border: 0;
    border-radius: ${theme.radius.xs};
    background-color: ${theme.colors.white};

    min-height: 44px;
    position: relative;
  `}
`;

export const LayerBlock = styled.div<ILayerBlock>`
  ${({ theme, isActived }) => css`
    width: 100%;
    margin-top: ${theme.spacings[10]};
    padding: ${theme.spacings[6]} ${theme.spacings[8]};
    display: flex;
    align-items: center;
    border-top-right-radius: ${theme.radius.xs};
    border-top-left-radius: ${theme.radius.xs};
    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.md};

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.sm};
    }

    svg {
      width: ${theme.font.sizes.xl};
      transition: all 0.2s;
    }

    #btn-subtitle {
      transform: rotate(-360deg);
      margin-right: ${theme.spacings[6]};
      cursor: auto;
    }

    #btn-dragglable {
      margin-left: auto;
    }

    #btn-subtitle,
    #btn-dragglable,
    #title-dragglable {
      cursor: grab;
    }

    p {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${!isActived && activedModifers.actived}

    ${!isActived && 'border-radius: ' + theme.radius.xs}
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    max-height: 29px;
    padding: ${theme.spacings[6]} ${theme.spacings[8]};
    display: flex;
    align-items: center;
    border-bottom-right-radius: ${theme.radius.xs};
    border-bottom-left-radius: ${theme.radius.xs};
    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};

    .counter {
      margin-left: 5px;
      font-size: ${theme.font.sizes.xs};
      font-family: ${theme.font.family.default};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100% - 44px);
    background-color: ${theme.colors.white};
    padding: 10px;
    display: grid;
    grid-auto-rows: min-content;

    img {
      pointer-events: none;
    }

    p {
      color: ${theme.colors.gray900};
      font-family: sans-serif;
    }

    a {
      color: #1e90ff;
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }
  `}
`;

export const Button = styled.button<IButton>`
  ${({ theme, label }) => css`
    position: relative;
    display: inline-flex;
    justify-content: center;
    border: 0;
    background-color: transparent;
    color: #fff;

    & + button {
      margin-left: ${theme.spacings[6]};
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: '${label}';
      padding: ${theme.spacings[6]};
      position: absolute;
      top: -3rem;

      font-family: ${theme.font.family.default};
      font-size: ${theme.font.sizes.xs};
      font-weight: ${theme.font.weights.bold};

      border: 1px solid ${theme.colors.white};
      border-radius: 0.4rem;

      background-color: ${theme.colors.black};
      color: ${theme.colors.white};

      opacity: 0;
      transition: all 0.2s;
    }
  `}
`;
