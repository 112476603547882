import React from 'react';

interface IProperties {
  properties: {
    ano_2002: number;
    ano_2003: number;
    ano_2004: number;
    ano_2005: number;
    ano_2006: number;
    ano_2007: number;
    ano_2008: number;
    ano_2009: number;
    ano_2010: number;
    ano_2011: number;
    ano_2012: number;
    ano_2013: number;
    ano_2014: number;
    ano_2015: number;
    ano_2016: number;
    ano_2017: number;
    ano_2018: number;
    ano_2019: number;
  };
}

export function Pib({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Produto Interno Bruto (PIB)</strong>
      </h4>
      <p>
        <strong>Produto Interno Bruto no Ano de 2002: </strong>{' '}
        {properties.ano_2002
          ? properties.ano_2002.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2003: </strong>{' '}
        {properties.ano_2003
          ? properties.ano_2003.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2004: </strong>{' '}
        {properties.ano_2004
          ? properties.ano_2004.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2005: </strong>{' '}
        {properties.ano_2005
          ? properties.ano_2005.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2006: </strong>{' '}
        {properties.ano_2006
          ? properties.ano_2006.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2007: </strong>{' '}
        {properties.ano_2007
          ? properties.ano_2007.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2008: </strong>{' '}
        {properties.ano_2008
          ? properties.ano_2008.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2009: </strong>{' '}
        {properties.ano_2009
          ? properties.ano_2009.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2010: </strong>{' '}
        {properties.ano_2010
          ? properties.ano_2010.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2011: </strong>{' '}
        {properties.ano_2011
          ? properties.ano_2011.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2012: </strong>{' '}
        {properties.ano_2012
          ? properties.ano_2012.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2013: </strong>{' '}
        {properties.ano_2013
          ? properties.ano_2013.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2014: </strong>{' '}
        {properties.ano_2014
          ? properties.ano_2014.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2015: </strong>{' '}
        {properties.ano_2015
          ? properties.ano_2015.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2016: </strong>{' '}
        {properties.ano_2016
          ? properties.ano_2016.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2017: </strong>{' '}
        {properties.ano_2017
          ? properties.ano_2017.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2018: </strong>{' '}
        {properties.ano_2018
          ? properties.ano_2018.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Produto Interno Bruto no Ano de 2019: </strong>{' '}
        {properties.ano_2019
          ? properties.ano_2019.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
