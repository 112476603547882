import React from 'react';

interface IProperties {
  properties: {
    sigla_uf: string;
  };
}

export function LimiteMesorregioes({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Limite de Mesorregiões</strong>
      </h4>
      <p>
        <strong>Unidade Federativa: </strong>
        {properties.sigla_uf ? properties.sigla_uf : 'Não informado'}
      </p>
    </div>
  );
}
