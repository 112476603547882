import React from 'react';

interface IProperties {
  properties: {
    num_rodada: string;
    nm_campo: string;
    area_campo: number;
    nm_bacia: string;
    cd_campo: number;
    sig_campo: string;
    dat_descob: string;
    dat_inicio: string;
    etapa: string;
  };
}

export function CamposProducao({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Campos de Produção</strong>
      </h4>
      <p>
        <strong>Número da rodada: </strong>{' '}
        {properties.num_rodada ? properties.num_rodada : 'Não informado'}
      </p>
      <p>
        <strong>Nome do campo: </strong>{' '}
        {properties.nm_campo ? properties.nm_campo : 'Não informado'}
      </p>
      <p>
        <strong>Área do campo: </strong>{' '}
        {properties.area_campo
          ? properties.area_campo.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Nome da bacia: </strong>{' '}
        {properties.nm_bacia ? properties.nm_bacia : 'Não informado'}
      </p>
      <p>
        <strong>Código do campo: </strong>{' '}
        {properties.cd_campo
          ? properties.cd_campo.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Sigla do campo: </strong>{' '}
        {properties.sig_campo ? properties.sig_campo : 'Não informado'}
      </p>
      <p>
        <strong>Data de descobrimento: </strong>{' '}
        {properties.dat_descob ? properties.dat_descob : 'Não informado'}
      </p>
      <p>
        <strong>Data de início: </strong>{' '}
        {properties.dat_inicio ? properties.dat_inicio : 'Não informado'}
      </p>
      <p>
        <strong>Etapa: </strong>{' '}
        {properties.etapa ? properties.etapa : 'Não informado'}
      </p>
    </div>
  );
}
