import styled, { css } from 'styled-components';

type WrapperType = {
  isClicked: string;
  // openFilter: boolean;
};
/* animation-name: ${openFilter ? 'addWidth' : 'removeWidth'}; */

export const Wrapper = styled.section<WrapperType>`
  ${({ theme }) => css`
    height: max-content;
    margin-top: 6rem;
    background-color: ${theme.colors.gray200};
    padding: ${theme.spacings[24]} ${theme.spacings[48]};
    overflow-x: auto;
    animation-duration: 1s;
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;

    @keyframes addWidth {
      0% {
        width: calc(100vw - 9rem);
      }
      100% {
        width: calc(100vw - 35rem);
      }
    }

    @keyframes removeWidth {
      0% {
        width: calc(100vw - 35rem);
      }
      100% {
        width: calc(100vw - 9rem);
      }
    }
  `}
`;
