import React from 'react';

interface IProperties {
  properties: {
    toponimia: string;
    mun: string;
    uf: string;
    tp_aflor: string;
    descricao: string;
  };
}

export function AfloramentoGeologico({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Afloramentos Geológicos</strong>
      </h4>
      <p>
        <strong>Toponímia: </strong>{' '}
        {properties.toponimia ? properties.toponimia : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.mun ? properties.mun : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>{' '}
        {properties.tp_aflor ? properties.tp_aflor : 'Não informado'}
      </p>
      <p>
        <strong>Descrição: </strong>{' '}
        {properties.descricao ? properties.descricao : 'Não informado'}
      </p>
    </div>
  );
}
