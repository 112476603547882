import React, { useEffect, useRef, useState } from 'react';
import * as RiIcons from 'react-icons/ri';

import theme from 'styles/theme';
import { GraphicDataRequest } from 'screens/NewDashboard/@types/graphic';

import { CardDataRequest } from 'screens/NewDashboard/@types/cards';
import { useClickOutside } from 'hooks/useClickOutside';
import { useOptions } from 'hooks/useOptions';
import { useNavigate } from 'react-router-dom';
// import { useMap } from 'hooks/useMap';
import { useTranslation } from 'react-i18next';
import { Wrapper, OptionsMenu } from './styles';

interface OptionsProps {
  data: GraphicDataRequest | CardDataRequest;
  type: string;
  color: string;
}

export function Options({ data, type, color }: OptionsProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const { t: translation } = useTranslation();

  const selectRef = useRef<HTMLDivElement>(null);

  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { update, setUpdate } = useOptions();
  const { setOpenMap } = useOptions();

  const favoriteGraphics: GraphicDataRequest[] = JSON.parse(localStorage.getItem('favoriteGraphics') || '[]');

  const favoriteCards: CardDataRequest[] = JSON.parse(localStorage.getItem('favoriteCards') || '[]');

  const hiddenGraphics: GraphicDataRequest[] = JSON.parse(localStorage.getItem('hiddenGraphics') || '[]');

  const hiddenCards: CardDataRequest[] = JSON.parse(localStorage.getItem('hiddenCards') || '[]');

  function handleShowMap() {
    // localStorage.setItem('dashboardOptions', JSON.stringify({}));
    localStorage.setItem(
      'dashboardOptions',
      JSON.stringify({
        aditionalInformation: data.aditionalInformation,
        theme: data.theme,
        layername: data.layername,
      }),
    );
    setIsDisabled(true);
    navigate('/map');

    setTimeout(() => {
      setOpenMap(true);
    }, 1000);
  }

  function handleFavoriteGraph() {
    if (isFavorite) {
      if (type === 'graphic') {
        const newFavoriteGraphics = favoriteGraphics.filter(graph => graph.title !== data.title);

        localStorage.setItem('favoriteGraphics', JSON.stringify(newFavoriteGraphics));
      } else {
        const newFavoriteCards = favoriteCards.filter(card => card.title !== data.title);

        localStorage.setItem('favoriteCards', JSON.stringify(newFavoriteCards));
      }
    } else if (type === 'graphic') {
      localStorage.setItem('favoriteGraphics', JSON.stringify([...favoriteGraphics, data]));
    } else {
      localStorage.setItem('favoriteCards', JSON.stringify([...favoriteCards, data]));
    }
    if (window.location.hash === '#/dashboard' || window.location.hash === '#/hidden') {
      setIsFavorite(!isFavorite);
    } else {
      setIsMenuOpen(false);
    }

    setUpdate(!update);
  }

  function handleChangeGraphStatus() {
    if (isHidden) {
      if (type === 'graphic') {
        const newHiddenGraphics = hiddenGraphics.filter(graph => graph.title !== data.title);

        localStorage.setItem('hiddenGraphics', JSON.stringify(newHiddenGraphics));
      } else {
        const newHiddenCards = hiddenCards.filter(card => card.title !== data.title);

        localStorage.setItem('hiddenCards', JSON.stringify(newHiddenCards));
      }
    } else if (type === 'graphic') {
      localStorage.setItem('hiddenGraphics', JSON.stringify([...hiddenGraphics, data]));
    } else {
      localStorage.setItem('hiddenCards', JSON.stringify([...hiddenCards, data]));
    }
    if (window.location.hash === '#/favorites') {
      setIsHidden(!isHidden);
    } else {
      setIsMenuOpen(false);
    }
    setUpdate(!update);
  }

  useEffect(() => {
    const favoriteGraph = favoriteGraphics.find(graph => graph.title === data.title);
    const favoriteCard = favoriteCards.find(card => card.title === data.title);

    const hiddenGraph = hiddenGraphics.find(graph => graph.title === data.title);
    const hiddenCard = hiddenCards.find(card => card.title === data.title);

    if (favoriteGraph || favoriteCard) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
    if (hiddenGraph || hiddenCard) {
      return setIsHidden(true);
    }
    return setIsHidden(false);
  }, [isFavorite, isHidden]);

  useClickOutside(selectRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <Wrapper ref={selectRef}>
      <button type="button">
        <RiIcons.RiMenuLine size={18} color={color} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      </button>
      {isMenuOpen && (
        <OptionsMenu>
          <button type="button" onClick={handleChangeGraphStatus} disabled={isDisabled}>
            {isHidden ? (
              <>
                <RiIcons.RiEyeFill size={18} />
                <span>{translation('dashboardPage.graphics.menu.unhideGraphic')}</span>
              </>
            ) : (
              <>
                <RiIcons.RiEyeOffFill size={18} />
                <span>{translation('dashboardPage.graphics.menu.hiddenGraphic')}</span>
              </>
            )}
          </button>
          <button type="button" onClick={handleFavoriteGraph} disabled={isDisabled}>
            {isFavorite ? (
              <>
                <RiIcons.RiStarFill size={18} color={theme.colors.yellow} />
                <span>{translation('dashboardPage.graphics.menu.disfavor')}</span>
              </>
            ) : (
              <>
                <RiIcons.RiStarLine size={18} />
                <span>{translation('dashboardPage.graphics.menu.favoriteGraphic')}</span>
              </>
            )}
          </button>
        </OptionsMenu>
      )}
    </Wrapper>
  );
}
