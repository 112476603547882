import React from 'react';

interface IProperties {
  properties: {
    indice_gre: string;
    obs: string;
    uso2000: string;
    uso2010: string;
    uso2012: string;
    uso2014: string;
    uso2016: string;
    uso2018: string;
    uso2020: string;
  };
}

export function CoberturaUsoSolo({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Cobertura e Uso do Solo</strong>
      </h4>
      <p>
        <strong>Índice GRE: </strong>{' '}
        {properties.indice_gre ? properties.indice_gre : 'Não informado'}
      </p>
      <p>
        <strong>Observações: </strong>{' '}
        {properties.obs ? properties.obs : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2010: </strong>{' '}
        {properties.uso2010 ? properties.uso2010 : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2012: </strong>{' '}
        {properties.uso2012 ? properties.uso2012 : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2014: </strong>{' '}
        {properties.uso2014 ? properties.uso2014 : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2016: </strong>{' '}
        {properties.uso2016 ? properties.uso2016 : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2018: </strong>{' '}
        {properties.uso2018 ? properties.uso2018 : 'Não informado'}
      </p>
      <p>
        <strong>Gridcode uso para levantamento de 2020: </strong>{' '}
        {properties.uso2020 ? properties.uso2020 : 'Não informado'}
      </p>
    </div>
  );
}
