export default [
  {
    title: 'Satélite - Instituto Cartográfico e Geológico da Catalunha',
    url: 'https://geoserveis.icgc.cat/servei/catalunya/contextmaps/wmts/contextmaps-orto-estandard/MON3857NW/{z}/{x}/{y}.png',
    maxZoon: 19,
    minZoon: 3,
    crossOrigin: 'Anonymous',
  },
  {
    title: 'Openstreet Road',
    url: 'http://tile.openstreetmap.org/{z}/{x}/{y}.png',
    maxZoon: 19,
    minZoon: 3,
    crossOrigin: 'Anonymous',
  },
];
