import styled, { css } from 'styled-components';
import theme from 'styles/theme';
// import { transparentize } from 'polished';

interface MainContainerProps {
  active: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  ${({ theme, active }) => css`
    color: white;

    position: fixed;
    right: ${theme.spacings[24]};
    bottom: ${theme.spacings[24]};

    .map {
      font-size: 28px;

      .tooltip {
        padding: ${theme.spacings[8]} ${theme.spacings[12]};
        position: absolute;
        top: -7.6rem;

        display: flex;
        justify-content: center;

        font-size: ${theme.font.sizes.sm};
        font-weight: ${theme.font.weights.bold};
        text-align: center;

        border: 1px solid ${theme.colors.white};
        border-radius: ${theme.radius.xs};

        background-color: ${theme.colors.blue700};
        color: ${theme.colors.white};

        opacity: 1;
        pointer-events: none;
        transition: all 0.2s;

        &::before {
          content: '';
          position: absolute;
          top: 105%;

          border: 1rem solid transparent;
          border-top-color: ${theme.colors.blue700};
        }
      }
    }

    @media (max-height: 600px) {
      .map {
        right: ${active ? '130px' : '40px'};
        bottom: 115px;
      }
      .hub {
        right: ${active ? '220px' : '40px'};
        bottom: 115px;
      }
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    width: 8rem;
    height: 8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${theme.colors.white};
    border-radius: ${theme.radius.full};

    background-color: ${theme.colors.blue700};

    transition: all 0.2s;

    &:hover {
      background-color: ${theme.colors.indigo800};
    }

    .tooltip {
      padding: ${theme.spacings[8]} ${theme.spacings[12]};
      position: absolute;
      top: -5.6rem;

      display: flex;
      justify-content: center;

      font-size: ${theme.font.sizes.sm};
      font-weight: ${theme.font.weights.bold};
      text-align: left;

      border: 1px solid ${theme.colors.white};
      border-radius: ${theme.radius.xs};

      background-color: ${theme.colors.blue700};
      color: ${theme.colors.white};

      opacity: 1;
      pointer-events: none;
      transition: all 0.2s;

      &::before {
        content: '';
        position: absolute;
        top: 105%;

        border: 1rem solid transparent;
        border-top-color: ${theme.colors.blue700};
      }
    }

    img {
      width: 4.8rem;
    }

    font-size: 42px;

    @media (max-width: 600px) {
      width: 7rem;
      height: 7rem;
      right: ${theme.spacings[24]};

      img {
        width: 4rem;
      }

      font-size: 36px;

      .tooltip {
        font-size: ${theme.font.sizes.xs};
        top: -7rem;
      }
    }

    @media (max-width: 400px) {
      width: 6rem;
      height: 6rem;
      right: ${theme.spacings[24]};

      img {
        width: 3.2rem;
      }

      font-size: 28px;

      .tooltip {
        font-size: ${theme.font.sizes.xxs};
        top: -6.7rem;
      }
    }
  `}
`;
