import { SetStateAction } from 'react';
import { objectGraph } from 'shared/models/ModalInterface';

type CheckIsFavoriteGraphType = {
  graphObject: objectGraph | null;
  setIsFavoriteOrSetIsOcult: React.Dispatch<SetStateAction<boolean>>;
  favoriteGraphOrOcultGraph: 'favoriteGraphics' | 'ocultGraph';
};

export function checkIsFavoriteGraphOrCheckIsOcultGraph({
  graphObject,
  setIsFavoriteOrSetIsOcult,
  favoriteGraphOrOcultGraph,
}: CheckIsFavoriteGraphType) {
  const favoriteGraphicsOrOcultGraphInLocalStorage = JSON.parse(
    localStorage.getItem(favoriteGraphOrOcultGraph) || '{}',
  );

  if (
    Object.values(favoriteGraphicsOrOcultGraphInLocalStorage).length > 0 &&
    graphObject !== null
  ) {
    if (favoriteGraphOrOcultGraph === 'favoriteGraphics') {
      const arrayFavoriteGraphs =
        favoriteGraphicsOrOcultGraphInLocalStorage.favoriteGraphics as objectGraph[];

      const favoriteExists = arrayFavoriteGraphs.filter(
        posicao => posicao.titleGraph === graphObject?.titleGraph,
      );

      if (favoriteExists.length > 0) {
        setIsFavoriteOrSetIsOcult(true);
      } else {
        setIsFavoriteOrSetIsOcult(false);
      }
    } else {
      const arrayFavoriteGraphs =
        favoriteGraphicsOrOcultGraphInLocalStorage.ocultGraph as objectGraph[];

      const array = arrayFavoriteGraphs.filter(
        posicao => posicao.titleGraph === graphObject?.titleGraph,
      );

      if (array.length > 0) {
        setIsFavoriteOrSetIsOcult(true);
      } else {
        setIsFavoriteOrSetIsOcult(false);
      }
    }
  }
}
