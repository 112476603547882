import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import { sectionsOrganization } from './linksOrganization';

export function Organization() {
  const { t: translation } = useTranslation();

  return (
    <Styled.Wrapper>
      <div>
        {sectionsOrganization.map((section, index) => {
          const lasElement = sectionsOrganization.length - 1 === index;

          return (
            <React.Fragment key={section.UUID}>
              <section key={section.UUID}>
                <h1>{section.title}</h1>
                <Styled.Content>
                  {section.links.map(link => {
                    return (
                      <Styled.ContanierLink key={link.UUID}>
                        <a href={link.href} target="_blank" rel="noreferrer">
                          {link.label}
                        </a>
                      </Styled.ContanierLink>
                    );
                  })}
                </Styled.Content>
              </section>
              {!lasElement && <hr />}
            </React.Fragment>
          );
        })}
      </div>
    </Styled.Wrapper>
  );
}
