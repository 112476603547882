import React from 'react';

interface IProperties {
  properties: {
    br_uf: string;
    total_2005: number;
    homens_2005: number;
    mulheres_2005: number;
    total_2006: number;
    homens_2006: number;
    mulheres_2006: number;
  };
}

export function IndiceGini({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Índice de Gini</strong>
      </h4>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.br_uf ? properties.br_uf : 'Não informado'}
      </p>
      <p>
        <strong>Número total para o ano de 2005: </strong>{' '}
        {properties.total_2005
          ? properties.total_2005.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Número de homens para o ano de 2005: </strong>{' '}
        {properties.homens_2005
          ? properties.homens_2005.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Número de mulheres para o ano de 2005: </strong>{' '}
        {properties.mulheres_2005
          ? properties.mulheres_2005.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Número total para o ano de 2006: </strong>{' '}
        {properties.total_2006
          ? properties.total_2006.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Número de homens para o ano de 2006: </strong>{' '}
        {properties.homens_2006
          ? properties.homens_2006.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Número de mulheres para o ano de 2006: </strong>{' '}
        {properties.mulheres_2006
          ? properties.mulheres_2006.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
