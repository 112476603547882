import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[12]};
    overflow-y: auto;

    .textInput {
      width: 100%;
      height: 100%;
      margin-left: ${theme.spacings[6]};
      border: none;
      outline: none;
      background-color: ${theme.colors.white};
    }

    .inputWrapper {
      width: 100%;
      min-height: 34px;
      margin-top: ${theme.spacings[2]};
      margin-bottom: ${theme.spacings[10]};
      padding: ${theme.spacings[4]} ${theme.spacings[8]};

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: 1px solid ${theme.colors.gray900};
      border-radius: 0.4rem;

      cursor: text;

      background: ${theme.colors.white};

      @media (max-height: 740px) {
        height: 20px;
      }
    }

    .notFound {
      text-align: center;
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[6]};
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }
  `}
`;
