import axios from 'axios';
import { Environment } from 'shared/environments';
import { ProductionChainStudies } from 'shared/models/ProductionChainStudies';
import { ProductionChainStudiesService } from 'shared/services/productionChainStudies/ProductionChainStudiesService';

export class RepositoryJsonProductionChainStudies implements ProductionChainStudiesService {
  async getProductionChainStudies(): Promise<ProductionChainStudies[] | Error> {
    try {
      const endPoint = `${Environment.API_URL_BASE}/cadeia_produtiva/`;
      const { data } = await axios.get<ProductionChainStudies[]>(endPoint);
      return data;
    } catch (error: any) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
