import React from 'react';

interface IProperties {
  properties: {
    terrai_nome: string;
    etnia_nome: string;
    municipio_nome: string;
    uf_sigla: string;
    fase_ti: string;
    mod_ti: string;
    faixa_front: string;
    undadm_no: string;
    undadm_sig: string;
  };
}

export function TerrasIndigenas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Terras Indígenas</strong>
      </h4>
      <p>
        <strong>Nome da terra indigena: </strong>{' '}
        {properties.terrai_nome ? properties.terrai_nome : 'Não informado'}
      </p>
      <p>
        <strong>Nome da etnia: </strong>{' '}
        {properties.etnia_nome ? properties.etnia_nome : 'Não informado'}
      </p>
      <p>
        <strong>Nome do municipio: </strong>{' '}
        {properties.municipio_nome
          ? properties.municipio_nome
          : 'Não informado'}
      </p>
      <p>
        <strong>Sigla da Unidade Federativa: </strong>{' '}
        {properties.uf_sigla ? properties.uf_sigla : 'Não informado'}
      </p>
      <p>
        <strong>Fase de regulamentação: </strong>{' '}
        {properties.fase_ti ? properties.fase_ti : 'Não informado'}
      </p>
      <p>
        <strong>Modalidade de terra indígena: </strong>{' '}
        {properties.mod_ti ? properties.mod_ti : 'Não informado'}
      </p>
      <p>
        <strong>Faixa da fronteira: </strong>{' '}
        {properties.faixa_front ? properties.faixa_front : 'Não informado'}
      </p>
      <p>
        <strong>Nome da Unidade administrativa: </strong>{' '}
        {properties.undadm_no ? properties.undadm_no : 'Não informado'}
      </p>
      <p>
        <strong>Sigla da unidade administrativa: </strong>{' '}
        {properties.undadm_sig ? properties.undadm_sig : 'Não informado'}
      </p>
    </div>
  );
}
