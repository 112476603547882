import styled, { css } from 'styled-components';

interface ChartContainerProps {
  size?: string;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  ${({ size }) => css`
    width: 100%;

    .labelLegend {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      font-family: Arial;
      font-size: 13px;
    }
  `}
`;
