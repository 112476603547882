import axios from 'axios';
import { Environment } from 'shared/environments';
import { MineralSubstance } from 'shared/models/MineralSubstance';
import { MineralSubstanceService } from 'shared/services/mineralSubstance/MineralSubstanceService';

export class RepositoryJsonMineralSubstance implements MineralSubstanceService {
  async getMineralSubstance(): Promise<MineralSubstance[] | Error> {
    try {
      const endPoint = `${Environment.API_URL_BASE}/subst_mineral/`;
      const { data } = await axios.get<MineralSubstance[]>(endPoint);
      return data;
    } catch (error: any) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
