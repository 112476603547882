export default {
  colors: {
    blue300: '#69a7e0',
    blue500: '#4c6f8f',
    blue600: '#017AC6',
    blue700: '#23669F',
    blueA700: '#2962ff',

    indigo800: '#242C5C',

    gray50: '#f8f8f8',
    gray100: '#f3f4f6',
    gray200: '#e0e0e0',
    gray600: '#707070',
    gray900: '#282828',

    blueGray500: '#607D8B',
    blueGray900: '#263238',

    yellow: '#FFCC5A',

    white: '#fff',
    black: '#000',

    success: '#22CB72',
    error: '#FF4040',

    background: '#fafafa',
  },

  spacings: {
    px: '1px',
    1: '0.1rem',
    2: '0.2rem',
    4: '0.4rem',
    6: '0.6rem',
    8: '0.8rem',
    10: '1rem',
    12: '1.2rem',
    16: '1.6rem',
    20: '2rem',
    24: '2.4rem',
    32: '3.2rem',
    40: '4rem',
    48: '4.8rem',
    56: '5.6rem',
    64: '6.4rem',
    72: '7.2rem',
    80: '8rem',
    120: '12rem',
    160: '16rem',
  },

  radius: {
    xs: '0.8rem',
    sm: '1.2rem',
    md: '1.6rem',
    lg: '2.4rem',
    xlg: '3.2rem',
    full: '9999px',
  },

  font: {
    family: {
      default: 'Inter',
      IbmPlexSans: 'IBM Plex Sans',
    },
    sizes: {
      xxs: '1rem',
      xs: '1.2rem',
      sm: '1.4rem',
      md: '1.6rem',
      lg: '1.8rem',
      xl: '2rem',
      '2xl': '2.4rem',
      '3xl': '2.8rem',
      '4xl': '3.2rem',
      '5xl': '3.6rem',
      '6xl': '4.8rem',
      '7xl': '6.4rem',
      '8xl': '7.2rem',
      '9xl': '9.6rem',
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },

  opacity: {
    default: '100%',
    semiOpaque: '75%',
    medium: '50%',
    light: '25%',
    semiTransparent: '10%',
  },

  media: {
    mobile: '@media (max-width: 400px)',
    tablet: '@media (max-width: 800px)',
    desktopSmall: '@media (max-width: 1600px)',
    desktop: '@media (max-width: 2000px)',
  },
} as const;
