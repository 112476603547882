/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { BiGridHorizontal } from 'react-icons/bi';

import { GraphicDataRequest } from 'screens/NewDashboard/@types/graphic';

import theme from 'styles/theme';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { Options } from '../Options';
import { ChartComponent } from './components/ChartComponent';

import { GraphContainer, LoadingContainer } from './styles';
import { FunnelChartComponent } from './components/FunnelChartComponent';

export interface GraphProps {
  data: GraphicDataRequest;
  loading?: boolean;
  indicatorColor: string;
  size?: 'small' | 'medium' | 'large';
  hasOptions?: boolean;
  hasBigGridHorizontal?: boolean;
}

export function Graph({
  data,
  loading,
  indicatorColor,
  size,
  hasBigGridHorizontal = false,
  hasOptions = false,
}: GraphProps) {
  const { t: translation, i18n } = useTranslation();

  const handleFunnelChartData = (data: (string | number)[][]) => {
    const newData = data.map(item => {
      const name = item[0];
      return {
        name,
        value: item[1],
      };
    });

    newData.sort((a, b) => {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    });

    return newData;
  };

  return (
    <GraphContainer color={indicatorColor} size={size}>
      {!loading ? (
        <>
          <header>
            {hasBigGridHorizontal && (
              <div className="headerTitle">
                <BiGridHorizontal size={24} />
              </div>
            )}
            <div className="title">
              <strong>{data.title}</strong>
            </div>
            {hasOptions && <Options data={data} type="graphic" color="#4c4e61" />}
          </header>
          {data.data.length > 1 ? (
            <>
              {data.type.type === 'ComboChart' || data.type.type === 'PieChart' || data.type.type === 'BarChart' ? (
                <>
                  <ChartComponent
                    chartType={data.type.type}
                    data={data.data}
                    options={data.options}
                    diffdata={data.diffdata}
                    size={size}
                  />
                  <div className="hAxis">
                    <span>{data.hAxisTitle}</span>
                  </div>
                </>
              ) : (
                <FunnelChartComponent data={handleFunnelChartData(data.data)} />
              )}
            </>
          ) : (
            <div className="noContent">
              <p>Não há dados disponíveis para este gráfico</p>
            </div>
          )}
          <footer>
            {data.sourceurl ? (
              <p>
                {translation('dashboardPage.graphics.source')}:{' '}
                <a href={data.sourceurl} target="_blank" rel="noopener noreferrer">
                  {' '}
                  {data.source}{' '}
                </a>
              </p>
            ) : (
              <p>
                {/* eslint-disable-next-line */}
                {translation('dashboardPage.graphics.source')}:{' '}
                {translation('dashboardPage.graphics.compilation')}{' '}
              </p>
            )}
            {data.consultDate ? (
              <p>
                <br />
                {translation('dashboardPage.graphics.date')}:{' '}
                {new Date(`${data.consultDate}T12:00:00`).toLocaleDateString(i18n.language, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            ) : (
              <br />
            )}
          </footer>
        </>
      ) : (
        <LoadingContainer>
          <Oval height={80} width={80} strokeWidth={4} color={theme.colors.blue600} secondaryColor={theme.colors.blue500} />
        </LoadingContainer>
      )}
    </GraphContainer>
  );
}
