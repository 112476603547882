import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function miningRecordsAmount<T>(filter?: string) {
  const response: AxiosResponse<T> = await api.get(`/qtd_cadastros_fase/${filter || ''}`);

  return response;
}

export async function getAllCards<T>(filter?: string) {
  const response: AxiosResponse<T> = await api.get(`/qtd_cadastros_cards/${filter || ''}`);

  return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAllGraphics<T>(filter?: any, lang?: string, substancia?: number) {
  const response: AxiosResponse<T> = await api.get('graficos/', {
    params: {
      ...filter,
      lang: lang,
      substanciaagrupadora: substancia,
    },
    paramsSerializer: {
      indexes: null,
    },
  });

  return response;
}
