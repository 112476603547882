import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface IDisclaimerBlock {
  isDisabled: boolean;
}

export const BackgroundBlur = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${transparentize(0.3, theme.colors.black)};

    z-index: 99;
  `}
`;

export const DisclaimerBlock = styled.div<IDisclaimerBlock>`
  ${({ theme, isDisabled }) => css`
    max-width: 160rem;
    max-height: 81.1rem;
    width: 90vw;
    padding: ${theme.spacings[56]};

    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};

    animation: animation 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19) normal;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem;
      background-color: ${theme.colors.gray200};
      border-radius: ${theme.radius.xs};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${transparentize(0.5, theme.colors.indigo800)};
    }

    h1 {
      font-size: ${theme.font.sizes['4xl']};
      text-align: center;
    }

    .contentBlock {
      margin-top: ${theme.spacings[20]};
      display: flex;

      text-align: justify;

      gap: ${theme.spacings[48]};

      font-size: ${theme.font.sizes.sm};

      p + p {
        margin-top: ${theme.spacings[20]};
      }
    }

    .grid {
      margin-top: ${theme.spacings[32]};

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .acceptButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }

    li {
      margin-left: 20px;
    }

    ${isDisabled &&
    css`
      animation: animReverse 300ms cubic-bezier(0.49, -0.01, 0.21, 1) normal;
    `}

    @keyframes animation {
      from {
        opacity: 0;
        transform: scale(0);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes animReverse {
      from {
        opacity: 1;
        transform: scale(1);
      }
      to {
        opacity: 0;
        transform: scale(0);
      }
    }

    @media (max-width: 1600px) {
      padding: ${theme.spacings[32]} ${theme.spacings[40]};
      max-height: 90vh;
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[32]};
      max-height: 90vh;
    }
  `}
`;
