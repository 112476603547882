import React, { ButtonHTMLAttributes, useState } from 'react';
import mapIcon from 'assets/svg/map-brazil.svg';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { SiHomeassistant } from 'react-icons/si';
import { RiDashboardFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as Styled from './styles';

interface NavigationButtonProps {
  isDashboard?: boolean;
}

export function NavigationButton({ isDashboard }: NavigationButtonProps) {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const { t: translation, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Styled.MainContainer active={showOptions}>
      {isDashboard ? (
        <>
          <Styled.Button data-tip data-for="map" className="map" onClick={() => navigate('/map')}>
            <img src={mapIcon} alt="" />
          </Styled.Button>
          <ReactTooltip id="map" place="left" type="info" effect="solid" delayShow={500}>
            <p>{translation('navigationButton.viewMapNavigationButton')}</p>
          </ReactTooltip>
        </>
      ) : (
        <>
          <Styled.Button data-tip data-for="dash" className="map" onClick={() => navigate('/dashboard')}>
            <RiDashboardFill color="white" />
          </Styled.Button>
          <ReactTooltip id="dash" place="left" type="info" effect="solid" delayShow={500}>
            <p>{translation('navigationButton.viewDashboardNavigationButton')}</p>
          </ReactTooltip>
        </>
      )}
    </Styled.MainContainer>
  );
}
