import React, { memo, useState } from 'react';
import { TbMenu2 } from 'react-icons/tb';
import { objectGraph } from 'shared/models/ModalInterface';
import * as Styled from './style';
import FloatingMenuInGraph from './FloatingMenuInGraph';

type ButtonGraphType = {
  graph: objectGraph;
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setUpdateGraphics?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClickedInOcult: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  updateDashboard?: (id: string) => void;
  id: string;
};

function ButtonsGraph({
  graph,
  isClickedTopMenu,
  setIsClickedInOcult,
  setUpdateGraphics,
  setSelectedOptionChart,
  updateDashboard,
  id,
}: ButtonGraphType) {
  const [floatingMenuOpen, setFloatingMenuOpen] = useState(false);

  return (
    <Styled.Wrapper>
      <TbMenu2
        className="burger-menu"
        title="Opções do Gráfico"
        onClick={() => {
          if (floatingMenuOpen) {
            setFloatingMenuOpen(false);
          } else {
            setFloatingMenuOpen(true);
          }
        }}
      />

      {floatingMenuOpen && (
        <FloatingMenuInGraph
          graph={graph}
          isClickedTopMenu={isClickedTopMenu}
          setIsClickedInOcult={setIsClickedInOcult}
          setUpdateGraphics={setUpdateGraphics}
          setSelectedOptionChart={setSelectedOptionChart}
          updateDashboard={updateDashboard}
          id={id}
        />
      )}
    </Styled.Wrapper>
  );
}

export default memo(ButtonsGraph);
