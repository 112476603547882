import React, { memo } from 'react';
import Chart from 'react-google-charts';
import { GraphModelType } from '../../type/GraphModelType';

function MaterialDesignHorizontalBarChart({ data }: GraphModelType) {
  const options = {
    bars: 'horizontal',
    axes: {
      y: {
        0: { side: 'right' },
      },
    },
  };

  return (
    <Chart
      chartType="Bar"
      width="33.5vw"
      height="40vh"
      data={data}
      options={options}
    />
  );
}

export default memo(MaterialDesignHorizontalBarChart);
