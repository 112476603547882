import React, { useEffect, useState } from 'react';

import { Loading } from 'components';
import { Graph } from 'screens/NewDashboard/components';

import { useLayers } from 'hooks/useLayers';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import { GraphicDataRequest } from 'screens/NewDashboard/@types/graphic';
import { getAllGraphics } from 'services/graphicsRequest';
import { DashboardRequestData } from 'screens/NewDashboard/@types/dashboard';
import { IndicatorProps } from 'components/Filter/@types/fields';
import { findAllIndicators } from 'services/indicatorsRequest';
import { getColorByCommoditie } from 'utils/getColorByCommoditie';
import { ISubTheme } from 'contexts/@types/Layers';
import { useFilter } from 'hooks/useFilter';

const titleGraphicsRemove = [
  'Cobertura de levantamentos aerogeofísicos',
  'Cobertura de levantamentos litoestratigráficos',
  'Áreas especiais',
];

type DashboardProps = {
  subThemeOpenedBySubstanciaAgrupadora: ISubTheme | null;
};

export function DashboardLayer({ subThemeOpenedBySubstanciaAgrupadora }: DashboardProps) {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [graphicsData, setGraphicsData] = useState(Array<GraphicDataRequest>);
  const { mountObjectFilter } = useFilter();

  const [indicators, setIndicators] = useState<IndicatorProps[]>([]);

  const { subThemeOpened } = useLayers();
  const colorByCommoditie = getColorByCommoditie(
    subThemeOpenedBySubstanciaAgrupadora?.nm_subtema || subThemeOpened.nm_subtema,
  );

  useEffect(() => {
    (async () => {
      await getAllGraphicsData();
      await getAllIndicators();
      setLoading(false);
    })();
  }, []);

  async function getAllGraphicsData() {
    const mountFilter = mountObjectFilter();

    console.log('subThemeOpenedBySubstanciaAgrupadora', subThemeOpenedBySubstanciaAgrupadora);
    console.log('subThemeOpened', subThemeOpened);

    const { data } = await getAllGraphics<DashboardRequestData>(
      mountFilter,
      i18n.language,
      subThemeOpenedBySubstanciaAgrupadora?.substancia_agrupadora_id || subThemeOpened.substancia_agrupadora_id,
    );
    removeHiddenGraphics(data.graphics.filter(graphic => !titleGraphicsRemove.includes(graphic.title)));
  }

  async function getAllIndicators() {
    const { data: indicators } = await findAllIndicators<IndicatorProps[]>(i18n.language);
    setIndicators(indicators);
  }

  function removeHiddenGraphics(data: GraphicDataRequest[]) {
    const hiddenGraphics: GraphicDataRequest[] = JSON.parse(localStorage.getItem('hiddenGraphics') || '[]');
    if (hiddenGraphics.length > 0) {
      let newGraphicsData = data;
      hiddenGraphics.forEach(hiddenGraphic => {
        newGraphicsData = newGraphicsData.filter(graphic => graphic.title !== hiddenGraphic.title);
      });
      setGraphicsData(newGraphicsData);
    } else {
      setGraphicsData(data);
    }
  }

  function getSizeByTitle(title: string) {
    switch (title) {
      case 'Fases ligadas ao regime de autorização e concessão':
        return 'large';
      case 'Cobertura de levantamentos aerogeofísicos':
        return 'medium';
      case 'Cobertura de levantamentos litoestratigráficos':
        return 'medium';
      case 'Quantitativo de Projetos por Substância':
        return 'medium';
      default:
        return 'small';
    }
  }

  return (
    <>
      <Styled.Header titleColor={colorByCommoditie}>
        <h1>{subThemeOpenedBySubstanciaAgrupadora?.nm_subtema || subThemeOpened.nm_subtema}</h1>
      </Styled.Header>
      <Styled.Wrapper>
        {/* <img src={myImage} alt={`Imagem representativa de ${subThemeOpened.nm_subtema}`} /> */}
        {loading ? (
          <Loading />
        ) : (
          <Styled.Content>
            {/* <div className="indicatorsBox">
              <IndicatorsBox data={indicators} onChoose={item => handleOnChooseIndicators(item)} />
            </div> */}
            <div className="gridGraphics">
              {graphicsData.map(
                graphic =>
                  graphic.data.length > 1 &&
                  indicators.find(i => i.name === graphic.indicator.name)?.checked && (
                    <Graph
                      size={getSizeByTitle(graphic.title)} // small, medium ou large
                      key={graphic.title}
                      data={graphic}
                      loading={loading}
                      indicatorColor={colorByCommoditie}
                    />
                  ),
              )}
            </div>
          </Styled.Content>
        )}
      </Styled.Wrapper>
    </>
  );
}
