import React from 'react';

interface IProperties {
  properties: {
    nm_prop: string;
    nm_emprd: string;
    tp_emprd: string;
  };
}

export function RepresasHidreletricas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Represas Hidrelétricas</strong>
      </h4>
      <p>
        <strong>Nome do proprietario: </strong>{' '}
        {properties.nm_prop ? properties.nm_prop : 'Não informado'}
      </p>
      <p>
        <strong>Nome do empreendimento: </strong>{' '}
        {properties.nm_emprd ? properties.nm_emprd : 'Não informado'}
      </p>
      <p>
        <strong>Tipo do empreendimento: </strong>{' '}
        {properties.tp_emprd ? properties.tp_emprd : 'Não informado'}
      </p>
    </div>
  );
}
