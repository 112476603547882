import React from 'react';

import { RiErrorWarningLine } from 'react-icons/ri';
import * as Styled from './styles';

interface PopupProps {
  text: string;
  animation: boolean;
}

export function Popup({ text, animation }: PopupProps) {
  return (
    <Styled.Wrapper animation={animation}>
      <div className="error">
        <RiErrorWarningLine size={20} />
        {text}
      </div>
    </Styled.Wrapper>
  );
}
