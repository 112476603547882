import React, { useState } from 'react';
import { IoMdMegaphone } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Disclaimer, Modal } from 'components';
import logoGov from 'assets/svg/logo-gov.svg';
import logoSGB from 'assets/img/logo-sgb.png';
import logoSGBEnUS from 'assets/img/logo-sgb-en-us_1.png';
import logoP3M from 'assets/img/logo-title.png';
import logoSmallP3M from 'assets/img/logo-title-small.png';
import { useNavigate } from 'react-router-dom';
import { FlagSwitch } from './components/FlagSwitch';
import links from './links';
import * as Styled from './styles';
import DropdownGov from './components/DropdownGov';

export function Header() {
  const { value: disclaimerStatus } = JSON.parse(sessionStorage.getItem('disclaimerStatus') || '{}');

  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState<boolean>(!disclaimerStatus ?? true);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);

  const { t: translation, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleOnAccept = () => {
    setIsDisclaimerOpen(false);
    sessionStorage.setItem('disclaimerStatus', JSON.stringify({ value: true }));
  };

  const handleOnCheck = (value: boolean) => {
    sessionStorage.setItem('disclaimerStatus', JSON.stringify({ value }));
  };

  const handleOnChangeLanguage = () => {
    navigate(0);
    setTimeout(() => {
      sessionStorage.removeItem('disclaimerStatus');
      setIsDisclaimerOpen(true);
    }, 1000);
  };

  return (
    <Styled.Wrapper>
      <nav className="govBar">
        <img src={logoGov} alt="gov.br" />
        <div className="links">
          {links.map(item => (
            <a key={item.id} className="link" href={item.link} target="_blank" rel="noreferrer">
              {translation(`header.${item.label}`)}
            </a>
          ))}
        </div>
        <div className="dropdownLinks">
          <DropdownGov label="Gov links" links={links} />
        </div>
      </nav>
      <Styled.Header className="nav">
        <a href="https://www.sgb.gov.br/" target="_blank" rel="noreferrer">
          {i18n.language === 'en-US' ? (
            <img src={logoSGBEnUS} alt="SGB" className="sgbLogoEnUs" />
          ) : (
            <img src={logoSGB} alt="SGB" className="sgbLogo" />
          )}
        </a>
        <div className="versionLogo nav">
          <img src={logoP3M} alt="P3M" className="P3MlogoBlock" />
          <img src={logoSmallP3M} alt="P3M" className="P3MlogoSmallBlock" />
          <p>v. 2.0.0</p>
        </div>
        <div className="linksBlock">
          <button id="contactButton" type="button" onClick={() => setIsContactModalOpen(true)}>
            <IoMdMegaphone size={24} />
            <div id="contactLabel">{translation('header.contact')}</div>
          </button>
          {/* <FlagSwitch onChangeLanguage={handleOnChangeLanguage} /> */}
        </div>
      </Styled.Header>
      {isDisclaimerOpen && (
        <Disclaimer title={translation('disclaimer.title')} onAccept={handleOnAccept} onCheck={handleOnCheck} />
      )}
      {isContactModalOpen && (
        <Modal onDisabled={() => setIsContactModalOpen(false)}>
          <h2>{translation('header.contact')}</h2>
          <Styled.ContentEmail>
            <span>Email: </span>
            <div className="contentLine">
              <MdEmail size={24} />
              <a href="mailto:p3m@sgb.gov.br" target="_blank" rel="noreferrer">
                p3m@sgb.gov.br
              </a>
            </div>
          </Styled.ContentEmail>
        </Modal>
      )}
    </Styled.Wrapper>
  );
}
