import styled, { css } from 'styled-components';

interface CardContainerProps {
  color: string;
}

export const LoadingContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 40rem;
    margin-top: ${theme.spacings[24]};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const CardContainer = styled.div<CardContainerProps>`
  ${({ theme, color }) => css`
    height: 100%;

    min-height: 190px;

    display: inline-flex;
    flex-direction: column;

    flex: 1 1 300px;

    background-color: ${theme.colors.white};

    border-left: 3px solid ${color};
    border-radius: 12px;

    font-size: 14px;
    font-family: 'Source Serif Pro', sans-serif;
    box-shadow: 0 0 2rem ${theme.colors.gray200};
    margin-bottom: 20px;
    &:not(last-child) {
      margin-right: 2%;
    }

    h3 {
      margin-bottom: 15px;
      font-size: 16px;
    }
    .card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dataDisplay {
        font-size: 26px;
        margin-left: 10px;
        font-weight: normal;
        color: ${color};

        .dataNumbers {
          display: flex;
          align-items: center;
        }

        .percent {
          font-family: Roboto;
          font-size: 12px;
          font-weight: thin;
          margin-left: 10px;
          color: ${color};
        }

        .title {
          font-size: 14px;
          font-weight: bold;
          color: #4c4e61;
        }
      }

      .icon {
        margin-right: 10px;
        font-size: 48px;
        color: ${color};
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
    }

    footer p,
    a {
      font-weight: normal;
      color: #4c4e61;
      font-weight: 100;
      font-size: 10px;
      margin: 0 auto;
      margin-right: 0;
      margin-bottom: 0;
      line-height: 0.7;
    }

    a:hover {
      color: ${theme.colors.blue600};
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 10px;

      .canvas-button {
        display: flex;
        align-items: center;

        font-weight: normal;
        color: #4c4e61;
        font-weight: 100;
        font-size: 12px;
        svg {
          cursor: pointer;
        }
      }
    }
  `}
`;
