import { MineralSubstancesProps } from 'components/Filter/@types/fields';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findMineralSubstances } from 'services/mineralSubstance';

interface IProperties {
  properties: {
    qt_area: number;
    ds_processo: string;
    ds_fase_agrupada: string;
    ds_fase_processo: string;
    nm_pessoa: string;
    tipo_uso: string;
    nm_substancia: string;
    substancia_agrupadora: string;
  };
}

export function ProcessosMinerarios({ properties }: IProperties) {
  const { i18n } = useTranslation();

  // eslint-disable-next-line no-return-assign
  return (
    <div className="information">
      <h4>
        <strong>Processos Minerários</strong>
      </h4>
      <p>
        <strong>Área em ha: </strong>{' '}
        {properties.qt_area
          ? properties.qt_area.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Processo: </strong>{' '}
        {properties.ds_processo ? properties.ds_processo : 'Não informado'}
      </p>
      <p>
        <strong>Fase agrupada: </strong>{' '}
        {properties.ds_fase_agrupada
          ? properties.ds_fase_agrupada
          : 'Não informado'}
      </p>
      <p>
        <strong>Fase: </strong>{' '}
        {properties.ds_fase_processo
          ? properties.ds_fase_processo
          : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nm_pessoa ? properties.nm_pessoa : 'Não informado'}
      </p>
      <p>
        <strong>Substância: </strong>{' '}
        {properties.nm_substancia ? properties.nm_substancia : 'Não informado'}
      </p>
      <p>
        <strong>Uso: </strong>{' '}
        {properties.tipo_uso ? properties.tipo_uso : 'Não informado'}
      </p>
    </div>
  );
}
