import React, { memo } from 'react';
import Chart from 'react-google-charts';
import { GraphModelType } from '../../type/GraphModelType';

function StackedBarChartWithMultipleSeries({ data }: GraphModelType) {
  const options = {
    chartArea: { width: '50%' },
    isStacked: true,
    hAxis: {
      minValue: 0,
    },
  };

  return (
    <Chart
      style={{ zIndex: '0' }}
      chartType="BarChart"
      width="32vw"
      height="40vh"
      data={data}
      options={options}
    />
  );
}

export default memo(StackedBarChartWithMultipleSeries);
