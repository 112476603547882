import React, { useEffect, useRef, useState } from 'react';
import { LayerOption } from 'components';
import { useLayers } from 'hooks/useLayers';
import { useMap } from 'hooks/useMap';
import { ILayers } from 'contexts/@types/Layers';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { FeaturesModal } from '../FeaturesModal';

export function ModalLayerOptions() {
  const { subTitles, openLayerFeaturesModal, layersFeatures, setOpenLayerFeaturesModal } = useMap();
  const { layersActived, subtitleLayerOpened, handleSubtitleLayerOpened } = useLayers();
  const { t: translation } = useTranslation();
  const [subtitlesOpen, setSubtitlesOpen] = useState<ILayers[]>();
  const [informationsOpen, setInformations] = useState<ILayers[]>();
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (subtitleLayerOpened) {
      handleSubtitleLayerOpened({} as ILayers);
    }
  }, []);

  useEffect(() => {
    const layersActiveBySubtitle = layersActived.filter((layer: ILayers) => layer.subtitle_open);
    const layersActiveByInformation = layersActived.filter((layer: ILayers) => layer.information_open);
    setSubtitlesOpen(layersActiveBySubtitle);
    setInformations(layersActiveByInformation);
  }, [layersActived]);

  return (
    <Styled.Wrapper>
      {subtitlesOpen && subtitlesOpen.length > 0 && (
        <LayerOption
          option={'subtitle'}
          index={0}
          pages={0}
          content={subtitlesOpen
            .sort((a: ILayers, b: ILayers) => {
              return a.layertitle.localeCompare(b.layertitle, 'pt');
            })
            .map((item: ILayers) => {
              const subTitleActived = subTitles.find(subTitle => subTitle.layername === item.layername);
              if (!subTitleActived || !item.subtitle_open) return;

              return (
                <>
                  <h4>
                    <strong>{item.layertitle}</strong>
                  </h4>
                  <img src={subTitleActived.url} alt={subTitleActived.layername} />
                  <hr />
                </>
              );
            })}
        />
      )}

      {informationsOpen && informationsOpen.length > 0 && (
        <LayerOption
          option={'information'}
          index={0}
          pages={0}
          content={informationsOpen
            .sort((a: ILayers, b: ILayers) => {
              return a.layertitle.localeCompare(b.layertitle, 'pt');
            })
            .map((item: ILayers) => {
              return (
                <>
                  <h4>
                    <strong>{item.layertitle}</strong>
                  </h4>

                  {item.metadados_camada ? (
                    <>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.thematicArea')}:</strong> {item.area_tematica}
                      </p>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.summary')}:</strong>{' '}
                        {/* {item.metadados_camada.resumo_proposto} */}
                      </p>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.organization')}:</strong>{' '}
                        {/* {item.metadados_camada.nome_da_organizacao_responsavel_pelo_recurso} */}
                      </p>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.spatialRepresentation')}:</strong>{' '}
                        {/* {item.metadados_camada.tipo_de_representacao_espacial} */}
                      </p>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.date')}:</strong>
                        {/* {item.metadados_camada.data} */}
                      </p>
                      <p>
                        <strong>{translation('mapPage.sideBar.informations.linkMetadata')}:</strong>{' '}
                        <a
                          href={`${item.metadado_url}layers/${item.scheme}:${item.layername}/metadata_detail`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {translation('mapPage.sideBar.informations.geonodeAccess')}
                        </a>
                      </p>
                    </>
                  ) : (
                    <p>{translation('mapPage.sideBar.informations.notFoundMetadados')}.</p>
                  )}

                  <hr />
                </>
              );
            })}
        />
      )}

      {openLayerFeaturesModal && (
        <LayerOption
          option={'features'}
          content={<FeaturesModal setIsOpen={setOpenLayerFeaturesModal} features={layersFeatures} index={index} />}
          index={index}
          setIndex={setIndex}
          pages={layersFeatures?.length || 0}
        />
      )}
    </Styled.Wrapper>
  );
}
