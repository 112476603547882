import axios from 'axios';
import { Environment } from 'shared/environments';
import { DataSubsEstadoType } from '..';
import { DataTypeProducaoBrutaEstado } from '../JsonType/Type';

type GetData = {
  uf?: string[];
  setDataPrdBrutaEstado: React.Dispatch<
    React.SetStateAction<DataSubsEstadoType[]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setGetGraphicsStateBruta: React.Dispatch<
    React.SetStateAction<DataSubsEstadoType[]>
  >;
};

function returnArraysForChart(
  responseData: DataTypeProducaoBrutaEstado[],
  textFilter: string[],
) {
  const filterData = textFilter.map(state =>
    responseData.filter(
      posicao => posicao.uf.toLowerCase() === state.toLowerCase(),
    ),
  );

  const anoBase = filterData.map(
    posicao =>
      typeof posicao === 'object' && posicao.map(object => object.ano_base),
  );

  const anoBaseNoRepeat = anoBase.map(
    posicao =>
      typeof posicao === 'object' &&
      posicao.filter((ano, index) => posicao.indexOf(ano) === index).sort(),
  );

  const returnAllProdutionPerYear = anoBaseNoRepeat.map(
    (posicao, index) =>
      typeof posicao === 'object' &&
      posicao.map(ano =>
        filterData[index].filter(array => array.ano_base === ano),
      ),
  );

  const forEachPositionReturnArrayGraph = returnAllProdutionPerYear.map(
    posicao =>
      typeof posicao === 'object' &&
      posicao.map(array => [
        `${array[0].ano_base}`,
        array.reduce((a, b) => a + b.quantidade_produzida_ton, 0),
        array.reduce((a, b) => a + b.quantidade_venda_t, 0),
      ]),
  );

  const arrayTitle1 = [
    'Ano',
    'Produção Beneficiada Ton',
    'Produção Mineral Comercializada Ton',
  ] as (string | number)[];

  forEachPositionReturnArrayGraph.map(posicao =>
    typeof posicao === 'object' ? posicao.unshift(arrayTitle1) : posicao,
  );

  const convertArrayInObject = [] as DataSubsEstadoType[];

  for (let i = 0; i < forEachPositionReturnArrayGraph.length; i += 1) {
    const object = {
      filterText: textFilter[i],
      dataChart: forEachPositionReturnArrayGraph[i],
    } as DataSubsEstadoType;

    convertArrayInObject.push(object);
  }

  return convertArrayInObject;
}

const MockApi = axios.create({
  baseURL: Environment.API_MOCK,
});

export async function getDataProdBrutaEstado({
  uf,
  setDataPrdBrutaEstado,
  setLoading,
  setError,
  setGetGraphicsStateBruta,
}: GetData) {
  if (uf?.length === 0) {
    await MockApi.get(`quantidadeBrutaProduzida`)
      .then(response => {
        const responseData = response.data as DataTypeProducaoBrutaEstado[];
        const anoBase = responseData.map(posicao => posicao.ano_base);
        const anoBaseNoRepeat = anoBase
          .filter((ano, index) => anoBase.indexOf(ano) === index)
          .sort();

        const returnProdution = anoBaseNoRepeat.map(ano =>
          responseData.filter(posicao => posicao.ano_base === ano),
        );

        const reduceProdutionBtdOld = returnProdution.map(posicao => [
          `${posicao[0].ano_base}`,
          posicao.reduce((a, b) => a + b.quantidade_produzida_ton, 0),
          posicao.reduce((a, b) => a + b.quantidade_venda_t, 0),
        ]) as (string | number)[][];
        const arrayTitle1 = [
          'Ano',
          'Produção Bruta Produzida',
          'Produção Bruta Comercializada',
        ];
        reduceProdutionBtdOld.unshift(arrayTitle1);

        const newGraph = reduceProdutionBtdOld;

        setDataPrdBrutaEstado([
          {
            filterText: '',
            dataChart: newGraph,
          },
        ]);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  } else {
    await MockApi.get(`quantidadeBrutaProduzida`)
      .then(response => {
        const responseData = response.data as DataTypeProducaoBrutaEstado[];

        if (uf !== undefined && uf?.length > 0) {
          const returnGraphicsState = returnArraysForChart(responseData, uf);

          setGetGraphicsStateBruta(returnGraphicsState);
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
}
