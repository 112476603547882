import React from 'react';

interface IProperties {
  properties: {
    toponimia: string;
    mun: string;
    UF: string;
    provincia: string;
    status_econ: string;
    importancia: string;
    substancias: string;
    classes_utilitarias: string;
    morfologia: string;
    texturas: string;
    tp_alteracao: string;
  };
}

export function OcorrenciasMinerais({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Ocorrências Minerais</strong>
      </h4>
      <p>
        <strong>Toponímia: </strong>{' '}
        {properties.toponimia ? properties.toponimia : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.mun ? properties.mun : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.UF ? properties.UF : 'Não informado'}
      </p>
      <p>
        <strong>Província mineral: </strong>{' '}
        {properties.provincia ? properties.provincia : 'Não informado'}
      </p>
      <p>
        <strong>Status econômico: </strong>{' '}
        {properties.status_econ ? properties.status_econ : 'Não informado'}
      </p>
      <p>
        <strong>Importância: </strong>{' '}
        {properties.importancia ? properties.importancia : 'Não informado'}
      </p>
      <p>
        <strong>Substâncias Minerais: </strong>{' '}
        {properties.substancias ? properties.substancias : 'Não informado'}
      </p>
      <p>
        <strong>Classes Utilitárias: </strong>{' '}
        {properties.classes_utilitarias
          ? properties.classes_utilitarias
          : 'Não informado'}
      </p>
      <p>
        <strong>Morfologia: </strong>{' '}
        {properties.morfologia ? properties.morfologia : 'Não informado'}
      </p>
      <p>
        <strong>Texturas: </strong>{' '}
        {properties.texturas ? properties.texturas : 'Não informado'}
      </p>
      <p>
        <strong>Alterações: </strong>{' '}
        {properties.tp_alteracao ? properties.tp_alteracao : 'Não informado'}
      </p>
    </div>
  );
}
