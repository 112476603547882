import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface ILayerBlock {
  isActived: boolean;
}

const activedModifers = {
  actived: css`
    svg {
      transform: rotate(-180deg);
    }
  `,
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};

    border: 0;
    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};
  `}
`;

export const LayerBlock = styled.div<ILayerBlock>`
  ${({ theme, isActived }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};
    padding: ${theme.spacings[12]} ${theme.spacings[16]};

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: ${theme.radius.xs};

    background-color: #2a2f3c;
    color: ${theme.colors.white};

    cursor: pointer;

    svg {
      transition: all 0.2s;
    }

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.xs};
    }

    ${isActived && activedModifers.actived}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-height: 30rem;

    padding: 20px;

    border: 0;
    border-radius: ${theme.radius.xs};

    background-color: #f0f0f0;

    p {
      color: ${theme.colors.gray900};
      font-family: sans-serif;
      text-align: justify;
    }

    a {
      color: #1e90ff;
    }

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 500px) {
      p {
        font-size: ${theme.font.sizes.xs};
        text-align: left;
      }
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem;
      background-color: ${theme.colors.gray200};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${transparentize(0.5, theme.colors.indigo800)};
    }
  `}
`;
