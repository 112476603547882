import React, { useState } from 'react';

import { useMap } from 'hooks/useMap';
import { useTranslation } from 'react-i18next';
import { RiPrinterLine } from 'react-icons/ri';
import Notification from 'components/Notification';
import { FaCheckCircle } from 'react-icons/fa';

export default function PrintMap() {
  const { handleDownloadPrintMap } = useMap();
  const { t: translation, i18n } = useTranslation();

  const [successPrint, setSuccessPrint] = useState(false);

  const handlePrintMap = async () => {
    await handleDownloadPrintMap();

    setSuccessPrint(true);

    setTimeout(() => {
      setSuccessPrint(false);
    }, 5000);
  };

  return (
    <>
      <span data-for="settingsButton">
        <button type="button" className="buttonLink" onClick={() => handlePrintMap()}>
          <div className="icons-sidebar">
            <RiPrinterLine />
          </div>
          <span>{translation('mapPage.sideBar.navigationRightBar.print')}</span>
        </button>
      </span>
      {successPrint && (
        <Notification
          text="Download realizado com sucesso"
          icon={{
            component: FaCheckCircle,
            color: '#22CB72',
            size: 24,
          }}
        />
      )}
    </>
  );
}
