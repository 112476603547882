import React from 'react';

interface IProperties {
  properties: {
    id: number;
  };
}

export function FaixaDeFronteira({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Faixa de Fronteira</strong>
      </h4>
      <p>Nenhuma propriedade para exibição.</p>
    </div>
  );
}
