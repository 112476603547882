import axios from 'axios';
import { Environment } from 'shared/environments';
import { MineralSubstanceClasses } from 'shared/models/MineralSubstanceClasses';
import { MineralSubstanceClassesService } from 'shared/services/mineralSubstanceClasses/MineralSubstanceClassesService';

export class RepositoryJsonMineralSubstanceClasses
  implements MineralSubstanceClassesService
{
  async getMineralSubstanceClasses(): Promise<
    MineralSubstanceClasses[] | Error
  > {
    try {
      const endPoint = `${Environment.API_URL_BASE}/classe_substancia/`;
      const { data } = await axios.get<MineralSubstanceClasses[]>(endPoint);
      return data;
    } catch (error: any) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
