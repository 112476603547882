import React, { memo, useCallback, useEffect, useState } from 'react';
import { GiRoundStar } from 'react-icons/gi';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { checkIsFavoriteGraphOrCheckIsOcultGraph } from 'screens/Dashboard/components/functionsUsedInDashboard/isFavoriteGraphOrIsOcultGraph';
import { objectGraph } from 'shared/models/ModalInterface';
import theme from 'styles/theme';
import { FiMap } from 'react-icons/fi';
import * as Styled from './style';

type FloatingMenuInGraph = {
  graph: objectGraph;
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setUpdateGraphics?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClickedInOcult: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  updateDashboard?: (id: string) => void;
  id: string;
};

function FloatingMenuInGraph({
  graph,
  isClickedTopMenu,
  setUpdateGraphics,
  setIsClickedInOcult,
  setSelectedOptionChart,
  updateDashboard,
  id,
}: FloatingMenuInGraph) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [ocultGraph, setOcultGraph] = useState(false);

  useEffect(() => {
    if (Object.values(graph).length > 0) {
      checkIsFavoriteGraphOrCheckIsOcultGraph({
        graphObject: graph,
        setIsFavoriteOrSetIsOcult: setIsFavorite,
        favoriteGraphOrOcultGraph: 'favoriteGraphics',
      });

      checkIsFavoriteGraphOrCheckIsOcultGraph({
        graphObject: graph,
        setIsFavoriteOrSetIsOcult: setOcultGraph,
        favoriteGraphOrOcultGraph: 'ocultGraph',
      });
    }
  }, []);

  const callbackMoveToOcult = useCallback(() => {
    localStorage.setItem('ocultGraph', JSON.stringify({ ocultGraph: [graph] }));

    setSelectedOptionChart('ocult');
    setTimeout(() => {
      setSelectedOptionChart('');
    }, 1800);

    setOcultGraph(true);
    setIsClickedInOcult(true);

    if (setUpdateGraphics) {
      setUpdateGraphics(true);
    }

    if (isClickedTopMenu === 'dashboard' && updateDashboard) {
      updateDashboard(id);
    }

    if (isClickedTopMenu === 'favoriteGraph') {
      callbackRemoveFavorite();
    }
  }, []);

  const callbackRemoveOcult = useCallback(() => {
    localStorage.removeItem('ocultGraph');

    setSelectedOptionChart('removedOcultGraph');
    setTimeout(() => {
      setSelectedOptionChart('');
    }, 1800);

    if (setUpdateGraphics) {
      setUpdateGraphics(true);
    }
  }, []);

  const callbackMoveToFavorite = useCallback(() => {
    localStorage.setItem(
      'favoriteGraphics',
      JSON.stringify({ favoriteGraphics: [graph] }),
    );

    setSelectedOptionChart('favorite');
    setTimeout(() => {
      setSelectedOptionChart('');
    }, 1800);

    setIsFavorite(true);
  }, []);

  const callbackRemoveFavorite = useCallback(() => {
    localStorage.removeItem('favoriteGraphics');

    setSelectedOptionChart('removedFavoriteGraph');
    setTimeout(() => {
      setSelectedOptionChart('');
    }, 1800);

    setIsFavorite(false);

    if (isClickedTopMenu === 'favoriteGraph' && setUpdateGraphics) {
      setUpdateGraphics(true);
    }
  }, []);

  return (
    <Styled.FloatingMenuInGraph>
      <div className="centralized">
        {!ocultGraph &&
        isClickedTopMenu !== 'ocultGraph' &&
        isClickedTopMenu !== 'favoriteGraph' ? (
          <button
            type="button"
            className="wrapperIconAndText"
            onClick={callbackMoveToOcult}
          >
            <HiEyeOff className="icon eye" />

            <div className="wrapperP">
              <p>Ocultar gráfico</p>
            </div>
          </button>
        ) : (
          ocultGraph &&
          isClickedTopMenu === 'ocultGraph' && (
            <button
              type="button"
              className="wrapperIconAndText"
              onClick={callbackRemoveOcult}
            >
              <HiEye className="icon eye2" />

              <div className="wrapperP">
                <p>Desocultar gráfico</p>
              </div>
            </button>
          )
        )}

        <button type="button" className="wrapperIconAndText">
          <FiMap className="icon map" />

          <div className="wrapperP">
            <p>Mostrar no mapa</p>
          </div>
        </button>

        {!isFavorite && isClickedTopMenu !== 'ocultGraph' ? (
          <button
            type="button"
            className="wrapperIconAndText"
            onClick={callbackMoveToFavorite}
          >
            <GiRoundStar className="icon" />

            <div className="wrapperP">
              <p>Favoritar</p>
            </div>
          </button>
        ) : (
          isFavorite &&
          isClickedTopMenu !== 'ocultGraph' && (
            <button
              type="button"
              className="wrapperIconAndText"
              onClick={callbackRemoveFavorite}
            >
              <GiRoundStar className="icon" color={theme.colors.yellow} />

              <div className="wrapperP">
                <p>Desfavoritar</p>
              </div>
            </button>
          )
        )}
      </div>
    </Styled.FloatingMenuInGraph>
  );
}

export default memo(FloatingMenuInGraph);
