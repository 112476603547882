import React from 'react';
import { BsFillFilterSquareFill } from 'react-icons/bs';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import * as Styled from './style';

type ButtonSideBarType = {
  setOnMouseOver: React.Dispatch<React.SetStateAction<boolean>>;
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ButtonsSideBar({
  setOnMouseOver,
  openFilter,
  setOpenFilter,
}: ButtonSideBarType) {
  return (
    <Styled.CentralizedComponentTop openFilter={openFilter}>
      <button
        type="button"
        className="iconAndTextFilter"
        onClick={() => setOpenFilter(!openFilter)}
        onMouseOver={() => setOnMouseOver(true)}
        onFocus={() => setOnMouseOver(true)}
        onBlur={() => setOnMouseOver(false)}
        onMouseOut={() => setOnMouseOver(false)}
      >
        <BsFillFilterSquareFill className="icon" />

        <h5 className="textFilter">Filtrar</h5>
      </button>
      <MdKeyboardArrowLeft
        className="arrowLeft"
        onClick={() => setOpenFilter(!openFilter)}
        onMouseOver={() => setOnMouseOver(true)}
        onMouseOut={() => setOnMouseOver(false)}
      />
    </Styled.CentralizedComponentTop>
  );
}
