import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function findAllThemes<T>(language: string) {
  // eslint-disable-next-line no-template-curly-in-string
  const response: AxiosResponse<T> = await api.get(`/tema/?lang=${language}`);

  return response;
}

export async function findAllThemeLayers<T>(language: string) {
  const response: AxiosResponse<T> = await api.get(`/camadas/?lang=${language}`);

  return response;
}

export async function findSubTheme<T>(language: string) {
  const response: AxiosResponse<T> = await api.get(`/subtematica/?lang=${language}`);

  return response;
}

export async function findCategorias<T>(language: string) {
  const response: AxiosResponse<T> = await api.get(`/categorias/?lang=${language}`);

  return response;
}
