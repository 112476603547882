import React, { memo } from 'react';
import Chart from 'react-google-charts';
import { GraphModelType } from '../../type/GraphModelType';

function MaterialDesignBarCharts({ data }: GraphModelType) {
  return (
    <Chart
      style={{ zIndex: '0' }}
      chartType="Bar"
      width="33.5vw"
      height="40vh"
      data={data}
    />
  );
}

export default memo(MaterialDesignBarCharts);
