import styled, { css, keyframes } from 'styled-components';

interface IWrapper {
  isActived: boolean;
}

interface IButton {
  label: string;
}

export const Wrapper = styled.div<IWrapper>`
  ${({ theme, isActived }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};
    margin-bottom: ${theme.spacings[12]};
    border: 0;
    border-radius: ${theme.radius.xs};
    font-weight: ${isActived ? theme.font.weights.semiBold : theme.font.weights.regular};
    margin-left: 0;

    p {
      margin-bottom: 0;
      margin-left: 10px;
      font-weight: ${isActived ? theme.font.weights.semiBold : theme.font.weights.regular};
    }

    span {
      border-radius: ${theme.radius.lg};
      background-color: #01c638;
      color: #fff;
      font-size: ${theme.font.sizes.xs};
      font-weight: ${theme.font.weights.medium};
      margin-left: ${theme.spacings[4]};
      padding: ${theme.spacings[2]} ${theme.spacings[8]};
    }

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.xs};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;

    .layer {
      display: inline-flex;
      -moz-box-align: center;
      align-items: center;
    }

    @media (max-width: 400px) {
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const OptionsBlock = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 400px) {
      width: 100%;
      margin-top: ${theme.spacings[12]};
      .menu-icons {
        margin-bottom: 0;
      }
    }
  `}
`;

export const Button = styled.button<IButton>`
  ${({ theme, label }) => css`
    position: relative;

    display: inline-flex;
    justify-content: center;

    border: 0;
    background-color: transparent;

    & + button {
      margin-left: ${theme.spacings[12]};
    }

    &:hover {
      &::before {
        top: ${label.includes(':') ? '-5rem' : '-3.5rem'};
        opacity: 1;
      }
    }

    &::before {
      content: '${label}';
      padding: ${theme.spacings[6]};
      position: absolute;
      top: -5rem;
      pointer-events: none;
      margin-right: ${label === 'Download' ? '30px' : '0px'};

      font-family: ${theme.font.family.default};
      font-size: ${theme.font.sizes.xs};
      font-weight: ${theme.font.weights.bold};

      border: 1px solid ${theme.colors.white};
      border-radius: 0.4rem;

      background-color: ${theme.colors.black};
      color: ${theme.colors.white};

      opacity: 0;
      transition: all 0.2s;
    }
  `}
`;

export const LinkDownloadLayer = styled.a`
  display: flex;
`;
