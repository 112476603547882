import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100vh - 109px);
    position: relative;

    display: flex;

    .mainDashboard {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
    }
  `}
`;

export const FilterContainer = styled.div`
  ${() => css`
    position: absolute;
    height: 100%;
    width: 28rem;
  `}
`;
