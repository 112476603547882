const colors: { [key: string]: string } = {
  Agrominerais: '#AFB9C9',
  'Alumínio (Al)': '#A4C2E2',
  'Chumbo (Pb)': '#EAB48A',
  'Cobre (Cu)': '#C9C9C9',
  'Cromo (Cr)': '#F9DB79',
  Diamante: '#94A9D7',
  'Estanho (Sn)': '#C9C9C9',
  'Ferro (Fe)': '#CADFB8',
  'Fosfato (P2O5)': '#F5C242',
  'Grafita (Graphite)': '#A5A5A5',
  'Lítio (Li)': '#DF8343',
  'Manganês (Mn)': '#6B99D0',
  'Materiais para construção': '#CADFB7',
  'Minerais Industriais': '#94A9D8',
  'Nióbio (Ni)': '#F9DB78',
  'Níquel (Ni)': '#B86029',
  'Ouro (Au)': '#B89230',
  'Outras Gemas': '#F1CDB1',
  Remineralizadores: '#FFFF54',
  'Terras Raras (REE)': '#9FCE62',
  'Urânio (U)': '#4FAD5B',
  'Vanádio (V)': '#4FADEA',
  'Zinco (Zi)': '#F5C242',
};

export const getColorByCommoditie = (commoditie: string) => colors[commoditie];
