import { State } from 'shared/models/State';
import { api } from 'services/api/api';
import { StateService } from 'shared/services/state/StateService';

export class RepositoryJsonState implements StateService {
  async getState(): Promise<State[] | Error> {
    try {
      const response = await api.get<State[]>('estados');
      return response.data;
    } catch (error) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }

  async getStateFindRegion(region: string): Promise<State[] | Error> {
    try {
      const regionCorrect = region
        .split(',')
        .map(state => state.trim().replace(' ', '%20').toUpperCase())
        .join(',');

      const response = await api.get<State[]>(
        `estados/?regiao__in=${regionCorrect}`,
      );
      return response.data;
    } catch (error) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }

  async getStateFindState(state: string): Promise<State[] | Error> {
    try {
      const stateCorrect = state
        .split(',')
        .map(state => state.trim().replace(' ', '%20').toUpperCase())
        .join(',');

      const response = await api.get<State[]>(
        `estados/?nome__in=${stateCorrect}`,
      );
      return response.data;
    } catch (error) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
