import { Environment } from 'shared/environments';
import { Region } from 'shared/models/Region';
import { JsonApi } from 'shared/services/axios-config';
import { RegionService } from 'shared/services/region/RegionService';

export class RepositoryJsonRegion implements RegionService {
  async getRegion(filterValue?: string): Promise<Region[] | Error> {
    try {
      const countrySelected = filterValue
        ?.split(',')
        .map(country => {
          const firstLetter = country.substring(0, 1).toUpperCase();
          const lastLetters = country.substring(1);
          const newString = `${firstLetter}${lastLetters}`;
          return newString;
        })
        .join('&pais=');

      const response =
        filterValue === undefined || filterValue === ''
          ? JsonApi.get('regiao/')
          : JsonApi.get(`regiao/?pais=${countrySelected}`);

      return (await response).data;
    } catch (error: any) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
