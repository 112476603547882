import React, { useRef, useEffect } from 'react';

import { View, Map } from 'ol';
// import { Point } from 'ol/geom';
// import { Vector as VectorLayer } from 'ol/layer';

// import { Vector as VectorSource } from 'ol/source';
import { MousePosition, ScaleLine, defaults } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
// import { Icon, Style } from 'ol/style';

// import marker from 'assets/img/marker.png';

import { useMap } from 'hooks/useMap';
import { MapBlock } from './styles';

export interface IMap {
  center: number[];
  children: React.ReactNode;
  // isMarker: boolean;
  zoom: number;
}

export function MapComponent({ center, children, zoom }: IMap) {
  const { map, setMap, mapRef } = useMap();

  const mousePosition = new MousePosition({
    coordinateFormat: createStringXY(4),
    projection: 'EPSG:4326',
  });

  useEffect(() => {
    const options = {
      target: 'map',
      view: new View({ zoom, center }),
      controls: defaults().extend([new ScaleLine(), mousePosition]),
      // layers: [],
      // overlays: [],
    };

    const mapObject = new Map(options);

    mapObject.setTarget(mapRef.current as HTMLDivElement);

    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [center]);

  // useEffect(() => {
  //   if (isMarker) {
  //     if (!map) return;

  //     const iconFeature = new Feature({
  //       geometry: new Point(center),
  //     });

  //     const iconStyle = new Style({
  //       image: new Icon({
  //         scale: 0.8,
  //         anchor: [0.5, 46],
  //         anchorXUnits: 'fraction',
  //         anchorYUnits: 'pixels',
  //         src: marker,
  //       }),
  //     });

  //     iconFeature.setStyle(iconStyle);

  //     const markerSource = new VectorSource({
  //       features: [iconFeature],
  //     });

  //     const markerLayer = new VectorLayer({
  //       source: markerSource,
  //       zIndex: 1,
  //     });

  //     map.addLayer(markerLayer);

  //     // eslint-disable-next-line
  //     return () => {
  //       if (map) {
  //         map.removeLayer(markerLayer);
  //       }
  //     };
  //   }
  // }, [center]);

  return <MapBlock ref={mapRef}>{children}</MapBlock>;
}
