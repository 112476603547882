import React from 'react';

interface IProperties {
  properties: {
    total__2010: number;
    urbana_2010: number;
    rural_2010: string;
  };
}

export function RendaDomicilioPermanente({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Renda Nominal Mensal dos Domicílios Particulares Permanentes</strong>
      </h4>
      <p>
        <strong>Valor Total no ano de 2010 (R$): </strong>
        {properties.total__2010 ? properties.total__2010.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor na área Urbana no ano de 2010 (R$): </strong>
        {properties.urbana_2010 ? properties.urbana_2010.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor na área Rural no ano de 2010 (R$): </strong>
        {properties.rural_2010 ? properties.rural_2010 : 'Não informado'}
      </p>
    </div>
  );
}
