import React from 'react';

interface IProperties {
  properties: {
    sgl_ue_afl: string;
    sgl_ue_sub: string;
    nom_ue_afl: string;
    nom_ue_sub: string;
    l_ue_afl: string;
    l_ue_sub: string;
    e_ue_afl: string;
    e_ue_sub: string;
    u_hl_afl: string;
    u_hl_sub: string;
    e_int: string;
    grau_frat: string;
    q_he_afl: string;
    q_he_sub: string;
    qs_he_afl: string;
    qs_he_sub: string;
    t_he_afl: string;
    t_he_sub: string;
    k_he_afl: string;
    k_he_sub: string;
    pe_he_afl: string;
    s_he_sub: string;
    prod_he_af: string;
    prod_he_su: string;
    cl_he_afl: string;
    cl_he_sub: string;
    u_he_afl: string;
    u_he_sub: string;
    estrat: string;
    repr_map: string;
    cls_style: string;
  };
}

export function PoligonoAtlasHidrologeologico({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Poligono Atlas Hidrogeológico</strong>
      </h4>
      <p>
        <strong>Sigla da Unidade Estratigráfica Aflorante: </strong>
        {properties.sgl_ue_afl ? properties.sgl_ue_afl : 'Não informado'}
      </p>
      <p>
        <strong>Sigla da Unidade Estratigráfica Subjacente: </strong>
        {properties.sgl_ue_sub ? properties.sgl_ue_sub : 'Não informado'}
      </p>
      <p>
        <strong>Nome da Unidade Estratigráfica Aflorante: </strong>
        {properties.nom_ue_afl ? properties.nom_ue_afl : 'Não informado'}
      </p>
      <p>
        <strong>Nome da Unidade Estratigráfica Subjacente: </strong>
        {properties.nom_ue_sub ? properties.nom_ue_sub : 'Não informado'}
      </p>
      <p>
        <strong>Litologia da Unidade Estratigráfica Aflorante: </strong>
        {properties.l_ue_afl ? properties.l_ue_afl : 'Não informado'}
      </p>
      <p>
        <strong>Litologia da Unidade Estratigráfica Subjacente: </strong>
        {properties.l_ue_sub ? properties.l_ue_sub : 'Não informado'}
      </p>
      <p>
        <strong>Espessura da Unidade Estratigráfica Aflorante: </strong>
        {properties.e_ue_afl ? properties.e_ue_afl : 'Não informado'}
      </p>
      <p>
        <strong>Espessura da Unidade Estratigráfica Subjacente: </strong>
        {properties.e_ue_sub ? properties.e_ue_sub : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Hidrolitológica Aflorante: </strong>
        {properties.u_hl_afl ? properties.u_hl_afl : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Hidrolitológica Subjacente: </strong>
        {properties.u_hl_sub ? properties.u_hl_sub : 'Não informado'}
      </p>
      <p>
        <strong>Espessura do Manto de Intemperismo: </strong>
        {properties.e_int ? properties.e_int : 'Não informado'}
      </p>
      <p>
        <strong>Grau de Fraturamento: </strong>
        {properties.grau_frat ? properties.grau_frat : 'Não informado'}
      </p>
      <p>
        <strong>Vazão da Unidade Hidroestratigráfica Aflorante: </strong>
        {properties.q_he_afl ? properties.q_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>Vazão da Unidade Hidroestratigráfica Subjacente: </strong>
        {properties.q_he_sub ? properties.q_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>
          Vazão Específica da Unidade Hidroestratigráfica Aflorante:{' '}
        </strong>
        {properties.qs_he_afl ? properties.qs_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>
          Vazão Específica da Unidade Hidroestratigráfica Subjacente:{' '}
        </strong>
        {properties.qs_he_sub ? properties.qs_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>
          Transmissividade da Unidade Hidroestratigráfica Aflorante:{' '}
        </strong>
        {properties.t_he_afl ? properties.t_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>
          Transmissividade da Unidade Hidroestratigráfica Subjacente:{' '}
        </strong>
        {properties.t_he_sub ? properties.t_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>
          Condutividade Hidráulica da Unidade Hidroestratigráfica Aflorante:{' '}
        </strong>
        {properties.k_he_afl ? properties.k_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>
          Condutividade Hidráulica da Unidade Hidroestratigráfica Subjacente:{' '}
        </strong>
        {properties.k_he_sub ? properties.k_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>
          Porosidade Efetiva da Unidade Hidroestratigráfica Aflorante em
          porcentagem:{' '}
        </strong>
        {properties.pe_he_afl ? properties.pe_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>
          Coeficiente de Armazenamento da Unidade Hidroestratigráfica
          Subjacente:{' '}
        </strong>
        {properties.s_he_sub ? properties.s_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>
          Produtividade da Unidade Hidroestratigráfica Aflorante:{' '}
        </strong>
        {properties.prod_he_af ? properties.prod_he_af : 'Não informado'}
      </p>
      <p>
        <strong>
          Produtividade da Unidade Hidroestratigráfica Subjacente:{' '}
        </strong>
        {properties.prod_he_su ? properties.prod_he_su : 'Não informado'}
      </p>
      <p>
        <strong>Classe da Unidade Hidroestratigráfica Aflorante: </strong>
        {properties.cl_he_afl ? properties.cl_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>Classe da Unidade Hidroestratigráfica Subjacente: </strong>
        {properties.cl_he_sub ? properties.cl_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Hidroestratigráfica Aflorante: </strong>
        {properties.u_he_afl ? properties.u_he_afl : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Hidroestratigráfica Subjacente: </strong>
        {properties.u_he_sub ? properties.u_he_sub : 'Não informado'}
      </p>
      <p>
        <strong>Sequência Hidroestratigráfica: </strong>
        {properties.estrat ? properties.estrat : 'Não informado'}
      </p>
      <p>
        <strong>Representação em Mapa: </strong>
        {properties.repr_map ? properties.repr_map : 'Não informado'}
      </p>
      <p>
        <strong>Cor no Mapa: </strong>
        {properties.cls_style ? properties.cls_style : 'Não informado'}
      </p>
    </div>
  );
}
