import axios from 'axios';
import { Environment } from 'shared/environments';
import { DataPibUfEstado } from '../JsonType/Type';

type GetData = {
  valuesForFilter?: {
    uf?: string[];
    region?: string;
    city?: string;
  };
  setDataPibUfRegiaoAno: React.Dispatch<
    React.SetStateAction<(string | number)[][][]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setDataPibRegiao: React.Dispatch<
    React.SetStateAction<(string | number)[][][]>
  >;
  setDataPibCity: React.Dispatch<React.SetStateAction<(string | number)[][][]>>;
  setDataPibUf: React.Dispatch<React.SetStateAction<(string | number)[][][]>>;
};

function returnArraysForChart(
  responseData: DataPibUfEstado[],
  textFilter: string[],
  type: 'uf' | 'city' | 'region',
) {
  const filterData = textFilter.map(string =>
    responseData.filter(posicao =>
      type === 'city'
        ? posicao.grande_regiao_municipio !== null &&
          posicao.grande_regiao_municipio
            .substring(0, posicao.grande_regiao_municipio.length - 5)
            .toLowerCase() === string.toLowerCase()
        : type === 'region'
        ? posicao.regiao_estado !== null &&
          posicao.regiao_estado.toLowerCase() === string.toLowerCase()
        : posicao.uf !== null &&
          posicao.uf.toLowerCase() === string.toLowerCase(),
    ),
  );

  const regionBase = filterData.map(posicao =>
    type === 'city'
      ? [
          [
            'Cidade',
            'Ano 2002',
            'Ano 2005',
            'Ano 2010',
            'Ano 2015',
            'Ano 2019',
          ],
          [
            posicao[0].grande_regiao_municipio,
            posicao.reduce((a, b) => a + b.ano_2002, 0),
            posicao.reduce((a, b) => a + b.ano_2005, 0),
            posicao.reduce((a, b) => a + b.ano_2010, 0),
            posicao.reduce((a, b) => a + b.ano_2015, 0),
            posicao.reduce((a, b) => a + b.ano_2019, 0),
          ],
        ]
      : type === 'region'
      ? [
          [
            'Região',
            'Ano 2002',
            'Ano 2005',
            'Ano 2010',
            'Ano 2015',
            'Ano 2019',
          ],
          [
            posicao[0].regiao_estado,
            posicao.reduce((a, b) => a + b.ano_2002, 0),
            posicao.reduce((a, b) => a + b.ano_2005, 0),
            posicao.reduce((a, b) => a + b.ano_2010, 0),
            posicao.reduce((a, b) => a + b.ano_2015, 0),
            posicao.reduce((a, b) => a + b.ano_2019, 0),
          ],
        ]
      : [
          [
            'Estado',
            'Ano 2002',
            'Ano 2005',
            'Ano 2010',
            'Ano 2015',
            'Ano 2019',
          ],
          [
            posicao[0].uf,
            posicao.reduce((a, b) => a + b.ano_2002, 0),
            posicao.reduce((a, b) => a + b.ano_2005, 0),
            posicao.reduce((a, b) => a + b.ano_2010, 0),
            posicao.reduce((a, b) => a + b.ano_2015, 0),
            posicao.reduce((a, b) => a + b.ano_2019, 0),
          ],
        ],
  );
  return regionBase;
}

const MockApi = axios.create({
  baseURL: Environment.API_MOCK,
});

export async function getDataPibUfRegiaoAno({
  valuesForFilter,
  setDataPibUfRegiaoAno,
  setLoading,
  setError,
  setDataPibRegiao,
  setDataPibCity,
  setDataPibUf,
}: GetData) {
  if (
    valuesForFilter?.city === undefined &&
    valuesForFilter?.region === undefined &&
    valuesForFilter?.uf?.length === 0
  ) {
    await MockApi.get(`pibUfRegiaoAno`)
      .then(response => {
        const responseData = response.data as DataPibUfEstado[];
        const allRegion = responseData.map(posicao => posicao.regiao_estado);
        const regiaoNoRepeat = allRegion
          .filter(
            (region, index) =>
              region !== null && allRegion.indexOf(region) === index,
          )
          .sort();

        const returnArrayForEachRegion = regiaoNoRepeat.map(region =>
          responseData.filter(posicao => posicao.regiao_estado === region),
        );

        const reduceRegionPib = returnArrayForEachRegion.map(region => [
          `${region[0].regiao_estado}`,
          region.reduce((a, b) => a + b.ano_2002, 0),
          region.reduce((a, b) => a + b.ano_2005, 0),
          region.reduce((a, b) => a + b.ano_2010, 0),
          region.reduce((a, b) => a + b.ano_2015, 0),
          region.reduce((a, b) => a + b.ano_2019, 0),
        ]) as (string | number)[][];

        const arrayTitle1 = [
          'Região',
          'Ano 2002',
          'Ano 2005',
          'Ano 2010',
          'Ano 2015',
          'Ano 2019',
        ];
        reduceRegionPib.unshift(arrayTitle1);

        setDataPibUfRegiaoAno([reduceRegionPib]);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  } else {
    await MockApi.get(`pibUfRegiaoAno`).then(response => {
      const responseData = response.data as DataPibUfEstado[];
      const uf = valuesForFilter?.uf;
      const allCityes = valuesForFilter?.city?.split(', ');
      const allRegion = valuesForFilter?.region?.split(', ');

      if (allCityes !== undefined && allCityes?.length > 0) {
        const newArrayGraphics = returnArraysForChart(
          responseData,
          allCityes,
          'city',
        );

        setDataPibCity(newArrayGraphics);
      }

      if (allRegion !== undefined && allRegion?.length > 0) {
        const newArrayGraphics = returnArraysForChart(
          responseData,
          allRegion,
          'region',
        );
        setDataPibRegiao(newArrayGraphics);
      }

      if (uf !== undefined && uf?.length > 0) {
        const newArrayGraphics = returnArraysForChart(responseData, uf, 'uf');

        setDataPibUf(newArrayGraphics);
      }
    });
  }
}
