import React from 'react';

interface IProperties {
  properties: {
    nome_uc: string;
    id_wcmc: string;
    categ: string;
    grupo: string;
    esfera: string;
  };
}

export function UnidadesConservacaoUS({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Unidades de Conservação Federais de Uso Sustentável</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome_uc ? properties.nome_uc : 'Não informado'}
      </p>
      {/* <p>
        <strong>Id: </strong>{' '}
        {properties.id_wcmc ? properties.id_wcmc : 'Não informado'}
      </p> */}
      <p>
        <strong>Categoria: </strong>{' '}
        {properties.categ ? properties.categ : 'Não informado'}
      </p>
      <p>
        <strong>Grupo: </strong>{' '}
        {properties.grupo ? properties.grupo : 'Não informado'}
      </p>
      <p>
        <strong>Esferas: </strong>{' '}
        {properties.esfera ? properties.esfera : 'Não informado'}
      </p>
    </div>
  );
}
