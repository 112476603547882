import React from 'react';
import * as Styled from './styles';
import { IconType } from 'react-icons';
import { createPortal } from 'react-dom';
import { size } from 'polished';

type NotificationProps = {
  text: string;
  icon: {
    component: IconType;
    color?: string;
    size?: number;
  };
};

export default function Notification({ text, icon: { component, color = '#fff', size = 32 } }: NotificationProps) {
  const Icon = component;

  return createPortal(
    <Styled.Notification>
      <Icon size={size} color={color} />
      {text}
    </Styled.Notification>,
    document.getElementById('modal') as HTMLElement,
  );
}
