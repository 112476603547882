import styled, { css } from 'styled-components';

export const WrapperError = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings[12]};
    background-color: ${theme.colors.error};
    color: ${theme.colors.white};
    font-family: ${theme.font.family.default};
    font-size: ${theme.font.sizes.md};
    position: fixed;
    top: 45rem;
    right: 8rem;
    animation-name: traslationX;
    animation-duration: 8s;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    border-bottom-left-radius: ${theme.spacings[6]};
    border-bottom-right-radius: ${theme.spacings[6]};
    transform: translateX(200%);

    @keyframes traslationX {
      0% {
        transform: translateX(200%);
        opacity: 0;
      }
      15% {
        transform: translateX(0%);
        opacity: 1;
      }
      85% {
        transform: translateX(0%);
        opacity: 1;
      }
      100% {
        transform: translateX(200%);
        opacity: 0;
      }
    }
  `}
`;
