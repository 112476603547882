// eslint jsx-a11y/label-has-associated-control: 0
import React, { InputHTMLAttributes } from 'react';

import * as Styled from './styles';

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  inverted?: boolean;
  label?: string;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
}

export function Checkbox({ inverted, label, onChecked, checked, ...props }: ICheckbox) {
  return (
    <Styled.Wrapper inverted={inverted}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (onChecked) {
            onChecked(!checked);
          }
        }}
      />
      {label && <span>{label}</span>}
    </Styled.Wrapper>
  );
}
