import React from 'react';

interface IProperties {
  properties: {
    cod_oaci: string;
    ciad: string;
    nm: string;
    mun: string;
    opr_notur: string;
    uf: string;
  };
}

export function Helipontos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Helipontos</strong>
      </h4>
      <p>
        <strong>
          Código Organização de Aviação Civil Internacional (OACI):{' '}
        </strong>{' '}
        {properties.cod_oaci ? properties.cod_oaci : 'Não informado'}
      </p>
      <p>
        <strong>Código de Identificação do Aeródromo (CIAD): </strong>{' '}
        {properties.ciad ? properties.ciad : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nm ? properties.nm : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.mun ? properties.mun : 'Não informado'}
      </p>
      <p>
        <strong>Operação noturna: </strong>{' '}
        {properties.opr_notur ? properties.opr_notur : 'Não informado'}
      </p>
    </div>
  );
}
