import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const BackgroundBlur = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${transparentize(0.3, theme.colors.black)};

    z-index: 10;
  `}
`;

export const Modal = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings[32]};
    position: relative;

    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};

    animation: animation 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19) normal;

    overflow-y: auto;

    @keyframes animation {
      from {
        opacity: 0;
        transform: scale(0);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacings[4]};
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;

    display: flex;

    border: 0;
    border-radius: ${theme.radius.full};

    background-color: transparent;

    transition: all 0.2s;

    &:hover {
      background-color: ${transparentize(0.5, theme.colors.gray200)};
    }
  `}
`;
