import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 60px;
    padding: ${theme.spacings[16]} ${theme.spacings[12]};
    background-color: #017ac6;
    display: flex;
    justify-content: center;
    align-items: end;

    .icon {
      color: #fff;
      font-size: ${theme.font.sizes['3xl']};
      margin-right: 5px;

      @media (max-width: 400px) {
        font-size: ${theme.font.sizes['2xl']};
      }
    }

    h1 {
      font-size: ${theme.font.sizes.xl};
      color: ${theme.colors.white};
      margin: 0px;

      @media (max-width: 600px) {
        font-size: ${theme.font.sizes['lg']};
      }
    }

    @media (max-width: 600px) {
      height: 70px;
      align-items: center;
    }
  `}
`;

export const ButtonTab = styled.button`
  ${({ theme, disabled }) => css`
    padding: ${theme.spacings[8]} 0;

    border: 0;

    background-color: transparent;

    ${disabled &&
    css`
      color: #696969;
    `}

    ${!disabled &&
    css`
      color: #fff;

      &:hover {
        color: ${disabled ? '#696969' : '#03a9f4'};
      }
    `}

    .icons {
      font-size: 28px;

      @media (max-width: 1600px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 60px);

  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(100% - 70px);
  }
`;

export const Nav = styled.nav`
  ${({ theme }) => css`
    width: 100%;

    padding: ${theme.spacings[4]} ${theme.spacings[12]};

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #2a2f3c;

    .activedLayers {
      width: 25px;
      height: 25px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: 50px;
      margin-top: -5px;
      margin-bottom: -20px;

      background-color: #d2691e;
      color: #fff;

      border-width: 1px;
      border-style: solid;
      border-radius: 100%;

      @media (max-width: 1000px) {
        font-size: 14px;
      }

      @media (max-width: 1000px) {
        font-size: 12px;
      }

      @media (max-width: 600px) {
        width: 18px;
        height: 18px;

        margin-left: 25px;
        margin-top: -10px;
        margin-bottom: -8px;

        border-width: 0px;
      }
    }

    .tag-label {
      margin-bottom: 0;

      @media (max-width: 1600px) {
        font-size: 14px;
      }

      @media (max-width: 600px) {
        font-size: 10px;
      }
    }

    @media (max-width: 900px) {
      font-size: ${theme.font.sizes.xs};
    }
  `}
`;

export const Breadcrumb = styled.nav``;

export const BreadcrumbItemAction = styled.span`
  cursor: pointer;
`;

export const ButtonClearAll = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background-color: #017ac6;
  padding: 4px;
  border-radius: 2px;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  background-color: #2a2f3c;

  padding: 10px;

  font-size: 14px;
  color: #fff;

  button {
    color: #fff;
  }
`;

export const ModalClearLayers = styled.div`
  ${({ theme }) => css`
    .header {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 0px;
        margin-left: ${theme.spacings[4]};
      }
    }

    p {
      margin: ${theme.spacings[20]} 0;
    }

    .buttons {
      display: flex;
    }

    .btn-cancel {
      margin-left: auto;
    }

    .btn-confirm {
      margin-left: ${theme.spacings[4]};
    }
  `}
`;
