import React from 'react';
import { CardDataRequest } from 'screens/NewDashboard/@types/cards';
import { Oval } from 'react-loader-spinner';
import theme from 'styles/theme';
import { BiGridHorizontal } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { CardContainer, LoadingContainer } from './styles';
import { Options } from '../Options';
import icons from './icons';

export interface CardGraphProps {
  data: CardDataRequest;
  loading?: boolean;
  indicatorColor: string;
}

export function CardGraph({ data, loading, indicatorColor }: CardGraphProps) {
  const { t: translation } = useTranslation();

  const { i18n } = useTranslation();

  const handleCardPercent = (percentage: number) => {
    if (percentage.toString().split('.').length > 1) {
      return percentage.toFixed(2).replace('.', ',');
    }
    return percentage;
  };

  const handleCardData = (data: CardDataRequest) => {
    if (data.seriesType && data.seriesType !== ' ') {
      return `${data.data[0]?.total.toFixed(2).replace('.', ',')} ${data.seriesType}`;
    }

    return data.data[0]?.total.toLocaleString('pt-BR');
  };

  return (
    <CardContainer color={indicatorColor}>
      {!loading ? (
        <>
          <header>
            <div className="canvas-button">
              <BiGridHorizontal size={24} />
            </div>
            <Options data={data} type="card" color="#4c4e61" />
          </header>
          <div className="card-body">
            <div className="dataDisplay">
              <div className="dataNumbers">
                {data.data[0] ? <p>{handleCardData(data)}</p> : <p>0</p>}
                <span className="percent">{!data.seriesType && <span>{handleCardPercent(data.percentage)}%</span>}</span>
              </div>
              <div className="title">
                <strong>{data.title}</strong>
              </div>
            </div>
            <div className="icon">{icons.find(icon => icon.identifier.find(id => id === data.infotype))?.icon}</div>
          </div>
          <footer>
            <p>
              {translation('dashboardPage.graphics.source')}:{' '}
              <a href={data.sourceurl} target="_blank" rel="noopener noreferrer">
                {' '}
                {data.source}{' '}
              </a>
            </p>
            {data.consultDate ? (
              <p>
                <br />
                {translation('dashboardPage.graphics.date')}:{' '}
                {new Date(`${data.consultDate}T12:00:00`).toLocaleDateString(i18n.language, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            ) : (
              <br />
            )}
          </footer>
        </>
      ) : (
        <LoadingContainer>
          <Oval height={80} width={80} strokeWidth={4} color={theme.colors.blue600} secondaryColor={theme.colors.blue500} />
        </LoadingContainer>
      )}
    </CardContainer>
  );
}
