import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function findRegionByCountry<T>(
  country: string,
  language: string,
) {
  const response: AxiosResponse<T> = await api.get(
    `/regiao/?pais=${country}&lang=${language}`,
  );

  return response;
}
