import React from 'react';
import * as Styled from './style';

export function LoadingGraphics() {
  return (
    <Styled.Wrapper>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </Styled.Wrapper>
  );
}
