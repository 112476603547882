import React from 'react';

interface IProperties {
  properties: {
    nome: string;
    navegacao: string;
    tipo: string;
    nome_rio: string;
    reg_rafica: string;
  };
}

export function Cabotagem({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Cabotagem</strong>
      </h4>
      <p>
        <strong>Nome: </strong>
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Navegação: </strong>
        {properties.navegacao ? properties.navegacao : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>
        {properties.tipo ? properties.tipo : 'Não informado'}
      </p>
      <p>
        <strong>Nome do rio: </strong>
        {properties.nome_rio ? properties.nome_rio : 'Não informado'}
      </p>
      <p>
        <strong>Região hidrográfica: </strong>
        {properties.reg_rafica ? properties.reg_rafica : 'Não informado'}
      </p>
    </div>
  );
}
