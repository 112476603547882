import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

import * as Styled from './styles';

export interface IDisclaimer {
  title?: string;
  onAccept?: () => void;
  onCheck?: (value: boolean) => void;
}

export function Disclaimer({ title, onAccept, onCheck }: IDisclaimer) {
  const [isDisabled, setIsDisabled] = useState(false);

  const { t: translation } = useTranslation();

  return createPortal(
    <Styled.BackgroundBlur>
      <Styled.DisclaimerBlock isDisabled={isDisabled}>
        {title && <h1>{title}</h1>}
        <div className="contentBlock">
          <div>
            <p>{translation('disclaimer.text.pg1')}</p>
            <ul>
              <li>{translation('disclaimer.text.li1')}</li>
              <li>{translation('disclaimer.text.li2')}</li>
              <li>{translation('disclaimer.text.li3')}</li>
              <li>{translation('disclaimer.text.li4')}</li>
              <li>{translation('disclaimer.text.li5')}</li>
              <li>{translation('disclaimer.text.li6')}</li>
            </ul>
            <p>{translation('disclaimer.text.pg2')}</p>
            <p>{translation('disclaimer.text.pg3')}</p>
            <p>{translation('disclaimer.text.pg4')}</p>
            <p>{translation('disclaimer.text.pg5')}</p>
          </div>
        </div>
        <div className="acceptButton">
          <Button
            onClick={() => {
              onAccept && onAccept();
              setIsDisabled(true);
            }}
            outline
          >
            {translation('disclaimer.buttonText')}
          </Button>
        </div>
      </Styled.DisclaimerBlock>
    </Styled.BackgroundBlur>,
    document.getElementById('modal')!, //eslint-disable-line
  );
}
