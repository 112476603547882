import React from 'react';

interface IProperties {
  properties: {
    amostra: string;
    toponimia: string;
    rocha: string;
    mat_analisados: string;
  };
}

export function Geocronologia({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Geocronologia</strong>
      </h4>
      <p>
        <strong>Amostra: </strong>{' '}
        {properties.amostra ? properties.amostra : 'Não informado'}
      </p>
      <p>
        <strong>Toponímia: </strong>{' '}
        {properties.toponimia ? properties.toponimia : 'Não informado'}
      </p>
      <p>
        <strong>Rocha: </strong>{' '}
        {properties.rocha ? properties.rocha : 'Não informado'}
      </p>
      <p>
        <strong>Materiais analisados: </strong>{' '}
        {properties.mat_analisados
          ? properties.mat_analisados
          : 'Não informado'}
      </p>
    </div>
  );
}
