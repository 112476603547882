import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100vh - 13.5rem);
    overflow-y: auto;

    background-color: ${theme.colors.gray200};
    display: flex;
    align-items: center;
  `}
`;

type DashboardNavigationType = {
  openFilter: boolean;
  isClicked: string;
};

export const DashboardNavigation = styled.section<DashboardNavigationType>`
  ${({ openFilter, isClicked }) => css`
    width: 100%;
    overflow-x: hidden;
    height: calc(100vh - 13.5rem);
    animation-name: ${openFilter && isClicked === 'dashboard'
      ? 'closeMenuTop'
      : !openFilter && isClicked === 'dashboard' && 'openMenuTop'};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    margin-left: ${isClicked === 'dashboard' ? '35rem' : ''};

    @keyframes closeMenuTop {
      0% {
        margin-left: 8rem;
      }
      100% {
        margin-left: 35rem;
      }
    }

    @keyframes openMenuTop {
      0% {
        margin-left: 35rem;
      }
      100% {
        margin-left: 8rem;
      }
    }
  `}
`;
