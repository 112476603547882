import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Environment } from 'shared/environments';

const config: AxiosRequestConfig = {
  baseURL: Environment.API_URL_BASE,
};

export const api: AxiosInstance = axios.create(config);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.message === 'Network Error') {
      return Promise.reject(new Error('Desculpem-nos o transtorno, estamos em obras para melhor atendê-los!'));
    }

    if (error.response?.status === 404) {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }

    return Promise.reject(error);
  },
);
