export const sectionsOrganization = [
  {
    UUID: '7243c2d8-626e-4f15-a398-293de2bd582d',
    title: 'Conheça a P3M',
    links: [
      {
        UUID: 'bef0fede-3b13-4f89-aa93-6b8948d67c5f',
        label: 'O projeto',
        href: 'https://p3m.sgb.gov.br/',
      },
      {
        UUID: '6283cd56-f683-42b7-ac7b-5f2a5f7187e3',
        label: 'Documentos e Apresentações',
        href: 'https://p3m.sgb.gov.br/#bloco_documentos',
      },
      {
        UUID: 'cd00a2e7-9cba-4fa5-8633-93b5a2d41e84',
        label: 'Links Úteis',
        href: 'https://p3m.sgb.gov.br/#bloco_links',
      },
      {
        UUID: 'c43d00f6-1694-42f8-b7f0-7610f765c361',
        label: 'Entidades Parceiras',
        href: 'https://p3m.sgb.gov.br/#bloco_parcerias',
      },
    ],
  },
  {
    UUID: 'e2fd382e-2cfd-469c-a2ac-80e40179d86a',
    title: 'Notícias P3M',
    links: [
      {
        UUID: 'fbe02c9f-078a-4325-94b4-c4101149263a',
        label: 'Receba as novidades sobre o P3M',
        href: 'https://p3m.sgb.gov.br/#contato',
      },
    ],
  },
  {
    UUID: 'fdf44ba3-0f83-474c-b8dd-853854cacaeb',
    title: 'Contato P3M',
    links: [
      {
        UUID: '29699b4b-bc9a-4d27-97e8-da44923de55c',
        label: 'Contato',
        href: 'https://p3m.sgb.gov.br/#contato',
      },
    ],
  },
];
