import React, { SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import * as BiIcons from 'react-icons/bi';

import maps from './maps';

import * as Styled from './styles';

interface IBaseMapModal {
  onChangeBaseMap: (index: number) => void;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

export function MapModal({ onChangeBaseMap, setIsOpen }: IBaseMapModal) {
  const { t: translation } = useTranslation();

  return createPortal(
    <Styled.BackgroundBlur>
      <Styled.ModalBlock>
        <h1>{translation('mapPage.baseMapButton')}</h1>
        <button type="button" className="closeButtonMap" onClick={() => setIsOpen(false)}>
          <BiIcons.BiX size={24} color="#000" />
        </button>
        <div className="optionsMapBlock">
          {maps.map((item, index) => (
            <Styled.OptionMap
              key={item.id}
              onClick={() => {
                onChangeBaseMap(index);
                setIsOpen(false);
              }}
            >
              {item.icon}
              <p>{item.name}</p>
            </Styled.OptionMap>
          ))}
        </div>
      </Styled.ModalBlock>
    </Styled.BackgroundBlur>,
    document.getElementById('modal')!, //eslint-disable-line
  );
}
