import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings[10]};
    position: fixed;
    z-index: 16;
    top: 45rem;
    right: 5%;
    background-color: ${theme.colors.success};
    color: ${theme.colors.white};
    border-radius: ${theme.spacings[6]};
    animation-name: openInformation;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-delay: 0ms;

    @keyframes openInformation {
      0% {
        transform: translateX(200%);
      }
      100% {
        transform: translateX(0%);
      }
    }

    div {
      width: 0.4px;
      position: relative;
    }

    &::after {
      content: '';
      display: flex;
      width: 100%;
      height: 0.6rem;
      position: absolute;
      margin-left: -3.2%;
      top: 85%;
      background-color: ${darken(0.2, theme.colors.success)};
      animation-name: regress;
      animation-duration: 0.7s;
      animation-iteration-count: 1;
      animation-delay: 0.9s;
      animation-fill-mode: forwards;
      border-bottom-left-radius: ${theme.spacings[6]};
      border-bottom-right-radius: ${theme.spacings[6]};

      @keyframes regress {
        0% {
          width: 100%;
        }
        3% {
          border-bottom-right-radius: 0;
        }
        99% {
          opacity: 1;
        }
        100% {
          width: 0%;
          opacity: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  `}
`;
