import styled, { css } from 'styled-components';
import theme from 'styles/theme';

type WrapperType = {
  filter: string;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ filter }) => css`
    width: 100%;
    height: max-content;

    .wrapperCards {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: ${theme.spacings[20]};
      justify-content: center;
      align-items: center;
      margin-bottom: ${theme.spacings[32]};
    }

    .removeFilter {
      width: max-content;
      height: 4rem;
      padding: ${theme.spacings[10]};
      background-color: ${theme.colors.blue700};
      color: ${theme.colors.white};
      font-family: ${theme.font.family.default};
      border-radius: ${theme.spacings[6]};
      cursor: pointer;
      position: fixed;
      left: 90.5%;
      top: 25rem;
      z-index: 5;
    }

    .loading {
      width: 100%;
      height: 64vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wrapperAllGraphics {
      width: 100%;
      max-width: 100%;
      height: max-content;
      display: grid;
      grid-template-columns: ${filter !== '' && '2fr 2fr'};
      flex-wrap: wrap;
      gap: ${filter !== '' && theme.spacings[32]};

      .wrapperGrid {
        width: max-content;
        display: flex;
        justify-content: flex-end;
      }
    }
  `}
`;

type GraphType = {
  chartModel?:
    | 'RegionGeoCharts'
    | 'MaterialDesignBarCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart';
};

function returnPaddingCorrect(
  chartModel?:
    | 'RegionGeoCharts'
    | 'MaterialDesignBarCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart',
) {
  if (chartModel === 'MaterialDesignBarCharts') {
    return theme.spacings[32];
  }

  if (chartModel === 'RegionGeoCharts') {
    return theme.spacings[4];
  }

  return theme.spacings[10];
}

export const Graph = styled.div<GraphType>`
  ${({ chartModel }) => css`
    padding: ${returnPaddingCorrect(chartModel)};
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacings[6]};
  `}
`;
