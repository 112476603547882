import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const MapBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    @media (max-width: 400px) {
      height: 100vh;
    }

    .ol-zoom {
      padding: ${theme.spacings[12]};

      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button {
        width: 3rem;
        height: 3rem;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: ${theme.font.sizes['2xl']};

        border: 1px solid ${theme.colors.gray900};
        border-radius: ${theme.radius.xs};

        background-color: ${theme.colors.white};
        color: ${theme.colors.gray900};

        transition: all 0.2s;

        & + button {
          margin-top: ${theme.spacings[4]};
        }

        &:hover {
          background-color: ${darken(0.1, theme.colors.white)};
        }
      }
    }

    .ol-rotate {
      display: none;
    }

    .ol-zoom-extent {
      display: none;
    }

    .ol-mouse-position {
      min-width: 18rem;
      padding: ${theme.spacings[12]};
      position: absolute;
      right: ${theme.spacings[12]};
      bottom: ${theme.spacings[64]};

      font-weight: ${theme.font.weights.semiBold};
      text-align: center;

      border: 1px solid ${theme.colors.gray900};
      border-radius: ${theme.radius.xs};

      background-color: ${theme.colors.white};
      color: ${theme.colors.gray900};
    }

    .ol-scale-line {
      max-width: 18rem;
      padding: ${theme.spacings[4]};
      position: absolute;
      right: ${theme.spacings[12]};
      bottom: ${theme.spacings[32]};

      font-weight: ${theme.font.weights.semiBold};
      text-align: center;

      border: 1px solid ${theme.colors.gray50};
      border-radius: ${theme.radius.xs};

      background-color: ${theme.colors.black};
      color: ${theme.colors.white};

      div {
        width: 18rem !important;
      }
    }
  `}
`;
