import styled, { css } from 'styled-components';

type CentralizedComponentTopType = {
  openFilter: boolean;
};

export const CentralizedComponentTop = styled.div<CentralizedComponentTopType>`
  ${({ theme, openFilter }) => css`
    margin: 0 auto;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconAndTextFilter {
      width: max-content;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;

      .icon {
        width: 2rem;
        height: 2rem;
        color: ${theme.colors.blue700};
        animation-name: ${openFilter ? 'resetTransition' : 'transition'};
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        transform: translateX(27rem);

        @keyframes resetTransition {
          0% {
            transform: translateX(27rem);
          }
          70% {
            opacity: 0;
          }
          71% {
            opacity: 1;
          }
          100% {
            transform: translateX(0);
          }
        }
      }

      @keyframes transition {
        0% {
          transform: translateX(0);
        }
        70% {
          opacity: 0;
        }
        71% {
          opacity: 1;
        }
        100% {
          transform: translateX(27rem);
        }
      }

      .textFilter {
        color: ${theme.colors.black};
        font-family: ${theme.font.family.default};
        font-weight: ${theme.font.weights.regular};
        font-size: ${theme.font.sizes.lg};
        margin-left: ${theme.spacings[10]};
        animation-name: ${openFilter ? 'removeOpacity' : 'opacity'};
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-delay: 700ms;
        animation-fill-mode: forwards;
        opacity: 0;

        @keyframes removeOpacity {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes opacity {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }

    .arrowLeft {
      font-size: 3.6rem;
      color: ${theme.colors.black};
      cursor: pointer;
      animation-name: ${openFilter ? 'rotate' : 'resetRotate'};
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      transform: rotateZ(180deg);
    }

    @keyframes rotate {
      0% {
        opacity: 0;
        transform: rotateZ(0deg);
      }
      100% {
        opacity: 100%;
        transform: rotateZ(0deg);
      }
    }
    @keyframes resetRotate {
      0% {
        opacity: 0;
        transform: rotateZ(180deg);
      }
      100% {
        opacity: 100%;
        transform: rotateZ(180deg);
      }
    }
  `}
`;
