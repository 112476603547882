import styled, { css } from 'styled-components';

export const CardChartWrapper = styled.div`
  ${({ theme }) => css`
    width: 90%;
    height: 15rem;
    padding: ${theme.spacings[16]};
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.white};
    font-family: ${theme.font.family.default};
    border-radius: ${theme.spacings[6]};

    h4 {
      font-size: ${theme.font.sizes.lg};
      font-weight: ${theme.font.weights.medium};
      color: ${theme.colors.black};
    }

    h3 {
      margin-top: ${theme.spacings[12]};
      font-size: ${theme.font.sizes['4xl']};
      font-weight: ${theme.font.weights.medium};
      color: ${theme.colors.blue600};
    }
  `}
`;
