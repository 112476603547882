import React from 'react';

interface IProperties {
  properties: {
    cod_imovel: string;
    nome_imove: string;
  };
}

export function ImovelSNCITotal({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Imóvel Certificado SNCI Total</strong>
      </h4>
      <p>
        <strong>Código do imóvel: </strong>
        {properties.cod_imovel ? properties.cod_imovel : 'Não informado'}
      </p>
      <p>
        <strong>Nome do imóvel: </strong>
        {properties.nome_imove ? properties.nome_imove : 'Não informado'}
      </p>
    </div>
  );
}
