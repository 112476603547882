type ReturnArrayCorrectForLoopType = {
  posicao: number;
  repositoryNames: {
    allRegionNames: string[];
    allStateNames: string[];
    allCityNames: string[];
    allMineralSubstanceClassesNames: string[];
    allMineralSubstanceNames: string[];
    allProductionChainStudiesNames: string[];
  };
};

export function returnArrayCorrectForLoop({
  posicao,
  repositoryNames,
}: ReturnArrayCorrectForLoopType) {
  switch (posicao) {
    case 0:
      return ['Brasil'];
      break;
    case 1:
      return repositoryNames.allRegionNames
        .map(posicaoArray => posicaoArray.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      break;
    case 2:
      return repositoryNames.allStateNames
        .map(posicaoArray => posicaoArray.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      break;
    case 3:
      return repositoryNames.allCityNames
        .map(posicaoArray => posicaoArray)
        .sort((a, b) => a.localeCompare(b));
      break;
    case 4:
      return repositoryNames.allMineralSubstanceClassesNames
        .map(posicaoArray => posicaoArray.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      break;
    case 5:
      return repositoryNames.allMineralSubstanceNames
        .map(posicaoArray => posicaoArray.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      break;
    case 6:
      return repositoryNames.allProductionChainStudiesNames
        .map(posicaoArray => posicaoArray.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      break;
    default:
      return ['aguardando'];
  }
}
