import React from 'react';

import InstitutoCatalunha from 'assets/img/InstitutoCatalunha.png';
import OpenStreet from 'assets/img/OpenStreet.png';

export default [
  {
    id: 1,
    name: 'Satélite',
    organization: 'Instituto Cartográfico e Geológico da Catalunha',
    href: 'https://www.icgc.cat/ca',
    icon: <img src={InstitutoCatalunha} alt="Satélite Instituto Cartográfico e Geológico da Catalunha" />,
  },
  {
    id: 2,
    name: 'Road',
    organization: 'OpenStreetMap',
    href: 'https://www.openstreetmap.org',
    icon: <img src={OpenStreet} alt="OpenStreet Road" />,
  },
];
