import styled, { css } from 'styled-components';

type WrapperType = {
  selected: string;
};

export const Wrapper = styled.section<WrapperType>`
  ${({ theme, selected }) => css`
    width: 100%;
    height: 6rem;
    background-color: ${theme.colors.gray200};
    display: flex;
    position: fixed;
    top: 135px;
    z-index: 3;

    .DashboardButton {
      width: max-content;
      padding: 0;
      border-radius: 0;
      margin-left: ${theme.spacings[24]};
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: ${theme.font.sizes.lg};
      color: ${theme.colors.black};
      font-weight: ${theme.font.weights.regular};
      border-bottom: ${selected === 'dashboard' &&
      `5px solid ${theme.colors.blue700}`};

      img {
        margin-right: ${theme.spacings[8]};
      }
    }

    .favoriteGraphButton {
      width: max-content;
      padding: 0;
      border-radius: 0;
      margin-left: ${theme.spacings[24]};
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: ${theme.font.sizes.lg};
      color: ${theme.colors.black};
      font-weight: ${theme.font.weights.regular};
      border-bottom: ${selected === 'favoriteGraph' &&
      `5px solid ${theme.colors.blue700}`};

      .star {
        margin-right: ${theme.spacings[8]};
        font-size: 2.4rem;
      }
    }

    .ocultGraphButton {
      width: max-content;
      padding: 0;
      border-radius: 0;
      margin-left: ${theme.spacings[24]};
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: ${theme.font.sizes.lg};
      color: ${theme.colors.black};
      font-weight: ${theme.font.weights.regular};
      border-bottom: ${selected === 'ocultGraph' &&
      `5px solid ${theme.colors.blue700}`};

      .ocult {
        margin-right: ${theme.spacings[8]};
        font-size: 2.4rem;
      }
    }
  `}
`;
