import React from 'react';

interface IProperties {
  properties: {
    nomconcurb: string;
    uf: string;
    regiao_estado: string;
  };
}

export function AreasUrbanas100a300({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Áreas Urbanas com 100 mil a 300 mil Habitantes</strong>
      </h4>
      <p>
        <strong>Nome concentração urbana: </strong>{' '}
        {properties.nomconcurb ? properties.nomconcurb : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Região do estado: </strong>{' '}
        {properties.regiao_estado ? properties.regiao_estado : 'Não informado'}
      </p>
    </div>
  );
}
