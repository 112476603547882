import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface HeaderProps {
  titleColor: string;
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]} ${theme.spacings[24]};

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem;
      background-color: ${theme.colors.gray200};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${transparentize(0.5, theme.colors.indigo800)};
    }

    > img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[10]};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    .emptyLayer {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    > header {
      width: 100%;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 25px;

      > h1 {
        font-size: ${theme.font.sizes.xl};
        line-height: 1;
        text-align: center;

        @media (max-width: 600px) {
          font-size: ${theme.font.sizes.lg};
        }

        @media (max-width: 400px) {
          font-size: ${theme.font.sizes.md};
        }
      }
    }

    .gridGraphics {
      display: grid;
      grid-template-columns: 1fr;
      gap: ${theme.spacings[24]};
    }
  `}
`;

export const Header = styled.header<HeaderProps>`
  ${({ titleColor, theme }) => css`
    width: 100%;
    height: 50px;
    background-color: ${titleColor};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 0;

    > h1 {
      font-size: ${theme.font.sizes.xl};
      line-height: 1;
      text-align: center;
      font-weight: ${theme.font.weights.bold};

      @media (max-width: 800px) {
        font-size: ${theme.font.sizes.lg};
      }

      @media (max-width: 400px) {
        font-size: ${theme.font.sizes.md};
      }
    }
  `}
`;
