import React, { useState } from 'react';

import iconeLoading from 'assets/icone/icone_loading.gif';

import { Graph } from 'screens/NewDashboard/components';

import { CardGraph } from 'screens/NewDashboard/components/Card';

import { CardDataRequest } from 'screens/NewDashboard/@types/cards';

import { GraphicDataRequest } from '../../@types/graphic';
import { MainContainer } from '../styles';

interface GraphicsProps {
  loadingGraphics: boolean;
  graphicsData: GraphicDataRequest[];
  cardsData: CardDataRequest[];
}

export function Graphics({ loadingGraphics, cardsData, graphicsData }: GraphicsProps) {
  return (
    <MainContainer>
      {loadingGraphics ? (
        <div className="loadingContainer">
          <img src={iconeLoading} alt="Loading" />
        </div>
      ) : (
        <>
          {cardsData.length > 0 && (
            <div className="gridCards">
              {cardsData.map(card => (
                <CardGraph key={card.title} data={card} indicatorColor={card.indicator.color} />
              ))}
            </div>
          )}
          {graphicsData.length > 0 && (
            <div className="gridGraphics">
              {graphicsData.map(graphic => (
                <Graph
                  key={graphic.title}
                  data={graphic}
                  indicatorColor={graphic.indicator.color}
                  hasBigGridHorizontal
                  hasOptions
                />
              ))}
            </div>
          )}
          {cardsData.length <= 0 && graphicsData.length <= 0 && (
            <div className="noContent">
              <p>Não foi encontrado registro para o(s) filtro(s) informado(s)!</p>
            </div>
          )}
        </>
      )}
    </MainContainer>
  );
}
