import React from 'react';
import * as Styled from './styles';

type ErrorType = {
  textError: string;
};

export function ErrorComponent({ textError }: ErrorType) {
  return <Styled.WrapperError>{textError}</Styled.WrapperError>;
}
