import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { IValuesForFilterType } from 'shared/models/ValuesForFilter';
import { State } from 'shared/models/State';
import { City } from 'shared/models/City';
import * as Styled from './style';
import iconeLoading from '../../../../../assets/icone/icone_loading.gif';
import { getDataCrescimentoAnual } from './functionsInCoumnDashboard/getDataCrescimentoAnual';
import { ErrorComponent } from '../../ErrorComponent';
import { getDataSubsEstado } from './functionsInCoumnDashboard/getDataSubsEstado';
import { getDataProdBrutaEstado } from './functionsInCoumnDashboard/getDataProdBruta';
import { getDataPibUfRegiaoAno } from './functionsInCoumnDashboard/getDataPibUfRegiaoAno';
import { getDataIbgeGiniBrasil } from './functionsInCoumnDashboard/getIbgeGiniBrasil';
import { getDataCadastroDeMinerios } from './functionsInCoumnDashboard/getDataCadastroDeMineriosFase';
import { searchParamState } from './searchParams/searchParamsState';
import { CardChart } from '../../../GraphicsModel/CardChart';
import { initialValuesCardsChart } from './initialValuesCard/initialValuesCardChart';
import { getDataIbgeGiniEstado } from './functionsInCoumnDashboard/getIbgeGiniEstado';
import { calcCardProps } from './CalcCardsFunctionAndRender/calcCardsFunctionForState';
import { searchParamCity } from './searchParams/searchParamCity';
import WrapperGraph from '../../WrapperGraph';
import { renderCardsFunction } from './CalcCardsFunctionAndRender/renderCards';

type ColumnDashboardProps = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  filterValue: IValuesForFilterType;
  resetFilter: boolean;
  setResetFilter: React.Dispatch<React.SetStateAction<boolean>>;
  isClickedFilter: boolean;
  setIsClickedFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DataSubsEstadoType = {
  filterText?: string;
  dataChart: (string | (string | number))[][];
};

function ColumnDashboard({
  isClickedTopMenu,
  setSelectedOptionChart,
  filterValue,
  resetFilter,
  setResetFilter,
  isClickedFilter,
  setIsClickedFilter,
}: ColumnDashboardProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [renderWithFilter, setRenderWithFilter] = useState('');

  const [dataCrescimentoAnual, setDataCrescimentoAnual] = useState(
    [] as (string | number)[][],
  );

  const [dataSubsEstado, setDataSubsEstado] = useState(
    {} as DataSubsEstadoType[],
  );

  const [dataPrdBrutaEstado, setDataPrdBrutaEstado] = useState(
    {} as DataSubsEstadoType[],
  );

  const [dataPibUfRegiaoAno, setDataPibUfRegiaoAno] = useState(
    [] as (string | number)[][][],
  );

  const [dataPibCity, setDataPibCity] = useState([] as (string | number)[][][]);

  const [dataPibRegiao, setDataPibRegiao] = useState(
    [] as (string | number)[][][],
  );

  const [dataPibUf, setDataPibUf] = useState([] as (string | number)[][][]);

  const [dataIbgeGiniBrasil, setDataIbgeGiniBrasil] = useState(
    {} as (string | number)[][],
  );
  const [dataIbgeGiniEstado, setDataIbgeGiniEstado] = useState(
    [] as (string | number)[][][],
  );

  const [cadastroMinerarios, setCadastroMinerarios] = useState(
    {} as DataSubsEstadoType[],
  );

  const [getGraphicsSubsMinBeneficiada, setGetGraphicsSubsMinBeneficiada] =
    useState([] as DataSubsEstadoType[]);

  const [getGraphicsStateBeneficiada, setGetGraphicsStateBeneficiada] =
    useState([] as DataSubsEstadoType[]);

  const [getGraphicsStateBruta, setGetGraphicsStateBruta] = useState(
    [] as DataSubsEstadoType[],
  );

  const [getRegionProps, setGetRegionProps] = useState([] as State[]);
  const [arrayCardsOfPropRegion, setArrayCardsOfPropRegion] = useState(
    [] as string[][],
  );

  const [getStateProps, setGetStateProps] = useState([] as State[]);
  const [arrayCardsOfPropState, setArrayCardsOfPropState] = useState(
    [] as string[][],
  );

  const [getCityProps, setGetCityProps] = useState([] as City[]);
  const [arrayCardsOfPropCity, setArrayCardsOfPropCity] = useState(
    [] as string[][],
  );

  useEffect(() => {
    if (getRegionProps.length > 0) {
      setArrayCardsOfPropRegion(
        calcCardProps(getRegionProps, 'region', filterValue.region),
      );
    }

    if (getStateProps.length > 0) {
      setArrayCardsOfPropState(calcCardProps(getStateProps, 'state'));
    }

    if (getCityProps.length > 0) {
      const areaCity = getCityProps.reduce((a, b) => a + b.area, 0).toFixed(0);

      if (getCityProps.length === 1) {
        const arraySingle = ['Município (km²):', `${areaCity}`];
        setArrayCardsOfPropCity([arraySingle]);
      } else {
        const arrayMultiplesCityes = ['Municípios (km²):', `${areaCity}`];
        setArrayCardsOfPropCity([arrayMultiplesCityes]);
      }
    }

    renderCardsFunction({
      valuesSelected: filterValue,
      setRenderCards: setRenderWithFilter,
    });
  }, [getRegionProps, getStateProps, getCityProps]);

  useLayoutEffect(() => {
    if (isClickedFilter) {
      getAllData(filterValue);
      setIsClickedFilter(false);
    }
  }, [isClickedFilter]);

  useEffect(() => {
    if (
      getGraphicsStateBeneficiada.length > 0 &&
      getGraphicsSubsMinBeneficiada.length > 0
    ) {
      const concat = getGraphicsSubsMinBeneficiada.concat(
        getGraphicsStateBeneficiada,
      );
      setDataSubsEstado(concat);
    }
    if (
      getGraphicsStateBeneficiada.length > 0 &&
      getGraphicsSubsMinBeneficiada.length === 0
    ) {
      setDataSubsEstado(getGraphicsStateBeneficiada);
    }

    if (
      getGraphicsSubsMinBeneficiada.length > 0 &&
      getGraphicsStateBeneficiada.length === 0
    ) {
      setDataSubsEstado(getGraphicsSubsMinBeneficiada);
    }
  }, [getGraphicsStateBeneficiada, getGraphicsSubsMinBeneficiada]);

  useEffect(() => {
    if (dataPibCity.length > 0) {
      setDataPibUfRegiaoAno(dataPibCity);
    }

    if (dataPibRegiao.length > 0) {
      setDataPibUfRegiaoAno(dataPibRegiao);
    }

    if (dataPibUf.length > 0) {
      setDataPibUfRegiaoAno(dataPibUf);
    }

    if (dataPibCity.length > 0 && dataPibRegiao.length > 0) {
      const concat = dataPibCity.concat(dataPibRegiao);
      setDataPibUfRegiaoAno(concat);
    }

    if (dataPibCity.length > 0 && dataPibUf.length > 0) {
      const concat = dataPibCity.concat(dataPibUf);
      setDataPibUfRegiaoAno(concat);
    }

    if (dataPibRegiao.length > 0 && dataPibUf.length > 0) {
      const concat = dataPibRegiao.concat(dataPibUf);
      setDataPibUfRegiaoAno(concat);
    }

    if (dataPibRegiao.length > 0 && dataPibCity.length > 0) {
      const concat = dataPibRegiao.concat(dataPibCity);
      setDataPibUfRegiaoAno(concat);
    }

    if (dataPibUf.length > 0 && dataPibCity.length > 0) {
      const concat = dataPibUf.concat(dataPibCity);
      setDataPibUfRegiaoAno(concat);
    }

    if (dataPibUf.length > 0 && dataPibRegiao.length > 0) {
      const concat = dataPibUf.concat(dataPibRegiao);
      setDataPibUfRegiaoAno(concat);
    }

    if (
      dataPibCity.length > 0 &&
      dataPibRegiao.length > 0 &&
      dataPibUf.length > 0
    ) {
      const concat = dataPibCity.concat(dataPibRegiao, dataPibUf);
      setDataPibUfRegiaoAno(concat);
    }
  }, [dataPibCity, dataPibRegiao, dataPibUf]);

  useEffect(() => {
    if (getGraphicsStateBruta.length > 0) {
      setDataPrdBrutaEstado(getGraphicsStateBruta);
    }
  }, [getGraphicsStateBruta]);

  async function getAllData(filterParam?: IValuesForFilterType) {
    setLoading(true);
    let uf = [] as string[];

    renderCardsFunction({
      valuesSelected: filterValue,
      setRenderCards: setRenderWithFilter,
    });

    if (filterParam?.state !== undefined || filterParam?.region) {
      const state = filterParam?.state;
      const region = filterParam?.region;

      await searchParamState(
        uf,
        setError,
        setGetStateProps,
        setGetRegionProps,
        state,
        region,
      );
    }

    if (filterParam?.city !== undefined) {
      const city = filterParam?.city;
      searchParamCity(city, setError, setGetCityProps);
    }

    if (filterParam?.region === undefined) {
      setArrayCardsOfPropRegion([]);
    }

    if (filterParam?.state === undefined) {
      setArrayCardsOfPropState([]);
    }

    if (filterParam?.city === undefined) {
      setArrayCardsOfPropCity([]);
    }

    await getDataCrescimentoAnual({
      valueForFilter: filterParam?.country ?? undefined,
      setDataCrescimentoAnual,
      setLoading,
      setError,
    });

    await getDataSubsEstado({
      valueForFilter: {
        subsMineral: filterParam?.mineralSubstance,
        uf,
      },
      setDataSubsEstado,
      setError,
      setLoading,
      setGetGraphicsSubsMin: setGetGraphicsSubsMinBeneficiada,
      setGetGraphicsState: setGetGraphicsStateBeneficiada,
    });

    await getDataProdBrutaEstado({
      uf,
      setDataPrdBrutaEstado,
      setLoading,
      setError,
      setGetGraphicsStateBruta,
    });

    await getDataPibUfRegiaoAno({
      valuesForFilter: {
        uf,
        region: filterParam?.region,
        city: filterParam?.city,
      },
      setDataPibUfRegiaoAno,
      setError,
      setLoading,
      setDataPibRegiao,
      setDataPibCity,
      setDataPibUf,
    });

    await getDataIbgeGiniBrasil({
      setDataIbgeGiniBrasil,
      setError,
      setLoading,
    });

    await getDataCadastroDeMinerios({
      valueForFilter: {
        uf,
        region: filterParam?.region,
        city: filterParam?.city,
        subsMin: filterParam?.mineralSubstance,
      },
      setCadastroMinerarios,
      setError,
      setLoading,
    });

    if (uf.length > 0) {
      await getDataIbgeGiniEstado({
        valuesForFilter: {
          uf: filterParam?.state,
        },
        setDataIbgeGiniEstado,
        setError,
        setLoading,
      });
    }

    uf = [];
    setLoading(false);
  }

  useEffect(() => {
    if (resetFilter) {
      getAllData();
      setResetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, []);

  const updateDashboard = (id: string) => {
    const divElement = document.getElementById(id);
    setTimeout(() => {
      divElement?.remove();
    }, 1200);
  };

  return (
    <Styled.Wrapper filter={renderWithFilter}>
      {error && !loading && (
        <ErrorComponent textError="oooppss... tivemos problemas ao buscar os gráficos. Por favor verifique a conexão." />
      )}

      {loading && (
        <div className="loading">
          <img src={iconeLoading} alt="Loading" />
        </div>
      )}

      <div className="wrapperCards">
        {!loading &&
          renderWithFilter === 'country' &&
          initialValuesCardsChart.map((card, index) => (
            <div key={`${card.title + index}`}>
              <CardChart
                informationTitle={card.title}
                informationText={card.text}
              />
            </div>
          ))}

        {!loading &&
          renderWithFilter === 'region' &&
          arrayCardsOfPropRegion.length > 0 &&
          arrayCardsOfPropRegion.map((card, index) => (
            <div key={`${card[0] + index}`}>
              <CardChart informationTitle={card[0]} informationText={card[1]} />
            </div>
          ))}

        {!loading &&
          renderWithFilter === 'state' &&
          arrayCardsOfPropState.length > 0 &&
          arrayCardsOfPropState.map((card, index) => (
            <div key={`${card[0] + index}`}>
              <CardChart informationTitle={card[0]} informationText={card[1]} />
            </div>
          ))}

        {!loading &&
          renderWithFilter === 'city' &&
          arrayCardsOfPropCity.length > 0 &&
          arrayCardsOfPropCity.map((card, index) => (
            <div key={`${card[0] + index}`}>
              <CardChart informationTitle={card[0]} informationText={card[1]} />
            </div>
          ))}
      </div>
      <div className="wrapperAllGraphics">
        {!loading && !error && dataCrescimentoAnual.length > 0 && (
          <div
            className="wrapperGrid"
            id={
              renderWithFilter !== ''
                ? 'Crescimento Anual de Preços ao Consumidor - Brasil'
                : 'Crescimento Anual de Preços ao Consumidor'
            }
          >
            <WrapperGraph
              titleGraph={
                renderWithFilter !== ''
                  ? 'Crescimento Anual de Preços ao Consumidor - Brasil'
                  : 'Crescimento Anual de Preços ao Consumidor'
              }
              chartModel="RegionGeoCharts"
              isClickedTopMenu={isClickedTopMenu}
              data={dataCrescimentoAnual}
              fontUsedLink="https://data.worldbank.org/indicator/FP.CPI.TOTL.ZG?end=2021&start=1960&view=chart"
              setSelectedOptionChart={setSelectedOptionChart}
              updateDashboard={updateDashboard}
              id={
                renderWithFilter !== ''
                  ? 'Crescimento Anual de Preços ao Consumidor - Brasil'
                  : 'Crescimento Anual de Preços ao Consumidor'
              }
            />
          </div>
        )}

        {renderWithFilter !== '' && (
          <>
            {!loading &&
              !error &&
              cadastroMinerarios.length > 0 &&
              cadastroMinerarios[0].dataChart.length > 1 &&
              cadastroMinerarios.map((cadastro, index) => (
                <div
                  key={`${Date.now()}${cadastro.filterText}`}
                  className="wrapperGrid"
                  id={`${index} Direitos Minerários`}
                >
                  <WrapperGraph
                    isClickedTopMenu={isClickedTopMenu}
                    chartModel="StackedBarChartWithMultipleSeries"
                    titleGraph={
                      cadastroMinerarios.length > 0
                        ? `Direitos Minerários ${cadastro.filterText}`
                        : 'Direitos Minerários'
                    }
                    data={cadastro.dataChart}
                    fontUsedLink="https://dados.gov.br/dataset/anuario-mineral-brasileiro-amb"
                    setSelectedOptionChart={setSelectedOptionChart}
                    updateDashboard={updateDashboard}
                    id={`${index} Direitos Minerários`}
                  />
                </div>
              ))}

            {!loading &&
              !error &&
              dataPibUfRegiaoAno.map((data, index) => (
                <div
                  className="wrapperGrid"
                  key={data.length + Math.random()}
                  id={`${index} PIB a preços correntes 2002-2019`}
                >
                  <WrapperGraph
                    isClickedTopMenu={isClickedTopMenu}
                    chartModel="MaterialDesignBarCharts"
                    titleGraph="PIB a preços correntes 2002-2019"
                    data={data}
                    fontUsedLink="http://www.atlasbrasil.org.br/consulta/planilha"
                    setSelectedOptionChart={setSelectedOptionChart}
                    updateDashboard={updateDashboard}
                    id={`${index} PIB a preços correntes 2002-2019`}
                  />
                </div>
              ))}

            {!loading && !error && dataIbgeGiniBrasil.length > 0 && (
              <div className="wrapperGrid" id="Índice Gini">
                <WrapperGraph
                  isClickedTopMenu={isClickedTopMenu}
                  chartModel="MaterialDesignHorizontalBarChart"
                  titleGraph="Índice Gini"
                  data={dataIbgeGiniBrasil}
                  fontUsedLink="http://tabnet.datasus.gov.br/cgi/ibge/censo/cnv/ginibr.def"
                  setSelectedOptionChart={setSelectedOptionChart}
                  updateDashboard={updateDashboard}
                  id="Índice Gini"
                />
              </div>
            )}

            {!loading &&
              !error &&
              dataIbgeGiniEstado.length > 0 &&
              dataIbgeGiniEstado.map((data, index) => (
                <div
                  key={`${Date.now()}${Math.random()}`}
                  className="wrapperGrid"
                  id={`${index} Índice Gini Estado`}
                >
                  <WrapperGraph
                    isClickedTopMenu={isClickedTopMenu}
                    chartModel="MaterialDesignHorizontalBarChart"
                    titleGraph="Índice Gini Estado"
                    data={data}
                    fontUsedLink="http://tabnet.datasus.gov.br/cgi/ibge/censo/cnv/ginibr.def"
                    setSelectedOptionChart={setSelectedOptionChart}
                    updateDashboard={updateDashboard}
                    id={`${index} Índice Gini Estado`}
                  />
                </div>
              ))}

            {!loading &&
              !error &&
              dataSubsEstado.length > 0 &&
              dataSubsEstado[0].dataChart.length > 1 &&
              dataSubsEstado.map((data, index) => (
                <div
                  className="wrapperGrid"
                  key={data.dataChart.length + Math.random()}
                  id={`${index} Produção Mineral Produzida - Produção Mineral Comercializada`}
                >
                  <WrapperGraph
                    titleGraph={
                      data.filterText
                        ? `Produção Mineral Produzida - Produção Mineral Comercializada - ${data.filterText}`
                        : 'Produção Mineral Produzida - Produção Mineral Comercializada'
                    }
                    isClickedTopMenu={isClickedTopMenu}
                    chartModel="DiffColumnChart"
                    data={data.dataChart}
                    fontUsedLink="https://dados.gov.br/dataset?q=produ%C3%A7%C3%A3o+mineral&sort=score+desc%2C+metadata_modified+desc"
                    setSelectedOptionChart={setSelectedOptionChart}
                    updateDashboard={updateDashboard}
                    id={`${index} Produção Mineral Produzida - Produção Mineral Comercializada`}
                  />
                </div>
              ))}

            {!loading &&
              !error &&
              dataPrdBrutaEstado.length > 0 &&
              dataPrdBrutaEstado[0].dataChart.length > 1 &&
              dataPrdBrutaEstado.map((data, index) => (
                <div
                  className="wrapperGrid"
                  key={data.dataChart.length + Math.random()}
                  id={`${index} Produção Mineral Bruta Produzida - Produção Mineral Bruta Comercializada`}
                >
                  <WrapperGraph
                    titleGraph={
                      data.filterText
                        ? `Produção Mineral Bruta Produzida - Produção Mineral Bruta Comercializada - ${data.filterText}`
                        : 'Produção Mineral Bruta Produzida - Produção Mineral Bruta Comercializada'
                    }
                    isClickedTopMenu={isClickedTopMenu}
                    chartModel="DiffColumnChart"
                    data={data.dataChart}
                    fontUsedLink="https://dados.gov.br/dataset/anuario-mineral-brasileiro-amb"
                    setSelectedOptionChart={setSelectedOptionChart}
                    updateDashboard={updateDashboard}
                    id={`${index} Produção Mineral Bruta Produzida - Produção Mineral Bruta Comercializada`}
                  />
                </div>
              ))}
          </>
        )}
      </div>
    </Styled.Wrapper>
  );
}

export default memo(ColumnDashboard);
