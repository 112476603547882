import styled, { css } from 'styled-components';

type SelectComponentType = {
  selectedInput: boolean;
  options: string[];
  width: string;
  multipleSelected: boolean;
  notSelectFirstInputDefault: boolean;
};

function returnSelectSideBarOrOthers(
  multipleSelect: boolean,
  notSelectDefault: boolean,
) {
  // if result === true, then is = sideBarSelect
  if (multipleSelect && notSelectDefault) {
    return 'max-content';
  }

  if (!multipleSelect && notSelectDefault) {
    return 'max-content';
  }

  return '2.5rem';
}

function adjustingWidthWithBorder(
  width: string,
  multipleSelect: boolean,
  notSelectDefault: boolean,
) {
  if (width && multipleSelect && notSelectDefault) {
    return `calc(${width} - 0.1rem)`;
  }

  if (width && !multipleSelect && notSelectDefault) {
    return `calc(${width} - 0.1rem)`;
  }

  return width;
}

export const SelectComponent = styled.div<SelectComponentType>`
  ${({
    theme,
    selectedInput,
    options,
    width,
    multipleSelected,
    notSelectFirstInputDefault,
  }) => css`
    width: ${width};
    height: ${returnSelectSideBarOrOthers(
      multipleSelected,
      notSelectFirstInputDefault,
    )};
    position: ${!multipleSelected && !notSelectFirstInputDefault && 'relative'};
    border 1px solid ${theme.colors.black};
    border-radius: 5px;
    margin-top: ${theme.spacings[8]};
    margin-bottom: ${theme.spacings[20]};
    border-bottom-left-radius: ${
      !multipleSelected && selectedInput && notSelectFirstInputDefault && '0'
    };
    border-bottom-right-radius: ${
      !multipleSelected && selectedInput && notSelectFirstInputDefault && '0'
    };

    .centralizedComponent {
      margin: 0 auto;
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: ${theme.spacings[4]} 0;
      border: none;

      p {
        color: ${theme.colors.black};
        font-weight: ${theme.font.weights.regular};
        max-width: 80%;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        text-transform: capitalize;
      }

      .arrowBottom {
        font-size: 2rem;
        transform: ${selectedInput && 'rotateZ(180deg)'}
      }
    }

    .listOfSelections {
      width: ${adjustingWidthWithBorder(
        width,
        multipleSelected,
        notSelectFirstInputDefault,
      )};
      height: ${options.length > 6 ? '17rem' : 'max-content'};
      position: ${
        !multipleSelected && !notSelectFirstInputDefault && 'absolute'
      };
      top: ${!multipleSelected && !notSelectFirstInputDefault && '100%'};
      left: ${!multipleSelected && !notSelectFirstInputDefault && '-1%'};
      background-color: ${theme.colors.gray200};
      border-right: ${
        multipleSelected && notSelectFirstInputDefault
          ? `1px solid ${theme.colors.black}`
          : !multipleSelected &&
            notSelectFirstInputDefault &&
            `1px solid ${theme.colors.black}`
      };
      border: ${
        !multipleSelected &&
        !notSelectFirstInputDefault &&
        `1px solid ${theme.colors.black}`
      };
      border-top: ${!multipleSelected && !notSelectFirstInputDefault && '0'};

      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.colors.gray50};  
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.blue700};
      }
    }

    .centralizedList {
      margin: 0 auto;
      width: 90%;
      height: 100%;
      padding: ${theme.spacings[6]} 0;
      background-color: transparent;
    }

    .inputWrapper {
      width: 100%;
      height: ${theme.spacings[24]};
      display: flex;
      align-items: center;


      .wrapperTextInput {
        width: 90%;
        height: max-content;
        border: none;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        border: none;
        background-color: transparent;
        text-align: left;

        p {
          font-family: ${theme.font.family.default};
          font-weight: ${theme.font.weights.regular};
          color: ${theme.colors.black};
          width: 100%;
          font-size: ${theme.font.sizes.md};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          margin-left: ${theme.spacings[6]};
          text-transform: capitalize;
        }
      }
  
    }
  `}
`;
