import React, { memo } from 'react';
import Chart from 'react-google-charts';
import { GraphModelType } from '../../type/GraphModelType';

function ComboChart({ data }: GraphModelType) {
  const options = {
    seriesType: 'bars',
  };

  return (
    <Chart
      chartType="ComboChart"
      width="33.5vw"
      height="40vh"
      data={data}
      options={options}
    />
  );
}

export default memo(ComboChart);
