import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Direction } from 'reactstrap/types/lib/Dropdown';
import { useTranslation } from 'react-i18next';

export interface DropdownProps {
  direction?: Direction;
  label: string;
  links: { id: number; label: string; link: string }[];
}

function DropdownGov({ direction, label, links, ...args }: DropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { t: translation } = useTranslation();

  return (
    <div className="d-flex p-5">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <DropdownToggle caret color="light">
          {label}
        </DropdownToggle>
        <DropdownMenu {...args}>
          {links.map(item => (
            <DropdownItem key={item.id} href={item.link} toggle>
              {translation(`header.${item.label}`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default DropdownGov;
