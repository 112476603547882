import React from 'react';

interface IProperties {
  properties: {
    nomconcurb: string;
    uf: string;
    regiao_estado: string;
    densidade: string;
  };
}

export function AreasUrbanasMais300({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Áreas Urbanas com Mais de 300 mil Habitantes</strong>
      </h4>
      <p>
        <strong>Nome concentração urbana: </strong>{' '}
        {properties.nomconcurb ? properties.nomconcurb : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Região do estado: </strong>{' '}
        {properties.regiao_estado ? properties.regiao_estado : 'Não informado'}
      </p>
      <p>
        <strong>Densidade populacional: </strong>{' '}
        {properties.densidade ? properties.densidade : 'Não informado'}
      </p>
    </div>
  );
}
