import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 92px);
  position: relative;

  display: flex;
  justify-content: center;
`;

export const ButtonBaseMaps = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacings[8]};
    position: absolute;
    bottom: ${theme.spacings[48]};
    right: 22.4rem;

    display: flex;
    justify-content: center;

    border: 0;
    border-radius: ${theme.radius.full};

    background-color: ${theme.colors.white};
    color: ${theme.colors.gray900};

    z-index: 1;

    &:hover {
      &::before {
        top: -4.5rem;

        opacity: 1;
      }
    }

    &::before {
      content: attr(name);
      padding: ${theme.spacings[8]} ${theme.spacings[12]};
      position: absolute;
      top: -3rem;

      font-size: ${theme.font.sizes.sm};
      font-weight: ${theme.font.weights.bold};
      white-space: nowrap;

      border-radius: 0.4rem;

      background-color: ${theme.colors.white};
      color: ${theme.colors.gray900};

      opacity: 0;
      transition: all 0.2s;
    }
  `}
`;

export const MapDisclaimer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: 0;

    span {
      background: #ffffffb2;
      font-size: ${theme.font.sizes.xs};
      padding-left: ${theme.spacings[4]};
      padding-right: ${theme.spacings[10]};

      a {
        color: #0078a8;
        padding-left: ${theme.spacings[4]};
      }
    }
  `}
`;
