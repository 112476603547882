import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;

    .govBar {
      width: 100%;
      padding: ${theme.spacings[4]} ${theme.spacings[64]};

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacings[4]} ${theme.spacings[24]};

      background: linear-gradient(to right, #295585, #23669f);

      .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: ${theme.spacings[4]} ${theme.spacings[12]};
      }

      .link {
        font-size: ${theme.font.sizes.xs};

        color: ${theme.colors.white};

        &:hover {
          text-decoration: underline;
        }
      }

      .dropdownLinks {
        display: none;
      }

      @media (max-width: 1000px) {
        padding: ${theme.spacings[4]} ${theme.spacings[32]};
      }

      @media (max-width: 600px) {
        justify-content: center;
      }
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings[4]} ${theme.spacings[64]} ${theme.spacings[4]} ${theme.spacings[32]};

    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.white};

    > a {
      display: inline-flex;
    }

    .sgbLogo,
    .sgbLogoEnUs {
      width: 12rem;
    }

    .P3MlogoBlock {
      width: 30rem;
    }

    .P3MlogoSmallBlock {
      display: none;
    }

    .versionLogo {
      display: flex;
      align-items: center;

      img {
        // margin-right: 10px;
      }

      p {
        background: ${theme.colors.blue600};
        color: white;
        border: 5px solid ${theme.colors.blue600};
        border-radius: 10px 30px;
        font-size: 12px;
      }
    }

    .versionBeta {
      p {
        background: #696969;
        color: white;
        border: 5px solid #696969;
        border-radius: 10px 30px;
        font-size: 12px;
      }
    }

    .linksBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a,
      button {
        font-family: ${theme.font.family.default};
        font-size: ${theme.font.sizes.md};

        display: flex;
        align-items: center;

        border: 0;

        background-color: transparent;
        color: ${theme.colors.indigo800};
        font-weight: ${theme.font.weights.medium};
        svg {
          margin-right: ${theme.spacings[8]};
        }

        &:hover {
          text-decoration: underline;
        }
      }

      a + a {
        margin-left: ${theme.spacings[20]};
      }

      @media (max-width: 800px) {
        margin: 0 auto;
      }
    }

    #contactButton {
      color: ${theme.colors.indigo800};
      font-weight: ${theme.font.weights.medium};
      font-size: ${theme.font.sizes.sm};
    }

    @media (max-width: 1000px) {
      padding: ${theme.spacings[4]} ${theme.spacings[24]} ${theme.spacings[4]} 0;
    }

    @media (max-width: 600px) {
      justify-content: center;
      padding-left: 0;
      padding-right: 0;

      .versionLogo {
        justify-content: center;
      }

      .P3MlogoBlock {
        display: none;
      }

      .P3MlogoSmallBlock {
        width: 10rem;
        margin-left: auto;
        display: block;
      }

      .sgbLogo,
      .sgbLogoEnUs {
        width: 14rem;
      }
    }

    @media (max-width: 500px) {
      #contactLabel {
        display: none;
      }
    }

    @media (max-width: 400px) {
      .P3MlogoBlock {
        display: none;
      }

      .P3MlogoSmallBlock {
        margin-left: auto;
        display: block;
      }

      .sgbLogo,
      .sgbLogoEnUs {
        width: 14rem;
      }

      #contactLabel {
        font-size: ${theme.font.sizes.xs};
      }
    }
  `}
`;

export const ContentEmail = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 30rem;
    margin-top: ${theme.spacings[24]};

    > span {
      font-family: ${theme.font.family.default};
      font-weight: ${theme.font.weights.bold};
    }

    .contentLine {
      margin-top: ${theme.spacings[4]};

      display: flex;
      align-items: center;

      a {
        margin-left: ${theme.spacings[8]};

        color: ${theme.colors.blue600};

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;
