import React, { useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { RiArrowDropLeftLine } from 'react-icons/ri';
import ReactTooltip from 'react-tooltip';

import { Button } from 'components';

import theme from 'styles/theme';
import { Oval } from 'react-loader-spinner';
import { IndicatorsBox } from 'components/IndicatorsBox';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { SideSelect } from '../../screens/NewDashboard/components';

import {
  SideBar,
  SelectContainer,
  ButtonContainer,
  LoadingContainer,
  FooterContainer,
  IndicatorContainer,
  FilterSelecteds,
  MainContainer,
} from './styles';
import { NameToSigla, nameToSigla } from 'utils/convertStateName';
import { useLayers } from 'hooks/useLayers';
import { useFilter } from 'hooks/useFilter';

interface IFilter {
  mapFilter?: boolean;
  applyFilter: () => Promise<void>;
}

export function Filter({ mapFilter, applyFilter }: IFilter) {
  const { t: translation } = useTranslation();

  const { layersActived } = useLayers();

  const [isCollapse, setIsCollapse] = useState(!mapFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledFilter, setDisabledFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  const {
    filterData,
    indicators,
    regions,
    counties,
    states,
    loadingDataFilter,
    toggleActiveIndicator,
    addItemToFilterData,
    removeItemFromFilterData,
    clearFilterData,
  } = useFilter();

  const globalDisabled = disabledFilter || loadingDataFilter || isLoading;

  const [transition, setTransition] = useState<boolean>(false);

  function handleOnChooseRegion(item: string) {
    const isExistsNew = filterData.regions.includes(item);

    if (isExistsNew) {
      removeItemFromFilterData(item, 'regions');

      return;
    }

    addItemToFilterData(item, 'regions');
  }

  function handleAddState(name: string) {
    const clearName = name.replace(/\s+/g, '') as NameToSigla;
    const valueName = nameToSigla[clearName];

    const isExistsNew = filterData.states.includes(valueName);

    if (!isExistsNew) addItemToFilterData(valueName, 'states');
  }

  function handleRemoveState(name: string) {
    removeItemFromFilterData(name, 'states');
  }

  function handleOnChooseCounty(item: string) {
    const isExistsNew = filterData.counties.includes(item);

    if (isExistsNew) {
      removeItemFromFilterData(item, 'counties');

      return;
    }

    addItemToFilterData(item, 'counties');
  }

  // eslint-disable-next-line
  function handleOnChooseIndicators(indicator: any) {
    const isExistsNew = filterData.indicators.includes(indicator.id.toString());

    toggleActiveIndicator(indicator.id);

    if (isExistsNew) {
      removeItemFromFilterData(indicator.id.toString(), 'indicators');

      return;
    }

    addItemToFilterData(indicator.id.toString(), 'indicators');
  }

  function handleTransition() {
    if (!disabledFilter) {
      setTransition(!transition);
    }
  }

  function filterStateByRegion() {
    const regionsFilter = filterData.regions;

    if (regionsFilter.length) {
      const statesFiltered = states.filter(state => regionsFilter.includes(state.regiao));

      return statesFiltered;
    }

    return states;
  }

  function filterCountyByState() {
    const statesFilter = filterData.states;
    const stateByRegion = filterStateByRegion().map(state => state.sigla);
    const filterCounties = statesFilter.length ? statesFilter : stateByRegion;

    return counties.filter(county => filterCounties.includes(county.sigla_uf));
  }

  async function handleApplyFilter() {
    setIsLoading(true);

    await applyFilter();
    mapFilter && setFilterApplied(true);
    setIsLoading(false);

    if (mapFilter) {
      setTimeout(() => {
        setFilterApplied(false);
      }, 5000);
    }
  }

  useEffect(() => {
    if (mapFilter && layersActived.length === 0) setDisabledFilter(true);
  }, []);

  return (
    <SideBar state={isCollapse} mapFilter={mapFilter} transition={transition}>
      {!mapFilter && (
        <header className="headerFilter">
          <div className="filter-icon">
            <BsFilter size={30} color="#FFF" />
          </div>
          <span>{translation('dashboardPage.sidebar.title')}</span>
          <button
            type="button"
            className="expandedButton"
            onClick={() => setIsCollapse(!isCollapse)}
            data-tip
            data-for="filter-tooltip"
          >
            <RiArrowDropLeftLine size={37} color="#FFF" />
          </button>
        </header>
      )}
      {isCollapse && (
        <ReactTooltip id="filter-tooltip" place="right" type="info" effect="solid" delayShow={500}>
          <p>{translation('dashboardPage.sidebar.title')}</p>
        </ReactTooltip>
      )}
      <MainContainer mapFilter={mapFilter}>
        <SelectContainer mapFilter={mapFilter}>
          {!isCollapse && (
            <>
              {mapFilter && <strong>{translation('mapPage.sideBar.filter.clipping')}</strong>}
              {/* Filtro por pais */}
              <SideSelect
                label={translation('dashboardPage.sidebar.selectsLabel.country')}
                placeholder={'Brasil'}
                data={[]}
                value={['']}
                itemLabel={item => item}
                keyExtractor={item => item}
                renderItem={item => item}
                disabled
              />
              <FilterSelecteds>
                <div className="box">
                  <span className="label">Brasil</span>
                </div>
              </FilterSelecteds>
              {/* Filtro por região */}
              <SideSelect
                label={translation('dashboardPage.sidebar.selectsLabel.geographicalRegion')}
                placeholder={translation('dashboardPage.sidebar.selectsLabel.selectRegion')}
                empty="Carregando..."
                data={regions}
                keyExtractor={item => item.sigla}
                renderItem={item => item.titulo}
                itemLabel={item => item.nome}
                onChoose={item => handleOnChooseRegion(item.nome)}
                disabled={globalDisabled}
                multiple
                value={filterData.regions}
                mapFilter={mapFilter}
                handleClickTransition={() => handleTransition()}
              />
              <FilterSelecteds>
                {filterData.regions.map((region, index) => (
                  <div className="box" key={`${region}-${index}`}>
                    <span className="label">{region}</span>
                    <span className="icon" onClick={() => handleOnChooseRegion(region)}>
                      <FiX />
                    </span>
                  </div>
                ))}
              </FilterSelecteds>
              {/* Filtro por estado */}
              <SideSelect
                label={translation('dashboardPage.sidebar.selectsLabel.state')}
                placeholder={translation('dashboardPage.sidebar.selectsLabel.selectState')}
                empty="Carregando..."
                data={filterStateByRegion()}
                keyExtractor={item => item.nome}
                renderItem={item => item.nome}
                itemLabel={item => item.nome}
                onChoose={({ nome }) => handleAddState(nome)}
                multiple
                value={filterData.states}
                disabled={globalDisabled}
                mapFilter={mapFilter}
                handleClickTransition={() => handleTransition()}
              />
              <FilterSelecteds>
                {filterData.states.map((state, index) => {
                  const siglaToName = states.filter(stateItem => stateItem.sigla === state)[0]?.nome;

                  return (
                    <div className="box" key={`${state}-${index}`}>
                      <span className="label">{siglaToName}</span>
                      <span
                        className="icon"
                        onClick={() => {
                          handleRemoveState(state);
                        }}
                      >
                        <FiX />
                      </span>
                    </div>
                  );
                })}
              </FilterSelecteds>
              {/* Filtro por município */}
              <SideSelect
                label={translation('dashboardPage.sidebar.selectsLabel.county')}
                placeholder={translation('dashboardPage.sidebar.selectsLabel.selectCounty')}
                empty="Carregando..."
                data={filterCountyByState()}
                keyExtractor={item => String(item.cod_mun)}
                renderItem={item => item.cod_mun}
                itemLabel={item => `${item.nm_mun} - ${item.sigla_uf}`}
                // eslint-disable-next-line camelcase
                onChoose={item => handleOnChooseCounty(item.cod_mun)}
                multiple
                value={filterData.counties}
                mapFilter={mapFilter}
                disabled={globalDisabled}
                handleClickTransition={() => handleTransition()}
              />
              <FilterSelecteds>
                {filterData.counties.map((countyItem, index) => {
                  const countyName = counties.filter(item => item.cod_mun === countyItem)[0].nm_mun;
                  return (
                    <div className="box" key={`${countyItem}-${index}`}>
                      <span className="label">{countyName}</span>
                      <span className="icon" onClick={() => handleOnChooseCounty(countyItem)}>
                        <FiX />
                      </span>
                    </div>
                  );
                })}
              </FilterSelecteds>
            </>
          )}
        </SelectContainer>
        <IndicatorContainer>
          {!isCollapse && !mapFilter && (
            <IndicatorsBox
              data={indicators}
              disabledFilter={globalDisabled}
              onChoose={item => handleOnChooseIndicators(item)}
            />
          )}
        </IndicatorContainer>
      </MainContainer>
      {!isCollapse && (
        <FooterContainer mapFilter={mapFilter}>
          {mapFilter && (
            <div className="messageFilter">
              {!disabledFilter && (
                <span className={filterApplied ? 'fadeIn' : 'fadeOut'}>Filtro(s) aplicado(s) com sucesso!</span>
              )}
              {disabledFilter && <span className="warning">{translation('mapPage.sideBar.filter.notFound')}</span>}
            </div>
          )}
          <ButtonContainer mapFilter={mapFilter}>
            <Button outline onClick={() => clearFilterData()} disabled={isLoading || disabledFilter}>
              {translation('dashboardPage.sidebar.buttonsLabel.clear')}
            </Button>
            <Button onClick={() => handleApplyFilter()} disabled={isLoading || disabledFilter}>
              {isLoading ? (
                <LoadingContainer>
                  <Oval
                    height={15}
                    width={15}
                    strokeWidth={5}
                    color={theme.colors.blueA700}
                    secondaryColor={theme.colors.white}
                  />
                </LoadingContainer>
              ) : (
                <span>{translation('dashboardPage.sidebar.buttonsLabel.apply')}</span>
              )}
            </Button>
          </ButtonContainer>
        </FooterContainer>
      )}
    </SideBar>
  );
}
