import React from 'react';

export function BaciasHidrograficas() {
  return (
    <div className="information">
      <h4>
        <strong>Bacias Hidrográficas</strong>
      </h4>
      <p>Nenhuma propriedade para exibição.</p>
    </div>
  );
}
