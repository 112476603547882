import { useLayers } from 'hooks/useLayers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItemAction,
  ButtonTab,
  Content,
  Header,
  Nav,
  SubHeader,
  ModalClearLayers,
  ButtonClearAll,
} from './styles';
import { Modal } from 'components/Modal';
import { Button } from 'components';
import { IoWarning } from 'react-icons/io5';
import { useMap } from 'hooks/useMap';
import { ILayers } from 'contexts/@types/Layers';

import { ImBin } from 'react-icons/im';

interface IOptions {
  index: number;
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
}

interface ITabs {
  actived: number;
  children: React.ReactNode;
  options: IOptions[];
  title: string;
  iconTitle: React.ReactNode;
  onChange: (index: number, title: string, icon: React.ReactNode) => void;
  breadcrumb: {
    label: string;
    action?: (() => void) | undefined;
  }[];
  showClearAll?: boolean;
}

export function Tabs({ actived, children, options, onChange, title, iconTitle, breadcrumb, showClearAll = false }: ITabs) {
  const { t: translation } = useTranslation();
  const { layersActived } = useLayers();
  const [isClearActivatedLayersModalOpen, setIsClearActivatedLayersModalOpen] = useState<boolean>(false);
  const { handleClearLayersActived } = useLayers();
  const { handleRemoveOverlayLayer } = useMap();

  const clearActivatedLayers = () => {
    setIsClearActivatedLayersModalOpen(false);
    layersActived.forEach((layer: ILayers) => {
      handleRemoveOverlayLayer(layer.layertitle + layer.substancia_agrupadora_id);
    });
    handleClearLayersActived();
  };

  return (
    <>
      <Header>
        <span className="icon">{iconTitle}</span>
        <h1>{title}</h1>
      </Header>

      <Content>
        <SubHeader>
          <Breadcrumb>
            {breadcrumb.map(({ label, action }, index) => {
              const labelTranslated = translation(label);
              const lastElement = index === breadcrumb.length - 1;

              return action ? (
                <BreadcrumbItemAction onClick={() => action()} key={`breadcrumb-item-${label}-${index}`}>
                  {labelTranslated} {!lastElement && '> '}
                </BreadcrumbItemAction>
              ) : (
                <span key={`breadcrumb-item-${label}-${index}`}>
                  {labelTranslated} {!lastElement && '> '}
                </span>
              );
            })}
          </Breadcrumb>
          {showClearAll && (
            <ButtonClearAll
              onClick={() => {
                setIsClearActivatedLayersModalOpen(true);
              }}
            >
              <ImBin size={18} color="#fff" />
              Limpar tudo
            </ButtonClearAll>
          )}
        </SubHeader>

        {React.Children.map(children, (child, index) => {
          //eslint-disable-line
          if (index === actived - 1) return child;
        })}
        <Nav>
          {options.map(item => (
            <span key={item.label} data-tip data-for={item.disabled ? 'settingsButton' : ''} className="teste">
              <ButtonTab
                key={item.index}
                onClick={() => onChange(item.index, item.label, item.icon)}
                disabled={item.disabled}
              >
                {translation(item.label) === 'Camadas' && <span className="activedLayers">{layersActived.length}</span>}
                <div className="icons">{item.icon}</div>
                <p className="tag-label">{translation(item.label)}</p>
              </ButtonTab>
            </span>
          ))}
        </Nav>
      </Content>
      {isClearActivatedLayersModalOpen && (
        <Modal onDisabled={() => setIsClearActivatedLayersModalOpen(false)}>
          <ModalClearLayers>
            <div className="header">
              <IoWarning size={32} color="#fc3" />
              <h2>{translation('mapPage.sideBar.layers.modalClear.title')}</h2>
            </div>

            <p>{translation('mapPage.sideBar.layers.modalClear.content')}</p>

            <div className="buttons">
              <Button outline={true} className="btn-cancel" onClick={() => setIsClearActivatedLayersModalOpen(false)}>
                {translation('mapPage.sideBar.layers.modalClear.cancel')}
              </Button>
              <Button className="btn-confirm" onClick={() => clearActivatedLayers()}>
                {translation('mapPage.sideBar.layers.modalClear.confirm')}
              </Button>
            </div>
          </ModalClearLayers>
        </Modal>
      )}
    </>
  );
}
