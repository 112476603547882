import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { Header } from 'components';

import { LayersProvider } from 'contexts/LayersContext';
import { OptionsProvider } from 'contexts/OptionsContext';
import { MapProvider } from 'contexts/MapContext';

import { Router } from 'routes';

import theme from 'styles/theme';
import GlobalStyles from 'styles/global';
import { FilterProvider } from 'contexts/FilterContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <FilterProvider>
        <MapProvider>
          <LayersProvider>
            <OptionsProvider>
              <HashRouter basename={process.env.PUBLIC_URL}>
                <Header />
                <Router />
                <GlobalStyles />
              </HashRouter>
            </OptionsProvider>
          </LayersProvider>
        </MapProvider>
      </FilterProvider>
    </ThemeProvider>
  );
}

export default App;
