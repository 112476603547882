/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import { ChartContainer } from './styles';

export interface GraphProps {
  data?: { name: string | number; value: string | number }[];
}

export function FunnelChartComponent({ data }: GraphProps) {
  const pallete = ['#f14c14', '#f39c35', '#68BC00', '#1d7b63', '#4e97a8', '#4466a3', '#00bfff', '#f4a460', '#b22222'];

  const generateTooltip = (row: any) => {
    return `${row.name}\n${row.value}`;
  };

  return (
    <ChartContainer>
      <FunnelChart data={data} pallette={pallete} getToolTip={(row: any) => generateTooltip(row)} />
    </ChartContainer>
  );
}
