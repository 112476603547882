import React from 'react';

interface IProperties {
  properties: {
    nm: string;
    prop: string;
    cnpj: string;
    ceg: string;
    mun_cf: string;
    uf_cf: string;
    mun_1: string;
    uf_1: string;
    mun_2: string;
    uf_2: string;
    pot_kw: number;
    vol_na_max: number;
    vol_na_min: number;
  };
}

export function UsinasHIdreletricasUHE({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Usinas Hidrelétricas UHE</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nm ? properties.nm : 'Não informado'}
      </p>
      <p>
        <strong>Proprietário: </strong>{' '}
        {properties.prop ? properties.prop : 'Não informado'}
      </p>
      <p>
        <strong>Cadastro Nacional da Pessoa Juridica (cnpj): </strong>{' '}
        {properties.cnpj ? properties.cnpj : 'Não informado'}
      </p>
      <p>
        <strong>
          Código Único de Empreendimentos de Geração (CEG) de uma central
          geradora:{' '}
        </strong>{' '}
        {properties.ceg ? properties.ceg : 'Não informado'}
      </p>
      <p>
        <strong>Município do empreendimento: </strong>{' '}
        {properties.mun_cf ? properties.mun_cf : 'Não informado'}
      </p>
      <p>
        <strong>Estado do empreendimento: </strong>{' '}
        {properties.uf_cf ? properties.uf_cf : 'Não informado'}
      </p>
      <p>
        <strong>Municipio 1: </strong>{' '}
        {properties.mun_1 ? properties.mun_1 : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa 1: </strong>{' '}
        {properties.uf_1 ? properties.uf_1 : 'Não informado'}
      </p>
      <p>
        <strong>Municipio 2: </strong>{' '}
        {properties.mun_2 ? properties.mun_2 : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa 2: </strong>{' '}
        {properties.uf_2 ? properties.uf_2 : 'Não informado'}
      </p>
      <p>
        <strong>Potencia em Quilowatts: </strong>{' '}
        {properties.pot_kw !== null
          ? properties.pot_kw.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Volume em cota máxima: </strong>{' '}
        {properties.vol_na_max !== null
          ? properties.vol_na_max.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Volume em cota mínima: </strong>{' '}
        {properties.vol_na_min !== null
          ? properties.vol_na_min.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
