export const initialValuesCardsChart = [
  {
    title: 'Área de Direitos Minerários em Lavra',
    text: '2,8 %',
  },
  {
    title: 'Área Especiais',
    text: '58,2%',
  },
  {
    title: 'Brasil (km²)',
    text: '8,5 Mi',
  },
  {
    title: 'Total de regiões no País:',
    text: '5',
  },
  {
    title: 'Total de estados no País:',
    text: '26',
  },
  {
    title: 'Total de municípios no País:',
    text: '5572',
  },
  {
    title: 'Municípios Produtores:',
    text: '2815',
  },
  {
    title: 'Municípios com Atividades de Pesquisa:',
    text: '4874',
  },
];
