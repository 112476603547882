import { transparentize } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';
import theme from 'styles/theme';

interface IButton {
  outline?: boolean;
}

const buttonModifiers = {
  outline: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.blue600};

    background-color: transparent;
    color: ${theme.colors.blue600};

    &:hover:not(&:disabled) {
      border: 1px solid ${theme.colors.blue600};

      background-color: ${theme.colors.blue600};
      color: ${theme.colors.white};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      background-color: transparent;
    }
  `,
};

export const Button = styled.button<IButton>`
  ${({ theme, outline }) => css`
    padding: ${theme.spacings[10]} ${theme.spacings[24]};

    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};

    border: 1px solid ${theme.colors.blue600};
    border-radius: ${theme.radius.sm};

    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};

    transition: all 0.2s;

    &:hover {
      background-color: ${transparentize(0.3, theme.colors.blue600)};
      border: 1px solid ${transparentize(0.3, theme.colors.blue600)};
    }

    &:disabled {
      border: 1px solid ${theme.colors.gray600};

      background-color: ${theme.colors.gray600};

      opacity: 0.5;
    }

    @media (max-width: 535px) {
      width: 100%;
      height: 30px;
      padding: 0;
      bottom: 0;
      font-size: ${theme.font.sizes.xs};
    }

    ${outline && buttonModifiers.outline(theme)};
  `}
`;
