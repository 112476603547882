import React from 'react';

interface IProperties {
  properties: {
    cod_imo: string;
    data_aprov: string;
    status: string;
    nome_area: string;
  };
}

export function ImovelSIGEFPrivado({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Imóvel Certificado SIGEF Privado</strong>
      </h4>
      <p>
        <strong>Código do imóvel: </strong>
        {properties.cod_imo ? properties.cod_imo : 'Não informado'}
      </p>
      <p>
        <strong>Data da aprovação: </strong>
        {properties.data_aprov ? properties.data_aprov : 'Não informado'}
      </p>
      <p>
        <strong>Situação: </strong>
        {properties.status ? properties.status : 'Não informado'}
      </p>
      <p>
        <strong>Nome da área: </strong>
        {properties.nome_area ? properties.nome_area : 'Não informado'}
      </p>
    </div>
  );
}
