import React, { memo, useEffect, useState } from 'react';
import * as Styled from './styles';
import DraggingIcon from '../../../../assets/svg/graphDragging-icon.svg';
import { objectGraph } from '../../../../shared/models/ModalInterface';
import ButtonGraph from './ButtonForGraph';
import Charts from './Charts';

type WrapperGraphProps = {
  titleGraph: string;
  chartModel:
    | 'RegionGeoCharts'
    | 'MaterialDesignBarCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart';
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setUpdateGraphics?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  data: (string | number)[][];
  fontUsedLink: string;
  updateDashboard?: (id: string) => void;
  id: string;
};

function WrapperGraph({
  titleGraph,
  chartModel,
  isClickedTopMenu,
  setUpdateGraphics,
  data,
  fontUsedLink,
  setSelectedOptionChart,
  updateDashboard,
  id,
}: WrapperGraphProps) {
  const [graph, setGraph] = useState({} as objectGraph);
  const [isClickedInOcult, setIsClickedInOcult] = useState(false);
  const [display, setDisplay] = useState('');

  useEffect(() => {
    if (isClickedInOcult && isClickedTopMenu !== 'ocultGraph') {
      setDisplay('none');
      setIsClickedInOcult(true);
    }
  }, [isClickedInOcult]);

  useEffect(() => {
    const newGraph = {
      id,
      data,
      titleGraph,
      chartModel,
      fontUsedLink,
    } as objectGraph;

    if (isClickedTopMenu !== 'ocultGraph') {
      const findOcultGraphic = JSON.parse(
        localStorage.getItem('ocultGraph') || '{}',
      ).ocultGraph;

      if (
        findOcultGraphic !== null &&
        findOcultGraphic !== undefined &&
        Object.values(findOcultGraphic).length > 0
      ) {
        const element = document.getElementById(findOcultGraphic[0].id);

        if (element !== null || element !== undefined) {
          element?.remove();
        }
      }
    }

    setGraph(newGraph);
  }, []);

  return (
    <Styled.WrapperGraphComponent
      display={display}
      isClickedTopMenu={isClickedTopMenu}
      isClickedInOcult={isClickedInOcult}
    >
      <div className="top">
        <div className="left">
          <img
            src={DraggingIcon}
            className="dragging-button"
            alt="Dragging Button"
          />

          <p className="firstP" title={titleGraph}>
            {titleGraph}
          </p>
        </div>
        <ButtonGraph
          graph={graph}
          isClickedTopMenu={isClickedTopMenu}
          setIsClickedInOcult={setIsClickedInOcult}
          setUpdateGraphics={setUpdateGraphics}
          setSelectedOptionChart={setSelectedOptionChart}
          updateDashboard={updateDashboard}
          id={id}
        />
      </div>

      <Charts chartModel={chartModel} data={data} />

      <div className="font">{`Fonte: ${fontUsedLink}`}</div>
    </Styled.WrapperGraphComponent>
  );
}

export default memo(WrapperGraph);
