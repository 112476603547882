import React from 'react';
import { FaSquareMinus, FaSquarePlus } from 'react-icons/fa6';
import * as Styled from './styles';

interface ILayerSelect {
  id: string;
  name: string;
  children: React.ReactNode;
  qtdOpened?: number;
  opened: boolean;
  is_new?: boolean;
  onChange: () => void;
}

export function LayerSelect({ id, children, name, qtdOpened, opened, is_new, onChange }: ILayerSelect) {
  return (
    <Styled.Wrapper id={id} opened={opened}>
      <div className="selectLayer" onClick={onChange}>
        <div className="icon">{!opened ? <FaSquarePlus size={22} /> : <FaSquareMinus size={22} />}</div>
        <p id={id}>{name}</p>
        {!!qtdOpened && qtdOpened > 0 && <span id="label_qtd_opened">{qtdOpened}</span>}
        {is_new && <span id="label_new">novo</span>}
      </div>
      {opened && <Styled.ContentLayer>{children}</Styled.ContentLayer>}
    </Styled.Wrapper>
  );
}
