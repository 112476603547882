import React from 'react';

interface IProperties {
  properties: {
    uf: string;
    nome_proje: string;
    municipio: string;
    num_famili: string;
  };
}

export function AssentamentoReconhecimento({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Projetos de Assentamento Reconhecimento</strong>
      </h4>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Nome do projeto: </strong>{' '}
        {properties.nome_proje ? properties.nome_proje : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.municipio ? properties.municipio : 'Não informado'}
      </p>
      <p>
        <strong>Número de famílias na área: </strong>{' '}
        {properties.num_famili ? properties.num_famili : 'Não informado'}
      </p>
    </div>
  );
}
