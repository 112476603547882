import React from 'react';

interface IProperties {
  properties: {
    nm_mun: string;
    sigla_uf: string;
  };
}

export function LimiteMunicipio({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Limite de Municípios</strong>
      </h4>
      <p>
        <strong>Nome do município: </strong>{' '}
        {properties.nm_mun ? properties.nm_mun : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.sigla_uf ? properties.sigla_uf : 'Não informado'}
      </p>
    </div>
  );
}
