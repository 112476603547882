import React from 'react';

interface IProperties {
  properties: {
    nom_bioma: string;
  };
}

export function Biomas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Biomas</strong>
      </h4>
      <p>
        <strong>Nome do Bioma: </strong>{' '}
        {properties.nom_bioma ? properties.nom_bioma : 'Não informado'}
      </p>
    </div>
  );
}
