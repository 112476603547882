import React from 'react';

interface IProperties {
  properties: {
    nome: string;
    classe: string;
    gov: string;
    protec: string;
    tipo: string;
    biom: string;
  };
}

export function CadNacioFlorestPub({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Cadastro Nacional de Florestas Públicas</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Classe: </strong>{' '}
        {properties.classe ? properties.classe : 'Não informado'}
      </p>
      <p>
        <strong>Governo: </strong>{' '}
        {properties.gov ? properties.gov : 'Não informado'}
      </p>
      <p>
        <strong>Proteção: </strong>{' '}
        {properties.protec ? properties.protec : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>{' '}
        {properties.tipo ? properties.tipo : 'Não informado'}
      </p>
      <p>
        <strong>Bioma: </strong>{' '}
        {properties.biom ? properties.biom : 'Não informado'}
      </p>
    </div>
  );
}
