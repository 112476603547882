import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function findAllIndicators<T>(language: string) {
  const response: AxiosResponse<T> = await api.get(
    `/indicadores/?lang=${language}`,
  );

  return response;
}
