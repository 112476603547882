import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  #root {
    background-color: #192b5b;
    min-height: 100vh;
  }

  ${({ theme }) => css`
    body {
      background: ${theme.colors.background};
      color: ${theme.colors.black};

      font: 400 ${theme.font.sizes.md} ${theme.font.family.default}, sans-serif;
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
    }

    button:disabled {
      cursor: not-allowed;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  `}
`;
