import React from 'react';
import { useTranslation } from 'react-i18next';

import { MainContainer, CheckBoxContainer } from './styles';

interface IndicatorsProps {
  id: number;
  name: string;
  color: string;
  checked: boolean;
}

interface IndicatorsBoxProps<T> {
  data: IndicatorsProps[];
  onChoose: (item: T) => void;
  disabledFilter: boolean;
}

export function IndicatorsBox<T>({ data, onChoose, disabledFilter }: IndicatorsBoxProps<T>) {
  const { t: translation } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (indicator: any) => {
    onChoose(indicator);
  };

  return (
    <MainContainer>
      <div className="title">
        <strong>{translation('dashboardPage.sidebar.selectsLabel.indicators')}</strong>
      </div>

      <ul className="box-list">
        {data.map((indicator, index) => (
          <CheckBoxContainer key={indicator.name} color={indicator.color}>
            <li key={indicator.id}>
              <input
                type="checkbox"
                id={`custom-checkbox-${index}`}
                name={indicator.name}
                value={indicator.id}
                onChange={() => handleOnChange(indicator)}
                checked={indicator.checked}
                disabled={disabledFilter}
              />
              <label htmlFor={`custom-checkbox-${index}`}>{indicator.name}</label>
            </li>
          </CheckBoxContainer>
        ))}
      </ul>
    </MainContainer>
  );
}
