import React, { useEffect, useState } from 'react';

import { ILayers } from 'contexts/@types/Layers';

import { findAllThemeLayers } from 'services/themeRequest';

import { Layer, LayerSelect, Loading } from 'components';

import { useLayers } from 'hooks/useLayers';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

interface IFavoritesLayer {
  handleChangeTabIndex: (index: number, text: string, icon?: React.ReactNode) => void;
}

export function FavoritesLayer({ handleChangeTabIndex }: IFavoritesLayer) {
  const { t: translation, i18n } = useTranslation();
  const [layers, setLayers] = useState<{ [key: string]: ILayers[] }>({});
  const [isLoading, setIsLoading] = useState(true);

  const [openCategories, setOpenCategories] = useState<string[]>([]);

  const favorite = JSON.parse(localStorage.getItem('layersFavorite') || '[]');

  const { layersActived } = useLayers();

  async function requestAllThemeLayers() {
    const { data } = await findAllThemeLayers<ILayers[]>(i18n.language);

    setIsLoading(false);

    return data;
  }

  async function filterFavorites(data: ILayers[]) {
    for (const subtheme in favorite) {
      try {
        favorite[subtheme].map((item: string) => {
          const favoriteLayer = data.filter(layer => layer.layertitle + layer.substancia_agrupadora_id === item);
          return setLayers(prevState => ({ ...prevState, [subtheme]: [...favoriteLayer, ...(prevState[subtheme] || [])] }));
        });
      } catch (e) {
        delete favorite[subtheme];
        localStorage.setItem('layersFavorite', JSON.stringify(favorite));
      }
    }
  }

  async function callActionsFavorite() {
    const data = await requestAllThemeLayers();

    filterFavorites(data);
  }

  useEffect(() => {
    if (favorite.length === undefined) callActionsFavorite();
    else setIsLoading(false);
  }, []);

  function handleOpenCategory(category: string) {
    if (openCategories.includes(category)) {
      return setOpenCategories(openCategories.filter(i => i != category));
    }

    setOpenCategories([...openCategories, category]);
  }

  function removeLayer(category: string, layer: string) {
    if (layers[category].length === 1) {
      return setLayers(prevState => {
        const { [category]: _, ...newState } = prevState;
        return newState;
      });
    }
    return setLayers(prevState => ({
      ...prevState,
      [category]: [...prevState[category].filter(l => l.layername !== layer)],
    }));
  }

  return (
    <Styled.Wrapper>
      <header>
        <h1>{translation('mapPage.sideBar.favorites.title')}</h1>
      </header>
      {isLoading ? (
        <Loading />
      ) : (
        <Styled.Content>
          {Object.keys(layers).length !== 0 ? (
            Object.keys(layers).map(category => {
              return (
                layers[category].length !== 0 && (
                  <LayerSelect
                    id={'theme_' + category}
                    name={category}
                    key={'theme_' + category}
                    opened={!!openCategories.includes(category)}
                    onChange={() => {
                      handleOpenCategory(category);
                    }}
                  >
                    {layers[category].map(layer => {
                      const isLayerActived = layersActived.find(
                        item => item.id === layer.id && item.substancia_agrupadora_id === layer.substancia_agrupadora_id,
                      );
                      return (
                        <Layer
                          key={layer.layername + layer.layer_order_id}
                          layer={layer}
                          layers={[layer]}
                          layertitle={layer.layertitle}
                          is_new={!!layer.nova_camada}
                          handleChangeTabIndex={handleChangeTabIndex}
                          checked={!!isLayerActived}
                          nm_category={category}
                          setLayers={() => {
                            removeLayer(category, layer.layername);
                          }}
                        />
                      );
                    })}
                  </LayerSelect>
                )
              );
            })
          ) : (
            <div className="emptyLayer">
              <h3>{translation('mapPage.sideBar.favorites.notFound')}</h3>
            </div>
          )}
        </Styled.Content>
      )}
    </Styled.Wrapper>
  );
}
