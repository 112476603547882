import React from 'react';

interface IProperties {
  properties: {
    fid: string;
    id_proj: string;
    titulo: string;
    metodo: string;
    alt_voo_m: string;
    area_levant_km2: string;
    quilom_voada_km: string;
    direcao_lv: string;
    direcao_lc: string;
    espac_lv_m: string;
    espac_lc_m: string;
    serie: string;
    disponibl: string;
    imagens: string;
  };
}

export function LevantamentoAero3k({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Levantamento Aerogeofísico 3000</strong>
      </h4>
      <p>
        <strong>ID_PROJETO: </strong>
        {properties.id_proj ? properties.id_proj : 'Não informado'}
      </p>
      <p>
        <strong>ID_SERIE: </strong>
        {properties.fid ? properties.fid : 'Não informado'}
      </p>
      <p>
        <strong>Título: </strong>
        {properties.titulo ? properties.titulo : 'Não informado'}
      </p>
      <p>
        <strong>Métodos: </strong>
        {properties.metodo ? properties.metodo : 'Não informado'}
      </p>
      <p>
        <strong>Altura Voo: </strong>
        {properties.alt_voo_m ? properties.alt_voo_m : 'Não informado'}
      </p>
      <p>
        <strong>Área levantada em km²: </strong>
        {properties.area_levant_km2 ? properties.area_levant_km2 : 'Não informado'}
      </p>
      <p>
        <strong>Quilometragem voada em km: </strong>
        {properties.quilom_voada_km ? properties.quilom_voada_km : 'Não informado'}
      </p>
      <p>
        <strong>Direção linhas de voo: </strong>
        {properties.direcao_lv ? properties.direcao_lv : 'Não informado'}
      </p>
      <p>
        <strong>Direção linhas de controle: </strong>
        {properties.direcao_lc ? properties.direcao_lc : 'Não informado'}
      </p>
      <p>
        <strong>Espaçamento linhas de voo em metros: </strong>
        {properties.espac_lv_m ? properties.espac_lv_m : 'Não informado'}
      </p>
      <p>
        <strong>Espaçamento linhas de voo em Km: </strong>
        {properties.espac_lc_m ? properties.espac_lc_m : 'Não informado'}
      </p>
      <p>
        <strong>Série: </strong>
        {properties.serie ? properties.serie : 'Não informado'}
      </p>
      <p>
        <strong>URL PDF: </strong>
        {properties.disponibl ? (
          <a href={properties.disponibl} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>URL TIFF: </strong>
        {properties.imagens ? (
          <a href={properties.imagens} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
    </div>
  );
}
