import React from 'react';

interface IProperties {
  properties: {
    nm_bacia: string;
    tipo: string;
  };
}

export function BaciasSedimentares({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Bacias Sedimentares</strong>
      </h4>
      <p>
        <strong>Nome da bacia: </strong>
        {properties.nm_bacia ? properties.nm_bacia : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>
        {properties.tipo ? properties.tipo : 'Não informado'}
      </p>
    </div>
  );
}
