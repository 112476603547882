import React from 'react';

interface IProperties {
  properties: {
    vl_br: string;
    sg_uf: string;
    nm_tipo: string;
    ds_legenda: string;
  };
}

export function Rodovias({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Rodovias</strong>
      </h4>
      <p>
        <strong>Número da BR: </strong>{' '}
        {properties.vl_br ? properties.vl_br : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.sg_uf ? properties.sg_uf : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de trecho: </strong>{' '}
        {properties.nm_tipo ? properties.nm_tipo : 'Não informado'}
      </p>
      <p>
        <strong>Legenda: </strong>{' '}
        {properties.ds_legenda ? properties.ds_legenda : 'Não informado'}
      </p>
    </div>
  );
}
