import React from 'react';

interface IProperties {
  properties: {
    cd_bloco: string;
    cd_fase_c: string;
    nm_bacia: string;
    nm_bloco: string;
    nm_fantas: string;
    operador_c: string;
    rodada: string;
    area_total: number;
    ambiente: string;
    blocos: string;
  };
}

export function BlocosExploratorios({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Blocos Exploratórios</strong>
      </h4>
      <p>
        <strong>Código do bloco: </strong>{' '}
        {properties.cd_bloco ? properties.cd_bloco : 'Não informado'}
      </p>
      <p>
        <strong>Fase: </strong>{' '}
        {properties.cd_fase_c ? properties.cd_fase_c : 'Não informado'}
      </p>
      <p>
        <strong>Nome da Bacia: </strong>{' '}
        {properties.nm_bacia ? properties.nm_bacia : 'Não informado'}
      </p>
      <p>
        <strong>Nome do bloco: </strong>{' '}
        {properties.nm_bloco ? properties.nm_bloco : 'Não informado'}
      </p>
      <p>
        <strong>Nome Fantasia: </strong>{' '}
        {properties.nm_fantas ? properties.nm_fantas : 'Não informado'}
      </p>
      <p>
        <strong>Empresa a explorar o bloco: </strong>{' '}
        {properties.operador_c ? properties.operador_c : 'Não informado'}
      </p>
      <p>
        <strong>Rodada: </strong>{' '}
        {properties.rodada ? properties.rodada : 'Não informado'}
      </p>
      <p>
        <strong>Área total: </strong>{' '}
        {properties.area_total
          ? properties.area_total.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Ambiente: </strong>{' '}
        {properties.ambiente ? properties.ambiente : 'Não informado'}
      </p>
      <p>
        <strong>Blocos: </strong>{' '}
        {properties.blocos ? properties.blocos : 'Não informado'}
      </p>
    </div>
  );
}
