import styled, { css } from 'styled-components';

interface GraphContainerProps {
  color: string;
  size?: 'small' | 'medium' | 'large';
}

export const LoadingContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 40rem;
    margin-top: ${theme.spacings[24]};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const GraphContainer = styled.div<GraphContainerProps>`
  ${({ theme, color, size }) => css`
    max-height: 1000px;
    background-color: ${theme.colors.white};
    border-radius: ${theme.radius.xs};

    border-radius: 12px;
    box-shadow: 0 0 2rem ${theme.colors.gray200};

    border-left: 3px solid ${color};
    padding: ${theme.spacings[16]};

    &:not(last-child) {
      margin-right: 1%;
    }
    header {
      display: flex;
      gap: ${theme.spacings[8]};
      align-items: center;
      justify-content: space-between;
      color: #4c4e61;
      margin-bottom: ${theme.spacings[8]};

      .headerTitle {
        display: flex;
        align-items: center;

        svg {
          cursor: pointer;
        }
      }
    }

    .title {
      color: #4c4e61;

      @media (max-width: 800px) {
        ${theme.font.sizes.md};
      }
    }

    .labelLegend {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      font-family: Arial;
      font-size: 13px;
    }

    .hAxis {
      font-family: Arial;
      font-size: 13px;
      font-style: italic;
      fill: #222222;
      margin: 0 auto;
      text-align: center;
    }

    .noContent {
      margin: 0 auto;
      margin-top: 20%;
      margin-bottom: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    footer {
      display: flex;
      flex-direction: column;

      margin-top: ${theme.spacings[20]};
    }

    footer p,
    a {
      font-weight: normal;
      color: #4c4e61;
      font-weight: 100;
      font-size: 10px;
      margin: 0 auto;
      margin-right: 0;
      margin-bottom: 0;
      line-height: 0.7;
    }

    a:hover {
      color: ${theme.colors.blue600};
    }
  `}
`;
