import React from 'react';

interface IProperties {
  properties: {
    regiao: string;
    nome: string;
    sigla: string;
    capital: string;
    popest2014: number;
    pop_2010: number;
    dens_dem: number;
  };
}

export function LimiteEstados({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Limite de Estados</strong>
      </h4>
      <p>
        <strong>Regiao: </strong>{' '}
        {properties.regiao ? properties.regiao : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nome ? properties.nome : 'Não informado'}
      </p>
      <p>
        <strong>Sigla: </strong>{' '}
        {properties.sigla ? properties.sigla : 'Não informado'}
      </p>
      <p>
        <strong>Capital do estado: </strong>{' '}
        {properties.capital ? properties.capital : 'Não informado'}
      </p>
      <p>
        <strong>População do estado no ano de 2014: </strong>{' '}
        {properties.popest2014
          ? properties.popest2014.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>População do estado no ano de 2010: </strong>{' '}
        {properties.pop_2010
          ? properties.pop_2010.toLocaleString()
          : 'Não informado'}
      </p>
      <p>
        <strong>Densidade demográfica: </strong>{' '}
        {properties.dens_dem
          ? properties.dens_dem.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
