import styled, { css } from 'styled-components';

type WrapperType = {
  error: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ theme, error }) => css`
    margin: 0 auto;
    width: 90%;
    height: max-content;
    position: relative;

    .error {
      width: 25rem;
      position: fixed;
      top: 0%;
      background-color: ${theme.colors.error};
      border-radius: ${theme.spacings[6]};
      color: ${theme.colors.white};
      padding: ${theme.spacings[10]};
      animation-name: ${error ? 'openError' : ''};
      animation-duration: 1s;
      animation-delay: 0ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      transform: translateX(-170%);

      @keyframes openError {
        0% {
          transform: translateX(-170%);
        }
        100% {
          transform: translateX(0%);
        }
      }
    }

    .loading {
      position: absolute;
      top: 97%;

      &::after {
        content: '.';
        animation-name: 'loadingSelect';
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-delay: 0ms;
        animation-direction: normal;
      }

      @keyframes loadingSelect {
        0% {
          content: '.';
        }
        33% {
          content: '..';
        }
        66% {
          content: '...';
        }
        100% {
          content: '.';
        }
      }
    }
  `}
`;

export const Label = styled.h4`
  ${({ theme }) => css`
    width: max-content;
    height: max-content;
    font-family: ${theme.font.family.default};
    color: ${theme.colors.black};
    font-weight: ${theme.font.weights.regular};
    display: block;
  `}
`;
