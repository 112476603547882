import React from 'react';

interface IProperties {
  properties: {
    zona: string;
    tp_umidade: string;
    distr_umid: string;
    temperatur: string;
    desc_compl: string;
  };
}

export function ZonasClimaticas({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Zonas Climáticas</strong>
      </h4>
      <p>
        <strong>Zona: </strong>{' '}
        {properties.zona ? properties.zona : 'Não informado'}
      </p>
      <p>
        <strong>Umidade: </strong>{' '}
        {properties.tp_umidade ? properties.tp_umidade : 'Não informado'}
      </p>
      <p>
        <strong>Distribuição Umidade: </strong>{' '}
        {properties.distr_umid ? properties.distr_umid : 'Não informado'}
      </p>
      <p>
        <strong>Temperatura: </strong>{' '}
        {properties.temperatur ? properties.temperatur : 'Não informado'}
      </p>
      <p>
        <strong>Descrição Completa: </strong>{' '}
        {properties.desc_compl ? properties.desc_compl : 'Não informado'}
      </p>
    </div>
  );
}
