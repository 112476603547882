import React, { useEffect } from 'react';
import Chart, { ReactGoogleChartProps } from 'react-google-charts';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
import { ChartContainer } from './styles';

type ChartsProps = ReactGoogleChartProps & {
  size?: 'small' | 'medium' | 'large';
};

export function ChartComponent({ chartType, data, options, diffdata, size }: ChartsProps) {
  useEffect(() => {
    setTimeout(() => {
      changePreviousData();
    }, 500);
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    changePreviousData();
  });

  const changePreviousData = () => {
    $('text').each(function x() {
      if ($(this).text() === 'Previous data') {
        if (i18n.language === 'en-US') {
          $(this).text('Total percentage');
        } else if (i18n.language === 'es-ES') {
          $(this).text('Porcentaje total');
        } else {
          $(this).text('Percentual total');
        }
      }
    });
  };

  return (
    <ChartContainer size={size}>
      {diffdata ? (
        <Chart
          chartType={chartType}
          width="100%"
          height="100%"
          diffdata={diffdata}
          options={options}
          chartLanguage={i18n.language}
        />
      ) : (
        <Chart
          chartType={chartType}
          width="100%"
          height="100%"
          data={data}
          options={options}
          chartLanguage={i18n.language}
        />
      )}
    </ChartContainer>
  );
}
