import React, { createContext, useState } from 'react';
import { CardDataRequest } from 'screens/NewDashboard/@types/cards';
import { GraphicDataRequest } from 'screens/NewDashboard/@types/graphic';

import { OptionsContextProps, OptionsProviderProps } from './@types/Options';

export const OptionsContext = createContext({} as OptionsContextProps);

export function OptionsProvider({ children }: OptionsProviderProps) {
  const [isFavorite, setFavorite] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  const [favoriteGraphics, setFavoriteGraphics] = useState<
    GraphicDataRequest[]
  >([]);
  const [hiddenGraphics, setHiddenGraphics] = useState<GraphicDataRequest[]>(
    [],
  );
  const [favoriteCards, setFavoriteCards] = useState<CardDataRequest[]>([]);
  const [hiddenCards, setHiddenCards] = useState<CardDataRequest[]>([]);

  function getFavoriteGraphics() {
    const dataGraphics: GraphicDataRequest[] = JSON.parse(
      localStorage.getItem('favoriteGraphics') || '[]',
    );

    const dataCards: CardDataRequest[] = JSON.parse(
      localStorage.getItem('favoriteCards') || '[]',
    );
    setFavoriteGraphics(dataGraphics);
    return setFavoriteCards(dataCards);
  }

  function getHiddenGraphics() {
    return false;
  }

  return (
    <OptionsContext.Provider
      value={{
        update,
        setUpdate,
        openMap,
        setOpenMap,
        isFavorite,
        setFavorite,
        isHidden,
        setHidden,
        favoriteGraphics,
        hiddenGraphics,
        favoriteCards,
        hiddenCards,
        getFavoriteGraphics,
        getHiddenGraphics,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
}
