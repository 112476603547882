import React from 'react';
import { Graphics, FavoriteGraphics, HiddenGraphics } from './pages';

export default [
  {
    id: 1,
    element: Graphics,
    path: '/dashboard',
  },
  {
    id: 2,
    element: FavoriteGraphics,
    path: '/favorites',
  },
  {
    id: 3,
    element: HiddenGraphics,
    path: '/hidden',
  },
];
