// react/jsx-no-constructed-context-values: off;
import React, { createContext, useEffect, useState } from 'react';
import { IContext, IProvider, IThemesList, ILayers, ICategory, ITheme, ISubTheme } from './@types/Layers';
// import { Map } from 'ol';
// import { useMap } from 'hooks/useMap';

export const LayersContext = createContext({} as IContext);

export function LayersProvider({ children }: IProvider) {
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [themesList, setThemesList] = useState<IThemesList[]>([]);
  const [layersActived, setLayersActived] = useState<ILayers[]>([]);
  const [themeOpened, setThemeOpened] = useState<IThemesList>({} as IThemesList);
  const [subThemeOpened, setSubThemeOpened] = useState<ISubTheme>({} as ISubTheme);
  const [subtitleLayerOpened, setSubtitleLayerOpened] = useState<ILayers>({} as ILayers);
  // const [map, setMap] = useState<Map>();
  // const { map } = useMap();

  // useEffect(() => {
  //   // eslint-disable-next-line no-useless-return
  //   if (!map) return;
  // }, [map]);

  const handleActiveAllThemeLayers = (theme: IThemesList) => {
    const { layers } = theme;

    layers.map(layer => {
      const layerToActive = layersActived.filter(layerActived => layerActived.area_tematica === layer.area_tematica);

      if (layerToActive.length > 0) return;

      return setLayersActived(prevState => [...prevState, layer]); //eslint-disable-line
    });
  };

  const handleOpenThemeLayers = (item: IThemesList) => {
    return setThemeOpened(item);
  };

  const handleSubtitleLayerOpened = (item: ILayers) => {
    return setSubtitleLayerOpened(item);
  };

  const handleRemoveLayer = (layer: ILayers) => {
    const select = document.getElementById(layer.layername);
    select?.click();
    const newLayers = layersActived.filter(
      layerActived =>
        layerActived.id !== layer.id || layerActived.substancia_agrupadora_id !== layer.substancia_agrupadora_id,
    );

    setLayersActived(newLayers);
  };

  const handleClearLayersActived = () => {
    setLayersActived([]);
  };

  const handleAddLayer = (layer: ILayers) => {
    const layerExist = layersActived.find(
      element => element.id === layer.id && element.substancia_agrupadora_id === layer.substancia_agrupadora_id,
    );

    if (!layerExist) {
      layer.subtitle_open = false;
      layer.information_open = false;

      setLayersActived(prevState => [...prevState, layer]);
    }
  };

  const handleEnableOnlyTheseLayers = (layer: ILayers[]) => {
    setLayersActived(layer);
  };

  const handleChangeLayerOptionsOpen = (layer: ILayers, option: string) => {
    const layerIndex = layersActived.findIndex(
      element => element.id === layer.id && element.substancia_agrupadora_id === layer.substancia_agrupadora_id,
    );

    if (layerIndex != null) {
      const layers = [...layersActived];
      if (option === 'subtitle') {
        layers[layerIndex].subtitle_open = true;
      } else if (option === 'information') {
        layers[layerIndex].information_open = true;
      }
      setLayersActived(layers);
    }
  };

  const handleCloseOptions = (option: string) => {
    const layers = [...layersActived];

    layers.map((layer: ILayers) => {
      if (layer.subtitle_open && option === 'subtitle') layer.subtitle_open = false;
      else if (layer.information_open && option === 'information') layer.information_open = false;
    });

    setLayersActived(layers);
  };

  const handleOpenCategory = (value: boolean, indexCategory: number) => {
    const categories = [...categoryList];
    categories[indexCategory].opened = value;

    if (!value) {
      categories[indexCategory].temas.forEach((theme: ITheme, indexTheme: number) => {
        handleOpenTheme(value, indexCategory, indexTheme);
      });
    }
    setCategoryList(categories);
  };

  const handleOpenTheme = (value: boolean, indexCategory: number, indexTheme: number) => {
    const categories = [...categoryList];
    categories[indexCategory].temas[indexTheme].opened = value;
    if (value) {
      handleOpenCategory(true, indexCategory);
    } else if (
      !value &&
      categories[indexCategory].temas[indexTheme].subtema &&
      categories[indexCategory].temas[indexTheme].subtema?.length
    ) {
      categories[indexCategory].temas[indexTheme].subtema!.forEach((theme: ISubTheme, indexSubTheme: number) => {
        handleOpenSubTheme(value, indexCategory, indexTheme, indexSubTheme);
      });
    }
    setCategoryList(categories);
  };

  const handleOpenSubTheme = (value: boolean, indexCategory: number, indexTheme: number, indexSubThema: number) => {
    const categories = [...categoryList];
    if (categories[indexCategory].temas[indexTheme].subtema && categories[indexCategory].temas[indexTheme].subtema?.length) {
      categories[indexCategory].temas[indexTheme].subtema![indexSubThema].opened = value;
    }
    setCategoryList(categories);
    setSubThemeOpened(categories[indexCategory].temas[indexTheme].subtema![indexSubThema]);
  };

  const handleCountLayersActived = () => {
    if (!categoryList.length) return;
    const categories = [...categoryList];

    categories.forEach((category: ICategory) => {
      let countCategory = 0;
      category.temas.forEach((theme: ITheme) => {
        let countTheme = 0;

        if (theme.layers) {
          theme.layers.forEach((layer: ILayers) => {
            if (
              layersActived.find(
                (layerActived: ILayers) =>
                  layerActived.id === layer.id && layerActived.substancia_agrupadora_id === layer.substancia_agrupadora_id,
              )
            ) {
              countCategory = countCategory + 1;
              countTheme = countTheme + 1;
            }

            category.qtdOpenend = countCategory;
            theme.qtdOpenend = countTheme;
          });
        }

        if (!theme.subtema) return;
        theme.subtema.forEach((subTheme: ISubTheme) => {
          let countSubTheme = 0;
          if (!subTheme.layers) return;

          subTheme.layers.forEach((layer: ILayers) => {
            if (
              layersActived.find(
                (layerActived: ILayers) =>
                  layerActived.id === layer.id && layerActived.substancia_agrupadora_id === layer.substancia_agrupadora_id,
              )
            ) {
              countCategory = countCategory + 1;
              countTheme = countTheme + 1;
              countSubTheme = countSubTheme + 1;
            }

            category.qtdOpenend = countCategory;
            theme.qtdOpenend = countTheme;
            subTheme.qtdOpenend = countSubTheme;
          });
        });
      });
    });
    setCategoryList(categories);
  };

  useEffect(() => {
    handleCountLayersActived();
  }, [layersActived]);

  return (
    <LayersContext.Provider
      value={{
        // map,
        // setMap,
        categoryList,
        setCategoryList,
        themesList,
        setThemesList,
        layersActived,
        themeOpened,
        subThemeOpened,
        subtitleLayerOpened,
        handleActiveAllThemeLayers,
        handleAddLayer,
        handleRemoveLayer,
        handleClearLayersActived,
        handleOpenThemeLayers,
        handleSubtitleLayerOpened,
        handleEnableOnlyTheseLayers,
        handleChangeLayerOptionsOpen,
        handleCloseOptions,
        handleOpenCategory,
        handleOpenTheme,
        handleOpenSubTheme,
        handleCountLayersActived,
      }}
    >
      {children}
    </LayersContext.Provider>
  );
}
