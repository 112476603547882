import styled, { keyframes } from 'styled-components';

const animationNotification = keyframes`
    0% {
      opacity: .3;
      transform: translateX(200px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
`;

export const Notification = styled.div`
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 9999;

  max-width: 300px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  border-radius: 2px;
  padding: 10px 12px;

  background-color: #2a2f3c;
  color: #fff;

  font-size: 14px;

  animation: ${animationNotification} 0.3s linear forwards;
`;
