import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const BackgroundBlur = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${transparentize(0.3, theme.colors.black)};

    z-index: 10;
  `}
`;

export const ModalBlock = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings[32]};
    position: relative;
    max-width: 100%;
    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.white};

    animation: animation 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19) normal;

    > h1 {
      font-size: ${theme.font.sizes['2xl']};
      font-weight: ${theme.font.weights.bold};
      text-align: center;

      color: ${theme.colors.gray900};
    }

    .optionsMapBlock {
      margin-top: ${theme.spacings[32]};
      display: flex;
    }

    @media (max-width: 400px) {
      .optionsMapBlock {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .closeButtonMap {
      position: absolute;
      top: ${theme.spacings[32]};
      right: ${theme.spacings[32]};
      display: flex;

      border: 0;

      background-color: transparent;
    }

    @keyframes animation {
      from {
        opacity: 0;
        transform: scale(0);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  `}
`;

export const OptionMap = styled.div`
  ${({ theme }) => css`
    width: 11.2rem;
    display: flex;
    flex-direction: column;

    cursor: pointer;

    & + div {
      margin-left: ${theme.spacings[24]};
    }

    p {
      margin-top: ${theme.spacings[8]};

      font-size: ${theme.font.sizes.sm};
      text-align: center;

      transition: all 0.2s;
    }

    img {
      width: 11.2rem;
      height: 9rem;

      transition: all 0.2s;
    }

    &:hover {
      p {
        color: ${theme.colors.blueA700};
      }

      img {
        box-shadow: 0 0rem 0.8rem ${transparentize(0.5, theme.colors.black)};
      }
    }

    @media (max-width: 400px) {
      margin-left: ${theme.spacings[10]};

      & + div {
        margin-left: ${theme.spacings[10]};
      }
    }
  `}
`;
