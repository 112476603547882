import styled, { css } from 'styled-components';

export const FloatingMenuInGraph = styled.div`
  ${({ theme }) => css`
    width: max-content;
    height: max-content;
    background-color: ${theme.colors.gray50};
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    top: 100%;
    right: 0%;
    z-index: 1;

    .centralized {
      margin: 0 auto;
      width: 90%;
    }

    .wrapperIconAndText {
      width: 100%;
      display: flex;
      align-items: center;
      height: 3rem;
      border: 0;

      .icon {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }

      .map {
        width: 3rem;
      }

      .eye {
        font-size: 2rem;
      }

      .eye2 {
        width: 4rem;
      }

      .wrapperP {
        flex: 1;
      }

      p {
        color: ${theme.colors.black};
        font-family: ${theme.font.family.IbmPlexSans};
        margin-left: ${theme.spacings[6]};
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;
