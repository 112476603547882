import React, { memo } from 'react';
import Chart from 'react-google-charts';
import { GraphModelType } from '../../type/GraphModelType';

function RegionGeoCharts({ data }: GraphModelType) {
  const options = {
    colorAxis: {
      colors: [
        '#156178',
        '#C35359',
        '#92CDD9',
        '#66B1C7',
        '#96CCDA',
        '#156178',
      ],
    },
    backgroundColor: '#81d4fa',
    datalessRegionColor: '#DEAAAA',
    defaultColor: '#C7C8CA',
  };
  return (
    <Chart
      style={{ zIndex: '0' }}
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data !== undefined && [selection[0].row + 1];
            // eslint-disable-next-line no-console
            console.log(`Selected : ${region}`);
          },
        },
      ]}
      chartType="GeoChart"
      width="33.5vw"
      height="40vh"
      data={data}
      options={options}
    />
  );
}

export default memo(RegionGeoCharts);
