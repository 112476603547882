import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface IWrapper {
  actived: boolean;
  isEnUS?: boolean;
}

const sideBarModifiers = {
  actived: () => css`
    left: 0 !important;

    .expandedButton {
      svg {
        transform: rotate(-180deg);
      }
    }
  `,
};

export const Wrapper = styled.div<IWrapper>`
  ${({ actived, isEnUS }) => css`
    width: 600px;
    height: calc(100vh - 92px);
    position: absolute;

    left: ${isEnUS ? 'calc(-665px + 95px)' : 'calc(-600px + 95px)'};

    display: flex;

    z-index: 2;

    transition: left 0.4s cubic-bezier(0.47, 1.13, 0.76, 0.96);

    ${actived && sideBarModifiers.actived()};

    @media (max-width: 1600px) {
      width: 520px;
      left: calc(-520px + 95px);
      ${actived && sideBarModifiers.actived()};
    }

    @media (max-width: 1000px) {
      width: 500px;
      left: calc(-500px + 95px);
      ${actived && sideBarModifiers.actived()};
    }

    @media (max-width: 800px) {
      width: 450px;
      left: calc(-450px + 90px);
      ${actived && sideBarModifiers.actived()};
    }

    @media (max-width: 600px) {
      width: 100%;
      left: calc(-100% + 70px);
      ${actived && sideBarModifiers.actived()};
    }

    @media (max-width: 400px) {
      height: 100vh;
      left: calc(-100% + 65px);
    }
  `}
`;

export const Content = styled.div<IWrapper>`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    background-color: #fff;
  `}
`;

export const NavigationBar = styled.nav`
  ${({ theme }) => css`
    width: 98px;
    header {
      height: 60px;
      background-color: ${lighten(0.2, theme.colors.blue300)};

      @media (max-width: 600px) {
        height: 70px;
      }
    }

    .expandedButton {
      width: 100%;
      height: 100%;
      padding: ${theme.spacings[16]} 0;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 0;
      // border-radius: 0 0 ${theme.radius.sm} 0;

      background-color: #017ac6;

      svg {
        transition: all 0.4s;
      }
    }

    .buttonsBlock {
      font-size: 14px;
      height: auto;
      border-bottom-right-radius: 10px 10px;

      background-color: #b0c4de;

      @media (max-width: 600px) {
        height: auto;
      }
    }

    .buttonLink {
      width: 100%;

      padding: ${theme.spacings[12]};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #03a9f4;
      }

      border: 0;

      background-color: transparent;

      @media (max-width: 1600px) {
        padding: ${theme.spacings[10]};
      }

      @media (max-width: 600px) {
        font-size: 10px;
      }

      .icons-sidebar {
        font-size: 32px;

        @media (max-width: 1600px) {
          font-size: 24px;
        }

        @media (max-width: 600px) {
          font-size: 20px;
        }
      }
    }

    .buttonLink + .buttonLink {
      margin-top: ${theme.spacings[16]};
    }

    @media (max-width: 800px) {
      width: 90px;
    }

    @media (max-width: 600px) {
      width: 70px;
    }

    @media (max-width: 400px) {
      width: 65px;
    }
  `}
`;

export const WrapperLoaders = styled.div`
  position: fixed;
  bottom: 100px;
  right: 15px;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;
