import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLayers } from 'react-icons/md';
import * as Styled from './styles';
import iconsThemes from './iconsThemes';
import linksEstatisticaMineral from './linksEstatisticaMineral';
import estudos from './estudos';
import { ICategory, ISubTheme, ITheme } from 'contexts/@types/Layers';
import { useLayers } from 'hooks/useLayers';
import empreendedor from 'assets/icone/busca/empreendedor.png';
import empreendimento from 'assets/icone/busca/empreendimento.png';
import documentos from 'assets/icone/busca/documentos.png';
import { useMap } from 'hooks/useMap';
import { findCategorias } from 'services/themeRequest';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

interface IHomeSideBar {
  handleChangeTabIndex: (index: number, text: string, icon: React.ReactNode, subBreadcrumbs?: string[]) => void;
  onLoadingCompleted: () => void;
  handleSubThemeOpenedBySubstanciaAgrupadora: (substanciaAgrupadora: ISubTheme) => void;
}

export function Home({
  handleChangeTabIndex,
  handleSubThemeOpenedBySubstanciaAgrupadora,
  onLoadingCompleted,
}: IHomeSideBar) {
  const {
    categoryList,
    setCategoryList,
    handleOpenCategory,
    handleOpenTheme,
    handleOpenSubTheme,
    handleAddLayer,
    handleCountLayersActived,
  } = useLayers();
  const { handleAddOverlayLayer } = useMap();
  const { t: translation, i18n } = useTranslation();
  const [estudosList, setEstudosList] = useState<ITheme[]>([]);
  const {} = useLayers();

  useEffect(() => {
    setEstudosList(estudos);

    (async () => {
      const { data: categorias } = await findCategorias<ICategory[]>(i18n.language);
      setCategoryList(categorias);
      handleCountLayersActived();
      onLoadingCompleted();
    })();
  }, []);

  const handleOpenThemeEstudo = (value: boolean, indexTheme: number) => {
    if (!estudosList.length) return;
    const estudos = [...estudosList];
    estudos[indexTheme].opened = value;
    setEstudosList(estudos);
  };

  return (
    <Styled.Wrapper>
      <h1>{translation('mapPage.sideBar.home.themes.titleCommodities')}</h1>
      <Styled.ThemeGrid>
        {categoryList.at(0) &&
          categoryList.at(0)?.temas.map((theme: ITheme, indexTheme: number) => {
            if (!theme.visivel) return;

            const iconTheme = iconsThemes.find(t => t.id === theme.id_tema);
            return (
              <Styled.Button key={'commodities_themes_' + indexTheme} color={iconTheme?.color}>
                <Styled.Header
                  color={iconTheme?.color}
                  open={theme.opened}
                  onClick={() => {
                    handleOpenTheme(!theme.opened, 0, indexTheme);
                  }}
                >
                  <Styled.Icone className="icone" path={iconTheme?.image} color={iconTheme?.color}></Styled.Icone>
                  <p>
                    {theme.opened && <Styled.IconeColor path={iconTheme?.imageColor}></Styled.IconeColor>}
                    {theme.nm_tema}
                  </p>
                </Styled.Header>

                {theme.opened && (
                  <Styled.Tematicas color={iconTheme?.color} className="tematicas">
                    {theme.subtema?.length &&
                      theme.subtema.map((subTheme: ISubTheme, indexSubTheme: number) => {
                        if (!subTheme.visivel) return;

                        return (
                          <Styled.Tematica
                            color={'commodities_sub_themes_' + iconTheme?.color}
                            key={indexSubTheme}
                            onClick={() => {
                              handleOpenSubTheme(!subTheme.opened, 0, indexTheme, indexSubTheme);
                              subTheme.layers?.forEach(layer => {
                                handleAddLayer(layer);
                                handleAddOverlayLayer(layer);
                              });

                              const concatText =
                                translation('mapPage.sideBar.home.themes.titleCommodities') + ' - ' + theme.nm_tema;

                              handleChangeTabIndex(6, concatText, <MdLayers />);
                              handleSubThemeOpenedBySubstanciaAgrupadora(subTheme);
                            }}
                          >
                            <a>{subTheme.nm_subtema}</a>
                          </Styled.Tematica>
                        );
                      })}
                  </Styled.Tematicas>
                )}
              </Styled.Button>
            );
          })}
      </Styled.ThemeGrid>

      <hr />
      <h1 className="titulo">{translation('mapPage.sideBar.home.themes.titleTema')}</h1>
      <Styled.ExplorarMapa>
        {categoryList.map((category: ICategory, indexCategory: number) => {
          if (category.id_categoria === 1 || category.id_categoria === 6) return;

          return (
            <Styled.ButtonFlex
              key={'category_' + indexCategory}
              onClick={() => {
                handleOpenCategory(!category.opened, indexCategory);
                handleChangeTabIndex(2, translation('mapPage.sideBar.navigationBottomBar.layers'), <MdLayers />);
              }}
            >
              <Styled.Header>
                <p>{category.nm_categoria}</p>
              </Styled.Header>
            </Styled.ButtonFlex>
          );
        })}
      </Styled.ExplorarMapa>

      <hr />
      <h1 className="titulo">{translation('mapPage.sideBar.home.themes.titleBusca')}</h1>
      <Styled.BuscaRapida>
        <Styled.Busca>
          <Styled.IconeBusca className="icone-busca" path={empreendedor}></Styled.IconeBusca>
          <p>Titular de Empreendimento</p>
          <Styled.ButtonBusca disabled data-tip data-for="functionalDev">
            Iniciar busca
          </Styled.ButtonBusca>
        </Styled.Busca>

        <Styled.Busca>
          <Styled.IconeBusca className="icone-busca" path={empreendimento}></Styled.IconeBusca>
          <p>Empreendimento</p>
          <Styled.ButtonBusca disabled data-tip data-for="functionalDev">
            Iniciar busca
          </Styled.ButtonBusca>
        </Styled.Busca>

        <Styled.Busca>
          <Styled.IconeBusca className="icone-busca" path={documentos}></Styled.IconeBusca>
          <p>Documentos e Relatórios</p>
          <Styled.ButtonBusca disabled data-tip data-for="functionalDev">
            Iniciar busca
          </Styled.ButtonBusca>
        </Styled.Busca>
      </Styled.BuscaRapida>

      {/* <hr />
      <h1 className="titulo">{translation('mapPage.sideBar.home.themes.titleEconomia')}</h1>
      <Styled.ThemeGrid>
        <Styled.Estudos>
          <h1 className="titulo">{translation('mapPage.sideBar.home.themes.titleEstudosAnaliticos')}</h1>
          <Styled.ThemeGrid>
            {estudosList.length
              ? estudosList.map((theme: ITheme, indexTheme: number) => {
                  return (
                    <Styled.Button
                      key={'estudos_themes_' + indexTheme}
                      onClick={() => {
                        handleOpenThemeEstudo(!theme.opened, indexTheme);
                      }}
                    >
                      <Styled.Header open={theme.opened}>
                        <p>{theme.nm_tema}</p>
                      </Styled.Header>

                      {theme.opened && (
                        <Styled.Tematicas className="tematicas">
                          {theme.subtema?.length &&
                            theme.subtema.map((subTheme: ISubTheme, indexSubTheme: number) => {
                              return (
                                <Styled.Tematica key={'estudos_sub_themes_' + indexSubTheme}>
                                  <button disabled data-tip data-for="functionalDev">
                                    {subTheme.nm_subtema}
                                  </button>
                                  <ReactTooltip
                                    id="functionalDev"
                                    place="top"
                                    type="warning"
                                    effect="solid"
                                    delayShow={500}
                                    delayHide={500}
                                  >
                                    <p>{translation('mapPage.sideBar.navigationRightBar.functionalDev')}</p>
                                  </ReactTooltip>
                                </Styled.Tematica>
                              );
                            })}
                        </Styled.Tematicas>
                      )}
                    </Styled.Button>
                  );
                })
              : ''}
          </Styled.ThemeGrid>
        </Styled.Estudos>
      </Styled.ThemeGrid> */}

      <hr />
      <h1 className="titulo">{translation('mapPage.sideBar.home.themes.titleEstatistica')}</h1>
      <Styled.Estatistica>
        {linksEstatisticaMineral.map((item, index) => {
          return (
            <Styled.ContentEconomiaMineral key={item.id}>
              <h1>{item.title}</h1>
              <div>
                {item.links.map(linkItem => {
                  const openInNewWindow = linkItem.link.startsWith('https://');

                  return (
                    <Styled.Button key={'links_' + linkItem.id}>
                      <Styled.Header className="default">
                        {openInNewWindow ? (
                          <a href={linkItem.link} target="_blank" rel="noreferrer">
                            {linkItem.nome}
                          </a>
                        ) : (
                          <Link to={linkItem.link}>{linkItem.nome}</Link>
                        )}
                      </Styled.Header>
                    </Styled.Button>
                  );
                })}
              </div>
            </Styled.ContentEconomiaMineral>
          );
        })}
      </Styled.Estatistica>

      <ReactTooltip id="functionalDev" place="top" type="warning" effect="solid" delayShow={500} delayHide={500}>
        <p>{translation('mapPage.sideBar.navigationRightBar.functionalDev')}</p>
      </ReactTooltip>
    </Styled.Wrapper>
  );
}
