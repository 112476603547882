import { IValuesForFilterType } from '../../../../../../shared/models/ValuesForFilter';

type RenderCardsType = {
  valuesSelected: IValuesForFilterType;
  setRenderCards: React.Dispatch<React.SetStateAction<string>>;
};

export function renderCardsFunction({
  valuesSelected,
  setRenderCards,
}: RenderCardsType) {
  const arrayOfObject = Object.entries(valuesSelected);
  const maxLength = arrayOfObject.length - 1;

  if (Object.values(valuesSelected).length > 0) {
    if (arrayOfObject[maxLength][0] === 'mineralSubstance') {
      arrayOfObject.pop();

      const lengthWithPop = arrayOfObject.length - 1;
      if (
        arrayOfObject !== undefined &&
        arrayOfObject[lengthWithPop] !== undefined &&
        arrayOfObject[lengthWithPop][0] === 'country'
      ) {
        return setRenderCards('country');
      }

      if (
        arrayOfObject !== undefined &&
        arrayOfObject[lengthWithPop] !== undefined &&
        arrayOfObject[lengthWithPop][0] === 'region'
      ) {
        return setRenderCards('region');
      }

      if (
        arrayOfObject !== undefined &&
        arrayOfObject[lengthWithPop] !== undefined &&
        arrayOfObject[lengthWithPop][0] === 'state'
      ) {
        return setRenderCards('state');
      }

      if (
        arrayOfObject !== undefined &&
        arrayOfObject[lengthWithPop] !== undefined &&
        arrayOfObject[lengthWithPop][0] === 'city'
      ) {
        return setRenderCards('city');
      }

      if (arrayOfObject[lengthWithPop] === undefined) {
        return setRenderCards('');
      }
    }

    if (arrayOfObject[maxLength][0] === 'country') {
      return setRenderCards('country');
    }

    if (arrayOfObject[maxLength][0] === 'region') {
      return setRenderCards('region');
    }

    if (arrayOfObject[maxLength][0] === 'state') {
      return setRenderCards('state');
    }

    if (arrayOfObject[maxLength][0] === 'city') {
      return setRenderCards('city');
    }
  }

  return setRenderCards('');
}
