import axios from 'axios';
import { Environment } from 'shared/environments';
import { DataSubsEstadoType } from '..';
import { DataTypeSubsEstado } from '../JsonType/Type';

type GetData = {
  valueForFilter?: {
    subsMineral?: string;
    uf?: string[];
  };
  setDataSubsEstado: React.Dispatch<React.SetStateAction<DataSubsEstadoType[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setGetGraphicsSubsMin: React.Dispatch<
    React.SetStateAction<DataSubsEstadoType[]>
  >;
  setGetGraphicsState: React.Dispatch<
    React.SetStateAction<DataSubsEstadoType[]>
  >;
};

const MockApi = axios.create({
  baseURL: Environment.API_MOCK,
});

function returnArraysForChart(
  responseData: DataTypeSubsEstado[],
  textFilter: string[],
  type: 'subsMin' | 'uf',
) {
  const filterData = textFilter.map(state =>
    responseData.filter(posicao =>
      type === 'subsMin'
        ? posicao.substancia_mineral.toLowerCase() === state.toLowerCase()
        : posicao.uf.toLowerCase() === state.toLowerCase(),
    ),
  );

  const anoBase = filterData.map(
    posicao =>
      typeof posicao === 'object' && posicao.map(object => object.ano_base),
  );

  const anoBaseNoRepeat = anoBase.map(
    posicao =>
      typeof posicao === 'object' &&
      posicao.filter((ano, index) => posicao.indexOf(ano) === index).sort(),
  );

  const returnAllProdutionPerYear = anoBaseNoRepeat.map((posicao, index) =>
    typeof posicao === 'object'
      ? posicao.map(ano =>
          filterData[index].filter(array => array.ano_base === ano),
        )
      : posicao,
  );

  const forEachPositionReturnArrayGraph = returnAllProdutionPerYear.map(
    posicao =>
      typeof posicao === 'object'
        ? posicao.map(array => [
            `${array[0].ano_base}`,
            array.reduce((a, b) => a + b.quantidade_prod_ton, 0),
            array.reduce((a, b) => a + b.quantidade_venda_ton, 0),
          ])
        : posicao,
  );

  const arrayTitle1 = [
    'Ano',
    'Produção Beneficiada Ton',
    'Produção Mineral Comercializada Ton',
  ] as (string | number)[];

  forEachPositionReturnArrayGraph.map(posicao =>
    typeof posicao === 'object' ? posicao.unshift(arrayTitle1) : posicao,
  );

  const convertArrayInObject = [] as DataSubsEstadoType[];

  for (let i = 0; i < forEachPositionReturnArrayGraph.length; i += 1) {
    const object = {
      filterText: textFilter[i],
      dataChart: forEachPositionReturnArrayGraph[i],
    } as DataSubsEstadoType;

    convertArrayInObject.push(object);
  }

  return convertArrayInObject;
}

export async function getDataSubsEstado({
  setDataSubsEstado,
  setLoading,
  setError,
  valueForFilter,
  setGetGraphicsSubsMin,
  setGetGraphicsState,
}: GetData) {
  if (
    valueForFilter?.subsMineral === undefined &&
    valueForFilter?.uf?.length === 0
  ) {
    await MockApi.get(`quantidadeProduzidaEstadoAno`)
      .then(response => {
        const responseData = response.data as DataTypeSubsEstado[];
        const anoBase = responseData.map(posicao => posicao.ano_base);
        const anoBaseNoRepeat = anoBase
          .filter((ano, index) => anoBase.indexOf(ano) === index)
          .sort();

        const returnProdution = anoBaseNoRepeat.map(ano =>
          responseData.filter(posicao => posicao.ano_base === ano),
        );

        const reduceProdutionOld = returnProdution.map(posicao => [
          `${posicao[0].ano_base}`,
          posicao.reduce((a, b) => a + b.quantidade_prod_ton, 0),
          posicao.reduce((a, b) => a + b.quantidade_venda_ton, 0),
        ]) as (string | number)[][];

        const arrayTitle1 = [
          'Ano',
          'Produção Beneficiada Ton',
          'Produção Mineral Comercializada Ton',
        ];

        reduceProdutionOld.unshift(arrayTitle1);

        const graphData = reduceProdutionOld;

        setDataSubsEstado([
          {
            filterText: undefined,
            dataChart: graphData,
          },
        ]);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  } else {
    await MockApi.get(`quantidadeProduzidaEstadoAno`)
      .then(response => {
        const responseData = response.data as DataTypeSubsEstado[];
        const substMineral = valueForFilter?.subsMineral?.split(', ');
        const uf = valueForFilter?.uf;

        if (substMineral !== undefined) {
          const returnGraphicsSubsMin = returnArraysForChart(
            responseData,
            substMineral,
            'subsMin',
          );

          setGetGraphicsSubsMin(returnGraphicsSubsMin);
        }

        if (uf !== undefined && uf?.length > 0) {
          const returnGraphicsState = returnArraysForChart(
            responseData,
            uf,
            'uf',
          );

          setGetGraphicsState(returnGraphicsState);
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
}
