import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]};

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }

    header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      > h1 {
        font-size: ${theme.font.sizes.xl};
        font-weight: ${theme.font.weights.bold};
        line-height: 1;

        @media (max-width: 600px) {
          font-size: ${theme.font.sizes.lg};
        }

        @media (max-width: 400px) {
          font-size: ${theme.font.sizes.md};
        }
      }
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  .emptyLayer {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LayerBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacings[12]};
    padding: ${theme.spacings[12]} ${theme.spacings[16]};

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: ${theme.radius.xs};

    background-color: ${theme.colors.indigo800};
    color: ${theme.colors.white};

    cursor: pointer;

    svg {
      transition: all 0.2s;
    }
  `}
`;
