/* eslint-disable camelcase */
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Map } from 'ol';
// import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
// import { ImageWMS } from 'ol/source';
// import VectorSource from 'ol/source/Vector';
// import GeoJSON from 'ol/format/GeoJSON';

// import { transformExtent } from 'ol/proj';
import { useLayers } from 'hooks/useLayers';
import TileLayer from 'ol/layer/Tile';
// import ImageLayer from 'ol/layer/Image';
import Feature from 'ol/format/Feature';
// import { unByKey } from 'ol/Observable';
import { Environment } from 'shared/environments';
import { IContext, IProvider, ISubTitlesLayer } from './@types/Map';
import { ILayers } from './@types/Layers';
import { api } from 'services/api/api';
import { useFilter } from 'hooks/useFilter';

export const MapContext = createContext<IContext>({} as IContext);

export function MapProvider({ children }: IProvider) {
  const [map, setMap] = useState<Map>();
  const mapRef = useRef<HTMLDivElement>(null);
  const [subTitles, setSubTitles] = useState<ISubTitlesLayer[]>([]);
  const [failedLoadTile, setFailedLoadTile] = useState<boolean>(false);
  const [layersFeatures, setLayersFeatures] = useState<Feature[]>();
  const [layerActiveFeature, setLayerActiveFeature] = useState<string | null>(null);
  const [openLayerFeaturesModal, setOpenLayerFeaturesModal] = useState<boolean>(false);
  const { filterData, mountObjectFilter } = useFilter();

  const [dashboardOptions, setDashboardOptions] = useState(false);

  // Antigo responsavel por filtrar as camadas
  // useEffect(() => {
  //   if (layersActived && layersActived.length === 0 && mapFilter) {
  //     setIsLoading(false);
  //     setFilterApplied(true);
  //   } else {
  //     // handleClearLayersActived();
  //     handleRemoveAllOverlayLayers(layersActived);
  //     handleAddAllOverlayLayers(layersActived);
  //     handleFitExtent();
  //   }
  // }, [mapFilter]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!map) return;
  }, [map]);

  const handleChangeOpacityLayer = (uuid: string, opacity: number) => {
    if (!map) return;

    const layers = map.getLayers().getArray();

    const layer = layers.find(item => item.getProperties().uuid === uuid);
    if (layer) {
      layer.setOpacity(opacity);
    }
  };

  const handleRemoveOverlayLayer = (uuid: string) => {
    if (!map) return;
    const layers = map.getLayers().getArray();

    const layerToDelete = layers.find(layer => layer.getProperties().uuid === uuid);
    if (layerToDelete) {
      map.removeLayer(layerToDelete);
    }
  };

  // const getMapFilter = useCallback(
  //   (aditionalInformation?: string) => {
  //     if ((!mapFilter || mapFilter === 'default') && !aditionalInformation) {
  //       return "sigla_uf<>''";
  //     }
  //     if (mapFilter && mapFilter !== 'default' && aditionalInformation) {
  //       const newMapFilter = `${mapFilter.slice(0, -1)} AND (ds_fase_agrupada='${aditionalInformation}'))`;
  //       return newMapFilter;
  //     }
  //     if ((!mapFilter || mapFilter === 'default') && aditionalInformation) {
  //       return `(ds_fase_agrupada='${aditionalInformation}')`;
  //     }
  //     return mapFilter;
  //   },
  //   [mapFilter],
  // );

  const handleFitExtent = useCallback(async () => {
    if (!map) return;
    const mountFilter = mountObjectFilter();
    const response = await api.get('/pmextent', {
      params: {
        ...mountFilter,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
    const extent = response.data[0].geoext;
    if (extent.length === 4) {
      map.getView().fit(extent, { duration: 2000 });
    }
  }, [map, filterData]);

  const handleAddOverlayLayer = useCallback(
    (layer: ILayers, aditionalInformation?: string) => {
      if (!map) return;
      //TODO Rever order
      const { scheme, layername, host_url, layertitle, area_tematica, order, substancia_agrupadora_id } = layer;
      //const wmsUrl = `${host_url}wms`;
      const wmsUrl = `${host_url}`;
      const uuid = layertitle + substancia_agrupadora_id;
      const zIndex = order || 0;

      // const cql = getMapFilter(aditionalInformation);
      let wmsSource: TileWMS;

      // setIsLoading(true);
      if (area_tematica !== 'Direitos Minerários') {
        wmsSource = new TileWMS({
          url: host_url,
          crossOrigin: 'Anonymous',
          params: {
            LAYERS: scheme ? `${scheme}:${layername}` : layername,
            TILED: true,
            VERSION: '1.3.0',
            FORMAT: 'image/png',
            SRS: 'EPSG:4674',
            CQL_FILTER:
              substancia_agrupadora_id &&
              `substancia_agrupadora_id LIKE '%, ${substancia_agrupadora_id}%' OR substancia_agrupadora_id LIKE '${substancia_agrupadora_id}%'`,
          },
          serverType: 'geoserver',
        });
      } else {
        // const cql = getMapFilter(aditionalInformation);
        wmsSource = new TileWMS({
          url: host_url,
          crossOrigin: 'Anonymous',
          params: {
            LAYERS: scheme ? `${scheme}:${layername}` : layername,
            TILED: true,
            VERSION: '1.3.0',
            FORMAT: 'image/png',
            SRS: 'EPSG:4674',
            CQL_FILTER:
              substancia_agrupadora_id &&
              `substancia_agrupadora_id LIKE '%, ${substancia_agrupadora_id}%' OR substancia_agrupadora_id LIKE '${substancia_agrupadora_id}%'`,
          },
          serverType: 'geoserver',
        });
      }

      const overlay = new TileLayer({
        source: wmsSource,
        properties: {
          uuid,
        },
        zIndex,
        opacity: 1,
      });

      map.addLayer(overlay);

      setSubTitles(prevState => [
        ...prevState,
        {
          layername,
          url: String(wmsSource.getLegendUrl(map.getView().getResolution())),
        },
      ]);

      map.on('singleclick', evt => {
        const layers = map.getLayers().getArray();
        const layerSelected = layers.find(layer => layer.getProperties().uuid === uuid);

        setLayerActiveFeature(layerSelected?.getProperties().uuid);

        const viewResolution = map.getView().getResolution();
        if (viewResolution && layerSelected && !openLayerFeaturesModal) {
          const url = wmsSource.getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
            FEATURE_COUNT: 50,
            INFO_FORMAT: 'application/json',
          });
          if (url) {
            fetch(url, {
              method: 'GET',
              mode: 'cors',
            })
              .then(response => response.json())
              .then(html => {
                const lng = html.features.length;
                if (lng > 0) {
                  setLayersFeatures(html.features);
                  setOpenLayerFeaturesModal(true);
                }
              });
          }
        }
      });

      map.on('pointermove', function movedrag(evt) {
        if (evt.dragging) {
          return;
        }
        const data = overlay.getData(evt.pixel);
        map.getTargetElement().style.cursor = data ? 'pointer' : '';
      });

      // eslint-disable-next-line
      return () => {
        map.removeLayer(overlay);
      };
    },
    [map],
  );

  const handleAddAllOverlayLayers = (layers: ILayers[]) => {
    if (!map) return;
    layers.map(layer => handleAddOverlayLayer(layer));
  };

  const handleRemoveAllOverlayLayers = (layers: ILayers[]) => {
    if (!map) return;
    // handleClearLayersActived();
    layers.map(layer => handleRemoveOverlayLayer(layer.layertitle + layer.substancia_agrupadora_id));
  };

  const handleDownloadPrintMap = async () => {
    const canvas = mapRef.current?.querySelector('.ol-layer canvas') as HTMLCanvasElement;

    const dataURL = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'mapa.jpeg';
    link.click();
  };

  return (
    <MapContext.Provider
      value={{
        map,
        mapRef,
        setMap,
        subTitles,
        dashboardOptions,
        failedLoadTile,
        setFailedLoadTile,
        layersFeatures,
        layerActiveFeature,
        openLayerFeaturesModal,
        setOpenLayerFeaturesModal,
        setDashboardOptions,
        handleAddAllOverlayLayers,
        handleAddOverlayLayer,
        handleFitExtent,
        handleRemoveOverlayLayer,
        handleRemoveAllOverlayLayers,
        handleChangeOpacityLayer,
        handleDownloadPrintMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}
