import React from 'react';

interface IProperties {
  properties: {
    cod_oaci: string;
    ciad: string;
    nm: string;
    mun: string;
    uf: string;
    oaci: string;
    aip_br: string;
  };
}

export function AerodromosPublicos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Aeródromos Públicos</strong>
      </h4>
      <p>
        <strong>
          Código Organização de Aviação Civil Internacional (OACI):{' '}
        </strong>{' '}
        {properties.cod_oaci ? properties.cod_oaci : 'Não informado'}
      </p>
      <p>
        <strong>Código de Identificação do Aeródromo (CIAD): </strong>{' '}
        {properties.ciad ? properties.ciad : 'Não informado'}
      </p>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nm ? properties.nm : 'Não informado'}
      </p>
      <p>
        <strong>Município: </strong>{' '}
        {properties.mun ? properties.mun : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>{' '}
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Tipo de tráfego permitido no aeródromo: </strong>{' '}
        {properties.aip_br ? properties.aip_br : 'Não informado'}
      </p>
    </div>
  );
}
