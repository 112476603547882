export function convertStateToInitials(names: string[]) {
  const initials: string[] = [];

  names.forEach(state => {
    switch (state) {
      case 'Acre':
        initials.push('AC');
        break;
      case 'Alagoas':
        initials.push('AL');
        break;
      case 'Amazonas':
        initials.push('AM');
        break;
      case 'Amapá':
        initials.push('AP');
        break;
      case 'Bahia':
        initials.push('BA');
        break;
      case 'Ceará':
        initials.push('CE');
        break;
      case 'Distrito Federal':
        initials.push('DF');
        break;
      case 'Espírito Santo':
        initials.push('ES');
        break;
      case 'Goiás':
        initials.push('GO');
        break;
      case 'Maranhão':
        initials.push('MA');
        break;
      case 'Minas Gerais':
        initials.push('MG');
        break;
      case 'Mato Grosso do Sul':
        initials.push('MS');
        break;
      case 'Mato Grosso':
        initials.push('MT');
        break;
      case 'Pará':
        initials.push('PA');
        break;
      case 'Paraíba':
        initials.push('PB');
        break;
      case 'Pernambuco':
        initials.push('PE');
        break;
      case 'Piauí':
        initials.push('PI');
        break;
      case 'Paraná':
        initials.push('PR');
        break;
      case 'Rio de Janeiro':
        initials.push('RJ');
        break;
      case 'Rio Grande do Norte':
        initials.push('RN');
        break;
      case 'Rondônia':
        initials.push('RO');
        break;
      case 'Roraima':
        initials.push('RR');
        break;
      case 'Rio Grande do Sul':
        initials.push('RS');
        break;
      case 'Santa Catarina':
        initials.push('SC');
        break;
      case 'Sergipe':
        initials.push('SE');
        break;
      case 'São Paulo':
        initials.push('SP');
        break;
      case 'Tocantins':
        initials.push('TO');
        break;
      default: //eslint-disable-line
        console.log('State not Founded'); //eslint-disable-line
    }
  });

  return initials;
}

export const nameToSigla = {
  Acre: 'AC',
  Alagoas: 'AL',
  Amazonas: 'AM',
  Amapá: 'AP',
  Bahia: 'BA',
  Ceará: 'CE',
  DistritoFederal: 'DF',
  EspíritoSanto: 'ES',
  Goiás: 'GO',
  Maranhão: 'MA',
  MinasGerais: 'MG',
  MatoGrossodoSul: 'MS',
  MatoGrosso: 'MT',
  Pará: 'PA',
  Paraíba: 'PB',
  Pernambuco: 'PE',
  Piauí: 'PI',
  Paraná: 'PR',
  RiodeJaneiro: 'RJ',
  RioGrandedoNorte: 'RN',
  Rondônia: 'RO',
  Roraima: 'RR',
  RioGrandedoSul: 'RS',
  SantaCatarina: 'SC',
  Sergipe: 'SE',
  SãoPaulo: 'SP',
  Tocantins: 'TO',
};

export type NameToSigla = keyof typeof nameToSigla;

// export function convertStateToInitialsWithQuotation(names: string[]) {
//   const initials: string[] = [];

//   names.forEach(state => {
//     switch (state) {
//       case 'Acre':
//         initials.push(`'AC'`);
//         break;
//       case 'Alagoas':
//         initials.push(`'AL'`);
//         break;
//       case 'Amazonas':
//         initials.push(`'AM'`);
//         break;
//       case 'Amapá':
//         initials.push(`'AP'`);
//         break;
//       case 'Bahia':
//         initials.push(`'BA'`);
//         break;
//       case 'Ceará':
//         initials.push(`'CE'`);
//         break;
//       case 'Distrito Federal':
//         initials.push(`'DF'`);
//         break;
//       case 'Espírito Santo':
//         initials.push(`'ES'`);
//         break;
//       case 'Goiás':
//         initials.push(`'GO'`);
//         break;
//       case 'Maranhão':
//         initials.push(`'MA'`);
//         break;
//       case 'Minas Gerais':
//         initials.push(`'MG'`);
//         break;
//       case 'Mato Grosso do Sul':
//         initials.push(`'MS'`);
//         break;
//       case 'Mato Grosso':
//         initials.push(`'MT'`);
//         break;
//       case 'Pará':
//         initials.push(`'PA'`);
//         break;
//       case 'Paraíba':
//         initials.push(`'PB'`);
//         break;
//       case 'Pernambuco':
//         initials.push(`'PE'`);
//         break;
//       case 'Piauí':
//         initials.push(`'PI'`);
//         break;
//       case 'Paraná':
//         initials.push(`'PR'`);
//         break;
//       case 'Rio de Janeiro':
//         initials.push(`'RJ'`);
//         break;
//       case 'Rio Grande do Norte':
//         initials.push(`'RN'`);
//         break;
//       case 'Rondônia':
//         initials.push(`'RO'`);
//         break;
//       case 'Roraima':
//         initials.push(`'RR'`);
//         break;
//       case 'Rio Grande do Sul':
//         initials.push(`'RS'`);
//         break;
//       case 'Santa Catarina':
//         initials.push(`'SC'`);
//         break;
//       case 'Sergipe':
//         initials.push(`'SE'`);
//         break;
//       case 'São Paulo':
//         initials.push(`'SP'`);
//         break;
//       case 'Tocantins':
//         initials.push(`'TO'`);
//         break;
//       default: //eslint-disable-line
//         console.log('State not Founded'); //eslint-disable-line
//     }
//   });

//   return initials;
// }
