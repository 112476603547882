import { AxiosResponse } from 'axios';
import { api } from './api/api';

export async function findAllCounty<T>() {
  const response: AxiosResponse<T> = await api.get(`/municipios/`);

  return response;
}

export async function findCountyByState<T>(state: string) {
  const response: AxiosResponse<T> = await api.get(
    `/municipios/?sigla_uf__in=${state}`,
  );

  return response;
}
