import styled, { css, DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';

interface SelectProps {
  state: boolean;
  disabled?: boolean;
  mapFilter?: boolean;
}

interface ButtonOptionProps {
  isActive: boolean;
}

const selectModifiers = {
  disabled: (theme: DefaultTheme) => css`
    .selectValueContainer strong {
      color: ${theme.colors.gray600};
    }
    .selectValue {
      border: 1px solid ${theme.colors.gray600};

      cursor: not-allowed;
    }
  `,
};

export const SelectContainer = styled.div<SelectProps>`
  ${({ theme, state, disabled }) => css`
    width: 100%;

    transition: all 0.2s;

    .selectValueContainer {
      white-space: nowrap;
      font-size: 14px;

      @media (max-height: 675px) {
        font-size: 12px;
      }
    }

    .selectValue {
      width: 100%;
      min-height: 34px;
      margin-top: ${theme.spacings[4]};
      padding: ${theme.spacings[4]} ${theme.spacings[8]};

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: 1px solid ${theme.colors.gray900};
      border-radius: 0.4rem;

      cursor: pointer;

      background: ${state ? theme.colors.gray100 : theme.colors.gray200};

      @media (max-height: 740px) {
        height: 20px;
      }
    }

    .arrowSelectFilter {
      transform: ${state && 'rotate(180deg)'};

      transition: all 0.2s;
    }

    .title {
      display: flex;
      align-items: center;

      strong {
        margin-right: 5px;
      }
    }

    .textInput {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      background-color: ${state ? theme.colors.gray100 : theme.colors.gray200};
    }

    ${disabled && selectModifiers.disabled(theme)}
  `}
`;

export const Options = styled.div<SelectProps>`
  ${({ theme, state, mapFilter }) => css`
    width: ${state ? '0rem' : '100%'};
    height: 100%;
    position: absolute;
    top: 0;
    left: ${mapFilter ? '36.8rem' : '28rem'};

    background-color: rgba(243, 244, 246, 0.9);

    ${mapFilter &&
    css`
      left: 100.5%;
      box-shadow: none;

      @media (max-width: 437px) {
        left: 0;
      }

      .selectHeader {
        @media (min-width: 438px) {
          .returnButton {
            display: none;
          }
        }
      }
    `}

    ${!mapFilter &&
    css`
      @media (max-width: 535px) {
        background-color: rgba(243, 244, 246, 1);
      }
    `}

    ${mapFilter &&
    css`
      @media (max-width: 437px) {
        background-color: rgba(243, 244, 246, 1);
      }
    `}


    overflow-y: auto;

    transition: all 0.2s;
    z-index: 1;

    .selectHeader {
      padding: 10px;
      background: #0083bf;
      color: white;
      display: flex;
      align-items: center;

      .selectLabel {
        margin: 0 auto;
      }

      ${!mapFilter &&
      css`
        @media (min-width: 536px) {
          .returnButton {
            display: none;
          }
        }
      `}
    }

    .emptyDataContainer {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: ${theme.colors.gray600};
      }
    }

    ${!mapFilter &&
    css`
      @media (max-width: 650px) {
        left: 26.8rem;
      }

      @media (max-width: 535px) {
        left: 0;
      }
    `}
  `}
`;

const buttonOptionModifiers = {
  isActive: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};

    &:hover {
      background-color: ${transparentize(0.3, theme.colors.blue600)};
    }
  `,
};

export const ButtonOption = styled.button<ButtonOptionProps>`
  ${({ theme, isActive }) => css`
    width: 100%;
    padding: ${theme.spacings[16]};

    display: block;

    font-family: ${theme.font.family.default};
    font-size: ${theme.font.sizes.sm};
    text-align: start;
    white-space: nowrap;

    border: 0;

    background-color: transparent;

    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: ${theme.colors.gray200};
    }

    ${isActive && buttonOptionModifiers.isActive(theme)}
  `}
`;
