import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 350px;
    right: 10px;
    z-index: 10;
    display: flex;

    p {
      font-size: ${theme.font.sizes.xs};
      font-family: ${theme.font.family.default};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css``}
`;
