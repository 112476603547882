import React from 'react';

interface IProperties {
  properties: {
    total: number;
    homens: number;
    mulheres: number;
  };
}

export function RendaTrabalhoPrincipal({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Renda Nominal Mensal das Pessoas Maiores de 10 Anos Com Rendimento no Trabalho Principal</strong>
      </h4>
      <p>
        <strong>Valor Total no ano de 2010 (R$): </strong>
        {properties.total ? properties.total.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Homens no ano de 2010 (R$): </strong>
        {properties.homens ? properties.homens.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Mulheres no ano de 2010 (R$): </strong>
        {properties.mulheres ? properties.mulheres.toLocaleString() : 'Não informado'}
      </p>
    </div>
  );
}
