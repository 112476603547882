import { useContext, useEffect } from 'react';
import { XYZ } from 'ol/source';
import { Tile } from 'ol/layer';

import { MapContext } from 'contexts/MapContext';
import { useLayers } from 'hooks/useLayers';
import { useOptions } from 'hooks/useOptions';

interface ITileLayer {
  source: XYZ;
  zIndex: number;
}

export function MapLayer({ source, zIndex }: ITileLayer) {
  const { map, handleRemoveAllOverlayLayers, handleAddAllOverlayLayers, handleFitExtent } = useContext(MapContext);
  const { layersActived, handleClearLayersActived } = useLayers();
  const { openMap } = useOptions();

  useEffect(() => {
    if (!map) return;

    const tileLayerConfig = new Tile({ source, zIndex });

    map.addLayer(tileLayerConfig);

    tileLayerConfig.setZIndex(zIndex);

    // eslint-disable-next-line
    return () => {
      map.removeLayer(tileLayerConfig);
      if (layersActived && !openMap) {
        handleRemoveAllOverlayLayers(layersActived);
        handleAddAllOverlayLayers(layersActived);
      }
      if (layersActived && openMap) {
        handleClearLayersActived();
      }
    };
  }, [map, source]);

  return null;
}
