import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as RiIcons from 'react-icons/ri';
import { Button } from 'components';

import * as Styled from './styles';

interface IOptions {
  address: {
    city: string;
    state: string;
    country: string;
    town: string;
    municipality: string;
  };
  lat: string;
  lon: string;
}

interface ISearchInput extends InputHTMLAttributes<HTMLInputElement> {
  options?: IOptions[];
  onSelected?: (item: IOptions) => void;
}

function clearText(text: string) {
  return text
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

function getCity(city: string[], search: string) {
  const result = city.find(c => c && clearText(c).includes(clearText(search)));
  return result || null;
}

function getLabel(item: IOptions, value?: string) {
  if (item.address.city || item.address.town || item.address.municipality)
    return `${
      getCity([item.address.city, item.address.town, item.address.municipality], value || '') ||
      item.address.city ||
      item.address.town ||
      item.address.municipality
    },
      ${item.address.state ? item.address.state + ', ' : ''}
      ${item.address.country}`;
  if (item.address.state) {
    return `${item.address.state}, ${item.address.country}`;
  }
  return `${item.address.country}`;
}

function verifyOccurence(occurences: Set<string>, element: string) {
  if (!occurences.has(element)) {
    occurences.add(element);
    return false;
  }
  return true;
}

export function SearchInput({ options, onSelected, value, ...props }: ISearchInput) {
  const [isActived, setIsActived] = useState(false);

  const { t: translation } = useTranslation();

  const ocurrences = new Set<string>();

  useEffect(() => {
    if (options?.length !== 0) {
      return setIsActived(true);
    }

    return setIsActived(false);
  }, [options]);

  return (
    <Styled.Wrapper>
      <RiIcons.RiSearchLine size={32} color="#000" />
      <input type="text" placeholder={translation('searchInputPlaceholder')} {...props} />
      {isActived && (
        <Styled.OptionsBlock>
          {options?.map((item, index) => {
            return (
              !verifyOccurence(ocurrences, getLabel(item, value?.toString())) && (
                <div
                  key={index}
                  className="option"
                  onClick={() => {
                    if (onSelected) {
                      onSelected(item);
                    }

                    setIsActived(false);
                  }}
                >
                  <strong>
                    {getLabel(item, value?.toString()).split(',')[0]}
                    {getLabel(item, value?.toString()).split(',').length > 1 && ', '}
                  </strong>
                  {getLabel(item, value?.toString()).split(',').slice(1).join(', ')}
                </div>
              )
            );
          })}
        </Styled.OptionsBlock>
      )}
      {/* 
      <Button id="buttonPesquisar">
        {translation('searchPesquisar')}
      </Button>

      <Button>{translation('searchPesquisaAvancada')}</Button> 
      */}
    </Styled.Wrapper>
  );
}
