import React from 'react';
import { createPortal } from 'react-dom';

import loadingIcon from 'assets/icone/icone_loading.gif';

import * as Styled from './styles';

export function Loading() {
  return createPortal(
    <Styled.BackgroundBlur>
      <img src={loadingIcon} alt="Loading" />
    </Styled.BackgroundBlur>,
    document.getElementById('modal')!, //eslint-disable-line
  );
}
