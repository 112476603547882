import styled, { css } from 'styled-components';

interface CheckBoxContainerProps {
  color?: string;
}

export const MainContainer = styled.div`
  ${({ theme }) => css`
    .title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
    }

    .box-list {
      font-size: ${theme.font.sizes.sm};
      li {
        max-height: 30px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
          cursor: pointer;
        }
      }
    }
  `}
`;

export const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
  ${({ color }) => css`
    font-size: 12px;
    input[type='checkbox'] {
      cursor: pointer;
      appearance: none;
      background-color: #fff;
      margin-right: 10px;
      font: inherit;
      color: ${color || '#000'};
      min-width: 1.15em;
      height: 1.15em;
      border: 0.11em solid;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }
    input[type='checkbox']::before {
      content: '';
      width: 0.7em;
      height: 0.7em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #fff;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    input[type='checkbox']:checked::before {
      transform: scale(1);
      background-color: ${color || '#000'};
    }
    input[type='checkbox']:checked {
      background-color: ${color || '#000'};
    }
    input[type='checkbox']:disabled {
      cursor: not-allowed;
    }
  `}
`;
