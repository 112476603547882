import axios from 'axios';
import { Environment } from 'shared/environments';
import { City } from 'shared/models/City';
import { State } from 'shared/models/State';
import { JsonApi } from 'shared/services/axios-config';
import { CityService } from 'shared/services/city/CityService';
import { RepositoryJsonState } from './RepositoryJsonState';

export class RepositoryJsonCity implements CityService {
  async getCity(filterValue?: string): Promise<City[] | Error> {
    try {
      const repositoryState = new RepositoryJsonState();

      const returnsTheAbbreviationOfUf =
        filterValue !== undefined
          ? ((await repositoryState.getStateFindState(filterValue)) as State[])
          : undefined;

      const abbreviationOfUf = returnsTheAbbreviationOfUf?.map(
        posicao => posicao.sigla,
      );

      const joinAbbreviation = abbreviationOfUf?.join('&estado=');

      const endPoint =
        filterValue === undefined || filterValue === ''
          ? `${Environment.API_URL_BASE}/municipios/`
          : `${Environment.API_URL_BASE}/municipios/?estado=${joinAbbreviation}`;

      const { data } = await axios.get<City[]>(endPoint);
      return data;
    } catch (error: any) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
  async getCityFindCity(city: string): Promise<Error | City[]> {
    try {
      const cityCorrect = city.split(', ').join(',');

      console.log(cityCorrect);
      const response = await JsonApi.get<City[]>(
        `municipios/?nome__in=${cityCorrect}`,
      );
      return response.data;
    } catch (error) {
      return new Error(
        (error as { message: string }).message ||
          'Erro ao Listar os registros.',
      );
    }
  }
}
