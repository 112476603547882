import React from 'react';
import { RiStarLine, RiEyeOffFill } from 'react-icons/ri';
import { MdDashboard } from 'react-icons/md';

export default [
  {
    label: 'dashboard',
    icon: <MdDashboard size={24} />,
    href: '/dashboard',
  },
  {
    label: 'favoriteCharts',
    icon: <RiStarLine size={24} />,
    href: '/favorites',
  },
  {
    label: 'hiddenCharts',
    icon: <RiEyeOffFill size={24} />,
    href: '/hidden',
  },
];
