import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface IWrapper {
  inverted?: boolean;
}

const wrapperModifiers = {
  left: () => css`
    flex-direction: row-reverse;
  `,
};

export const Wrapper = styled.div<IWrapper>`
  ${({ theme, inverted }) => css`
    display: inline-flex;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    
    input {
      appearance: none;
      -webkit-appearance: none;
      width: 1.8rem;
      height: 1.8rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 0.2rem;
      border: 2px solid rgb(1, 122, 198);

      cursor: pointer;

      &:checked {
        &::after {
          content: '✔';

          color: ${theme.colors.white};
        }

        background-color: ${theme.colors.blue600};
      }
    }

    span {
      margin-left: ${theme.spacings[12]};

      display: block;

      cursor: pointer;
    }

    ${inverted && wrapperModifiers.left()}
  `}
`;
