import axios from 'axios';
import { Environment } from 'shared/environments';
import { IbgeGiniBrasil } from '../JsonType/Type';

type GetData = {
  setDataIbgeGiniBrasil: React.Dispatch<
    React.SetStateAction<(string | number)[][]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const MockApi = axios.create({
  baseURL: Environment.API_MOCK,
});

export async function getDataIbgeGiniBrasil({
  setDataIbgeGiniBrasil,
  setLoading,
  setError,
}: GetData) {
  await MockApi.get(`ibgeGiniBrasil`)
    .then(response => {
      const responseData = response.data as IbgeGiniBrasil[];
      const graph = [
        ['País', 'Ano 1991', 'Ano 2000', 'Ano 2010'],
        [
          responseData[0].pais,
          responseData[0].gini_1991,
          responseData[0].gini_2000,
          responseData[0].gini_2010,
        ],
      ];
      setDataIbgeGiniBrasil(graph);
    })
    .catch(() => {
      setError(true);
      setLoading(false);
    });
}
