import React, { SetStateAction, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { BiX } from 'react-icons/bi';
import { TbMapSearch } from 'react-icons/tb';
import { AreasQuilombolas } from './Cases/AreasEspeciais/AreasQuilombolas';
import { FaixaDeFronteira } from './Cases/AreasEspeciais/FaixaDeFronteira';
import { AssentamentoFederal } from './Cases/AreasEspeciais/AssentamentoFederal';
import { AssentamentoReconhecimento } from './Cases/AreasEspeciais/AssentamentoReconhecimento';
import { AssentamentoTotal } from './Cases/AreasEspeciais/AssentamentoTotal';
import { ImovelSIGEFPrivado } from './Cases/ConhecimentoTerritorio/ImovelSigefPrivado';
import { ImovelSIGEFPublico } from './Cases/ConhecimentoTerritorio/ImovelSigefPublico';
import { ImovelSIGEFTotal } from './Cases/ConhecimentoTerritorio/ImovelSigefTotal';
import { ImovelSNCIPrivado } from './Cases/ConhecimentoTerritorio/ImovelSnciPrivado';
import { ImovelSNCIPublico } from './Cases/ConhecimentoTerritorio/ImovelSnciPublico';
import { ImovelSNCITotal } from './Cases/ConhecimentoTerritorio/ImovelSnciTotal';
import { CadNacioFlorestPub } from './Cases/AreasEspeciais/CadNacioFlorestPub';
import { CavidadesNaturais } from './Cases/AreasEspeciais/CavidadesNaturais';
import { TerrasIndigenas } from './Cases/AreasEspeciais/TerrasIndigenas';
import { UnidadesConservacaoPI } from './Cases/AreasEspeciais/UnidadesConservacaoPI';
import { UnidadesConservacaoUS } from './Cases/AreasEspeciais/UnidadesConservacaoUS';
import { BarragemMineracao } from './Cases/CamadasComplementares/BarragemMineracao';
import { ReservasGarimpeiras } from './Cases/CamadasComplementares/ReservasGarimpeiras';
import { ServidoesMinerarias } from './Cases/CamadasComplementares/ServidoesMinerarias';
import { SitiosArqueologicos } from './Cases/CamadasComplementares/SitiosArqueologicos';
import { AfloramentoGeologico } from './Cases/ConhecimentoGeocientifico/AfloramentoGeologico';
import { AreasBatimetrica } from './Cases/ConhecimentoGeocientifico/AreasBatimetrica';
import { BaciasSedimentares } from './Cases/ConhecimentoGeocientifico/BaciasSedimentares';
import { ContatoAtlasHidrologeologico } from './Cases/ConhecimentoGeocientifico/ContatoAtlasHidrologeologico';
import { CurvasBatimetrica } from './Cases/ConhecimentoGeocientifico/CurvasBatimetrica';
import { Geocronologia } from './Cases/ConhecimentoGeocientifico/Geocronologia';
import { GeoquimicaIntegrada } from './Cases/ConhecimentoGeocientifico/GeoquimicaIntegrada';
import { GeoquimicaBaixaDens } from './Cases/ConhecimentoGeocientifico/GeoquimicaBaixaDens';
import { LevantamentoAero1k } from './Cases/ConhecimentoGeocientifico/LevantamentoAero1k';
import { LevantamentoAero2k } from './Cases/ConhecimentoGeocientifico/LevantamentoAero2k';
import { LevantamentoAero3k } from './Cases/ConhecimentoGeocientifico/LevantamentoAero3k';
import { LevantamentoAero4k } from './Cases/ConhecimentoGeocientifico/LevantamentoAero4k';
import { LevantamentoLito100k } from './Cases/ConhecimentoGeocientifico/LevantamentoLito100k';
import { LevantamentoLito1m } from './Cases/ConhecimentoGeocientifico/LevantamentoLito1m';
import { LevantamentoLito250k } from './Cases/ConhecimentoGeocientifico/LevantamentoLito250k';
import { LevantamentoLito25k } from './Cases/ConhecimentoGeocientifico/LevantamentoLito25k';
import { LevantamentoLito50k } from './Cases/ConhecimentoGeocientifico/LevantamentoLito50k';
import { OcorrenciasFossiliferas } from './Cases/ConhecimentoGeocientifico/OcorrenciasFossiliferas';
import { OcorrenciasMinerais } from './Cases/ConhecimentoGeocientifico/OcorrenciasMinerais';
import { Petrografia } from './Cases/ConhecimentoGeocientifico/Petrografia';
import { PocosRimas } from './Cases/ConhecimentoGeocientifico/PocosRimas';
import { PoligonoAtlasHidrologeologico } from './Cases/ConhecimentoGeocientifico/PoligonoAtlasHidrologeologico';
import { AreasUrbanas100a300 } from './Cases/ConhecimentoTerritorio/AreasUrbanas100a300';
import { AreasUrbanasMais300 } from './Cases/ConhecimentoTerritorio/AreasUrbanasMais300';
import { BaciasHidrograficas } from './Cases/ConhecimentoTerritorio/BaciasHidrograficas';
import { BaseHidroOttocodificada } from './Cases/ConhecimentoTerritorio/BaseHidroOttocodificada';
import { Biomas } from './Cases/ConhecimentoTerritorio/Biomas';
import { CoberturaUsoSolo } from './Cases/ConhecimentoTerritorio/CoberturaUsoSolo';
import { DensidadePopulacional } from './Cases/ConhecimentoTerritorio/DensidadePopulacional';
import { Idh } from './Cases/ConhecimentoTerritorio/Idh';
import { IndiceGini } from './Cases/ConhecimentoTerritorio/IndiceGini';
import { LimiteEstados } from './Cases/ConhecimentoTerritorio/LimiteEstados';
import { LimiteMacrorregioes } from './Cases/ConhecimentoTerritorio/LimiteMacrorregioes';
import { LimiteMesorregioes } from './Cases/ConhecimentoTerritorio/LimiteMesorregioes';
import { LimiteMicrorregioes } from './Cases/ConhecimentoTerritorio/LimiteMicrorregioes';
import { LimiteMunicipio } from './Cases/ConhecimentoTerritorio/LimiteMunicipio';
import { Pib } from './Cases/ConhecimentoTerritorio/Pib';
import { RendaNominalMensal } from './Cases/ConhecimentoTerritorio/RendaNominalMensal';
import { RendaMaiores10Anos } from './Cases/ConhecimentoTerritorio/RendaMaiores10Anos';
import { RendaTrabalhoPrincipal } from './Cases/ConhecimentoTerritorio/RendaTrabalhoPrincipal';
import { RendaDomicilioPermanente } from './Cases/ConhecimentoTerritorio/RendaDomicilioPermanente';
import { ZonasClimaticas } from './Cases/ConhecimentoTerritorio/ZonasClimaticas';
import { ProcessosMinerarios } from './Cases/DireitosMinerarios/ProcessosMinerarios';
import { LinhaTransmissaoExistente } from './Cases/InfraestruturaEnergia/LinhaTransmissaoExistente';
import { LinhaTransmissaoPlanejado } from './Cases/InfraestruturaEnergia/LinhaTransmissaoPlanejado';
import { RepresasHidreletricas } from './Cases/InfraestruturaEnergia/RepresasHidreletricas';
import { UsinasHIdreletricasUHE } from './Cases/InfraestruturaEnergia/UsinasHIdreletricasUHE';
import { BlocosExploratorios } from './Cases/InfraestruturaPG/BlocosExploratorios';
import { CamposProducao } from './Cases/InfraestruturaPG/CamposProducao';
import { Gasodutos } from './Cases/InfraestruturaPG/Gasodutos';
import { Oleodutos } from './Cases/InfraestruturaPG/Oleodutos';
import { Pocos } from './Cases/InfraestruturaPG/Pocos';
import { AerodromosPrivados } from './Cases/InfraestruturaTransporte/AerodromosPrivados';
import { AerodromosPublicos } from './Cases/InfraestruturaTransporte/AerodromosPublicos';
import { Cabotagem } from './Cases/InfraestruturaTransporte/Cabotagem';
import { Ferrovias } from './Cases/InfraestruturaTransporte/Ferrovias';
import { Helipontos } from './Cases/InfraestruturaTransporte/Helipontos';
import { Hidrovias } from './Cases/InfraestruturaTransporte/Hidrovias';
import { Portos } from './Cases/InfraestruturaTransporte/Portos';
import { Rodovias } from './Cases/InfraestruturaTransporte/Rodovias';
import { MapaIndicedosMapasGeologicosEstaduais } from './Cases/ConhecimentoGeocientifico/MapaIndicedosMapasGeologicosEstaduais';
import { MapeamentoGeologicoGeologyPDAC2023 } from './Cases/ConhecimentoGeocientifico/MapeamentoGeologicoGeologyPDAC2023';
import * as Styled from './styles';

interface IFeaturesModal {
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line
  features: any;
  index: number;
}

export function FeaturesModal({ setIsOpen, features, index }: IFeaturesModal) {
  // eslint-disable-next-line
  const renderFeaturesProperties = (feature: any) => {
    const id = feature?.id.split('.')[0];
    switch (id) {
      case 'vw_cprm_petrogf':
        return <Petrografia properties={feature.properties} />;
      case 'vw_cprm_aflor_geo':
        return <AfloramentoGeologico properties={feature.properties} />;
      case 'vw_cprm_geocron':
        return <Geocronologia properties={feature.properties} />;
      case 'vw_cprm_geoq_int':
        return <GeoquimicaIntegrada properties={feature.properties} />;
      case 'vw_cprm_geoq_b_d':
        return <GeoquimicaBaixaDens properties={feature.properties} />;
      case 'vw_cprm_ocorr_fossi':
        return <OcorrenciasFossiliferas properties={feature.properties} />;
      case 'vw_cprm_ocorr_min':
        return <OcorrenciasMinerais properties={feature.properties} />;
      case 'vw_cprm_cur_bat':
        return <CurvasBatimetrica properties={feature.properties} />;
      case 'vw_cprm_a_bat':
        return <AreasBatimetrica properties={feature.properties} />;
      case 'vw_cprm_bac_sed':
        return <BaciasSedimentares properties={feature.properties} />;
      case 'vw_cprm_lev_litostrat_25000':
        return <LevantamentoLito25k properties={feature.properties} />;
      case 'vw_cprm_lev_litostrat_50000':
        return <LevantamentoLito50k properties={feature.properties} />;
      case 'vw_cprm_lev_litostrat_100000':
        return <LevantamentoLito100k properties={feature.properties} />;
      case 'vw_cprm_lev_litostrat_250000':
        return <LevantamentoLito250k properties={feature.properties} />;
      case 'vw_cprm_lev_litostrat_1000000':
        return <LevantamentoLito1m properties={feature.properties} />;
      case 'vw_cprm_lev_aerogf_1':
        return <LevantamentoAero1k properties={feature.properties} />;
      case 'vw_cprm_lev_aerogf_2':
        return <LevantamentoAero2k properties={feature.properties} />;
      case 'vw_cprm_lev_aerogf_3':
        return <LevantamentoAero3k properties={feature.properties} />;
      case 'vw_cprm_lev_aerogf_4':
        return <LevantamentoAero4k properties={feature.properties} />;
      case 'vw_cprm_pocos_rima':
        return <PocosRimas properties={feature.properties} />;
      case 'vw_cprm_cont_a_hidrog':
        return <ContatoAtlasHidrologeologico properties={feature.properties} />;
      case 'vw_cprm_pol_a_hidrog':
        return <PoligonoAtlasHidrologeologico properties={feature.properties} />;
      case 'mvw_processos_minerarios_ativos':
        return <ProcessosMinerarios properties={feature.properties} />;
      case 'vw_icmbio_cav_nat':
        return <CavidadesNaturais properties={feature.properties} />;
      case 'vw_incra_area_qlb':
        return <AreasQuilombolas properties={feature.properties} />;
      case 'vw_ibge_faixa_front':
        return <FaixaDeFronteira properties={feature.properties} />;
      case 'vw_incra_ast_fed':
        return <AssentamentoFederal properties={feature.properties} />;
      case 'vw_incra_ast_rec':
        return <AssentamentoReconhecimento properties={feature.properties} />;
      case 'vw_incra_ast':
        return <AssentamentoTotal properties={feature.properties} />;
      case 'vw_incra_sigef_prv':
        return <ImovelSIGEFPrivado properties={feature.properties} />;
      case 'vw_incra_sigef_pbl':
        return <ImovelSIGEFPublico properties={feature.properties} />;
      case 'vw_incra_sigef_total':
        return <ImovelSIGEFTotal properties={feature.properties} />;
      case 'vw_incra_imv_cert_snci_prv':
        return <ImovelSNCIPrivado properties={feature.properties} />;
      case 'vw_incra_imv_cert_snci_pbl':
        return <ImovelSNCIPublico properties={feature.properties} />;
      case 'vw_incra_imv_cert_snci_total':
        return <ImovelSNCITotal properties={feature.properties} />;
      case 'vw_funai_ter_indg':
        return <TerrasIndigenas properties={feature.properties} />;
      case 'vw_mma_uc_us':
        return <UnidadesConservacaoUS properties={feature.properties} />;
      case 'vw_mma_uc_pi':
        return <UnidadesConservacaoPI properties={feature.properties} />;
      case 'vw_sfb_cnfp':
        return <CadNacioFlorestPub properties={feature.properties} />;
      case 'vw_ibge_lim_uf':
        return <LimiteEstados properties={feature.properties} />;
      case 'vw_ibge_lim_macror':
        return <LimiteMacrorregioes properties={feature.properties} />;
      case 'vw_ibge_lim_mesor':
        return <LimiteMesorregioes properties={feature.properties} />;
      case 'vw_ibge_lim_micror':
        return <LimiteMicrorregioes properties={feature.properties} />;
      case 'vw_ibge_lim_mun':
        return <LimiteMunicipio properties={feature.properties} />;
      case 'vw_ibge_aurb_100a300hab':
        return <AreasUrbanas100a300 properties={feature.properties} />;
      case 'vw_ibge_aurb_mais300hab':
        return <AreasUrbanasMais300 properties={feature.properties} />;
      case 'vw_ibge_cob_uso_ter':
        return <CoberturaUsoSolo properties={feature.properties} />;
      case 'vw_ibge_dens_pop':
        return <DensidadePopulacional properties={feature.properties} />;
      case 'vw_ibge_idh':
        return <Idh properties={feature.properties} />;
      case 'vw_ibge_ind_gini':
        return <IndiceGini properties={feature.properties} />;
      case 'vw_ibge_pib':
        return <Pib properties={feature.properties} />;
      case 'vw_ibge_valor_rend_nom_med_mensal':
        return <RendaNominalMensal properties={feature.properties} />;
      case 'vw_ibge_valor_rend_nom_med_mensal_10_mais':
        return <RendaMaiores10Anos properties={feature.properties} />;
      case 'vw_ibge_valor_rend_nom_med_mensal_10_mais_rend_trab_princ':
        return <RendaTrabalhoPrincipal properties={feature.properties} />;
      case 'vw_ibge_valor_rend_nom_med_mens_mun_par_perm':
        return <RendaDomicilioPermanente properties={feature.properties} />;
      case 'vw_ibge_biomas':
        return <Biomas properties={feature.properties} />;
      case 'vw_ana_bho_ach_lv3':
        return <BaciasHidrograficas />;
      case 'vw_ana_bho_2017_cd':
        return <BaseHidroOttocodificada />;
      case 'vw_ibge_zon_clim':
        return <ZonasClimaticas properties={feature.properties} />;
      case 'vw_epe_lin_trans_exist':
        return <LinhaTransmissaoExistente properties={feature.properties} />;
      case 'vw_epe_lin_trans_plan':
        return <LinhaTransmissaoPlanejado properties={feature.properties} />;
      case 'vw_aneel_uhe':
        return <UsinasHIdreletricasUHE properties={feature.properties} />;
      case 'vw_ana_rep_hdlt':
        return <RepresasHidreletricas properties={feature.properties} />;
      case 'vw_anac_aerod_prv':
        return <AerodromosPrivados properties={feature.properties} />;
      case 'vw_anac_aerod_pbl':
        return <AerodromosPublicos properties={feature.properties} />;
      case 'vw_anac_helipt':
        return <Helipontos properties={feature.properties} />;
      case 'vw_min_infra_port':
        return <Portos properties={feature.properties} />;
      case 'vw_min_infra_cabot':
        return <Cabotagem properties={feature.properties} />;
      case 'vw_min_infra_frv':
        return <Ferrovias properties={feature.properties} />;
      case 'vw_min_infra_hdrv':
        return <Hidrovias properties={feature.properties} />;
      case 'vw_min_infra_rdv':
        return <Rodovias properties={feature.properties} />;
      case 'vw_anm_gasdt':
        return <Gasodutos properties={feature.properties} />;
      case 'vw_anm_oleodt':
        return <Oleodutos properties={feature.properties} />;
      case 'vw_anp_bloco_expl':
        return <BlocosExploratorios properties={feature.properties} />;
      case 'vw_anp_campos_prod':
        return <CamposProducao properties={feature.properties} />;
      case 'vw_anp_pocos':
        return <Pocos properties={feature.properties} />;
      case 'vw_anm_barg_min':
        return <BarragemMineracao properties={feature.properties} />;
      case 'vw_iphan_sitio_arq':
        return <SitiosArqueologicos properties={feature.properties} />;
      case 'vw_anm_reserv_garip':
        return <ReservasGarimpeiras properties={feature.properties} />;
      case 'vw_anm_svd_min':
        return <ServidoesMinerarias properties={feature.properties} />;
      case 'vw_crpm_mapa_indc_geologico_est':
        return <MapaIndicedosMapasGeologicosEstaduais properties={feature.properties} />;
      case 'vw_cprm_map_geo_50k':
        return <MapeamentoGeologicoGeologyPDAC2023 properties={feature.properties} />;
      case 'vw_cprm_map_geo_100k':
        return <MapeamentoGeologicoGeologyPDAC2023 properties={feature.properties} />;
      case 'vw_cprm_map_geo_250k':
        return <MapeamentoGeologicoGeologyPDAC2023 properties={feature.properties} />;
      case 'vw_cprm_map_geo_1M':
        return <MapeamentoGeologicoGeologyPDAC2023 properties={feature.properties} />;
      default:
        return setIsOpen(false);
    }
  };

  return (
    <div className="content">
      <>{renderFeaturesProperties(features[index])}</>
    </div>
  );
}
