import React, { memo } from 'react';
import { IValuesForFilterType } from 'shared/models/ValuesForFilter';
import * as Styled from './styles';
import ColumnDashboard from './ColumnDashboard';
import ColumnFavoriteGraph from './ColumnFavoriteGraph';
import ColumnOcultGraph from './ColumnOcultGraph';

type ColumnsOfDashBoardProps = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  filterValue: IValuesForFilterType;
  setFilterValue: React.Dispatch<React.SetStateAction<IValuesForFilterType>>;
  resetFilter: boolean;
  setResetFilter: React.Dispatch<React.SetStateAction<boolean>>;
  isClickedFilter: boolean;
  setIsClickedFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

function ColumnsOfDashBoard({
  isClickedTopMenu,
  setSelectedOptionChart,
  filterValue,
  setFilterValue,
  resetFilter,
  setResetFilter,
  isClickedFilter,
  setIsClickedFilter,
}: ColumnsOfDashBoardProps) {
  return (
    <Styled.Wrapper isClicked={isClickedTopMenu}>
      {isClickedTopMenu === 'dashboard' && (
        <ColumnDashboard
          isClickedTopMenu={isClickedTopMenu}
          setSelectedOptionChart={setSelectedOptionChart}
          filterValue={filterValue}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
          isClickedFilter={isClickedFilter}
          setIsClickedFilter={setIsClickedFilter}
        />
      )}
      {isClickedTopMenu === 'favoriteGraph' && (
        <ColumnFavoriteGraph
          isClickedTopMenu={isClickedTopMenu}
          setSelectedOptionChart={setSelectedOptionChart}
          setFilterValue={setFilterValue}
        />
      )}
      {isClickedTopMenu === 'ocultGraph' && (
        <ColumnOcultGraph
          isClickedTopMenu={isClickedTopMenu}
          setSelectedOptionChart={setSelectedOptionChart}
          setFilterValue={setFilterValue}
        />
      )}
    </Styled.Wrapper>
  );
}

export default memo(ColumnsOfDashBoard);
