import React, { memo } from 'react';
import MaterialDesignBarCharts from 'screens/Dashboard/GraphicsModel/BarCharts/MaterialDesignBarCharts';
import MaterialDesignHorizontalBarChart from 'screens/Dashboard/GraphicsModel/BarCharts/MaterialDesignHorizontalBarChart';
import StackedBarChartWithMultipleSeries from 'screens/Dashboard/GraphicsModel/BarCharts/StackedBarChartWithMultipleSeries';
import ComboChart from 'screens/Dashboard/GraphicsModel/ColumnChart/ComboChart/indexx';
import RegionGeoCharts from 'screens/Dashboard/GraphicsModel/GeoChart/RegionGeoCharts';
import { LoadingGraphics } from './LoadingGraphics';

type ChartsType = {
  chartModel:
    | 'RegionGeoCharts'
    | 'MaterialDesignBarCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart';
  data: (string | number)[][];
};

function Charts({ chartModel, data }: ChartsType) {
  return (
    <div className="graphComponentWrapper">
      {chartModel === 'RegionGeoCharts' && data.length > 0 ? (
        <RegionGeoCharts data={data} />
      ) : (
        chartModel === 'RegionGeoCharts' && <LoadingGraphics />
      )}

      {chartModel === 'MaterialDesignBarCharts' && data.length > 0 ? (
        <MaterialDesignBarCharts data={data} />
      ) : (
        chartModel === 'MaterialDesignBarCharts' && <LoadingGraphics />
      )}

      {chartModel === 'DiffColumnChart' && data.length > 0 ? (
        <ComboChart data={data} />
      ) : (
        chartModel === 'DiffColumnChart' && <LoadingGraphics />
      )}

      {chartModel === 'MaterialDesignHorizontalBarChart' && data.length > 0 ? (
        <MaterialDesignHorizontalBarChart data={data} />
      ) : (
        chartModel === 'MaterialDesignHorizontalBarChart' && <LoadingGraphics />
      )}

      {chartModel === 'StackedBarChartWithMultipleSeries' && data.length > 0 ? (
        <StackedBarChartWithMultipleSeries data={data} />
      ) : (
        chartModel === 'StackedBarChartWithMultipleSeries' && (
          <LoadingGraphics />
        )
      )}
    </div>
  );
}

export default memo(Charts);
