import { ITheme } from 'contexts/@types/Layers';

const dados: ITheme[] = [
  {
    id_tema: 1,
    nm_tema: 'Avaliação de potencial geoeconômico',
    subtema: [
      {
        id_subtema: 1,
        nm_subtema: 'APGR (Carajás)',
      },
      {
        id_subtema: 2,
        nm_subtema: 'Quadrilátero ferrífero',
      },
      {
        id_subtema: 3,
        nm_subtema: 'Outros',
      },
    ],
  },
  {
    id_tema: 2,
    nm_tema: 'Estudos geoeconômicos estaduais',
    subtema: [
      {
        id_subtema: 1,
        nm_subtema: "EGE's diversos estudos",
      },
      {
        id_subtema: 2,
        nm_subtema: 'Outros',
      },
    ],
  },
  {
    id_tema: 3,
    nm_tema: 'Estudos setoriais',
    subtema: [
      {
        id_subtema: 1,
        nm_subtema: 'PNF',
      },
      {
        id_subtema: 2,
        nm_subtema: 'EGCI TASK 2',
      },
      {
        id_subtema: 3,
        nm_subtema: 'Outros',
      },
    ],
  },
];

export default dados;
