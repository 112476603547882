import React from 'react';
import { TbReportSearch, TbBuildingCommunity } from 'react-icons/tb';
import { AiTwotoneGold } from 'react-icons/ai';
import { GiMining, GiMiningHelmet, GiGoldMine } from 'react-icons/gi';

export default [
  {
    identifier: [
      'Pesquisa',
      'Exploration',
      'Exploracion',
      'Requerimentos para pesquisa',
      'Exploration requirements',
      'Requisitos de exploración',
    ],
    icon: <TbReportSearch />,
  },
  {
    identifier: ['Direitos', 'Rights', 'Derechos'],
    icon: <GiMiningHelmet />,
  },
  {
    identifier: [
      'Lavra',
      'Mining',
      'Minera',
      'Expectativa de outorga de lavra - outros regimes',
      'Expectation of mining concession - other regimes',
      'Expectativa de minera - otros regímenes',
    ],
    icon: <GiMining />,
  },
  {
    identifier: ['Produtores', 'Producing', 'Productores'],
    icon: <GiGoldMine />,
  },
  {
    identifier: [
      'Disponibilidade',
      'Available',
      'Disponible',
      'Apto para Disponibilidade',
      'Eligible for availability',
      'Elegibles para disponibilidad',
    ],
    icon: <AiTwotoneGold />,
  },
  {
    identifier: ['Municipio', 'Municipality', 'Municipio'],
    icon: <TbBuildingCommunity />,
  },
];
