import React from 'react';

interface IProperties {
  properties: {
    projeto: string;
    cod_folha: string;
    nome_folha: string;
    execucao: string;
    ano_mapa: string;
    escala: string;
    enquadrante: string;
    ciclo_map: string;
    url_rigeo: string;
    download_mapa: string;
    download_sig: string;
    download_relatorio: string;
    foto: string;
  };
}

export function MapeamentoGeologicoGeologyPDAC2023({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Mapeamento Geológico (Geology - PDAC 2023)</strong>
      </h4>
      <p>
        <strong>Projeto: </strong>
        {properties.projeto ? properties.projeto : 'Não informado'}
      </p>
      <p>
        <strong>Código da Folha: </strong>
        {properties.cod_folha ? properties.cod_folha : 'Não informado'}
      </p>
      <p>
        <strong>Nome da Folha: </strong>
        {properties.nome_folha ? properties.nome_folha : 'Não informado'}
      </p>
      <p>
        <strong>Instituição Executora: </strong>
        {properties.execucao ? properties.execucao : 'Não informado'}
      </p>
      <p>
        <strong>Ano de Publicação: </strong>
        {properties.ano_mapa ? properties.ano_mapa : 'Não informado'}
      </p>
      <p>
        <strong>Escala: </strong>
        {properties.escala ? properties.escala : 'Não informado'}
      </p>
      <p>
        <strong>Ciclo de Mapeamento Fase: </strong>
        {properties.ciclo_map ? properties.ciclo_map : 'Não informado'}
      </p>
      <p>
        <strong>Download: </strong>
        {properties.url_rigeo ? (
          <a href={properties.url_rigeo} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>Foto: </strong>
        {properties.foto ? (
          <a href={properties.foto} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>ENQUADRANTE: </strong>
        {properties.enquadrante ? properties.enquadrante : 'Não informado'}
      </p>
      <p>
        <strong>DOWNLOAD_MAPA: </strong>
        {properties.download_mapa ? (
          <a href={properties.download_mapa} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>DOWNLOAD_SIG: </strong>
        {properties.download_sig ? (
          <a href={properties.download_sig} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
      <p>
        <strong>DOWNLOAD_RELATORIO: </strong>
        {properties.download_relatorio ? (
          <a href={properties.download_relatorio} target="blank">
            Mais info
          </a>
        ) : (
          'Não informado'
        )}
      </p>
    </div>
  );
}
