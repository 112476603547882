import React from 'react';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import { HiHome } from 'react-icons/hi';

export default [
  {
    index: 1,
    label: 'mapPage.sideBar.navigationBottomBar.home',
    icon: <HiHome />,
  },
  {
    index: 2,
    label: 'mapPage.sideBar.navigationBottomBar.layers',
    icon: <MdIcons.MdLayers />,
  },
  {
    index: 3,
    label: 'mapPage.sideBar.navigationBottomBar.charts',
    icon: <RiIcons.RiBarChart2Fill color="#696969" />,
    disabled: true,
  },
  {
    index: 4,
    label: 'mapPage.sideBar.navigationBottomBar.organization',
    icon: <RiIcons.RiBankLine />,
  },
];
