import axios from 'axios';
import { Environment } from 'shared/environments';
import { DataTypeCrescimentoAnual } from '../JsonType/Type';

type GetData = {
  valueForFilter?: string;
  setDataCrescimentoAnual: React.Dispatch<
    React.SetStateAction<(string | number)[][]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const Api = axios.create({
  baseURL: Environment.API_URL_BASE,
});

export async function getDataCrescimentoAnual({
  valueForFilter,
  setDataCrescimentoAnual,
  setLoading,
  setError,
}: GetData) {
  if (valueForFilter === undefined) {
    await Api.get(`processoConsumidor/`)
      .then(response => {
        const responseData = response.data as DataTypeCrescimentoAnual[];

        const dataFinalyWithoutFilter = responseData.map(posicao =>
          posicao.year_2021 !== null
            ? [
                posicao.country_name === 'Russian Federation'
                  ? 'RU'
                  : posicao.country_name,
                posicao.year_2021,
              ]
            : [posicao.country_name, 0],
        );

        const arrayTitle = ['Ano', 'Crescimento anual de preços ao consumidor'];
        dataFinalyWithoutFilter.unshift(arrayTitle);

        setDataCrescimentoAnual(dataFinalyWithoutFilter);
      })
      .catch(() => {
        setDataCrescimentoAnual([]);
        setError(true);
        setLoading(false);
      });
  } else {
    await Api.get(`processoConsumidor/`)
      .then(response => {
        const responseData = response.data as DataTypeCrescimentoAnual[];
        const stringBrazil = valueForFilter === 'Brasil' ? 'Brazil' : '';

        const filterValue = responseData.filter(
          posicao => posicao.country_name === stringBrazil,
        );

        if (filterValue.length > 0) {
          const dataWithFilter = [
            [filterValue[0].country_name, filterValue[0].year_2021 ?? ''],
          ];
          const arrayTitle = [
            'Ano',
            'Crescimento anual de preços ao consumidor - Brasil',
          ];
          dataWithFilter.unshift(arrayTitle);
          setDataCrescimentoAnual(dataWithFilter);
        } else {
          setDataCrescimentoAnual([]);
        }
      })
      .catch(() => {
        setDataCrescimentoAnual([]);
        setError(true);
        setLoading(false);
      });
  }
}
