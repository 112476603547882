import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]} ${theme.spacings[24]};

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem;
      background-color: ${theme.colors.gray200};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${transparentize(0.5, theme.colors.indigo800)};
    }

    > header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme.spacings[10]};

      > h1 {
        font-size: ${theme.font.sizes.lg};
        font-weight: ${theme.font.weights.bold};
        line-height: 1;

        @media (max-width: 600px) {
          font-size: ${theme.font.sizes.md};
        }

        @media (max-width: 400px) {
          font-size: ${theme.font.sizes.sm};
        }
      }
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[10]};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: calc(100% - 35px);

    .emptyLayer {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .emptyLayer h3 {
      font-size: ${theme.font.sizes.sm};
      font-weight: ${theme.font.weights.regular};
    }
  `}
`;
