import React from 'react';
import gemas from 'assets/icone/themes/gemas.png';
import gemasColor from 'assets/icone/themes/gemas_color.png';
import energiticos from 'assets/icone/themes/energiticos.png';
import energiticosColor from 'assets/icone/themes/energiticos_color.png';
import industriais from 'assets/icone/themes/industriais.png';
import industriaisColor from 'assets/icone/themes/industriais_color.png';
import metalicos from 'assets/icone/themes/metalicos.png';
import metalicosColor from 'assets/icone/themes/metalicos_color.png';
import nao_metalicos from 'assets/icone/themes/nao_metalicos.png';
import nao_metalicosColor from 'assets/icone/themes/nao_metalicos_color.png';

export default [
  {
    id: 106,
    image: gemas,
    imageColor: gemasColor,
    color: '#00AAAA',
  },
  {
    id: 107,
    image: energiticos,
    imageColor: energiticosColor,
    color: '#FF8A00',
  },
  {
    id: 108,
    image: industriais,
    imageColor: industriaisColor,
    color: '#01C638',
  },
  {
    id: 109,
    image: metalicos,
    imageColor: metalicosColor,
    color: '#D3202C',
  },
  {
    id: 110,
    image: nao_metalicos,
    imageColor: nao_metalicosColor,
    color: '#9747FF',
  },
];
