import React from 'react';

interface IProperties {
  properties: {
    name: string;
    descript: string;
  };
}

export function BarragemMineracao({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Barragens de Mineração</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.name ? properties.name : 'Não informado'}
      </p>
      <p>
        <strong>Descrição: </strong>{' '}
        {properties.descript ? properties.descript : 'Não informado'}
      </p>
    </div>
  );
}
