import React from 'react';

interface IProperties {
  properties: {
    nm_gasdt: string;
    sg_prop: string;
  };
}

export function Gasodutos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Gasodutos</strong>
      </h4>
      <p>
        <strong>Nome do Gasoduto: </strong>{' '}
        {properties.nm_gasdt ? properties.nm_gasdt : 'Não informado'}
      </p>
      <p>
        <strong>SG Proprietário: </strong>{' '}
        {properties.sg_prop ? properties.sg_prop : 'Não informado'}
      </p>
    </div>
  );
}
