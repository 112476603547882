import React from 'react';

interface IProperties {
  properties: {
    n_campo_lamina: string;
    notas: string;
    rocha: string;
  };
}

export function Petrografia({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Petrografia</strong>
      </h4>
      <p>
        <strong>Nº de campo da lâmina: </strong>
        {properties.n_campo_lamina
          ? properties.n_campo_lamina
          : 'Não informado'}
      </p>
      <p>
        <strong>Notas: </strong>
        {properties.notas ? properties.notas : 'Não informado'}
      </p>
      <p>
        <strong>Rocha: </strong>
        {properties.rocha ? properties.rocha : 'Não informado'}
      </p>
    </div>
  );
}
