import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[32]} ${theme.spacings[24]};

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }

    > h1 {
      font-size: ${theme.font.sizes.lg};
      font-weight: ${theme.font.weights.bold};
      line-height: 1;
      text-align: center;

      @media (max-width: 400px) {
        font-size: ${theme.font.sizes.sm};
        margin-bottom: ${theme.spacings[10]};
      }
    }

    .titulo {
      padding-top: ${theme.spacings[20]};

      @media (max-width: 400px) {
        padding-top: ${theme.spacings[6]};
        margin-bottom: ${theme.spacings[10]};
      }
    }

    hr {
      margin: ${theme.spacings[16]} 0;
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[10]};
    }
  `}
`;

interface ThemeGridProps {
  colunas?: string;
}

export const ThemeGrid = styled.div<ThemeGridProps>`
  ${({ theme, colunas }) => css`
    width: 100%;
    padding: ${theme.spacings[24]} 0;
    display: grid;
    grid-template-columns: ${colunas ? colunas : '1fr'};
    gap: ${theme.spacings[20]};

    @media (max-width: 800px) {
      gap: ${theme.spacings[16]};
    }

    @media (max-width: 400px) {
      padding: ${theme.spacings[4]};
      grid-template-columns: 1fr;
    }
  `}
`;

export const BuscaRapida = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: ${theme.spacings[24]} 0;
  gap: ${theme.spacings[20]};

  & div {
    flex: 1;
  }
`;

export const ExplorarMapa = styled(ThemeGrid)`
  grid-template-columns: 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

interface BackgroundProps {
  path?: string;
  color?: string;
  open?: boolean;
}

export const Button = styled.div<BackgroundProps>`
  ${({ theme, color }) => css`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
  `}
`;

export const ButtonFlex = styled(Button)`
  display: flex;
  padding: ${theme.spacings[16]};

  @media (max-width: 400px) {
    padding: 0;
  }
`;

export const Header = styled.div<BackgroundProps>`
  ${({ theme, color, open }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: ${theme.font.sizes.sm};
    font-weight: ${theme.font.weights.semiBold};
    word-wrap: break-word;
    background: transparent;
    border: 0;
    color: #23669f;
    border-radius: 20px;
    cursor: pointer;

    p {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0 auto;
      text-align: center;
      color: ${open ? color : '#23669f'};
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    a {
      width: 100%;
      text-align: center;
      text-decoration: underline;
    }

    &:hover p {
      color: ${color};
    }

    .icone {
      visibility: hidden;
    }

    &:hover .icone {
      visibility: visible;
    }

    .icone {
      width: 0px;
      height: ${theme.spacings[64]};
      transition: 0.5s;
    }

    &.default {
      padding: ${theme.spacings[24]};

      @media (max-width: 400px) {
        padding: ${theme.spacings[16]};
      }
    }

    &:hover .icone {
      transition: 0.5s;
      width: ${open ? '0px' : '80px'};
    }

    .tematicas {
      height: 0px;
    }

    &:hover .tematicas {
      transition: 1s;
      height: 100%;
    }

    @media (max-width: 900px) {
      font-size: ${theme.font.sizes.md};
    }

    @media (max-width: 400px) {
      justify-content: center;
      font-size: ${theme.font.sizes.xs};
      height: ${theme.spacings[48]};

      &:hover .icone {
        visibility: hidden;
      }

      p {
        width: 100%;
      }
    }
  `}
`;

export const Icone = styled.span<BackgroundProps>`
  ${({ theme, path, color }) => css`
    width: 100px;
    height: ${theme.spacings[72]};
    background-color: ${color};
    background-image: url(${path});
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  `}
`;

export const IconeColor = styled.span<BackgroundProps>`
  ${({ theme, path }) => css`
    width: ${theme.spacings[64]};
    height: ${theme.spacings[72]};
    background-image: url(${path});
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 400px) {
      height: ${theme.spacings[24]};
    }
  `}
`;

export const Tematicas = styled.ul<BackgroundProps>`
  ${({ theme, path, color }) => css`
    width: 100%;
    padding: 0 ${theme.spacings[16]} ${theme.spacings[16]} ${theme.spacings[16]};
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    color: ${color ? color : '#23669f'};
    list-style: none;

    @media (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const Tematica = styled.li<BackgroundProps>`
  ${({ theme, path, color }) => css`
    // width: 33.3%;
    padding: ${theme.spacings[10]} ${theme.spacings[6]};

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.xs};
    }

    a {
      display: block;
      position: relative;
      cursor: pointer;
    }

    a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.09em;
      background-color: ${color ? color : '#23669f'};
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    a:hover::after,
    a:focus::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }

    a {
      overflow: hidden;
    }

    a::after {
      opacity 1;
      transform: translate3d(-100%, 0, 0);
    }

    a:hover::after,
    a:focus::after{
      transform: translate3d(0, 0, 0);
    }
  `}
`;

export const Estudos = styled.div`
  ${({ theme }) => css`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding: ${theme.spacings[24]};

    h1 {
      font-size: ${theme.font.sizes.lg};
      font-weight: ${theme.font.weights.bold};
      line-height: 1;
      // text-align: center;

      @media (max-width: 400px) {
        font-size: ${theme.font.sizes.sm};
      }
    }
  `}
`;

export const Busca = styled.div`
  ${({ theme }) => css`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacings[24]} ${theme.spacings[12]};

    p {
      margin-top: auto;
      text-align: center;
    }

    @media (max-width: 600px) {
      font-size: ${theme.font.sizes.xs};
    }
  `}
`;

export const IconeBusca = styled.span<BackgroundProps>`
  ${({ theme, path }) => css`
    width: 100px;
    height: ${theme.spacings[80]};
    background-image: url(${path});
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: ${theme.spacings[6]};
  `}
`;

export const ButtonBusca = styled.button`
  ${({ theme }) => css`
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacings[8]} ${theme.spacings[12]};
    margin-top: auto;
    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};
    font-weight: ${theme.font.weights.semiBold};

    @media (max-width: 800px) {
      font-weight: ${theme.font.weights.regular};
    }
  `}
`;

export const ContentEconomiaMineral = styled.section`
  ${({ theme }) => css`
    padding: ${theme.spacings[24]};
    margin-bottom: ${theme.spacings[20]};
  `}

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings[20]};

    @media (max-width: 400px) {
      gap: ${theme.spacings[16]};
    }
  }

  h1 {
    text-align: center;
    font-size: ${theme.font.sizes.md};
    font-weight: ${theme.font.weights.bold};
    line-height: 1;
    margin-bottom: 24px;

    @media (max-width: 400px) {
      font-size: ${theme.font.sizes.sm};
    }
  }
`;

export const Estatistica = styled(ThemeGrid)`
  padding-bottom: 0;
`;
