import React, { memo, useEffect, useRef, useState } from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { SelectComponent } from './style';
import { changeText } from './functionsUsedInSelect/functions';

type SelectType = {
  width: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  selectedInput: boolean;
  valueOfInputInText: string;
  setValueOfInputInText: React.Dispatch<React.SetStateAction<string>>;
  referenceSelect?: React.RefObject<HTMLButtonElement>;
  inputIsActive: number[];
  setInputIsActive: React.Dispatch<React.SetStateAction<number[]>>;
  options: string[];
  multipleSelect: boolean;
  className?: string;
  notSelectFirstInputDefault: boolean;
};

function Select({
  width,
  onClick,
  selectedInput,
  valueOfInputInText,
  setValueOfInputInText,
  referenceSelect,
  inputIsActive,
  setInputIsActive,
  options,
  multipleSelect,
  className,
  notSelectFirstInputDefault,
}: SelectType) {
  const divElement = useRef<HTMLDivElement>(null);
  const [initialTextInputOneSelected, setInitialTextInputOneSelected] =
    useState('');

  useEffect(() => {
    setInitialTextInputOneSelected(valueOfInputInText);
  }, []);

  function validChecked(indexNumber: number) {
    const valid = inputIsActive.filter(posicao => posicao === indexNumber);

    if (valid.length > 0) {
      return true;
    }
    return false;
  }

  function clickInInput(index: number, text: string) {
    if (divElement.current) {
      const input = divElement.current.children[index]
        .children[0] as HTMLInputElement;
      input.click();
      changeText(
        text,
        valueOfInputInText,
        setValueOfInputInText,
        input.checked,
        multipleSelect,
      );
    }
  }

  function checkInputAndExecuteComand(
    target: HTMLInputElement,
    index: number,
    text: string,
  ) {
    if (target.checked && multipleSelect) {
      inputIsActive.push(index);
      setInputIsActive(inputIsActive);
      changeText(
        text,
        valueOfInputInText,
        setValueOfInputInText,
        target.checked,
        multipleSelect,
      );
    }
    if (target.checked === false && multipleSelect) {
      const array = inputIsActive.filter(
        posicaoArray => posicaoArray !== index,
      );
      setInputIsActive(array);
      changeText(
        text,
        valueOfInputInText,
        setValueOfInputInText,
        target.checked,
        multipleSelect,
      );
    }

    if (target.checked && !multipleSelect && notSelectFirstInputDefault) {
      const inputSelect = inputIsActive.filter(posicao => posicao === index);

      if (inputSelect.length === 0 && inputIsActive.length === 0) {
        inputIsActive.push(index);
        setInputIsActive(inputIsActive);
        setValueOfInputInText(text);
      } else {
        const newIndexInArray = [index];
        setInputIsActive(newIndexInArray);
        setValueOfInputInText(text);
      }
    }

    if (
      target.checked === false &&
      !multipleSelect &&
      notSelectFirstInputDefault
    ) {
      setInputIsActive([]);
      setValueOfInputInText('');
    }

    if (target.checked && !multipleSelect && !notSelectFirstInputDefault) {
      const inputSelect = inputIsActive.filter(posicao => posicao === index);

      if (inputSelect.length === 0) {
        const newInputValue = inputIsActive.map(() => index);
        setInputIsActive(newInputValue);
        setValueOfInputInText(text);
      }
    }

    if (
      target.checked === false &&
      !multipleSelect &&
      !notSelectFirstInputDefault
    ) {
      const newInputValue = inputIsActive.map(() => 0);
      setInputIsActive(newInputValue);
      setValueOfInputInText(initialTextInputOneSelected);
    }
  }

  return (
    <SelectComponent
      selectedInput={selectedInput}
      options={options}
      width={width}
      className={className ?? ''}
      multipleSelected={multipleSelect}
      notSelectFirstInputDefault={notSelectFirstInputDefault}
    >
      <button
        type="button"
        ref={referenceSelect}
        className="centralizedComponent"
        onClick={onClick}
      >
        <p title={valueOfInputInText ?? ''}>
          {valueOfInputInText.length > 0 ? valueOfInputInText : 'Selecione'}
        </p>
        <MdOutlineArrowDropDown className="arrowBottom" />
      </button>

      {selectedInput && (
        <div className="listOfSelections">
          <div className="centralizedList" ref={divElement}>
            {options.map((posicao, index) => (
              <div className="inputWrapper" key={Math.random() * 125436}>
                <input
                  type="checkbox"
                  readOnly
                  defaultChecked={validChecked(index)}
                  onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                    const target = event.target as HTMLInputElement;
                    checkInputAndExecuteComand(target, index, posicao);
                  }}
                />

                <button
                  type="button"
                  className="wrapperTextInput"
                  onClick={() => clickInInput(index, posicao)}
                >
                  <p title={posicao}>{posicao}</p>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </SelectComponent>
  );
}

export default memo(Select);
