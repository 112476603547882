import React from 'react';

interface IProperties {
  properties: {
    nm: string;
  };
}

export function ReservasGarimpeiras({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Reservas Garimpeiras</strong>
      </h4>
      <p>
        <strong>Nome: </strong>{' '}
        {properties.nm ? properties.nm : 'Não informado'}
      </p>
    </div>
  );
}
