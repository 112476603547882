export default [
  {
    id: 'e54d4cda-a689-4d88-a313-d96bb113e267',
    title: 'Estatísticas de Economia Mineral',
    links: [
      {
        id: '3a296b8b-1a76-468c-aa64-8e271efd63a5',
        nome: 'Atividades Econômicas do Setor Mineral - ANM',
        link: 'https://sgb.gov.br/p3m/estatisticas.html#ce_nacionais',
      },
      {
        id: '80b748d8-8d9e-4c4c-9af7-b80c2e6388d6',
        nome: 'Pesquisa e Produção',
        link: 'https://sgb.gov.br/p3m/estatisticas.html#pp_nacionais',
      },
      {
        id: 'b54b80d8-bb20-4385-9d30-d9995f656310',
        nome: 'Transformação Mineral',
        link: 'https://sgb.gov.br/p3m/estatisticas.html#tm_nacionais',
      },
      {
        id: '2b5b18f4-9c5e-4b02-b0ec-a5d92ce15af8',
        nome: 'Comércio Exterior',
        link: 'https://sgb.gov.br/p3m/estatisticas.html#exterior',
      },
    ],
  },
  {
    id: 'e637f588-1446-4536-a1ef-26ddf01e0d35',
    title: 'Gráficos de Economia Mineral',
    links: [
      {
        id: 'aec2979c-b116-4235-b015-d266bcb31242',
        link: '/dashboard',
        nome: 'Dashboard',
      },
    ],
  },
];
