import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  width: 98.5%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacings[32]};
  padding: ${theme.spacings[32]};

  .noExistsChart {
    width: 100%;
    height: calc(63vh);
    background-color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: ${theme.font.sizes.lg};
      font-family: ${theme.font.family.default};
      color: ${theme.colors.black};
    }
  }
`;

type GraphType = {
  chartModel:
    | 'RegionGeoCharts'
    | 'ColoringYourCharts'
    | 'LineIntervalCharts'
    | 'MaterialDesignBarCharts'
    | 'ExplodingASliceChart'
    | 'DonutChart'
    | 'SimpleExample'
    | 'MaterialDesignDualYCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'Distribution'
    | 'MaterialDesignLineChart'
    | 'DiffPieChart'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart';
};

function returnPaddingCorrect(
  chartModel:
    | 'RegionGeoCharts'
    | 'ColoringYourCharts'
    | 'LineIntervalCharts'
    | 'MaterialDesignBarCharts'
    | 'ExplodingASliceChart'
    | 'DonutChart'
    | 'SimpleExample'
    | 'MaterialDesignDualYCharts'
    | 'StackedBarChartWithMultipleSeries'
    | 'Distribution'
    | 'MaterialDesignLineChart'
    | 'DiffPieChart'
    | 'MaterialDesignHorizontalBarChart'
    | 'DiffColumnChart'
    | undefined,
) {
  if (chartModel === 'MaterialDesignBarCharts') {
    return theme.spacings[32];
  }

  if (chartModel === 'RegionGeoCharts') {
    return theme.spacings[4];
  }

  if (chartModel === 'ColoringYourCharts') {
    return '0';
  }

  return theme.spacings[10];
}

export const Graph = styled.div<GraphType>`
  ${({ chartModel }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacings[6]};
    padding: ${returnPaddingCorrect(chartModel)};
  `}
`;
