import React from 'react';

interface IProperties {
  properties: {
    tot2010: number;
    tothom2010: number;
    totmul2010: number;
  };
}

export function RendaMaiores10Anos({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Renda Nominal Mensal das Pessoas Maiores de 10 Anos</strong>
      </h4>
      <p>
        <strong>Valor Total no ano de 2010 (R$): </strong>
        {properties.tot2010 ? properties.tot2010.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Homens no ano de 2010 (R$): </strong>
        {properties.tothom2010 ? properties.tothom2010.toLocaleString() : 'Não informado'}
      </p>
      <p>
        <strong>Valor Mulheres no ano de 2010 (R$): </strong>
        {properties.totmul2010 ? properties.totmul2010.toLocaleString() : 'Não informado'}
      </p>
    </div>
  );
}
