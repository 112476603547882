import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  > button {
    display: flex;

    border: 0;

    background-color: transparent;
  }
`;

export const OptionsMenu = styled.div`
  ${({ theme }) => css`
    min-width: 20rem;
    position: absolute;
    margin-top: ${theme.spacings[4]};
    right: 0;

    background-color: ${theme.colors.gray100};

    z-index: 1;

    button {
      width: 100%;
      padding: ${theme.spacings[8]};
      display: flex;
      align-items: center;
      gap: ${theme.spacings[8]};

      white-space: nowrap;

      border: 0;

      background-color: transparent;

      transition: all 0.2s;

      &:hover {
        background-color: ${theme.colors.gray200};
      }
    }
  `}
`;
