import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const BackgroundBlur = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;

    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${transparentize(0.3, theme.colors.black)};

    z-index: 10;
  `}
`;
