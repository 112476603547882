import React, { memo, useEffect, useState } from 'react';
import * as Styled from './style';
import { objectGraph } from '../../../../../shared/models/ModalInterface';
import WrapperGraph from '../../WrapperGraph';

import { IValuesForFilterType } from '../../../../../shared/models/ValuesForFilter';

type ColumnFavoriteGraphType = {
  isClickedTopMenu: 'dashboard' | 'favoriteGraph' | 'ocultGraph';
  setSelectedOptionChart: React.Dispatch<React.SetStateAction<string>>;
  setFilterValue: React.Dispatch<React.SetStateAction<IValuesForFilterType>>;
};

function ColumnFavoriteGraph({
  isClickedTopMenu,
  setSelectedOptionChart,
  setFilterValue,
}: ColumnFavoriteGraphType) {
  const [favoriteGraphics, setFavoriteGraphics] = useState<objectGraph[]>(
    {} as objectGraph[],
  );

  const [updateInFavoriteGraphics, setUpdateInFavoriteGraphics] =
    useState(false);

  useEffect(() => {
    setFilterValue({});
    const getItemStorage = JSON.parse(
      localStorage.getItem('favoriteGraphics') || '{}',
    );

    if (Object.keys(getItemStorage).length > 0) {
      const object = getItemStorage.favoriteGraphics as objectGraph[];
      setFavoriteGraphics(object);
    } else {
      setFavoriteGraphics([]);
    }
  }, []);

  useEffect(() => {
    if (updateInFavoriteGraphics) {
      const getItemStorage = JSON.parse(
        localStorage.getItem('favoriteGraphics') || '{}',
      );

      if (Object.values(getItemStorage).length > 0) {
        const object = getItemStorage.favoriteGraphics as objectGraph[];
        setFavoriteGraphics(object);
      } else {
        setFavoriteGraphics([]);
      }
      setUpdateInFavoriteGraphics(false);
    }
  }, [updateInFavoriteGraphics]);

  return (
    <Styled.Wrapper>
      {Object.values(favoriteGraphics).length === 0 && (
        <div className="noExistsChart">
          <p>Não existem gráficos favoritos</p>
        </div>
      )}
      {Object.values(favoriteGraphics).length > 0 &&
        favoriteGraphics.map(graph => (
          <div key={`${Date.now()}${graph.titleGraph}`} id={graph.id}>
            <WrapperGraph
              chartModel={graph.chartModel}
              titleGraph={graph.titleGraph}
              isClickedTopMenu={isClickedTopMenu}
              setUpdateGraphics={setUpdateInFavoriteGraphics}
              data={graph.data}
              fontUsedLink={graph.fontUsedLink}
              setSelectedOptionChart={setSelectedOptionChart}
              id={graph.id}
            />
          </div>
        ))}
    </Styled.Wrapper>
  );
}

export default memo(ColumnFavoriteGraph);
