import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NavigationButton } from 'components';
import { Filter, NavegationDashboard } from './components';

import { Wrapper, FilterContainer } from './styles';

import pages from './mock';
import { GraphicDataRequest } from './@types/graphic';
import { CardDataRequest } from './@types/cards';
import { getAllGraphics } from 'services/graphicsRequest';
import { DashboardRequestData } from './@types/dashboard';
import { useOptions } from 'hooks/useOptions';
import { MountObjectFilter } from 'contexts/@types/Filter';
import { useFilter } from 'hooks/useFilter';

export function Dashboard() {
  const location = useLocation();

  const { i18n } = useTranslation();
  const { update } = useOptions();
  const { filterData, clearFilterData, mountObjectFilter } = useFilter();

  const [loading, setLoading] = useState(false);

  const [graphicsData, setGraphicsData] = useState<GraphicDataRequest[]>([]);
  const [cardsData, setCardsData] = useState<CardDataRequest[]>([]);

  function removeHiddenCards(data: CardDataRequest[]) {
    const hiddenCards: CardDataRequest[] = JSON.parse(localStorage.getItem('hiddenCards') || '[]');
    if (hiddenCards.length > 0) {
      let newCardsData = data;
      hiddenCards.forEach(hiddenCard => {
        newCardsData = newCardsData.filter(card => card.title !== hiddenCard.title);
      });
      setCardsData(newCardsData);
    } else {
      setCardsData(data);
    }
  }

  function removeHiddenGraphics(data: GraphicDataRequest[]) {
    const hiddenGraphics: GraphicDataRequest[] = JSON.parse(localStorage.getItem('hiddenGraphics') || '[]');
    if (hiddenGraphics.length > 0) {
      let newGraphicsData = data;
      hiddenGraphics.forEach(hiddenGraphic => {
        newGraphicsData = newGraphicsData.filter(graphic => graphic.title !== hiddenGraphic.title);
      });
      setGraphicsData(newGraphicsData);
    } else {
      setGraphicsData(data);
    }
  }

  async function getAllGraphicsData(filter: MountObjectFilter | null) {
    setLoading(true);

    const { data } = await getAllGraphics<DashboardRequestData>(filter === null ? '' : filter, i18n.language);
    removeHiddenGraphics(data.graphics);
    removeHiddenCards(data.cards);
    setLoading(false);
  }

  const handleApplyFilter = async () => {
    const filterMount = mountObjectFilter();
    console.log('filterData', filterData);

    await getAllGraphicsData(filterMount);
  };

  useEffect(() => {
    getAllGraphicsData(null);
    clearFilterData();
  }, []);

  useEffect(() => {
    removeHiddenCards(cardsData);
    removeHiddenGraphics(graphicsData);
  }, [update]);

  return (
    <Wrapper>
      <FilterContainer>
        <Filter applyFilter={handleApplyFilter} />
      </FilterContainer>
      <main className="mainDashboard col-md-12 col-sm-1">
        <NavegationDashboard />
        {pages.map(page => {
          if (location.pathname === page.path) {
            const Element = page.element;

            return (
              <Element
                key={`${page.path}-${page.id}`}
                cardsData={cardsData}
                graphicsData={graphicsData}
                loadingGraphics={loading}
              />
            );
          }
        })}
      </main>
      <NavigationButton isDashboard />
    </Wrapper>
  );
}
