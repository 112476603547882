import React, { memo } from 'react';
import * as Styled from './style';

type FloatingInformationForUserProps = {
  selectedOptionChart: string;
};

export function FloatingInformationForUser({
  selectedOptionChart,
}: FloatingInformationForUserProps) {
  return (
    <Styled.Wrapper>
      {selectedOptionChart === 'favorite' &&
        'O Gráfico foi adicionado aos favoritos'}
      {selectedOptionChart === 'ocult' && 'O Gráfico foi ocultado com sucesso.'}
      {selectedOptionChart === 'removedFavoriteGraph' &&
        'O Gráfico foi removido de favoritos'}
      {selectedOptionChart === 'removedOcultGraph' &&
        'O Gráfico foi removido dos ocultos'}
    </Styled.Wrapper>
  );
}

export default memo(FloatingInformationForUser);
