import React from 'react';

interface IProperties {
  properties: {
    ds_process: string;
    area_ha: number;
  };
}

export function ServidoesMinerarias({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Servidões Minerárias</strong>
      </h4>
      <p>
        <strong>Processo: </strong>{' '}
        {properties.ds_process ? properties.ds_process : 'Não informado'}
      </p>
      <p>
        <strong>Área em hectares: </strong>{' '}
        {properties.area_ha
          ? properties.area_ha.toLocaleString()
          : 'Não informado'}
      </p>
    </div>
  );
}
