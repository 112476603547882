import styled, { css } from 'styled-components';

type WrapperType = {
  openFilter: boolean;
};

export const Wrapper = styled.aside<WrapperType>`
  ${({ theme, openFilter }) => css`
    width: 35rem;
    background-color: ${theme.colors.gray50};
    height: calc(100vh - 135px);
    animation-name: ${openFilter ? 'open' : 'close'};
    animation-duration: 1s;
    position: fixed;
    bottom: 0%;
    left: 0%;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    transform: translateX(-27rem);
    z-index: 6;

    @keyframes open {
      0% {
        transform: translateX(-27rem);
      }
      100% {
        transform: translateX(0%);
      }
    }
    @keyframes close {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-27rem);
      }
    }
  `}
`;

type ToolTipSideBarType = {
  openFilter: boolean;
};

export const ToolTipSideBar = styled.div<ToolTipSideBarType>`
  ${({ theme, openFilter }) => css`
    padding: ${theme.spacings[4]} ${theme.spacings[12]};
    position: fixed;
    top: 147px;
    left: 375px;
    border: ${theme.spacings[4]} solid ${theme.colors.black};
    background-color: ${theme.colors.blue600};
    color: ${theme.colors.white};
    border-radius: ${theme.spacings[6]};
    z-index: 12;
    animation-name: ${openFilter ? 'newOpacity1' : 'opacity2'};
    animation-duration: ${openFilter ? '1s' : '0.5s'};
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: ${openFilter ? '0ms' : '500ms'};
    animation-fill-mode: forwards;
    transform: ${openFilter ? '' : 'translateX(-27rem)'};
    opacity: ${openFilter ? '0' : '0'};

    @keyframes opacity2 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes newOpacity1 {
      100% {
        opacity: 1;
      }
    }

    .wrapperShape {
      width: 0.1px;
      height: 0.1px;
      position: relative;
    }

    .shape {
      width: 2rem;
      height: 2rem;
      clip-path: polygon(51% 50%, 0% 100%, 100% 100%);
      background-color: ${theme.colors.black};
      position: absolute;
      left: -36px;
      transform: rotateZ(-90deg);
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 6rem;
    border-bottom: 1px solid ${theme.colors.black};
  `}
`;

type FilterType = {
  openFilter: boolean;
};

export const Filter = styled.div<FilterType>`
  ${({ theme, openFilter }) => css`
    width: 100%;
    height: 77.91%;
    overflow-y: auto;
    animation-name: ${openFilter ? 'removeOpacity' : 'opacity'};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    opacity: 0;

    .marginTopForFirstChildren {
      margin-top: ${theme.spacings[20]};
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.colors.gray50};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.blue700};
    }
  `};
`;

type CentralizedComponentBottomType = {
  isBrazil?: boolean;
};

export const CentralizedComponentBottom = styled.div<CentralizedComponentBottomType>`
  ${({ isBrazil }) => css`
    width: 100%;
    height: max-content;
    animation-name: ${isBrazil !== undefined && !isBrazil
      ? 'opacityAndTransation'
      : isBrazil !== undefined && isBrazil && 'removeOpacityAndTrasation'};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    opacity: ${isBrazil !== undefined && !isBrazil && '0'};
    transform: ${isBrazil !== undefined && !isBrazil && 'translateX(-200%)'};

    @keyframes opacityAndTransation {
      0% {
        transform: translateX(0%);
        opacity: 1;
      }
      100% {
        transform: translateX(-200%);
        opacity: 0;
      }
    }

    @keyframes removeOpacityAndTrasation {
      0% {
        transform: translateX(-200%);
        opacity: 0;
      }
      100% {
        transform: translateX(0%);
        opacity: 1;
      }
    }
  `}
`;

type WrapperButtonType = {
  openFilter: boolean;
};

export const WrapperButton = styled.div<WrapperButtonType>`
  ${({ theme, openFilter }) => css`
    width: 100%;
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    animation-name: ${openFilter ? 'removeOpacity' : 'opacity'};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    opacity: 0;

    .clean {
      border: none;
      padding: ${theme.spacings[12]} ${theme.spacings[20]};
      border: 2px solid ${theme.colors.blue600};
      color: ${theme.colors.blue600};
      font-family: ${theme.font.family.default};
      background-color: ${theme.colors.white};
      border-radius: 5px;
      transition: 0.5s;
      font-weight: ${theme.font.weights.semiBold};

      &:hover {
        background-color: ${theme.colors.blue600};
        color: ${theme.colors.white};
      }
    }

    .apply {
      padding: ${theme.spacings[12]} ${theme.spacings[20]};
      font-weight: ${theme.font.weights.semiBold};
      background-color: ${theme.colors.blue600};
      color: ${theme.colors.white};
      border-radius: 5px;

      &:hover {
        background-color: ${theme.colors.gray50};
        color: ${theme.colors.blue600};
        border: 2px solid ${theme.colors.blue600};
      }
    }
  `}
`;
