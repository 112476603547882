import React, { InputHTMLAttributes } from 'react';

import * as Styled from './styles';

interface IInputSlider extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  max: number;
  min: number;
  value: number;
}

export function InputSlider({ label, max, min, value, ...props }: IInputSlider) {
  const offset = -10 - 24 * value;

  return (
    <Styled.Wrapper percentage={((value - min) * 100) / (max - min)} offset={offset}>
      {label && <label htmlFor={props.id}>{label}</label>}
      <input type="range" max={max} min={min} value={value} {...props} />
    </Styled.Wrapper>
  );
}
