import React, { ButtonHTMLAttributes } from 'react';

import * as Styled from './styles';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean;
  children: React.ReactNode;
}

export function Button({ children, outline, ...props }: IButton) {
  return (
    <Styled.Button outline={outline} {...props}>
      {children}
    </Styled.Button>
  );
}
