import styled, { css } from 'styled-components';

type WrapperType = {
  animation: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ theme, animation }) => css`
    .error {
      display: flex;
      justify-content: space-between;
      width: 50rem;
      position: fixed;
      top: 18%;
      right: 5%;
      background-color: ${theme.colors.error};
      border-radius: ${theme.spacings[6]};
      color: ${theme.colors.white};
      padding: ${theme.spacings[10]};
      animation-name: ${animation ? 'openError' : 'closeError'};
      animation-duration: 1s;
      animation-delay: 0ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      transform: translateX(-170%);
      z-index: 3;
      text-align: right;

      @keyframes openError {
        0% {
          transform: translateX(170%);
        }
        100% {
          transform: translateX(0%);
        }
      }
      @keyframes closeError {
        0% {
          transform: translateX(-170%);
        }
        100% {
          transform: translateX(0%);
        }
      }

      @media (max-width: 650px) {
        width: 30rem;
        top: 35%;
        font-size: ${theme.font.sizes.xxs};
      }

      @media (max-width: 400px) {
        width: 25rem;
        font-size: ${theme.font.sizes.xxs};
      }

      @media (max-width: 330px) {
        width: 22rem;
        font-size: ${theme.font.sizes.xxs};
      }
    }
  `}
`;
