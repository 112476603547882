import React, { useEffect, useState } from 'react';

import { Graph } from 'screens/NewDashboard/components';
import { GraphicDataRequest } from 'screens/NewDashboard/@types/graphic';

import { CardDataRequest } from 'screens/NewDashboard/@types/cards';
import { CardGraph } from 'screens/NewDashboard/components/Card';
import { useOptions } from 'hooks/useOptions';
import { MainContainer } from '../styles';

export function FavoriteGraphics() {
  const [graphics, setGraphics] = useState(Array<GraphicDataRequest>);
  const [cards, setCards] = useState(Array<CardDataRequest>);

  const { update } = useOptions();

  useEffect(() => {
    const dataGraphics: GraphicDataRequest[] = JSON.parse(localStorage.getItem('favoriteGraphics') || '[]');

    const dataCards: CardDataRequest[] = JSON.parse(localStorage.getItem('favoriteCards') || '[]');
    setGraphics(dataGraphics);
    return setCards(dataCards);
  }, [update]);

  return (
    <MainContainer>
      {graphics.length > 0 || cards.length > 0 ? (
        <>
          <div className="gridCards">
            {cards.map(card => (
              <CardGraph key={card.title} data={card} indicatorColor={card.indicator.color} />
            ))}
          </div>
          <div className="gridGraphics">
            {graphics.map(graphic => (
              <Graph
                key={graphic.title}
                data={graphic}
                indicatorColor={graphic.indicator.color}
                hasBigGridHorizontal
                hasOptions
              />
            ))}
          </div>
        </>
      ) : (
        <div className="noContent">
          <p>Não existem gráficos favoritos</p>
        </div>
      )}
    </MainContainer>
  );
}
