import React from 'react';

interface IProperties {
  properties: {
    num_lab: string;
    local: string;
    uf: string;
    bacia: string;
    Tipo: string;
  };
}

export function GeoquimicaBaixaDens({ properties }: IProperties) {
  return (
    <div className="information">
      <h4>
        <strong>Geoquímica de Baixa Densidade</strong>
      </h4>
      <p>
        <strong>Num_Lab: </strong>
        {properties.num_lab ? properties.num_lab : 'Não informado'}
      </p>
      <p>
        <strong>Local: </strong>
        {properties.local ? properties.local : 'Não informado'}
      </p>
      <p>
        <strong>Unidade Federativa: </strong>
        {properties.uf ? properties.uf : 'Não informado'}
      </p>
      <p>
        <strong>Bacia: </strong>
        {properties.bacia ? properties.bacia : 'Não informado'}
      </p>
      <p>
        <strong>Tipo: </strong>
        {properties.Tipo ? properties.Tipo : 'Não informado'}
      </p>
    </div>
  );
}
