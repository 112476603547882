import React, { memo, useEffect, useMemo, useState } from 'react';
import { fromLonLat } from 'ol/proj';
import { XYZ } from 'ol/source';
import { MapProvider } from 'contexts/MapContext';
import { Loading, SearchInput } from 'components';
import { useDebounce } from 'hooks/useDebounce';
import nominationAPI from 'services/api/nominatimAPI';
import baseMaps from './baseMaps';
import { MapComponent } from './components/MapComponent';
import { MapLayer } from './components/Layers/MapLayer';
import { SideBar } from './components/SideBar';
import { ModalLayerOptions } from './components/ModalLayerOptions';
import * as Styled from './styles';
import { MapModal } from './components/MapModal';
import { useFilter } from 'hooks/useFilter';
import maps from './components/MapModal/maps';

interface IOptions {
  address: {
    city: string;
    state: string;
    country: string;
    town: string;
    municipality: string;
  };
  lat: string;
  lon: string;
}

const MapComponentMemoized = memo(MapComponent);
const MapLayerMemoized = memo(MapLayer);

function getZoom() {
  if (window.innerWidth <= 500) {
    return 3;
  }
  if (window.innerWidth <= 800) {
    return 3.5;
  }
  return 4.5;
}

function getCenter() {
  if (window.innerWidth <= 500) {
    return [-63, -35];
  }
  if (window.innerWidth <= 800) {
    return [-65, -20];
  }
  return [-58, -16];
}

export function Map() {
  const [isSearchActived, setIsSearchActived] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchOptions, setSearchOptions] = useState<IOptions[]>([]);

  const [baseMapCounter, setBaseMapCounter] = useState<number>(0);
  const [isBaseMapModalOpen, setIsBaseMapModalOpen] = useState<boolean>(false);

  const [center, setCenter] = useState<number[]>(getCenter());
  const [zoom, setZoom] = useState<number>(getZoom());

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { clearFilterData } = useFilter();

  const centerMemo = useMemo(() => fromLonLat(center), [center]);
  const sourceMemo = useMemo(() => new XYZ(baseMaps[baseMapCounter]), [baseMapCounter]);

  const handleChangeSearchInput = (value: string) => {
    setSearchValue(value);
  };

  const handleEnterSubmit = async (key: string) => {
    if (key === 'Enter') {
      setCenter([Number(searchOptions[0].lon), Number(searchOptions[0].lat)]);
      setZoom(10);
    }
  };

  const handleOnSelectedOption = (option: IOptions) => {
    setCenter([Number(option.lon), Number(option.lat)]);
    setZoom(10);
  };

  function verifySearchResults(data: IOptions, query: string) {
    return (
      clearText(data.address.city)?.includes(clearText(query)) ||
      clearText(data.address.town)?.includes(clearText(query)) ||
      clearText(data.address.municipality)?.includes(clearText(query)) ||
      clearText(data.address.state)?.includes(clearText(query)) ||
      clearText(data.address.country)?.includes(clearText(query))
    );
  }

  function clearText(text: string) {
    return text
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  useEffect(() => {
    (async () => {
      if (debouncedSearchValue.startsWith('-')) {
        const terms = debouncedSearchValue.split(',');
        if (Number(terms[0]) < 0 && Number(terms[1]) < 7) {
          if (terms[0] && terms[0].trim().length > 0 && terms[1] && terms[1].trim().length > 0) {
            const { data } = await nominationAPI.get(
              `/reverse?lat=${Number(terms[1])}&lon=${Number(terms[0])}&format=json&format=json`,
            );
            if (data.error != 'Unable to geocode') {
              const point = [
                {
                  address: {
                    city: data.address.city ? data.address.city : data.address.city_district,
                    state: data.address.state,
                    country: data.address.country,
                    town: data.address.town,
                    municipality: data.address.municipality,
                  },
                  lat: terms[1],
                  lon: terms[0],
                },
              ];
              setSearchOptions(point);
            }
          }
        }
      } else {
        if (!debouncedSearchValue || debouncedSearchValue.trim().length === 0) return;
        const { data } = await nominationAPI.get(`/search?&addressdetails=1&q=${debouncedSearchValue}&format=json`);
        const dataFilter = data.filter(
          (item: IOptions) => item.lat && item.lon && verifySearchResults(item, debouncedSearchValue),
        );
        setSearchOptions(dataFilter.slice(0, 5));
      }
    })();
  }, [debouncedSearchValue]);

  useEffect(() => {
    clearFilterData();
  }, []);

  return (
    <MapProvider>
      {isLoading && <Loading />}
      <Styled.Wrapper>
        <SideBar
          handleSearchInputActive={() => setIsSearchActived(!isSearchActived)}
          onLoadingCompleted={() => setIsLoading(false)}
          onBaseMapModalOpen={() => setIsBaseMapModalOpen(!isBaseMapModalOpen)}
        />
        {isSearchActived && (
          <SearchInput
            options={searchOptions}
            value={searchValue}
            onChange={({ target }) => handleChangeSearchInput(target.value)}
            onKeyDown={({ key }) => handleEnterSubmit(key)}
            onSelected={option => handleOnSelectedOption(option)}
            autoComplete="off"
          />
        )}
        <MapComponentMemoized center={centerMemo} zoom={zoom}>
          <MapLayerMemoized source={sourceMemo} zIndex={0} />
        </MapComponentMemoized>
        {isBaseMapModalOpen && (
          <MapModal setIsOpen={setIsBaseMapModalOpen} onChangeBaseMap={index => setBaseMapCounter(index)} />
        )}
      </Styled.Wrapper>

      <ModalLayerOptions />

      <Styled.MapDisclaimer>
        <span>
          Mapa:
          <a href={maps[baseMapCounter].href} target="_blank" rel="noreferrer">
            {maps[baseMapCounter].organization}
          </a>
        </span>
      </Styled.MapDisclaimer>
    </MapProvider>
  );
}
