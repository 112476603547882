import React from 'react';
import { Routes, Route, RouteProps, Navigate } from 'react-router-dom';
import { Map, Dashboard } from 'screens';
import Dash from 'screens/Dashboard';

// interface IRoutes extends RouteProps
export type IRoutes = RouteProps & {
  routeName: string;
  isPrivate?: boolean;
};

//TODO Remover outras rotas
const routesList: IRoutes[] = [
  {
    routeName: 'Inicio',
    path: '/',
    element: <Map />,
  },
  {
    routeName: 'Dashboard',
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    routeName: 'Gráficos Favoritos',
    path: '/favorites',
    element: <Dashboard />,
  },
  {
    routeName: 'Gráficos Ocultos',
    path: '/hidden',
    element: <Dashboard />,
  },
  {
    routeName: 'Dashboard',
    path: '/old',
    element: <Dash />,
  },
  {
    routeName: 'Mapa',
    path: '/map',
    element: <Map />,
  },
  {
    routeName: 'Default',
    path: '*',
    element: <Navigate to="/" />,
  },
];

export function Router() {
  return (
    <Routes>
      {routesList.map(
        ({
          routeName,

          ...rest
        }) => (
          <Route key={routeName} {...rest} />
        ),
      )}
    </Routes>
  );
}
