import axios from 'axios';
import { errorInterceptor } from './interceptors/ErrorInterceptor';
import { responseInterceptor } from './interceptors/ResposeInterceptor';
import { Environment } from '../../environments';

const JsonApi = axios.create({
  baseURL: Environment.API_URL_BASE,
  timeout: 5000,
});

JsonApi.interceptors.response.use(
  response => responseInterceptor(response),
  error => errorInterceptor(error),
);

export { JsonApi };
